import React, {ChangeEvent, useMemo} from "react";

import classes from "./AvatarEmotionSelect.module.css";
import {useTranslation} from "react-i18next";
import WithTooltip from "../../../ui/tooltip";
import {AvatarEmotion} from "../../../schema";

type props = {
  name?: string,
  value?: string
  onChange?: (e: ChangeEvent) => void
}

export default function AvatarEmotionSelect({name, value, onChange}: props) {
  const {t} = useTranslation();

  const choices = useMemo(() => ([
    {
      value: AvatarEmotion.IDLE,
      label: t("types.AvatarEmotion.IDLE"),
      emoji: "😐"
    },
    {
      value: AvatarEmotion.SURPRISED,
      label: t("types.AvatarEmotion.SURPRISED"),
      emoji: "😮"
    },
    {
      value: AvatarEmotion.ANGRY,
      label: t("types.AvatarEmotion.ANGRY"),
      emoji: "😡"
    },
    {
      value: AvatarEmotion.DISAPPOINTED,
      label: t("types.AvatarEmotion.DISAPPOINTED"),
      emoji: "😔",
    },
    {
      value: AvatarEmotion.SMILING,
      label: t("types.AvatarEmotion.SMILING"),
      emoji: "🙂",
    },
  ]), [t]);

  return (
    <div className={classes.root}>
      {choices.map(choice => (
        <WithTooltip key={choice.value} as="label" className={classes.itemWrapper} helpText={choice.label}>
          <input
            className={classes.input}
            type="radio"
            name={name}
            checked={choice.value === value}
            value={choice.value}
            onChange={onChange}
          />
          <div className={classes.item}>{choice.emoji}</div>
        </WithTooltip>
      ))}
    </div>
  )
}
