import {PlayerReduxState} from "../types";
import isResultStage from "./isResultStage";

export default function isIntroStage(state: PlayerReduxState): boolean {
  if (isResultStage(state)) {
    return false;
  }

  return state.index === -1;
}
