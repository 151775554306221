/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {StoreHelpers} from "react-joyride";
import ReactTour, {TourStep} from "../../components/ReactTour";
import {avatarsQueryData, queryData} from "./lesson-edit.graphql";
import {
  createDummyAvatarAction,
  createDummyChoiceUserInputAction,
  createDummyChoiceUserInputBranch,
  createDummyLesson,
  createDummyRandomAvatarAction,
  createDummyRandomAvatarBranch,
  createDummyScenario,
  createDummySystemAction,
  createDummyUserInputAction
} from "../../dummies";
import {getLanguageCode} from "../../i18n";
import {useTour} from "../../providers/tour";
import {Action, ChoiceUserInputBranchState} from "../../schema";
import {
  getTargetsClassNamesWithPrefix,
  targetClassNamesToSelectors,
  TourClassesMock
} from "../../tours";
import EditableItemName from "../../components/editor/EditableItemName";

export const NAME = "editorLessonEdit";

/// Targets
type TargetNames = [
  "lessonName", "scenarios", "avatarAction", "timeline", "systemAction", "userInputAction", "choiceUserInputAction",
  "randomAvatarAction", "addActionRow", "lessonCustomizeForm", "avatarSelect", "videoCallMode", "lessonType",
  "language", "recognitionEngine", "recognitionAccuracy", "badWordsFilter", "triggerDicts", "generateVideo",
  "detailedResults", "evaluateScenarios", "inviteByLink", "characterErrors", "muteVoices", "showPreviousPhrase",
  "background", "topRightMenu", "participants", "scorm", "shareButton", "preview", "lessonInput"
]
type TargetName = TargetNames[number]
const targetNames: TargetNames = [
  "lessonName", "scenarios", "avatarAction", "timeline", "systemAction", "userInputAction", "choiceUserInputAction",
  "randomAvatarAction", "addActionRow", "lessonCustomizeForm", "avatarSelect", "videoCallMode", "lessonType",
  "language", "recognitionEngine", "recognitionAccuracy", "badWordsFilter", "triggerDicts", "generateVideo",
  "detailedResults", "evaluateScenarios", "inviteByLink", "characterErrors", "muteVoices", "showPreviousPhrase",
  "background", "topRightMenu", "participants", "scorm", "shareButton", "preview", "lessonInput"
]

export type Targets = Record<TargetName, TourStep["target"]>

export const classes = getTargetsClassNamesWithPrefix(targetNames, "tour_editor_lessonEdit_");

/// Types
type MockByAction = TourClassesMock<"item">

type MockDynamicData = {
  lessonName?: string
}

export type EditorLessonEditTourMock = {
  tour: "editorLessonEdit"
  mockBy: {
    action: Map<Action["id"], MockByAction>
  }

  nameEditSave: React.ComponentProps<typeof EditableItemName>["onSave"]
  data: MockDynamicData
}

export function isEditorLessonEditTourMock(mock: any): mock is EditorLessonEditTourMock {
  return mock?.tour === NAME;
}

/// Component
type props = {
  loading: boolean,
  targets?: Partial<Targets>,
  returnToPreviousPage?: boolean
}

export default function EditorLessonEditTour({loading, targets: componentTargets, returnToPreviousPage}: props) {
  const history = useHistory();

  const {t} = useTranslation("tours");
  const {end: endTour, setMock} = useTour();

  const onTourEnd = useCallback(() => {
    if (returnToPreviousPage) {
      history.goBack();
      return true;
    }
    return endTour(NAME);
  }, [returnToPreviousPage, history, endTour])

  useEffect(() => () => {
    endTour(NAME);
  }, [endTour])

  const [helpers, setHelpers] = useState<StoreHelpers>();

  const [data, setData] = useState<EditorLessonEditTourMock["data"]>({})

  const targets = useMemo(() => ({
    ...targetClassNamesToSelectors(classes),
    ...componentTargets
  }), [componentTargets])

  const actionMocks = useMemo<Map<Action["id"], MockByAction>>(() => {
    const actions = ids.lesson.scenarios[0].actions
    return new Map([
      [actions[0].id, {
        classes: {
          item: classes.avatarAction,
        }
      }],
      [actions[1].id, {
        classes: {
          item: classes.systemAction,
        }
      }],
      [actions[2].id, {
        classes: {
          item: classes.userInputAction,
        }
      }],
      [actions[4].id, {
        classes: {
          item: classes.choiceUserInputAction,
        }
      }],
      [actions[5].id, {
        classes: {
          item: classes.randomAvatarAction,
        }
      }]
    ])
  }, [])

  const mock = useMemo<Omit<EditorLessonEditTourMock, "tour" | "mockBy" | "data">>(() => ({
    nameEditSave: ({name}) => {
      setData(prevData => ({...prevData, lessonName: name}))
      setTimeout(() => helpers?.next())
    }
  }), [helpers])

  useEffect(() => {
    setMock({
      ...mock,
      data,
      tour: NAME,
      mockBy: {
        action: actionMocks
      }
    })
    return () => setMock(undefined);
  }, [mock, data, actionMocks, setMock])

  const steps = useMemo<TourStep[]>(() => ([
    {
      title: t("editorLessonEdit.start.title"),
      content: t("editorLessonEdit.start.content"),
      target: "body",
      placement: "center",
    },
    {
      title: t("editorLessonEdit.lessonName.title"),
      content: t("editorLessonEdit.lessonName.content"),
      target: targets.lessonName,
      placement: "bottom",
      disableNextButton: true,
      spotlightClicks: true
    },
    {
      title: t("editorLessonEdit.lessonNameUpdated.title"),
      content: t("editorLessonEdit.lessonNameUpdated.content"),
      target: targets.lessonName,
      placement: "bottom",
    },
    {
      title: t("editorLessonEdit.scenarios.title"),
      content: t("editorLessonEdit.scenarios.content"),
      target: targets.scenarios,
      placement: "bottom",
    },
    // Actions
    {
      title: t("editorLessonEdit.timeline.title"),
      content: t("editorLessonEdit.timeline.content"),
      target: targets.timeline,
      placement: "right",
    },
    {
      title: t("editorLessonEdit.avatarAction.title"),
      content: t("editorLessonEdit.avatarAction.content"),
      target: targets.avatarAction,
      placement: "right",
    },
    {
      title: t("editorLessonEdit.systemAction.title"),
      content: t("editorLessonEdit.systemAction.content"),
      target: targets.systemAction,
      placement: "right",
    },
    {
      title: t("editorLessonEdit.userInputAction.title"),
      content: t("editorLessonEdit.userInputAction.content"),
      target: targets.userInputAction,
      placement: "right",
    },
    {
      title: t("editorLessonEdit.choiceUserInputAction.title"),
      content: t("editorLessonEdit.choiceUserInputAction.content"),
      target: targets.choiceUserInputAction,
      placement: "right",
    },
    {
      title: t("editorLessonEdit.randomAvatarAction.title"),
      content: t("editorLessonEdit.randomAvatarAction.content"),
      target: targets.randomAvatarAction,
      placement: "right",
    },
    {
      title: t("editorLessonEdit.addActionRow.title"),
      content: t("editorLessonEdit.addActionRow.content"),
      target: targets.addActionRow,
      placement: "right",
    },
    // TODO: Let user add action and edit. Same for each one
    // Lesson preferences
    {
      title: t("editorLessonEdit.lessonCustomizeForm.title"),
      content: t("editorLessonEdit.lessonCustomizeForm.content"),
      target: targets.lessonCustomizeForm,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.avatarSelect.title"),
      content: t("editorLessonEdit.avatarSelect.content"),
      target: targets.avatarSelect,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.videoCallMode.title"),
      content: t("editorLessonEdit.videoCallMode.content"),
      target: targets.videoCallMode,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.lessonType.title"),
      content: t("editorLessonEdit.lessonType.content"),
      target: targets.lessonType,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.lessonInput.title"),
      content: t("editorLessonEdit.lessonInput.content"),
      target: targets.lessonInput,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.language.title"),
      content: t("editorLessonEdit.language.content"),
      target: targets.language,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.recognitionEngine.title"),
      content: t("editorLessonEdit.recognitionEngine.content"),
      target: targets.recognitionEngine,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.recognitionAccuracy.title"),
      content: t("editorLessonEdit.recognitionAccuracy.content"),
      target: targets.recognitionAccuracy,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.badWordsFilter.title"),
      content: t("editorLessonEdit.badWordsFilter.content"),
      target: targets.badWordsFilter,
      placement: "left",
    },

    {
      title: t("editorLessonEdit.triggerDicts.title"),
      content: t("editorLessonEdit.triggerDicts.content"),
      target: targets.triggerDicts,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.generateVideo.title"),
      content: t("editorLessonEdit.generateVideo.content"),
      target: targets.generateVideo,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.detailedResults.title"),
      content: t("editorLessonEdit.detailedResults.content"),
      target: targets.detailedResults,
      placement: "left",
    },

    {
      title: t("editorLessonEdit.evaluateScenarios.title"),
      content: t("editorLessonEdit.evaluateScenarios.content"),
      target: targets.evaluateScenarios,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.inviteByLink.title"),
      content: t("editorLessonEdit.inviteByLink.content"),
      target: targets.inviteByLink,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.characterErrors.title"),
      content: t("editorLessonEdit.characterErrors.content"),
      target: targets.characterErrors,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.muteVoices.title"),
      content: t("editorLessonEdit.muteVoices.content"),
      target: targets.muteVoices,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.showPreviousPhrase.title"),
      content: t("editorLessonEdit.showPreviousPhrase.content"),
      target: targets.showPreviousPhrase,
      placement: "left",
    },
    {
      title: t("editorLessonEdit.background.title"),
      content: t("editorLessonEdit.background.content"),
      target: targets.background,
      placement: "left",
    },
    // Top right menu
    {
      title: t("editorLessonEdit.topRightMenu.title"),
      content: t("editorLessonEdit.topRightMenu.content"),
      target: targets.topRightMenu,
      placement: "bottom",
    },
    {
      title: t("editorLessonEdit.participants.title"),
      content: t("editorLessonEdit.participants.content"),
      target: targets.participants,
      placement: "bottom",
    },
    {
      title: t("editorLessonEdit.scorm.title"),
      content: t("editorLessonEdit.scorm.content"),
      target: targets.scorm,
      placement: "bottom",
    },
    {
      title: t("editorLessonEdit.shareButton.title"),
      content: t("editorLessonEdit.shareButton.content"),
      target: targets.shareButton,
      placement: "bottom",
    },
    {
      title: t("editorLessonEdit.preview.title"),
      content: t("editorLessonEdit.preview.content"),
      target: targets.preview,
      placement: "bottom",
      // spotlightClicks: true
    }
  ]), [t, targets])

  return (
    <ReactTour
      continuous
      run={!loading}
      steps={steps}
      onTourEnd={onTourEnd}
      getHelpers={setHelpers}
      scrollToFirstStep
      disableOverlayClose
      disableCloseOnEsc
      showSkipButton
      hideCloseButton
    />
  );
}

/// IDs of mock data to refer
const ids = {
  lesson: {
    id: "editorLessonEditTour_Lesson_1",
    scenarios: [
      {
        id: "editorLessonEditTour_Scenario_1",
        actions: [
          {id: "editorLessonEditTour_AvatarAction_1"},
          {id: "editorLessonEditTour_SystemAction_1"},
          {id: "editorLessonEditTour_UserInputAction_1"},
          {id: "editorLessonEditTour_AvatarAction_2"},
          {id: "editorLessonEditTour_ChoiceUserInputAction_1"},
          {id: "editorLessonEditTour_RandomAvatarAction_1"}
        ]
      }
    ]
  },
}

/// Mock data to show during tour
export function useMockData(avatars?: avatarsQueryData["avatars"], lessonName?: string): Partial<queryData> {
  const {t, i18n} = useTranslation("tours");
  const languageCode = getLanguageCode(i18n.language);

  const lesson = useMemo(() => (
    createDummyLesson({
      name: lessonName ?? t("editorLessonEdit.mockData.lessons.1.name"),
      id: ids.lesson.id,
      languageCode,
      lastUpdatedAt: Date.UTC(2020, 10, 16, 8, 36)/1000,
      avatar: avatars && avatars[0]
    })
  ), [avatars, languageCode, lessonName, t]);

  const scenarios = useMemo(() => {
    const scenariosActions = ids.lesson.scenarios.map(({actions}) => actions);
    return [
      createDummyScenario({
        isMain: true,
        id: ids.lesson.scenarios[0].id,
        actions: [
          createDummyAvatarAction({
            text: t("editorLessonEdit.mockData.scenario.1.action.1"),
            id: scenariosActions[0][0].id
          }),
          createDummySystemAction({
            text: t("editorLessonEdit.mockData.scenario.1.action.2"),
            id: scenariosActions[0][1].id
          }),
          createDummyUserInputAction({
            expectedText: t("editorLessonEdit.mockData.scenario.1.action.3"),
            id: scenariosActions[0][2].id
          }),
          createDummyAvatarAction({
            text: t("editorLessonEdit.mockData.scenario.1.action.4"),
            id: scenariosActions[0][3].id
          }),
          createDummyChoiceUserInputAction({
            id: scenariosActions[0][4].id,
            branches: [
              createDummyChoiceUserInputBranch({
                state: ChoiceUserInputBranchState.INCORRECT,
                userInput: createDummyUserInputAction({
                  expectedText: t("editorLessonEdit.mockData.scenario.1.action.5.branch.1.user")
                }),
                systemReaction: createDummySystemAction({
                  text: t("editorLessonEdit.mockData.scenario.1.action.5.branch.1.system")
                })
              }),
              createDummyChoiceUserInputBranch({
                state: ChoiceUserInputBranchState.CORRECT,
                userInput: createDummyUserInputAction({
                  expectedText: t("editorLessonEdit.mockData.scenario.1.action.5.branch.2.user")
                }),
                avatarReaction: createDummyAvatarAction({
                  text: t("editorLessonEdit.mockData.scenario.1.action.5.branch.2.avatar")
                })
              })
            ]
          }),
          createDummyRandomAvatarAction({
            id: scenariosActions[0][5].id,
            branches: [
              createDummyRandomAvatarBranch({
                avatarAction: createDummyAvatarAction({
                  text: t("editorLessonEdit.mockData.scenario.1.action.6.branch.1.avatar")
                }),
                targetScenarioId: undefined,
                dropChance: 50,
              }),
              createDummyRandomAvatarBranch({
                avatarAction: createDummyAvatarAction({
                  text: t("editorLessonEdit.mockData.scenario.1.action.6.branch.2.avatar")
                }),
                targetScenarioId: undefined,
                dropChance: 50,
              })
            ]
          })
        ]
      })
    ]
  }, [t])

  return {
    lesson,
    course: undefined,
    scenarios
  };
}
