import React, {useContext, useMemo} from "react";
import {AudioTracksStatusMap, AudioTrackStatus, PlaybackInterface} from "../libs/audio";

export class AudioManager {
  private readonly playbacks: Map<string, PlaybackInterface>;
  readonly tracksStatus: AudioTracksStatusMap;

  constructor() {
    this.playbacks = new Map();
    this.tracksStatus = new Map<string, AudioTrackStatus>()
  }

  installPlayback(name: string, playback: PlaybackInterface, override?: boolean) {
    if (this.playbacks.get(name) && !override) {
      throw new Error(`Cannot install playback "${name}": another playback already installed with same name`)
    }

    this.playbacks.set(name, playback);

    return this.playbacks.get(name)
  }

  uninstallPlayback(name: string): void {
    if (!this.playbacks.has(name)) {
      return;
    }

    this.playbacks.get(name)?.destroy();
    this.playbacks.delete(name);
  }

  getPlayback(name: string): PlaybackInterface | undefined {
    return this.playbacks.get(name);
  }

  overridePlayback(name: string, playback: PlaybackInterface) {
    const prevPlayback = this.playbacks.get(name);

    this.installPlayback(name, playback, true);
    return () => {
      if (!this.playbacks.has(name)) {
        return;
      }

      this.uninstallPlayback(name);

      if (prevPlayback) {
        this.installPlayback(name, prevPlayback)
      }
    }
  }
}

const Context = React.createContext<{
  audioContext: AudioContext,
  audioManager: AudioManager
}>(null!);

export default function AudioProvider({children}: React.PropsWithChildren<{}>) {
  const ctx = useMemo(() => ({
    audioContext: new (window.AudioContext || window.webkitAudioContext)(),
    audioManager: new AudioManager(),
  }), []);

  return (
    <Context.Provider value={ctx}>
      {children}
    </Context.Provider>
  )
}

export function MockAudioProvider({children}: React.PropsWithChildren<{}>) {
  const ctx = useMemo(() => ({
    audioContext: {__ref: "AudioContext"} as any,
    audioManager: new AudioManager(),
  }), []);

  return (
    <Context.Provider value={ctx}>
      {children}
    </Context.Provider>
  )
}

export function useAudio() {
  return useContext(Context);
}
