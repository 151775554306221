import React, {forwardRef, MouseEventHandler, useCallback, useImperativeHandle, useRef} from "react";
import {ListAction} from ".";

import Dropdown, {DropdownContent, DropdownRef, DropdownReference} from "../ui/dropdown";

import cn from "classnames"
import classes from "./dropdownlist.module.css";

export type Props = React.PropsWithChildren<{
  content?: React.ReactNode,
  notCloseOnClick?: boolean,
  adaptive?: boolean,
  rounded?: boolean,
  transparent?: boolean,
  dropShadow?: boolean,
  size?: "s" | "m" | "l",

  onRefClick?: MouseEventHandler<HTMLDivElement>
} & Pick<React.ComponentProps<typeof Dropdown>, "stopCloseOnOuterClick">
  & Partial<Pick<React.ComponentProps<typeof DropdownContent>, "placement" | "className" | "modifiers" | "offset">>>

type DropdownListType = React.ForwardRefExoticComponent<
  Props & React.RefAttributes<DropdownRef>
> & {
  Action: ListAction
}

const DropdownList: DropdownListType = forwardRef<DropdownRef, Props>(function ({
  children, content, notCloseOnClick, adaptive, rounded, transparent, dropShadow, size, className,
  stopCloseOnOuterClick, onRefClick: exOnRefClick, ...props
}, ref) {
  const ctxMenuRef = useRef<DropdownRef>(null);

  useImperativeHandle(ref, () => ctxMenuRef.current!, [])

  const onRefClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
    ctxMenuRef.current?.open();
    exOnRefClick && exOnRefClick(e);
  }, [exOnRefClick]);

  const onContentClick = useCallback(() => {
    if (!notCloseOnClick) {
      ctxMenuRef.current?.close();
    }
  }, [notCloseOnClick])

  return (
    <Dropdown ref={ctxMenuRef} stopCloseOnOuterClick={stopCloseOnOuterClick}>
      <DropdownReference onClick={onRefClick}>
        {children}
      </DropdownReference>
      <DropdownContent
        className={cn(
          classes.content,
          {[classes.adaptive]: adaptive},
          {[classes.rounded]: rounded},
          {[classes.transparent]: transparent},
          {[classes.dropShadow]: dropShadow},
          classes[size ?? "m"],
          className
        )}
        onClick={onContentClick}
        {...props}
      >
        {content}
      </DropdownContent>
    </Dropdown>
  )
}) as DropdownListType;

const Action: ListAction = ({
  className, title, icon: Icon, notCloseOnClick, onClick: onClickAction, as: Component, ...props
}) => {
  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (notCloseOnClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClickAction && onClickAction(e)
  }, [notCloseOnClick, onClickAction])

  return (
    <Component
      className={cn(classes.action, className)}
      onClick={onClick}
      {...props}
    >
      {Icon && <Icon className={classes.icon}/>}
      <span className={classes.text}>{title}</span>
    </Component>
  )
}

Action.defaultProps = {
  as: "button"
}

DropdownList.Action = Action

export default DropdownList;
