import React from "react"
import {getTextFromAction} from "../../redux/player/utils"
import {ListAction} from "../../ui"
import ActionSheet from "../../ui/actionsheet"
import {NavigationListItemProps} from "./NavigationList"

type props = Omit<React.ComponentProps<ListAction>, "title" | "as"> & Pick<NavigationListItemProps, "action">

export default function NavigationListAction({action, ...props}: props) {
  return (
    <ActionSheet.Action
      as="button"
      title={getTextFromAction(action)}
      {...props}
    />
  )
}