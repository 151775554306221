import React from "react";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import classes from "../account/AssignModal.module.css";
import {useTranslation} from "react-i18next";
import {ResultDescription, ResultDescriptionInput} from "../../schema";
import Input from "../../ui/input";

const MIN = 0
const MAX = 100

type contentProps = React.ComponentProps<"div"> & {
  desc?: ResultDescription
  onSave?: (data: ResultDescriptionInput) => void
  onDelete?: () => void
}

function ResultDescriptionModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.AddMembersModal.AddMembersModalTitle")}</span>
}

 function ResultDescriptionModalContent({desc, onSave, onDelete}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    minScore: number,
    maxScore: number,
    message: string,
  }>({
    initialValues: {
      minScore: desc?.minScore ?? 0,
      maxScore: desc?.maxScore ?? 100,
      message: desc?.message ?? "",
    },

    preventDefault: true,

    onSubmit: (data) => {
      onSave && onSave(data);
    }
  });

  const submitDisabled = undefined

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.ResultDescriptionModal.scoreHelpText")}</p>
        <Input
          style={{width: "70px", height: "40px", marginRight: "12px"}}
          required
          type='number'
          name='minScore'
          min={MIN}
          max={MAX}
          value={formState.values.minScore}
          onChange={formState.changeHandler}
        />
        <Input
          style={{width: "70px", height: "40px", marginBottom: "16px"}}
          required
          type='number'
          name='maxScore'
          min={MIN}
          max={MAX}
          value={formState.values.maxScore}
          onChange={formState.changeHandler}
        />
        <p className={classes.helpText}>{t("components.ResultDescriptionModal.messageHelpText")}</p>
        <Textarea
          className={classes.input}
          name="message"
          value={formState.values.message}
          rows={8}
          required
          placeholder={t("components.ResultDescriptionModal.messagePlaceholder")}
          onChange={formState.changeHandler}
        />
      </>

      <div className={classes.bottom}>
        <Button type="submit" color={submitDisabled ? "secondary" : "success"} disabled={submitDisabled}>
          {t("components.ResultDescriptionModal.submit")}
        </Button>
        {desc && (
          <Button type="button" color="danger" onClick={onDelete}>
            {t("components.ResultDescriptionModal.delete")}
          </Button>
        )}
      </div>
    </form>
  )
}

const ResultDescriptionModal = {
  Header: ResultDescriptionModalHeader,
  Content: ResultDescriptionModalContent,
}

export default ResultDescriptionModal;
