import React, {useMemo} from "react";
import cn from "classnames";
import classes from "./languageicon.module.css";

type props = Omit<React.ComponentProps<"img">, "src" | "alt" | "value"> & {
  value: string
}

export default function LanguageIcon({className, value, ...props}: props) {
  const src = useMemo(() => {
    switch (value) {
      case "EN_US":
        return require("../assets/icons/flags/gb.svg").default;
      case "RU_RU":
        return require("../assets/icons/flags/ru.svg").default;
      case "FR_FR":
        return require("../assets/icons/flags/fr.svg").default;
      default:
        console.error("Unsupported language icon");
    }
  }, [value])

  return (
    <img className={cn(classes.root, className)} src={src} alt={value} {...props}/>
  )
}
