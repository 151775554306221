import React, {forwardRef} from "react";
import ReactQRCode, {QRCodeCanvasProps} from "../libs/qrcode/qrcode";

export type QRProps = Omit<QRCodeCanvasProps, "renderAs" | "innerRef">

const imageSizes = {
  width: 42.75 * 0.85, height: 17.516 * 0.85
  // width: 44.25 * 0.85, height: 18.75 * 0.85
  // width: 46 * 0.75, height: 20.5 * 0.75
}

export type QRCodeRef = HTMLCanvasElement

const QRCode = forwardRef<QRCodeRef, QRProps>(function ({...props}, ref) {
  return (
    <ReactQRCode
      renderAs="canvas"
      includeMargin
      imageSettings={{
        src: require("../assets/logo.svg").default,
        ...getImageSize(props.size, props.includeMargin ?? true),
        excavate: true,
      }}
      innerRef={ref}
      {...props}
    />
  )
})

function getImageSize(wholeSize?: number, includeMargin?: boolean) {
  const multiplier = (wholeSize ?? 128) / 128 * (includeMargin ? 0.8 : 1);
  return {
    width: imageSizes.width * multiplier,
    height: imageSizes.height * multiplier
  }
}

export default QRCode;