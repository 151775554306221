import React, {useCallback, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {copyCanvasContentsToClipboard, getCanvasContentAsPNG} from "../utils";

import Button from "../ui/button";
import WithTooltip from "../ui/tooltip";
import {ClipboardIcon, DownloadOutlineIcon} from "../ui/icons";
import {QRCodeCanvasProps} from "../libs/qrcode/qrcode";
import QRCode, {QRCodeRef} from "../ui/qrcode";
import useAdaptiveClasses from "../hooks/useAdaptiveClasses";

import cn from "classnames"
import classes from "./CopyQRCode.module.css";


type props = Omit<React.ComponentProps<"div">, "children" | "value"> & {
  value: string
  adaptive?: boolean
  qrProps?: Omit<QRCodeCanvasProps, "value" | "onClick">
  fileName?: string
}

export default function CopyQRCode({className, value, adaptive, qrProps, fileName, ...props}: props) {
  const {t} = useTranslation();
  const ac = useAdaptiveClasses(classes, adaptive);

  const ref = useRef<QRCodeRef | null>(null);

  const [isCopied, setCopied] = useState(false);
  const [isUnsupported, setUnsupported] = useState(false);

  const copyToClipboard = useCallback((e) => {
    if (ref.current === null) {
      console.warn("Can't copy QR code: ref is null");
      return;
    }
    copyCanvasContentsToClipboard(ref.current).then(
      () => setCopied(true),
      () => setUnsupported(true)
    )
  }, [])

  const downloadAsPNG = useCallback<React.MouseEventHandler<HTMLAnchorElement>>((e) => {
    if (ref.current) {
      e.currentTarget.href = getCanvasContentAsPNG(ref.current);
    }
  }, [])

  const helpText = useMemo(() => (
    isUnsupported ? t("components.CopyQRCode.unsupported") :
      isCopied
        ? t("components.CopyQRCode.copied")
        : t("components.CopyQRCode.clickToCopy")
  ), [t, isUnsupported, isCopied])

  // Second QRCode to render downloadable high-res image
  return (
    <div className={cn(ac.root, className)} {...props}>
      <QRCode className={ac.qr} value={value} {...qrProps}/>
      <QRCode ref={ref} value={value} style={{display: "none"}} {...qrProps} size={1024}/>
      <div className={ac.buttonsWrapper}>
        <WithTooltip
          className={ac.downloadTooltip}
          placement="top"
          helpText={t("components.CopyQRCode.downloadHelpText")}
        >
          <Button as="a"
            className={cn(ac.button, ac.downloadButton)}
            color="primary"
            download={`${fileName ?? "qr"}.png`}
            onClick={downloadAsPNG}
          >
            {t("components.CopyQRCode.download")}
            {" "}
            <DownloadOutlineIcon className={ac.icon}/>
          </Button>
        </WithTooltip>
        <WithTooltip className={ac.tooltip} placement="top" helpText={helpText}>
          <Button
            className={ac.button}
            color="primary"
            onClick={copyToClipboard}
            disabled={isUnsupported}
          >
            <ClipboardIcon className={ac.icon}/>
          </Button>
        </WithTooltip>
      </div>
    </div>
  )
}
