import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import cn from "classnames";
import classes from "./LangSelector.module.css";
import {analyticsSendEvent} from "../libs/analytics";

const LangSelector = React.memo(function (props: React.ComponentProps<"span">) {
  const {i18n} = useTranslation();

  const langOnClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const lang = e.currentTarget.getAttribute("data-id");

    i18n.changeLanguage(lang);
    analyticsSendEvent("changeLanguage", {
      languageCode: lang
    });
  }, [i18n]);

  return (
    <span {...props}>
      <a data-id="en" onClick={langOnClick} className={cn(classes.lang, {[classes.active]: i18n.language === "en"})}
         href='/#'>en</a>
      {" "}/{" "}
      <a data-id="ru" onClick={langOnClick} className={cn(classes.lang, {[classes.active]: i18n.language === "ru"})}
         href='/#'>ru</a>
    </span>
  )
});

export default LangSelector;