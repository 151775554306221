import {gql, useMutation} from "@apollo/client";
import SessionActionsList from "../../components/results/SessionActionsList";
import {
  MutationEducationSessionSubmitFeedbackArgs
} from "../../schema";

const query = gql`
  query LMSLessonEducationStateQuery($lessonId: ID!, $externalUserId: ID!) {
    state: getLessonEducationState(lessonId: $lessonId, externalUserId: $externalUserId) {
      status
      canRetry
      bestAttempt {
        passed
        score
        time
        usedHints
        requestFeedback
        record
        recordState
        isContentMatches

        actionResults {
          __typename
          actionId
          branchId
          duration
          attempts
          isSkipped
          usedHint
          passed
        }
      }
      lastAttempt {
        sessionId
        feedbackText
        feedbackScore
      }
      attemptsCount
      passedAttemptsCount
      lessonId
    }

    account: getAccountByLesson(lessonId: $lessonId) {
      id
      code
      name
    }

    user: getExternalUser(externalUserId: $externalUserId, lessonId: $lessonId) {
      id
      name
      fullName
      email
    }

    lesson: getLesson(lessonId: $lessonId) {
      id
      mode
      avatar {
        name
      }
    }

    actions: getLessonActions(lessonId: $lessonId) {
      id
      ...SessionActionsList
    }
  }

  ${SessionActionsList.fragments.root}
`;

export default query;

export const sendFeedbackMutation = gql`
  mutation PlayerSessionSubmitFeedback(
    $data: DetailedSessionFeedbackInput, $score: Int, $message: String, $sessionId: ID!
  ) {
    result: educationSessionSubmitFeedback(data: $data, score: $score, message: $message, sessionId: $sessionId)
  }
`

export function useSendFeedbackMutation() {
  return useMutation<{ result: boolean }, MutationEducationSessionSubmitFeedbackArgs>(sendFeedbackMutation);
}
