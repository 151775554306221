import React, {ComponentProps, useEffect, useMemo, useState} from "react";
import {minutesToString} from "../../utils";

export type Props = Omit<ComponentProps<"span">, "children"> & {
  updateInterval?: number
}

export default function Timer({updateInterval = 1000, ...props}: Props) {
  const [secondsPassed, setSecondsPassed] = useState(0);
  const startTime = useMemo(() => Date.now(), [])

  useEffect(() => {
    const counter = setInterval(() => {
      const timePassed = Date.now() - startTime;
      setSecondsPassed(timePassed / 1000)
    }, updateInterval);

    return () => {
      clearInterval(counter)
    }
  }, [updateInterval, startTime])

  return (
    <span {...props}>
      {minutesToString(secondsPassed / 60)}
    </span>
  );
}

function Placeholder({updateInterval, ...props}: Props) {
  return (
    <span {...props}>
      {minutesToString(0)}
    </span>
  )
}

Timer.Placeholder = Placeholder;
