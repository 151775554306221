import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import GuideWidget from "../components/player/GuideWidget";
import guides from "../guides/index";
import {analyticsSendEvent} from "../libs/analytics";
import PopupPortal from "../ui/popupportal";
import useAsyncMemo from "../hooks/useAsyncMemo";
import {markdownFetch} from "../libs/markdown";

type GuideContext = {
  isVisible: boolean
  open: (guide?: string) => boolean
  close: () => boolean
}

const Context = createContext<GuideContext>(null!);

export default function GuideProvider({children}: React.PropsWithChildren<{}>) {
  const {i18n} = useTranslation();
  const lang = i18n.language as ("en" | "ru");

  const [isVisible, setVisibility] = useState<GuideContext["isVisible"]>(false);

  const [currentGuidePath, setCurrentGuidePath] = useState<string | undefined>();
  const currentGuide = useAsyncMemo<string | undefined>(async () => {
    if (!currentGuidePath) {
      return;
    }
    // Trick to get object item from path
    // @ts-ignore
    const guideFilePath: string | undefined = currentGuidePath?.split(".").reduce((o,i)=> o[i], guides)[lang];

    if (!guideFilePath) {
      console.error(`Can't find guide "${guideFilePath}"`)
    }

    return markdownFetch(guideFilePath!);
  }, [currentGuidePath, lang])

  const open = useCallback<GuideContext["open"]>((guidePath) => {
    let opened = false;
    setCurrentGuidePath(currentGuidePath => {
      setVisibility(isVisible => {
        if (!isVisible || (guidePath !== currentGuidePath)) {
          analyticsSendEvent("guideOpen", {guidePath});
          opened = true;
        }
        return true;
      });
      return guidePath;
    })
    return opened;
  }, [])

  const close = useCallback<GuideContext["close"]>(() => {
    let closed = false;
    setVisibility(isVisible => {
      if (isVisible) {
        analyticsSendEvent("guideClose");
        setVisibility(false);
        setCurrentGuidePath(undefined);
        return closed;
      }
      return true;
    })
    return closed;
  }, [])

  const ctx = useMemo(() => ({
    isVisible,
    open,
    close,
  }), [isVisible, open, close]);

  return (
    <Context.Provider value={ctx}>
      {children}
      {isVisible && (
        <PopupPortal center>
          <GuideWidget guide={currentGuide} onCloseButtonClick={close}/>
        </PopupPortal>
      )}
    </Context.Provider>
  )
}

export function useGuide() {
  return useContext(Context);
}
