import React from "react";
import useLongPress from "../hooks/useLongPress";
import {AsAnyComponentWithDefault} from ".";

import cn from "classnames";
import classes from "./button.module.css";

type ButtonVariant = "filled" | "morph" | "outlined"
type ButtonColor = "secondary" | "primary" | "brand" | "success" | "danger" | "dark"
type ButtonSize = "s" | "m" | "l"

type props = {
  variant?: ButtonVariant
  color?: ButtonColor
  disabledColor?: ButtonColor
  size?: ButtonSize

  onLongPress?: (event: React.MouseEvent | React.TouchEvent) => void,
  onLongRelease?: (event: React.MouseEvent | React.TouchEvent) => void,
  longPressDelay?: number

  innerRef?: any
}

type ButtonProps<T extends React.ElementType> = AsAnyComponentWithDefault<T, props, "button">

const defaultProps: {
  as: React.ElementType,
  color: ButtonColor,
  variant: ButtonVariant,
  size: ButtonSize
} = {
  as: "button",
  color: "secondary",
  variant: "filled",
  size: "m"
}

const Button = function Button<T extends React.ElementType>({
  as: Component,
  variant, color, disabledColor, size, className: extraClassName, disabled,
  onClick, onLongPress, onLongRelease, longPressDelay,
  children, innerRef, ...props
}: ButtonProps<T>) {
  const className = cn(classes.root, {
    [classes[`${variant}`]]: !!variant,
    [classes[`${variant}${size?.toUpperCase()}`]]: !!size,
    [classes[`${color}`]]: !!color,
    [classes[`${disabledColor}`]]: !!disabledColor && disabled,
  }, extraClassName);

  const longPressEvent = useLongPress<HTMLButtonElement>({onClick, onLongPress, onLongRelease, delay: longPressDelay})

  const pressEvent = disabled ? undefined : (
    onLongPress || onLongRelease ? longPressEvent : {onClick}
  )

  return (
    <Component className={className} disabled={disabled} ref={innerRef} {...props} {...pressEvent}>
      {variant === "morph" ? (children && <span>{children}</span>) : children}
    </Component>
  )
}

Button.defaultProps = defaultProps;

export default Button;
