import React from "react";
import {useTranslation} from "react-i18next";
import WithTooltip from "../ui/tooltip";
import classes from "./RandomAvatarIcon.module.css";
import cn from "classnames";
import {DiceIcon} from "../ui/icons";

type props = React.ComponentProps<"div"> & {
  adaptive?: boolean,
}

const RandomAvatarIcon = React.memo(function ({className: extraClassName, adaptive, ...props}: props) {
  const {t} = useTranslation();

  return (

    <WithTooltip className={cn(classes.root, {[classes.adaptive]: adaptive}, extraClassName)} showAfter={1000}
                 helpText={t("components.SystemIcon.helpText")} {...props}>
      <DiceIcon style={{
        width: "21px",
        height: "21px",
        paddingLeft: "2px",
      }}/>
    </WithTooltip>
  )
});

export default RandomAvatarIcon;
