import React from "react";
import {useSelector} from "react-redux";
import Content from "./Content"
import IntroMessage from "../../IntroMessage";
import {Lesson} from "../../../../schema";
import {PlayerReduxState} from "../../../../redux/player/types";
import {getLevel} from "../../../../redux/player/selectors";
import isEqual from "lodash/isEqual";

export type Props = {
  lesson: Lesson
}

const IntroContent = ({lesson, ...props}: Props) => {
  const {level} = useSelector(IntroContent.selector, isEqual);

  return (
    <Content variant="info" title={
      <IntroMessage.Title lesson={lesson} level={level}/>
    } {...props}>
      <IntroMessage lesson={lesson} level={level}/>
    </Content>
  );
};

export default React.memo(IntroContent);

IntroContent.selector = (state: PlayerReduxState) => ({
  level: getLevel(state)
});
