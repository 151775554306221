import React, {useCallback, useMemo} from "react";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import {useTranslation} from "react-i18next";
import Select from "../../ui/select";
import {Course, Lesson, LessonCourse} from "../../schema";
import {gql} from "@apollo/client";
import classes from "./ParticipationsActivityModal.module.css";

type PickedLesson = Pick<Lesson, "id" | "name">
type PickedCourse = Pick<Course, "id" | "name"> & {
  lessons: Pick<Lesson, "id">[]
}
type PickedLessonCourse = PickedLesson | PickedCourse

type contentProps = React.ComponentProps<"div"> & ({
  initialLessonId?: undefined,
  initialAction?: undefined,
  initialType?: undefined,
} | {
  initialLessonId: "no",
  initialAction: "activate" | "deactivate",
  initialType: LessonCourse["__typename"],
}) & {
  lessons?: PickedLesson[],
  courses?: PickedCourse[],
  params: {
    onAssign?: (emails: string[], action: boolean, lessonId?: string, courseId?: string) => void
    onDelete?: (emails: string[], lessonId?: string, courseId?: string) => void
  }
}

function ParticipationActivityModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.ParticipationActivityModal.title")}</span>
}

 function ParticipationActivityModalContent({lessons, courses, initialType, params}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    emails: string,
    id?: string | "no",
    action: "activate" | "deactivate" | "delete",
    type: NonNullable<LessonCourse["__typename"]>,
  }>({
    initialValues: {
      emails: "",
      id: "no",
      action: "deactivate",
      type: initialType ?? "Lesson",
    },

    preventDefault: true,
    onSubmit: (data) => {
      const emails = data.emails.split(/[ ,;\n]/)
        .map(email => email.trim())
        .filter(email => !!email);

      if (!emails.length) {
        formState.setValues({
          emails: ""
        })
        return;
      }

      if (data.id && data.id !== "no") {
        switch (data.action) {
          case "activate":
          case "deactivate":
            if (data.type === "Lesson") {
              params.onAssign && params.onAssign(emails, data.action === "activate", data.id, undefined);
            } else if (data.type === "Course") {
              params.onAssign && params.onAssign(emails, data.action === "activate", undefined, data.id);
            }
            break;
          case "delete":
            if (data.type === "Lesson") {
              params.onDelete && params.onDelete(emails, data.id, undefined);
            } else if (data.type === "Course") {
              params.onDelete && params.onDelete(emails, undefined, data.id);
            }
            break;
          default:
            console.error(`Unsupported action "${data.action}" in ParticipationsActivityModal`)
        }
      }
    }
  });

  const {type, id: currentId} = formState.values;

  const onTypeChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>((e) => {
    formState.setValues({id: "no"})
    formState.changeHandler(e)
  }, [formState])

  const onActionChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>((e) => {
    formState.changeHandler(e)
  }, [formState])

  const currentItems = useMemo<typeof lessons | typeof courses>(() => {
    switch (type) {
      case "Lesson":
        return lessons;
      case "Course":
        return courses;
      default:
        console.error(`Unsupported type of item to assign in AssignModal: "${type}"`)
    }
  }, [type, lessons, courses])

  const selectedItem = useMemo<PickedLessonCourse | undefined>(() => (
    currentId === "no"
      ? undefined
      : currentItems?.find(({id}) => id === currentId) as PickedLessonCourse | undefined
  ), [currentItems, currentId])

  const isCurrentCourseEmpty = useMemo(() => (
    selectedItem && ("lessons" in selectedItem) ? (selectedItem.lessons.length === 0) : false
  ), [selectedItem])

  const submitDisabled = !selectedItem || isCurrentCourseEmpty;

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
        <>
          <p className={classes.helpText}>{t("components.ParticipationActivityModal.helpText")}</p>
          <Textarea
            className={classes.input}
            name="emails"
            rows={6}
            required
            placeholder={t("components.ParticipationActivityModal.placeholder")}
            onChange={formState.changeHandler}
          />
        </>

      <p className={classes.helpText}>{t("components.ParticipationActivityModal.action.helpText")}</p>
      <Select
        className={classes.input}
        name="action"
        value={formState.values.action}
        onChange={onActionChange}
      >
        <option value="deactivate">
          {t("components.ParticipationActivityModal.action.deactivate")}
        </option>
        <option value="activate">
          {t("components.ParticipationActivityModal.action.activate")}
        </option>
        <option value="delete">
          {t("components.ParticipationActivityModal.action.delete")}
        </option>
      </Select>

      <p className={classes.helpText}>{t("components.ParticipationActivityModal.type.helpText.label")}</p>
      <Select
        className={classes.input}
        name="type"
        value={formState.values.type}
        onChange={onTypeChange}
      >
        <option value="Lesson">
          {t("components.AssignModal.type.lesson")}
        </option>
        <option value="Course">
          {t("components.AssignModal.type.сourse")}
        </option>
      </Select>

      <p className={classes.helpText}>
        {type === "Lesson" && t("components.ParticipationActivityModal.type.helpText.lesson")}
        {type === "Course" && t("components.ParticipationActivityModal.type.helpText.course")}
      </p>
      {isCurrentCourseEmpty && (
        <p className={classes.warning}>
          {t("components.ParticipationActivityModal.warning.emptyCourse")}
        </p>
      )}

      <div className={classes.bottom}>
        <Select
          name="id"
          className={classes.select}
          value={formState.values.id}
          onChange={formState.changeHandler}
        >
          <option value="no">
            {t("components.AssignModal.no")}
          </option>
          {currentItems && currentItems.map((item) => (
            <option key={item.id} value={item.id} translate="no">
              {item.name}
            </option>
          ))}
        </Select>

        <Button type="submit" color={submitDisabled ? "secondary" : "success"} disabled={submitDisabled}>
          {t("components.ParticipationActivityModal.submit")}
        </Button>
      </div>
    </form>
  )
}

const ParticipationActivityModal = {
  Header: ParticipationActivityModalHeader,
  Content: ParticipationActivityModalContent,
  fragments: {
    root: gql`
      fragment ParticipationActivityModal on LessonCourse {
        __typename
        id
      }
    `,
    lessons: gql`
      fragment ParticipationActivityModalLessons on Lesson {
        id
        name
      }
    `,
    courses: gql`
      fragment ParticipationActivityModalCourses on Course {
        id
        name
      }
    `,
  }
}

export default ParticipationActivityModal;
