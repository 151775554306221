import React, {useCallback, useEffect, useState} from "react";

import cn from "classnames"
import classes from "./ReloadButton.module.css";

import Button from "../ui/button";
import {SyncIcon} from "../ui/icons";
import WithTooltip from "../ui/tooltip";
import {useTranslation} from "react-i18next";

type props = Omit<React.ComponentProps<typeof Button>, "as"> & {
  onReloadClick?: (e: React.MouseEvent) => void,
  loading?: boolean,
  round?: boolean,
}

export default function ReloadButton({
                                       className,
                                       onReloadClick,
                                       loading,
                                       round,
                                       children,
                                       ...props
                                     }: props) {
  const {t} = useTranslation();

  const [loaded, setLoaded] = useState<boolean>()

  const onClick = useCallback((e: React.MouseEvent) => {
    setLoaded(false)
    onReloadClick && onReloadClick(e)
  }, [onReloadClick])

  useEffect(() => {
    if (loaded === false && loading === false) {
      setLoaded(true)
    }
  }, [loaded, loading])

  return (
    <WithTooltip
      placement='top'
      className={cn(classes.root, className)}
      helpText={loaded ? t("components.ReloadButton.loadedHelpText") : t("components.ReloadButton.helpText")}
      showAfter={500}
    >
      <Button
        onClick={onClick}
        disabled={loading}
        className={round ? classes.roundButton : undefined}
        color="secondary"
        {...props}
      >
        {children}
        <SyncIcon className={cn({[classes.loading]: loaded === false})}/>
      </Button>
    </WithTooltip>
  )
}

ReloadButton.defaultProps = {
  round: true
}
