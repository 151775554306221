import {
  UserInputAction, AvatarAction, SystemAction, ChoiceUserInputAction,
  UserInputActionInput, AvatarActionInput, SystemActionInput, ChoiceUserInputActionInput,
  Scenario as GQLScenario,
  User, Editor, Student,
  Lesson, Course, Scalars, RandomAvatarAction, RandomAvatarActionInput
} from "./schema";
export type Action = UserInputAction | AvatarAction | SystemAction | ChoiceUserInputAction | RandomAvatarAction;
export type ActionInput =
UserInputActionInput | AvatarActionInput | SystemActionInput | ChoiceUserInputActionInput | RandomAvatarActionInput;
export type Scenario = Omit<GQLScenario, "actions" | "id" | "name" | "isMain"> & {
  actions: Action[],
  id: Scalars["ID"],
  name: Scalars["String"],
  isMain: Scalars["Boolean"]
}

export function getLessonActionTextRepr(
  action: Action
) {
  switch (action.__typename) {
    case "UserInputAction":
      return action.expectedText;

    case "SystemAction":
      return action.text;

    case "AvatarAction":
      return action.text;

    case "ChoiceUserInputAction":
    case "RandomAvatarAction":
      return undefined

    default:
      console.error(`Unsupported action type "${action.__typename}" in getLessonActionTextRepr`)
      return undefined;
  }
}

export type Nullable<T> = T | null | undefined

export type DeepNullable<T> = T extends Function
    ? T
    : T extends Array<infer U>
    ? _DeepNullableArray<U>
    : T extends object
    ? _DeepNullableObject<T>
    : T | null;

interface _DeepNullableArray<T> extends Array<DeepNullable<T>> {}

type _DeepNullableObject<T> = { [P in keyof T]: DeepNullable<T[P]> | null };

export function isUserEditor(user: User & TypedItem<string>): user is Editor {
  return user?.__typename === "Editor"
}

export function isUserStudent(user: User & TypedItem<string>): user is Student {
  return user?.__typename === "Student"
}

export function isSharedId(lessonId: string): boolean {
  return !(/^\d+$/.test(lessonId));
}

type SharedItem = {
  id: Scalars["ID"]
  accessToken: Scalars["String"]
}

export function getSharedId<T extends SharedItem>(item: T) {
  return `${item.id}_${item.accessToken}`
}

export function isAvatarAction(action: Nullable<Action>): action is AvatarAction {
  return action?.__typename === "AvatarAction"
}

export function isSystemAction(action: Nullable<Action>): action is SystemAction {
  return action?.__typename === "SystemAction"
}

export function isUserInputAction(action: Nullable<Action>): action is UserInputAction {
  return action?.__typename === "UserInputAction"
}

export function isChoiceUserInputAction(action: Nullable<Action>): action is ChoiceUserInputAction {
  return action?.__typename === "ChoiceUserInputAction"
}

export function isRandomAvatarAction(action: Nullable<Action>): action is RandomAvatarAction {
  return action?.__typename === "RandomAvatarAction"
}

export function isLesson(item: TypedItem<string>): item is Lesson {
  return item?.__typename === "Lesson"
}

export function isCourse(item: TypedItem<string>): item is Course {
  return item?.__typename === "Course"
}

export type TypedItem<K> = {
  __typename?: K
}

export type WithoutTypename<T extends TypedItem<string>> = Omit<T, "__typename">

export enum ChartVisualization {
  BAR = "BAR",
  DONUT = "DONUT",
}

export enum ChartMeasurementUnits {
  ABS = "ABS",
  PERCENTAGE = "PERCENTAGE",
}
