export default function bindHotkey(
  keyCode: number,
  onKeyDown: (e: KeyboardEvent) => void,
  onKeyUp: (e: KeyboardEvent) => void,
  preventKeyUpFirst?: boolean,
) {
  let isPressed = false;

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.keyCode === keyCode && (!preventKeyUpFirst || !isPressed)) {
      e.preventDefault();
      isPressed = true;
      onKeyDown(e);
    }
  }

  const keyUpHandler = (e: KeyboardEvent) => {
    if (e.keyCode === keyCode && (!preventKeyUpFirst || isPressed)) {
      e.preventDefault();
      isPressed = false;
      onKeyUp(e);
    }
  }

  window.addEventListener("keydown", keyDownHandler);
  window.addEventListener("keyup", keyUpHandler);

  return () => {
    window.removeEventListener("keydown", keyDownHandler);
    window.removeEventListener("keyup", keyUpHandler);
  }
}
