import React, {EventHandler, KeyboardEventHandler, SyntheticEvent, useCallback} from "react";

import cn from "classnames";
import classes from "./clickableDiv.module.css";

type ClickableDivProps = Omit<React.ComponentProps<"div">, "onClick"> & {
  onClick?: EventHandler<SyntheticEvent>
  stopTriggerOnSpacebar?: boolean
  disabled?: boolean
}

export default function ClickableDiv({
  onClick, onKeyDown, stopTriggerOnSpacebar, disabled, className: exClassName, ...props
}: ClickableDivProps) {
  const accessibleOnKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>((event) => {
    onKeyDown && onKeyDown(event);
    if (
      (event.key === "Enter" || (!stopTriggerOnSpacebar && event.key === " ")) &&
      (event.currentTarget === event.target)
    ) {
      event.stopPropagation();
      event.preventDefault();
      onClick && onClick(event);
    }
  }, [onKeyDown, stopTriggerOnSpacebar, onClick]);

  const className = cn({[classes.disabled]: disabled}, exClassName);

  return <div tabIndex={0} role="button" onClick={onClick} onKeyDown={accessibleOnKeyDown} {...{...props, className}}/>
}
