import React, {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {Avatar} from "../../schema";
import SimpleAvatarPlayer from "./SimpleAvatarPlayer";
import cn from "classnames";
import classes from "./AvatarPlayer.module.css";
import BabylonAvatarPlayerLazy from "./BabylonAvatarPlayerLazy";
import {ThreeDimensionsOffIcon, ThreeDimensionsIcon} from "../../ui/icons";
import {analyticsSendEvent} from "../../libs/analytics";

type PlayerDisplayMode = "2D" | "3D"

type props = React.ComponentProps<"div"> & {
  className?: string
  initialMode: PlayerDisplayMode,
  avatar: Avatar,
  gestures: string[]
  portraitMode?: boolean,
  hideLoaderOnMobile?: boolean,
  hideSwitchOnMobile?: boolean,
  onUnloaded?: () => void,
  onLoaded?: () => void,
}

export type AvatarPlayerRef = {
  switchMode: () => void,
  mode: PlayerDisplayMode
}

const AvatarPlayer = forwardRef<AvatarPlayerRef, props>(function ({
                                                                    className,
                                                                    initialMode,
                                                                    avatar,
                                                                    gestures,
                                                                    portraitMode,
                                                                    hideLoaderOnMobile,
                                                                    hideSwitchOnMobile,
                                                                    onLoaded,
                                                                    onUnloaded,
                                                                    ...props
                                                                  }, ref) {
  const [mode, setMode] = useState<PlayerDisplayMode>(initialMode);
  const [isLoaded, setLoaded] = useState(initialMode === "2D");

  const handleLoaded = React.useCallback(() => {
    setLoaded(true);
    onLoaded && onLoaded();
  }, [onLoaded]);

  const switchMode = useCallback((e?: React.SyntheticEvent) => {
    setLoaded(false);
    const targetClassname = e?.currentTarget.className
    onUnloaded && onUnloaded();
    setMode((mode) => {
      const nextMode = mode === "3D" ? "2D" : "3D"
      analyticsSendEvent("avatarPlayerSwitchMode", {
        switchTo: nextMode,
        toggleClassname: targetClassname
      })

      return nextMode
    });
  }, [onUnloaded]);

  useImperativeHandle(ref, () => ({
    switchMode, mode
  }), [mode, switchMode]);

  return (
    <>
      {mode === "2D" && (
        <SimpleAvatarPlayer
          className={className}
          avatar={avatar}
          portraitMode={portraitMode}
          onLoaded={handleLoaded}
          {...props}
        />
      )}

      {isLoaded && (
        <div className={classes.switchRoot}>
          <button
            className={cn(classes.switchModeBtn, {[classes.hideOnMobile]: hideSwitchOnMobile})}
            onClick={switchMode}
          >
            {mode === "2D" ? <ThreeDimensionsIcon/> : <ThreeDimensionsOffIcon/>}
          </button>
        </div>
      )}

      {(mode === "3D") && (
        <BabylonAvatarPlayerLazy
          className={className}
          avatar={avatar}
          gestures={gestures}
          portraitMode={portraitMode}
          previewScreen={SimpleAvatarPlayer}
          onLoaded={handleLoaded}
          onLoadCancel={switchMode}
        />
      )}
    </>
  )
});

export default AvatarPlayer;
