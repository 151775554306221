import React, {useCallback, useEffect, useState} from "react";

import ErrorWidget from "./GenericErrorWidget";
import {useErrorContext} from "../../providers/errorBoundary";

type props = {
  errorWidgets: Map<string, ErrorWidgetOptions>,
  children: React.ReactNode
}

export type ErrorWidgetOptions = {
  widget?: (props?: any) => JSX.Element,
  stopErrorProcessing?: boolean,
}

export default function WidgetErrorBoundary({errorWidgets, children}: props) {

  const {addErrorListener, removeErrorListener} = useErrorContext()

  const [errorCode, setErrorCode] = useState<string>();

  const Widget = errorWidgets.get(errorCode ?? "")?.widget ?? ErrorWidget

  const onClose = useCallback(() => {
    setErrorCode(undefined)
  }, [])

  useEffect(() => {
    const onError = (e: ErrorEvent) => {
      let errorCode = e.error.message

      setErrorCode(errorCode)
      if (errorWidgets.get(errorCode)?.stopErrorProcessing) {
        console.warn(e)
        return true
      }
    }

    addErrorListener(onError, true)
    return () => {
      removeErrorListener(onError)
    }
  }, [errorWidgets, addErrorListener, removeErrorListener]);


  return (
    <>
      {children}
      {errorCode && <Widget onClose={onClose}/>}
    </>
  )
}