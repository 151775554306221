/* eslint-disable max-lines */
import React, {useCallback, useMemo, useState} from "react";
import {TFunction, useTranslation} from "react-i18next";
import useVisibility from "../../hooks/useVisibility";
import {getFormatFromUrl, megabytesToBytes, parseMimeType} from "../../utils";

import WithTooltip from "../../ui/tooltip";
import Button from "../../ui/button";
import Spinner from "../../ui/spinner";
import FSImage from "../../ui/fullscreen/image";
import FSVideo from "../../ui/fullscreen/video";
import AudioRecorder, {Props as AudioRecorderProps} from "./AudioRecorder";

import cn from "classnames";
import classes from "./FileInput.module.css";

const DEFAULT_MAX_FILE_SIZE = 20

type FileInputVariant = "any" | "audio" | "image" | "video" | "csv"

type FileInputPreset = {
  previewDisplay: React.ReactNode,
  formats: string[] | "any",
  maxSize?: number
}

export type Props = Omit<React.ComponentProps<"input">, "onChange" | "src" | "name"> & {
  src?: string | File,
  isLoading?: boolean,
  name: string,
  variant: FileInputVariant | FileInputVariant[],
  formats?: string[],
  customHelpText?: string,
  maxSize?: number,

  allowMicInput?: boolean,

  onDelete: (event: React.FormEvent) => void,
  setValues: (data: any) => void,
}

type PreviewData = {url: string, format: string}

export default function FileInput({
  src, isLoading, name, accept, variant, formats, customHelpText, maxSize,
  allowMicInput,
  onDelete, setValues,
  className, ...props
}: Props) {
  const {t} = useTranslation();

  const [fileLoading, setFileLoading] = useState(false)
  const [preview, setPreview] = useState<PreviewData | undefined>()
  const [error, setError] = useState<string>()

  const setFileAsPreview = useCallback((file: File) => {
    setPreview((previousPreview) => {
      previousPreview?.url && URL.revokeObjectURL(previousPreview.url)
      return {url: URL.createObjectURL(file), format: parseMimeType(file.type).subtype}
    })
  }, [])

  const loading = fileLoading || isLoading

  const variantsArray = useMemo(() => (Array.isArray(variant) ? variant : [variant] as FileInputVariant[]), [variant])

  const presets = useMemo(() => {
    const presets = getPresets({t, className, preview, src});

    const filteredPresets = new Map<FileInputVariant, FileInputPreset>();

    for (const item of variantsArray) {
      // if map.has(a) === true, then map.get(a) will be map value type (not undefined)
      presets.has(item) && filteredPresets.set(item, presets.get(item)!)
    }

    return filteredPresets;
  }, [t, className, preview, src, variantsArray])

  const getPresetFromFormat = useCallback((format: string) => {
    const [, preset] = Array.from(presets)
      .find(([, item]) => item.formats?.includes(format)) ?? [undefined, undefined]

    if (preset) {
      return preset
    } else if (presets.has("any")) {
      return presets.get("any") as FileInputPreset
    } else {
      throw new Error(`Unexpected preview file format: ${format}`)
    }
  }, [presets])

  const onFileChange = useCallback(({
    target: {
      validity,
      files,
    },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined)
    setFileLoading(true)
    if (validity.valid && files) {
      const sizeLimit = maxSize ?? getPresetFromFormat(parseMimeType(files[0].type).subtype).maxSize ?? 0;

      if ((sizeLimit !== 0) && (megabytesToBytes(sizeLimit) < files[0].size)) {
        setFileLoading(false)
        setError(t("components.FileInput.errors.sizeLimit", {sizeLimit}))
        return;
      }
      setFileAsPreview(files[0])
      setValues({[name]: files[0]})
      setFileLoading(false)
    }
  }, [maxSize, t, getPresetFromFormat, setFileAsPreview, name, setValues])

  const defaultFormats = useMemo(() => Array.from(new Set<string>(  // Remove duplicates
    Array.from(presets)
      .flatMap(([, item]) => item.formats)
      .filter(item => item !== undefined)
  )), [presets]);

  const defaultHelpText = useMemo(() => {
    function generateHelpText(formats: string[] | "any", maxSize?: number, continuous?: boolean) {
      const textOptions: {
        formats?: string,
        any?: string,
        maxSize?: number
      } = formats === "any" ? {
        any: t("components.FileInput.any")
      } : {
        formats: formats.join(", ").toUpperCase()
      }

      textOptions.maxSize = maxSize ?? DEFAULT_MAX_FILE_SIZE

      return continuous
        ? t("components.FileInput.continuousHelpText", textOptions)
        : t("components.FileInput.helpText", textOptions)
    }

    if (maxSize !== undefined || formats !== undefined) {
      return generateHelpText(formats ?? defaultFormats, maxSize);
    }

    let result: string = "";
    let i = 0;

    presets.forEach((item) => {
      result += generateHelpText(item.formats, item.maxSize, !!i);
      i++;
    })

    return result;
  }, [t, maxSize, formats, defaultFormats, presets])

  const helpText = customHelpText ?? defaultHelpText

  accept = useMemo(() => (
    (formats?.includes("any") ?? defaultFormats.includes("any")) ? undefined : (
      accept ?? (formats ?? defaultFormats).map(item => "." + item).join(", ")
    )
  ), [accept, formats, defaultFormats])

  const preset = useMemo(() => {
    if (preview || src) {
      const format = preview
        ? preview.format
        : getFormatFromUrl(src as string)!;

      return getPresetFromFormat(format);
    }
  }, [preview, src, getPresetFromFormat]);

  const [micInputVisible, showMicInput, hideMicInput] = useVisibility();

  const onAudioRecordStop = useCallback<NonNullable<AudioRecorderProps["onRecordStop"]>>((blob, url) => {
    const file = new File([blob], "editor_record", {type: blob.type});

    setFileAsPreview(file);
    setValues({[name]: file});
    setFileLoading(false);
    hideMicInput();
  }, [setFileAsPreview, name, setValues, hideMicInput])

  return (
    <>
      {preset && !micInputVisible && (
        <div className={classes.previewWrapper}>
          {preset.previewDisplay}
          {loading && (
            <div className={classes.loadingWrapper}>
              <Spinner/>
            </div>
          )}
        </div>
      )}
      {micInputVisible && (
        <AudioRecorder className={classes.audioRecorder} onRecordStop={onAudioRecordStop}/>
      )}
      <span className={classes.error}>{error}</span>
      <div className={classes.buttonsRow}>
        <WithTooltip className={classes.fileButtonTooltip} placement='top'
                     helpText={helpText}>
          <Button as={"label"} className={classes.fileButton}>
            <input
              className={classes.fileInput}
              type="file"
              accept={accept}
              onChange={onFileChange}
              {...props}
            />
            {(src && !allowMicInput)
              ? t("components.FileInput.selectAnotherFile")
              : t("components.FileInput.selectFile")
            }
          </Button>
        </WithTooltip>
        {src && (
          <Button
            className={classes.deleteButton}
            type="submit"
            name={name}
            value=''
            onClick={onDelete}
          >{t("common.delete")}</Button>
        )}
        {allowMicInput && (micInputVisible ? (
          <Button type="button" className={classes.recordButton} onClick={hideMicInput}>
            {t("components.FileInput.cancelRecord")}
          </Button>
        ) : (
          <Button type="button" className={classes.recordButton} onClick={showMicInput}>
            {t("components.FileInput.recordAudio")}
          </Button>
        ))}
      </div>
    </>
  )
}

type PresetsProps = {
  className?: string,
  src?: string | File,
  preview?: PreviewData,
  t: TFunction
}

function getPresets({className, src, preview, t}: PresetsProps): Map<FileInputVariant, FileInputPreset> {
  return new Map<FileInputVariant, FileInputPreset>([
    ["image", {
      previewDisplay: (
        <FSImage
          className={cn(classes.previewImage, className)}
          // @ts-ignore: File is valid type for src HTML prop
          src={preview?.url ?? src}
          alt={t("components.FileInput.image.alt")}
        />
      ),
      formats: ["jpeg", "png", "jpg"],
      maxSize: 20
    }],
    ["video", {
      previewDisplay: (
        <FSVideo
          className={cn(classes.previewVideo, className)}
          // @ts-ignore: File is valid type for src HTML prop
          src={preview?.url ?? src}
        />
      ),
      formats: ["mp4", "webm", "mpeg"],
      maxSize: 96
    }],
    ["audio", {
      previewDisplay: (
        <audio
          controls
          className={cn(classes.previewAudio, className)}
          // @ts-ignore: File is valid type for src HTML prop
          src={preview?.url ?? src}
        />
      ),
      formats: ["mp3", "mpeg", "ogg"],
      maxSize: 5
    }],
    ["csv", {
      previewDisplay: (
        <a className={classes.previewLink} target="_blank" rel="noopener noreferrer" href={
          preview?.url ?? (
            typeof src === "string" ? src :
            src ? URL.createObjectURL(src) : undefined
          )
        }>{preview ? "file_upload." + preview?.format : src}</a>
      ),
      formats: ["csv"],
      maxSize: 96
    }],
    ["any", {
      previewDisplay: (
        <a className={classes.previewLink} target="_blank" rel="noopener noreferrer" href={
          preview?.url ?? (
            typeof src === "string" ? src :
            src ? URL.createObjectURL(src) : undefined
          )
        }>{preview ? "file_upload." + preview?.format : src}</a>
      ),
      formats: "any",
    }]
  ])
}
