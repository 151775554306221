import {isIntroStage, getLevel} from "../selectors";
import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {sessionStartLevelMutation} from "../graphql";
import {MutationEducationSessionStartLevelArgs, Scalars} from "../../../schema";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function passIntro() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    if (!isIntroStage(state)) {
      console.warn("passIntro: wrong player state");
      return;
    }

    analyticsSendEvent("playerPassIntro", {
      sessionId: state.sessionId!,
      level: getLevel(state)
    });

    dispatch({type: "next"});

    state.client.mutate<{ ok: Scalars["Boolean"] }, MutationEducationSessionStartLevelArgs>({
      mutation: sessionStartLevelMutation,
      variables: {
        sessionId: state.sessionId!,
        level: getLevel(state),
      }
    }).catch(console.error);
  }
}
