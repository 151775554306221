/* eslint-disable max-lines */
// Hooks
import React, {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useLazyQuery} from "@apollo/client";
import {query as courseQuery, queryData, queryVars, useUpdateChildrenMutation, useUpdateCourseMutation
} from "./course-edit.graphql";
import {
  useAddLessonMutation, useCopyLessonMutation, useDeleteLessonMutation, useMoveLessonMutation, useReorderLessonMutation
} from "./index.graphql";
import {useHistory, useParams} from "react-router";
import {useTour} from "../../providers/tour";
import {useCurrentUser} from "../../App.context";

// Libs, types and consts
import {reverse} from "../../routing";
import {getLanguageCode} from "../../i18n";
import {CourseInput, Lesson, LessonInput} from "../../schema";
import {analyticsSendEvent, toEventData} from "../../libs/analytics";
import {getDataWithMock} from "../../tours";

// Components
import {useModal} from "../../components/ModalProvider";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Button from "../../ui/button";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Breadcrumbs from "../../ui/breadcrumbs";
import {AddIcon} from "../../ui/icons";
import LessonsTable, {LessonProps} from "../../components/editor/lessons-table/LessonsTable";
import ReactLink from "../../components/ReactLink";
import CourseCustomizeForm from "../../components/editor/CourseCustomizeForm";
import EditableItemName from "../../components/editor/EditableItemName";
import Skeleton from "../../ui/skeleton";
import ShareButton from "../../components/editor/ShareButton";
import PageNotFoundScene from "../system/page-not-found";
import DeleteModal from "../../components/editor/DeleteModal";
import EditorCourseEditTour, {
  useMockData,
  classes as tourClasses,
  isEditorCourseEditTourMock,
  NAME as tourName
} from "./course-edit.tour";
import {DragDropContext, DropResult} from "react-beautiful-dnd";

// Classes
import cn from "classnames";
import classes from "./course-edit.module.css";

export default function EditorCourseEditScene() {
  const history = useHistory();

  const {t, i18n} = useTranslation();
  const language = getLanguageCode(i18n.language);

  const {runningTourName, bindToPage: bindTourToPage, run: runTour, mock: tourMock} = useTour();
  const tourMode = runningTourName === tourName;
  const isStaff = useCurrentUser()?.isStaff;

  useEffect(() => bindTourToPage(tourName), [bindTourToPage])

  const mock = useMemo(() => {
    if (isEditorCourseEditTourMock(tourMock)) {
      return tourMock;
    }
  }, [tourMock]);

  const mockData = useMockData(mock?.data.courseName);

  const {id: courseId} = useParams<{ id: string }>();

  useEffect(() => {
    analyticsSendEvent("editorCourseView", {courseId});
  }, [courseId]);

  const [query, {data, loading: fetching}] = useLazyQuery<queryData, queryVars>(courseQuery, {
    variables: {courseId, language}
  });

  useEffect(() => {
    if (courseId === "tour") {
      runTour(tourName);
    } else {
      query();
    }
  }, [courseId, runTour, query])

  const {course, courses} = useMemo(() => (
    getDataWithMock(data, mockData, tourMode)
  ), [data, mockData, tourMode]);

  const lessons = course?.lessons;
  const loading = !course;
  const shareCourseId = course?.id ? `${course?.id}_${course?.accessToken}` : courseId;

  const [updateCourse] = useUpdateCourseMutation();
  const [addLesson] = useAddLessonMutation();
  const [deleteLesson] = useDeleteLessonMutation();
  const [moveLesson] = useMoveLessonMutation();
  const [copyLesson] = useCopyLessonMutation();
  const [reorderLesson] = useReorderLessonMutation();
  const [applyUpdateChildrenMutation] = useUpdateChildrenMutation();

  const shareLink = useMemo(() => (
    window.location.origin + reverse("playerCourse", {id: shareCourseId})
  ), [shareCourseId])

  const onShareClick = useCallback(() => {
    analyticsSendEvent("editorCopyCourseShareLink", {courseId});
  }, [courseId]);

  const onCourseUpdate = useCallback((data: CourseInput) => {
    analyticsSendEvent("editorCourseUpdate", {courseId});

    updateCourse({
      variables: {id: courseId, data}
    })
  }, [courseId, updateCourse]);

  const onAddLesson = useCallback(() => {
    addLesson({
      variables: {
        data: {
          name: t("editorIndex.newLessonName"),
          languageCode: course?.languageCode,
          courseId,
        }
      }
    }).then(({data}) => {
      const lesson = data?.lesson!;

      analyticsSendEvent("editorAddLesson", {
        lessonId: lesson.id
      });
      history.push(reverse("editorLessonEdit", {
        id: lesson.id
      }))
    })
  }, [history, addLesson, t, course?.languageCode, courseId]);

  const onLessonCopy = useCallback((lessonId: Lesson["id"]) => {
    copyLesson({
      variables: {id: lessonId}
    }).then(({data}) => {
      const lesson = data?.lesson!;

      analyticsSendEvent("editorCopyLesson", {
        lessonId: lesson.id
      });
    })
  }, [copyLesson]);

  const {add: addModal} = useModal();
  const onLessonDelete = useCallback((lessonId: Lesson["id"]) => {
    const onDelete = () => {
      analyticsSendEvent("editorLessonDelete", {
        lessonId: lessonId,
      });
      deleteLesson({
        variables: {id: lessonId}
      });
      modal.remove();
    };

    const onCancel = () => modal.remove();

    const modal = addModal({
      id: `deleteLesson_${lessonId}`,
      header: t("editorIndex.deleteLesson"),
      content: t("editorIndex.deleteLessonConfirmation"),
      footer: <DeleteModal.Footer onConfirm={onDelete} onCancel={onCancel}/>
    });
  }, [t, deleteLesson, addModal]);

  const onLessonMove = useCallback((lessonId: string, data: Pick<LessonInput, "courseId">) => {
    analyticsSendEvent("editorLessonUpdate", {lessonId, ...toEventData(data)});

    moveLesson({
      variables: {id: lessonId, data}
    })
  }, [moveLesson]);

  const onLessonReorder = useCallback((lessonId: string, data: Pick<LessonInput, "beforeLessonId">) => {
    analyticsSendEvent("editorLessonUpdate", {lessonId, ...toEventData(data)});

    reorderLesson({
      variables: {id: lessonId, data}
    })
  }, [reorderLesson]);

  const onDragEnd = useCallback((result: DropResult) => {
    if (!lessons) {
      return;
    }

    const {destination, source} = result;
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const draggedLesson = lessons[source.index];
    const beforeActionIndex = destination.index > source.index ? destination.index + 1 : destination.index;
    if (beforeActionIndex === lessons.length) {
      onLessonReorder(draggedLesson.id, {
        beforeLessonId: null
      })
    } else {
      const targetAction = lessons[beforeActionIndex]
      onLessonReorder(draggedLesson.id, {
        beforeLessonId: targetAction.id
      })
    }
  }, [lessons, onLessonReorder])

  const onChildrenUpdate = useCallback(() => {
    analyticsSendEvent("editorCourseChildrenUpdate", {courseId: courseId});
    applyUpdateChildrenMutation({
      variables: {courseId}
    })
  }, [courseId, applyUpdateChildrenMutation]);

  if (!fetching && !course) {
    return <PageNotFoundScene/>
  }

  return (
    <>
      <ReactDocumentTitle title={course?.name ?? t("editorCourseEdit.title")}/>
      {tourMode && <EditorCourseEditTour returnToPreviousPage={courseId === "tour"}/>}
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <ReactLink
                className={tourClasses.editorMain}
                onClick={mock?.onReturnToMainPage}
                href={reverse("editor")}
              >
                {t("breadcrumbs.editor")}
              </ReactLink>
              <span title={course?.name} translate="no">
                {course?.name === undefined
                  ? t("breadcrumbs.lesson")
                  : course.name || <span className={classes.notSpecified}>{t("common.notSpecified")}</span>
                }
              </span>
            </Breadcrumbs>

            <div className={classes.header}>
              {!loading ? (
                <EditableItemName
                  key={course!.id}
                  className={tourClasses.courseName}
                  item={course!}
                  onSave={mock?.nameEditSave ?? onCourseUpdate}
                />
              ) : (
                <Skeleton.Text height={28} width={250}/>
              )}
              <div className={cn(classes.topRightMenu, tourClasses.topRightMenu)}>
                <Button as={ReactLink}
                  className={tourClasses.participants}
                  href={reverse("accountMembers") + "?courseId=" + encodeURIComponent(courseId)}
                >{t("editorCourseEdit.participants")}</Button>
                <ShareButton
                  className={tourClasses.shareButton}
                  value={shareLink}
                  name={course?.name}
                  onClick={onShareClick}
                  title={t("components.ShareButton.courseTitle")}
                  helpText={t("editorCourseEdit.shareButtonHelpText")}
                />
                {!loading && (
                  <>
                    <Button
                      className={cn(classes.addButton, tourClasses.addLesson)}
                      onClick={onAddLesson}
                      color="primary"
                    >
                      <AddIcon/>{t("editorIndex.addLesson")}
                    </Button>
                    {isStaff && course?.isInitial && (
                      <Button
                        className={classes.previewButton}
                        onClick={onChildrenUpdate}
                        color='success'
                      >
                        {t("editorLessonEdit.updateChildrenCourses")}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className={classes.content}>
              <div className={classes.contentMain}>
                <div className={classes.tableRoot}>
                  {(!loading && lessons && courses) ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <LessonsTable<LessonProps>
                        className={tourClasses.lessonTable}
                        allowDragNDrop
                        data={lessons}
                        currentCourse={course}
                        courses={courses}
                        onLessonDelete={onLessonDelete}
                        onLessonMove={onLessonMove}
                        onLessonCopy={onLessonCopy}
                      />
                    </DragDropContext>
                  ) : (
                    <LessonsTable.Skeleton/>
                  )}
                </div>
              </div>
              <div className={classes.contentAside}>
                {course && (
                  <CourseCustomizeForm
                    className={tourClasses.courseCustomizeForm}
                    course={course}
                    onSave={onCourseUpdate}
                  />
                )}
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
