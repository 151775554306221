import React, {ComponentProps, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Group, User} from "../../../schema";

import UsersSelect from "../UsersSelect";

import classes from "./EditGroupMembersModal.module.css";

type PickedUser = Pick<User, "id" | "fullName" | "name" | "email"> & {
  groups: Pick<Group, "id" | "name">[]
}
type PickedGroup = Pick<Group, "id" | "name"> & {
  users: Pick<User, "id">[]
}

export type QueryData = {
  group: PickedGroup
  users: PickedUser[]
  groups: PickedGroup[]
}

export type ContentProps = Omit<ComponentProps<"form">, "children" | "onSubmit"> & QueryData & {
  onEditGroupMembers?: (ids: User["id"][]) => void
}

function EditGroupMembersModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.EditGroupMembersModal.title")}</span>
}

 function EditGroupMembersModalContent({group, users, groups, onEditGroupMembers, ...props}: ContentProps) {
  const {t} = useTranslation();

  const initialUsersIds = useMemo(() => getIds(group.users), [group.users]);

  const initialUsers = group.users;
  const [selectedUsersIds, setSelectedUsersIds] = useState<User["id"][]>([]);

  const usersChanged = useMemo(() => {
    if (selectedUsersIds.length !== initialUsers.length) {
      return true;
    }
    if (selectedUsersIds.length === 0) {
      return false;
    }
    return !selectedUsersIds.every((id) => initialUsersIds.includes(id));
  }, [initialUsersIds, selectedUsersIds, initialUsers.length])

  const submitDisabled = !usersChanged;

  return (
    <form className={classes.root} method="post" {...props}>
      <p className={classes.helpText}>{t("components.EditGroupMembersModal.helpText", {name: group.name})}</p>
      <UsersSelect
        users={users}
        groups={groups}
        initialValues={{
          usersIds: initialUsersIds
        }}
        submitDisabled={submitDisabled}
        onSelectChange={setSelectedUsersIds}
        onSubmit={onEditGroupMembers}
      />
    </form>
  )
}

function getIds(arrayOfObjects: {id: string}[]) {
  return arrayOfObjects.map(({id}) => id);
}

const EditGroupMembersModal = {
  Header: EditGroupMembersModalHeader,
  Content: EditGroupMembersModalContent,
}

export default EditGroupMembersModal;
