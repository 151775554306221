import React, {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {useTour} from "../../../providers/tour";

import Table from "../../../ui/table";
import Skeleton from "../../../ui/skeleton";
import LessonsTableTable, {CourseParams, LessonParams} from "./LessonsTable.table";
import {classes as tourClasses, isEditorIndexTourMock} from "../../../scenes/editor/index.tour";
import {isEditorCourseEditTourMock} from "../../../scenes/editor/course-edit.tour";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {DragIndicatorIcon} from "../../../ui/icons";

import cn from "classnames";
import classes from "./LessonsTable.module.css"

type GenericProps = Pick<ComponentProps<typeof Table>, "className"> & {
  allowDragNDrop?: boolean
}

export type LessonProps = GenericProps & LessonParams;
export type CourseProps = GenericProps & CourseParams;

export type Props = (LessonProps | CourseProps) & {manageDisabled?: boolean};

export default function LessonsTable<P extends Props>({
  className, data, courses, currentCourse,
  onLessonCopy, onLessonDelete, onCourseCopy, onCourseDelete, onLessonMove, allowDragNDrop, manageDisabled
}: P) {
  const {t} = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = LessonsTableTable.use(data, courses, currentCourse, manageDisabled,
    onLessonCopy, onLessonDelete, onLessonMove, onCourseCopy, onCourseDelete);

  const {mock} = useTour();

  return (
    <Table className={cn(classes.root, className)} {...getTableProps()}>
      <Table.Head className={tourClasses.lessonTableHeader}>
        {headerGroups.map((headerGroup) => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {allowDragNDrop && (
              <Table.Cell/>
            )}
            {headerGroup.headers.map(column => (
              <Table.Cell {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Head>
      <DroppableTableBody
        {...(allowDragNDrop ? {asDroppable: true, droppableId: "lessonTable"} : {asDroppable: false})}
        {...getTableBodyProps()
      }>
        {rows.map((row, index) => {
          prepareRow(row);

          const type = row.original.__typename?.toLowerCase() as "lesson" | "course";
          const indexItemMock = isEditorIndexTourMock(mock)
            ? mock?.getBy[type]?.get(row.original.id) : undefined;
          const courseItemMock = (isEditorCourseEditTourMock(mock) && type === "lesson")
            ? mock?.getBy[type]?.get(row.original.id) : undefined;

          const draggableProps: ComponentProps<typeof DraggableTableRow> = allowDragNDrop ? {
            asDraggable: true,
            draggableId: `${type}-${row.original.id}`,
            index
          } : {
            asDraggable: false
          }

          return (
            <DraggableTableRow
              {...draggableProps}
              className={cn(indexItemMock?.classes.row, classes.row)}
              {...row.getRowProps()}
            >
              {allowDragNDrop && (
                <Table.Cell className={cn(classes.dragIndicatorCell, courseItemMock?.classes.dragHandle)}>
                  <DragIndicatorIcon className={classes.dragIndicator}/>
                </Table.Cell>
              )}
              {row.cells.map(cell => (
                <Table.Cell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </Table.Cell>
              ))}
            </DraggableTableRow>
          )
        })}
        {!rows.length && (
          <Table.Row>
            <Table.Cell colSpan={999}>
              <div className={classes.noData}>
                {t("components.LessonsTable.noData")}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </DroppableTableBody>
    </Table>
  )
}

function DroppableTableBody(props: Omit<ComponentProps<typeof Table.Body>, "innerRef"> & ({
  asDroppable?: false
} | ({
  asDroppable: true
} & Omit<ComponentProps<typeof Droppable>, "children">))) {
  const {asDroppable, ...rest} = props;

  if (asDroppable) {
    const {
      droppableId, type, mode, isDropDisabled, isCombineEnabled, direction, ignoreContainerClipping,
      renderClone, getContainerForClone,
      children, asDroppable, ...rest
    } = props;

    const droppableProps = {
      droppableId, type, mode, isDropDisabled, isCombineEnabled, direction, ignoreContainerClipping,
      renderClone, getContainerForClone,
    }
    return (
      <Droppable {...droppableProps}>
        {provided => (
          <Table.Body {...rest} innerRef={provided.innerRef}>
            {children}
            {provided.placeholder}
          </Table.Body>
        )}
      </Droppable>
    )
  }

  return (<Table.Body {...rest}/>)
}

function DraggableTableRow(props: Omit<ComponentProps<typeof Table.Row>, "innerRef"> & (
  {asDraggable?: false} | ({asDraggable: true} & Omit<ComponentProps<typeof Draggable>, "children">)
)) {
  const {asDraggable, ...rest} = props;

  if (asDraggable) {
    const {
      draggableId, index, disableInteractiveElementBlocking, isDragDisabled, shouldRespectForcePress,
      asDraggable, ...rest
    } = props;
    const draggableProps = {
      draggableId, index, disableInteractiveElementBlocking, isDragDisabled, shouldRespectForcePress
    }
    return (
      <Draggable {...draggableProps} key={draggableId}>
        {provided => (
          <Table.Row
            {...rest}
            innerRef={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          />
        )}
      </Draggable>
    )
  }

  return (<Table.Row {...rest}/>)
}

LessonsTable.Skeleton = React.memo(function (props) {
  return (
    <Table className={classes.root} {...props}>
      <Table.Body>
        <LessonsTable.SkeletonRow/>
        <LessonsTable.SkeletonRow/>
        <LessonsTable.SkeletonRow/>
        <LessonsTable.SkeletonRow/>
      </Table.Body>
    </Table>
  )
})

LessonsTable.SkeletonRow = React.memo(function () {
  return (
    <Table.Row>
      <Table.Cell><Skeleton.Block width={280} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={28} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={50} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={28} height={28}/></Table.Cell>
    </Table.Row>
  )
})
