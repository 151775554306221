import {isEmpty} from "lodash";
import React, {ComponentType, memo, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useGuide} from "../../providers/guide";
import {isPreview} from "../../redux/player/selectors";
import canSkipLevel from "../../redux/player/selectors/canSkipLevel";
import {Lesson, LessonMode} from "../../schema";
import Link from "../../ui/link";

type NumericDict<V> = { [key: number]: V }

type props = {
  lesson: Lesson,
  level: number,
} & Omit<React.ComponentProps<"span">, "children">

type Component = ComponentType<props> & {
  Title: ComponentType<props>
};

const IntroMessage: Component = Object.assign(memo<props>(function ({lesson, level, ...props}: props) {
  const {t} = useTranslation();
  const canSkip = useSelector(canSkipLevel);
  const preview = useSelector(isPreview);

  let text: string;

  switch (lesson.mode) {
    case LessonMode.STUDY_THREE_STEP:
    case LessonMode.STUDY_TWO_STEP:
      text = ({
        1: t("player.introMessageLevel1"),
        2: t("player.introMessageLevel2"),
        3: t("player.introMessageLevel3"),
      } as NumericDict<string>)[level] ?? "[NotImplemented]"
      break;

    case LessonMode.CHOICE_BASED_STUDY:
      text = t("player.introMessageTest")
      break;

    case LessonMode.CHOICES_TEST_TWO_STEP:
      text = ({
        1: t("player.introMessageTestLevel1"),
        2: t("player.introMessageTestLevel2"),
      } as NumericDict<string>)[level] ?? "[NotImplemented]"
      break;

    case LessonMode.CUSTOM_PARAMETERS_TEST:
    case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
    case LessonMode.QUIZ:
      text = isEmpty(lesson.startMessage)
      ? t("player.introMessageQuiz")
      : lesson.startMessage!
      break;

    case LessonMode.INTERVIEW:
      text = t("player.introMessageInterview")
      break;

    default:
      text = "[NotImplemented]"
      break;
  }

  const {open: openGuide} = useGuide();

  const onHelpClick = useCallback(() => {
    openGuide("player.main");
  }, [openGuide]);

  return (
    <span {...props}>
      {level === 1 && lesson.mode !== LessonMode.QUIZ ? (
        <span>
          {text + "\n\n"}
          <Link
            style={{
              font: "inherit",
              display: "inherit"
            }}
            onClick={onHelpClick}
          >
            {t("player.introGuideLink")}
          </Link>
          {t("player.introGuideInfo")}
        </span>
      ) : <span>{text}</span>}
      {canSkip && !preview && (
          "\n\n" + t("player.introSkipLevel")
      )}
    </span>
  )

}), {
  Title: memo<props>(function ({lesson, level, ...props}: props) {
    const {t} = useTranslation();

    let text: string;

    switch (lesson.mode) {
      case LessonMode.STUDY_THREE_STEP:
      case LessonMode.STUDY_TWO_STEP:
      case LessonMode.CHOICE_BASED_STUDY:
      case LessonMode.CHOICES_TEST_TWO_STEP:
        text = t("player.introTitle", {level});
        break;
      case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
      case LessonMode.CUSTOM_PARAMETERS_TEST:
      case LessonMode.QUIZ:
        text = t("player.introQuizTitle")
        break;
      case LessonMode.INTERVIEW:
        text = t("player.introInterviewTitle")
        break;
      default:
        text = "[NotImplemented]"
        break;
    }

    return <span {...props}>{text}</span>
  })
});

export default IntroMessage;