import React from "react";
import {gql} from "@apollo/client";
import {Scalars, Scenario} from "../../../schema";
import {Action, ActionInput} from "../../../types";
import AvatarActionEditForm from "./AvatarActionEditForm";
import SystemActionEditForm from "./SystemActionEditForm";
import UserInputActionEditForm from "./UserInputActionEditForm";
import ChoiceUserInputActionEditForm from "./ChoiceUserInputActionEditForm";
import RandomAvatarActionEditForm from "./RandomAvatarActionEditForm";

type opts = {
  action: Action,
  testId?: string,
  branchId?: Scalars["ID"],
  scenarios?: Scenario[],
  noAvatar?: boolean,
  customScores?: boolean,
  customParametersSupport?: boolean,
  customParameters?: Scalars["String"][],
  inputPhraseLimit?: Scalars["Int"],
  submitDisabled?: boolean,
  onSubmit?: (data: ActionInput) => void
}

export default function ActionEditForm({
  action, testId, branchId, scenarios, noAvatar,
  customScores, customParametersSupport, customParameters, inputPhraseLimit, submitDisabled, onSubmit
}: opts) {
  const submit = submitDisabled ? undefined : onSubmit
  switch (action.__typename) {
    case "AvatarAction":
      return (
        <AvatarActionEditForm
          testId={testId}
          action={action}
          noAvatar={noAvatar}
          onSubmit={submit}
        />
      )

    case "UserInputAction":
      return (
        <UserInputActionEditForm
          testId={testId}
          action={action}
          phraseLimit={inputPhraseLimit}
          onSubmit={submit}
        />
      )

    case "SystemAction":
      return (
        <SystemActionEditForm
          testId={testId}
          action={action}
          onSubmit={submit}
        />
      )

    case "ChoiceUserInputAction":
      return (
        <ChoiceUserInputActionEditForm
          testId={testId}
          action={action}
          scenarios={scenarios}
          branchId={branchId!}
          customScoreSupport={customScores}
          customParametersSupport={customParametersSupport}
          customParameters={customParameters}
          phraseLimit={inputPhraseLimit}
          onSubmit={submit}
        />
      )

    case "RandomAvatarAction":
      return (
        <RandomAvatarActionEditForm
          testId={testId}
          action={action}
          branchId={branchId!}
          scenarios={scenarios}
          onSubmit={submit}
        />
      )

    default:
      return null;
  }
}

ActionEditForm.fragments = {
  root: gql`
    fragment ActionEditForm on Action {
      id

      ... on AvatarAction {
        ...AvatarActionEditForm
      }

      ... on UserInputAction {
        ...UserInputActionEditForm
      }

      ... on SystemAction {
        ...SystemActionEditForm
      }

      ... on ChoiceUserInputAction {
        ...ChoiceUserInputActionEditForm
      }

      ... on RandomAvatarAction {
        ...RandomAvatarActionEditForm
      }
    }

    ${AvatarActionEditForm.fragments.root}
    ${UserInputActionEditForm.fragments.root}
    ${SystemActionEditForm.fragments.root}
    ${ChoiceUserInputActionEditForm.fragments.root}
    ${RandomAvatarActionEditForm.fragments.root}
  `
}

// For Avatar and System text or anything else using TTS
export function limitSpeechTextOnChange(value: string, maxLength: number) {
  const regexp = /(.*?)(?:\+|$)(\w|[а-яА-Я]|\n|$)/gm;
  let m;
  let length = 0;
  let text = "";

  while (((m = regexp.exec(value ?? "")) !== null) && (length < maxLength)) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regexp.lastIndex) {
      regexp.lastIndex++;
    }
    if (length + m[1].length + m[2].length > maxLength) {
      text += m[0].slice(0, maxLength - length)
      length = maxLength
    } else {
      length += m[1].length + m[2].length
      text += m[0]
    }
  }

  return {value: text, length};
}
