import {PlayerReduxState} from "../types";
import {LessonMode} from "../../../schema";

export default function getMaxLevel(state: PlayerReduxState): number {
  switch (state.lesson.mode) {
    case LessonMode.STUDY_THREE_STEP:
      return 3;
    case LessonMode.CHOICES_TEST_TWO_STEP:
    case LessonMode.STUDY_TWO_STEP:
      return 2;
    case LessonMode.INTERVIEW:
    case LessonMode.CHOICE_BASED_STUDY:
    case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
    case LessonMode.CUSTOM_PARAMETERS_TEST:
    case LessonMode.QUIZ:
      return 1;
    default:
      console.warn("getMaxLevel: unsupported lesson mode " + state.lesson.mode)
      return 1;
  }
}
