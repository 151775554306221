import React from "react";

import cn from "classnames";
import classes from "./breadcrumbs.module.css";

export default function Breadcrumbs({className, children, ...props}: React.ComponentProps<"nav">) {
  return (
    <nav className={cn(classes.root, className)} {...props}>
      {React.Children.map(children, (child, index) => !child ? undefined : (
        <React.Fragment>
          {index > 0 && (
            <span className="ml-1 mr-1">{">"}</span>
          )}
          {child}
        </React.Fragment>
      ))}
    </nav>
  )
}
