import React from "react"

import CloseModalButton from "./closemodalbutton";
import Scroll from "./scroll";

import cn from "classnames"
import classes from "./modal.module.css"

type props = React.ComponentProps<"div"> & {
  onClose?: () => void,
  adaptive?: boolean,
  size?: "s" | "m" | "l" | "xl",
  hideCloseButton?: boolean
}

function Modal({size, adaptive, onClose, className: extraClassName, children, hideCloseButton, ...props}: props) {
  const className = cn(classes.root, {[classes[size!]]: size}, {[classes.adaptive]: adaptive}, extraClassName);

  return (
    <div className={className} {...props}>
      {!hideCloseButton && (
        <CloseModalButton onClose={onClose}/>
      )}

      {children}
    </div>
  )
}

function ModalHeader({className, ...props}: React.ComponentProps<"div">) {
  return <div className={cn(classes.header, className)} {...props}/>
}

function ModalContent({className, ...props}: React.ComponentProps<"div">) {
  return <Scroll className={cn(classes.content, className)} {...props}/>
}


function ModalFooter({className, ...props}: React.ComponentProps<"div">) {
  return <div className={cn(classes.footer, className)} {...props}/>
}

export {ModalHeader, ModalContent, ModalFooter}
export default Modal;
