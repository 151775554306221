/* eslint-disable max-lines */
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {DashboardDataType, SelectedDashboardData} from "../../schema";
import {BarChart, Card, Color, DonutChart, Title} from "@tremor/react";
import {ChartMeasurementUnits, ChartVisualization} from "../../types";

type props = {
  data?: SelectedDashboardData,
  dataType: DashboardDataType,
  visualization: ChartVisualization,
  measurementUnits: ChartMeasurementUnits,
  overrideTitle?: string,
  overrideCategories?: string[],
  overrideColors?: Color[],
}

export default function DashboardChart(
  {data, dataType, visualization, measurementUnits, overrideTitle, overrideCategories, overrideColors}: props
) {
  const {t} = useTranslation();
  const valueFormatterAbs = (number: number
    ) => `${Intl.NumberFormat("us", {maximumFractionDigits: 1}).format(number).toString()}`;
  const valueFormatter = (number: number
    ) => `${Intl.NumberFormat("us", {maximumFractionDigits: 1}).format((number * 100 / data?.sum!)).toString()} %`;
  const valueFormatterByLesson = (number: number
    ) => `${Intl.NumberFormat("us", {maximumFractionDigits: 1}).format(number).toString()} %`;

  const {title, categories, minValue, maxValue} = useMemo(() => {
    switch (dataType) {
      case DashboardDataType.STARTED_TO_ALL:
        return {title: t("components.DashboardChart.title.STARTED_TO_ALL"), categories: [],
        minValue: 0, maxValue: 0}
      case DashboardDataType.PASSED_TO_STARTED:
        return {title: t("components.DashboardChart.title.PASSED_TO_STARTED"), categories: [], minValue: 0,
        maxValue: 0}
      case DashboardDataType.NO_HINTS_TO_PASSED:
        return {title: t("components.DashboardChart.title.NO_HINTS_TO_PASSED"), categories: [], minValue: 0,
        maxValue: 0}
      case DashboardDataType.SESSIONS:
        return {title: t("components.DashboardChart.title.SESSIONS"), categories: [], minValue: 0,
        maxValue: 0}
      case DashboardDataType.FAIL_REASONS:
        return {title: t("components.DashboardChart.title.FAIL_REASONS"), categories: [], minValue: 0, maxValue: 0}
      case DashboardDataType.LESSONS_STATUS:
        return {
          title: t("components.DashboardChart.title.LESSONS_STATUS"),
          categories: ["passed", "started", "assigned"],
          minValue: 0,
          maxValue: 0
        }
      case DashboardDataType.AVG_SCORE:
        return {title: t("components.DashboardChart.title.AVG_SCORE"), categories: [], minValue: 0,
        maxValue: 100}
      case DashboardDataType.AVG_SCORE_BY_LESSON:
        return {title: t("components.DashboardChart.title.AVG_SCORE_BY_LESSON"), categories: ["avg score"],
        minValue: 0, maxValue: 100}
      case DashboardDataType.AVG_FEEDBACK:
        return {title: t("components.DashboardChart.title.AVG_FEEDBACK"), categories: [], minValue: 0, maxValue: 5}
      case DashboardDataType.AVG_FEEDBACK_BY_LESSON:
        return {title: t("components.DashboardChart.title.AVG_FEEDBACK_BY_LESSON"), categories: ["avg feedback"],
        minValue: 0, maxValue: 5}
      case DashboardDataType.AVG_TIME_BY_LESSON:
        return {title: t("components.DashboardChart.title.AVG_TIME_BY_LESSON"), categories: ["avg time (minutes)"],
        minValue: 0, maxValue: 0}
      default:
        return {title: "NotImplemented", categories: [], minValue: 0, maxValue: 0}
      }
  }, [dataType, t])

  const chart = useMemo(() => {
    if (!data) {
      return;
    }
    const indexedData = [
      {
        name: t("components.DashboardChart.type.noHints"),
        value: data?.noHints
      },
      {
        name: t("components.DashboardChart.type.passed"),
        value: data?.passed
      },
      {
        name: t("components.DashboardChart.type.started"),
        value: data?.started
      },
      {
        name: t("components.DashboardChart.type.assigned"),
        value: data?.assigned
      },
      {
        name: t("components.DashboardChart.type.byScore"),
        value: data?.byScore
      },
      {
        name: t("components.DashboardChart.type.badWord"),
        value: data?.badWord
      },
      {
        name: t("components.DashboardChart.type.notFinished"),
        value: data?.notFinished
      },
      {
        name: t("components.DashboardChart.type.avgScore"),
        value: measurementUnits === ChartMeasurementUnits.ABS ? data?.avgScore! / 10 : data?.avgScore!
      },
      {
        name: t("components.DashboardChart.type.avgFeedback"),
        value: data?.avgFeedbackScore
      }
    ]

    const indexedDataByLessonAbs = data.byLesson.map(lessonData => (
      {
        name: lessonData.name,
        "passed": lessonData?.passed!,
        "started": lessonData?.started!,
        "assigned": lessonData?.assigned!,
        "avg score": lessonData?.avgScore! / 10,
        "score": lessonData?.avgScore! / 10,
        "avg feedback": lessonData.avgFeedbackScore!,
        "avg time (minutes)": lessonData.avgTime / 60
      }
    ))

    const indexedDataByLessonPercent = data.byLesson.map(lessonData => (
      {
        name: lessonData.name,
        "passed": (lessonData.sum! > 0) ? lessonData?.passed! / lessonData.sum! * 100 : 0,
        "started": (lessonData.sum! > 0) ? lessonData?.started! / lessonData.sum! * 100 : 0,
        "assigned": (lessonData.sum! > 0) ? lessonData?.assigned! / lessonData.sum! * 100 : 0,
        "avg score": lessonData?.avgScore! / 10,
        "score": lessonData?.avgScore! / 10,
        "avg feedback": lessonData.avgFeedbackScore! / 5 * 100,
        "avg time (minutes)": 0
      }
    ))

    const indexedDataByLessonDonut = data.byLesson.map(lessonData => [
      {
        lessonName: lessonData.name,
        sum: lessonData.sum
      },
      {
        name: t("components.DashboardChart.type.passed"),
        value: lessonData?.passed
      },
      {
        name: t("components.DashboardChart.type.started"),
        value: lessonData?.started
      },
      {
        name: t("components.DashboardChart.type.assigned"),
        value: lessonData?.assigned
      },
      {
        name: t("components.DashboardChart.type.avgScore"),
        value: measurementUnits === ChartMeasurementUnits.ABS ? lessonData?.avgScore! / 10 : lessonData?.avgScore!
      },
      {
        name: t("components.DashboardChart.type.avgFeedback"),
        value: lessonData?.avgFeedbackScore
      },
      {
        name: t("components.DashboardChart.type.avgTime"),
        value: lessonData?.avgTime / 60
      },
    ])

    const indexedDataByLesson = measurementUnits === ChartMeasurementUnits.ABS
    ? indexedDataByLessonAbs
    : indexedDataByLessonPercent

    if (data && data.byLesson.length > 0) {
      const columns = data.byLesson.length > 3 ? 4 : data.byLesson.length
      const style = ` min-h-[50vh] grid grid-cols-${columns} gap-4`
      if (visualization === ChartVisualization.DONUT) {
        return (
          <Card className={style}>
            {indexedDataByLessonDonut.map(lessonData => (
              <div>
                <Title className="pl-8">{overrideTitle ?? lessonData[0].lessonName}</Title>
                <DonutChart
                  className="mt-6"
                  data={lessonData}
                  category="value"
                  index="name"
                  showLabel={false}
                  valueFormatter={measurementUnits === ChartMeasurementUnits.PERCENTAGE ? (number: number
                    ) => `${Intl.NumberFormat("us", {maximumFractionDigits: 1}).format(
                      (number * 100 / lessonData[0].sum!)).toString()} %` : valueFormatterAbs}
                  colors={["violet", "slate", "cyan", "rose", "emerald", "yellow"]}
                />
              </div>
            ))}
          </Card>
        )
      } else {
        return (
          <Card className="mt-6">
              <>
                <Title className="pl-8">{overrideTitle ?? title}</Title>
                <BarChart
                  className="min-h-[50vh]"
                  data={indexedDataByLesson}
                  categories={overrideCategories ?? categories}
                  index="name"
                  minValue={minValue}
                  maxValue={maxValue}
                  valueFormatter={measurementUnits === ChartMeasurementUnits.PERCENTAGE
                    ? valueFormatterByLesson
                    : valueFormatterAbs}
                  colors={overrideColors ?? ["emerald", "cyan", "violet", "rose", "yellow", "slate"]}
                />
              </>
          </Card>
        )
      }
    } else {
      if (visualization === ChartVisualization.DONUT) {
        return (
          <Card className="mt-6">
            <Title className="pl-8">{overrideTitle ?? title}</Title>
            <DonutChart
              className="mt-6 min-h-[50vh]"
              data={indexedData}
              category="value"
              index="name"
              valueFormatter={
                measurementUnits === ChartMeasurementUnits.PERCENTAGE ? valueFormatter : valueFormatterAbs
              }
              colors={["violet", "slate", "cyan", "rose", "emerald", "yellow"]}
            />
          </Card>
        )
      } else {
        const nonNullData = indexedData.filter(data => !!data.value)
        return (
          <Card className="mt-6">
            <Title className="pl-8">{overrideTitle ?? title}</Title>
            <BarChart
              className="mt-6 min-h-[50vh]"
              data={nonNullData}
              index="name"
              minValue={minValue}
              maxValue={maxValue}
              categories={["value"]}
              valueFormatter={
                measurementUnits === ChartMeasurementUnits.PERCENTAGE ? valueFormatter : valueFormatterAbs
              }
              colors={["violet"]}
              yAxisWidth={48}
            />
          </Card>
        )
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, visualization, measurementUnits, title, t])

  return (
    <>
      {chart}
    </>
  );
}
