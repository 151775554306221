import {PlayableAction, PlayerReduxState} from "../types";
import isResultStage from "./isResultStage";
import isIntroStage from "./isIntroStage";
import getCurrentScenario from "./getCurrentScenario";

export function getPreviousAction(state: PlayerReduxState): PlayableAction | null {
    if (isResultStage(state) || isIntroStage(state) || state.index === 0) {
        return null;
    }

    const action = getCurrentScenario(state).actions[state.index - 1];

    if (!action) {
        console.warn("getPreviousAction: wrong state");
        return null;
    }

    return action;
}
