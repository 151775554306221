import React from "react";
import {AsAnyComponentWithDefault} from ".";
import classes from "./link.module.css";
import cn from "classnames";

export type OwnProps = {
  color?: "secondary" | "primary" | "brand" | "success" | "danger"
}

export type Props<T extends React.ElementType> = AsAnyComponentWithDefault<T, OwnProps, "button">
export type DefaultProps = Omit<Props<"button">, "as">

const defaultProps = {
  as: "button",
  color: "primary",
}

export default function Link<T extends React.ElementType>({
  as: Component, color, className, ...props
}: Props<T> & Omit<typeof defaultProps, "as">) {
  return <Component className={cn(classes.root, {[classes[color]]: !!color}, className)} {...props}/>
}

Link.defaultProps = defaultProps;
