import React, {useEffect} from "react";
import classes from "./SimpleAvatarPlayer.module.css";
import {Avatar} from "../../schema";
import cn from "classnames";

const avatars: { [key: string]: string } = {
  MALE: require("../../assets/avatars/male.png").default,
  FEMALE: require("../../assets/avatars/female.png").default,
}

type props = React.ComponentProps<"div"> & {
  className?: string
  avatar: Avatar,
  portraitMode?: boolean,
  onLoaded?: () => void,
}

const SimpleAvatarPlayer = function ({className, avatar, portraitMode, onLoaded, ...props}: props) {
  useEffect(() => {
    onLoaded && onLoaded();
  }, [onLoaded]);

  return (
    <div className={cn(classes.root, className)} {...props}>
      <img
        className={cn(classes.img, {[classes.portrait]: portraitMode})}
        alt={avatar.name}
        src={avatar.previewFull ?? avatars[avatar.gender] ?? avatars["MALE"]}
      />
    </div>
  );
};

export default SimpleAvatarPlayer;
