import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {analyticsSendEvent} from "../../../libs/analytics";
import {getLevel} from "../selectors";

export default function skipLevel() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();
    const level = getLevel(state);

    analyticsSendEvent("playerSkipLevel", {
      sessionId: state.sessionId!,
      level: getLevel(state),
    });

    dispatch({
      type: "skipLevel",
      payload: {
        level: level+1
      }
    })
  }
}