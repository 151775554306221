import React from "react";
import {useTranslation} from "react-i18next";
import WithTooltip from "../ui/tooltip";
import classes from "./SystemIcon.module.css";
import cn from "classnames";

type props = React.ComponentProps<"div"> & {
  adaptive?: boolean,
}

const SystemIcon = React.memo(function ({className: extraClassName, adaptive, ...props}: props) {
  const {t} = useTranslation();

  return (
    <WithTooltip className={cn(classes.root, {[classes.adaptive]: adaptive}, extraClassName)} showAfter={1000}
                 helpText={t("components.SystemIcon.helpText")} {...props}>
      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
        <circle fill="currentColor" r="50%" cx="50%" cy="50%"/>
        <text textAnchor="middle" x="50%" y="80" fill="#fff" fontFamily="Helvetica" fontSize="52" fontWeight="bold">
          {t("components.SystemIcon.letter")}
        </text>
      </svg>
    </WithTooltip>
  )
});

export default SystemIcon;
