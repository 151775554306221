import React, {ComponentPropsWithoutRef, useMemo} from "react";
import {useTranslation} from "react-i18next";
import useLessonModeTranslation from "../hooks/translation/useLessonModeTransaltion";
import {gql} from "@apollo/client";
import {Course, Lesson, LessonEducationState} from "../schema";
import {reverse} from "../routing";
import {formatDuration} from "../time";
import {getSharedId} from "../types";

import ReactLink from "./ReactLink";
import Skeleton from "../ui/skeleton";
import {CheckmarkFilledCircleIcon, CloseCircleOutlineIcon, PlayIcon, StatsChartIcon} from "../ui/icons";
import Link from "../ui/link";
import Mutable from "../ui/mutable";
import IconButton from "../ui/iconbutton";

import cn from "classnames";
import classes from "./LessonCard.module.css";

export type QueryData = {
  lesson: Pick<Lesson, "id" | "name" | "canPlay" | "hasPassed" | "mode" | "accessToken">,
  course: Pick<Course, "id" | "accessToken">,
  lessonState: Pick<LessonEducationState, "status" | "attemptsCount" | "passedAttemptsCount"> & {
    bestAttempt: Pick<NonNullable<LessonEducationState["bestAttempt"]>, "passed" | "score" | "time"> | undefined
  }
}

type props = ComponentPropsWithoutRef<"div"> & {
  lesson: QueryData["lesson"]
  course?: QueryData["course"],
} & ({
  lessonState: QueryData["lessonState"],
  isShared?: false
} | {
  lessonState?: undefined,
  isShared: true
})

export default function LessonCard({className, lesson, course, lessonState, isShared, ...props}: props) {
  const {t} = useTranslation();

  const {canPlay, name} = lesson;

  const {bestAttempt, attemptsCount, passedAttemptsCount} = lessonState ?? {};

  const mode = useLessonModeTranslation(lesson.mode)

  const linkProps = useMemo(() => {
    const url = new URL(reverse("playerPlay", {
      id: isShared ? getSharedId(lesson) : lesson.id
    }), document.baseURI);
    if (isShared && course) {
      url.searchParams.set("courseId", getSharedId(course));
    }
    return {
      // Making relative URL because react-router don't accept absolute
      href: canPlay ? (url.pathname + url.search) : undefined,
      target: isShared ? "_blank" : "_self",
      title: name
    };
  }, [canPlay, lesson, course, isShared, name])

  return (
    <div className={cn(classes.root, className)} {...props}>
      <div className={classes.card}>
        <Mutable
          as={isShared ? "a" : ReactLink}
          className={cn(classes.playArea, {[classes.disabled]: !canPlay})}
          {...linkProps}
        >
          <PlayIcon className={classes.playIcon}/>
        </Mutable>
        {!isShared && (
          <div className={classes.resultsArea}>
            <div className={classes.resultsSummary}>
              {bestAttempt ? (
                <>
                  <div className={classes.bestAttempt}>
                    <span className={cn(
                      classes.resultsSummaryPassed,
                      bestAttempt.passed ? classes.success : classes.fail
                    )}>
                      {bestAttempt.passed ? (
                        t("components.LessonCard.resultsSummary.sucess")
                      ) : (
                        t("components.LessonCard.resultsSummary.fail")
                      )}
                    </span>
                    <span>
                      {t("components.LessonCard.resultsSummary.highestScore")}: {bestAttempt.score}/100
                    </span>
                    <span>
                      {t("components.LessonCard.resultsSummary.bestTime")}: {formatDuration(bestAttempt.time)}
                    </span>
                  </div>
                  <div className={classes.attemptsCount}>
                    <span>
                      {t("components.LessonCard.resultsSummary.attemptsCount")}: {attemptsCount}
                    </span>
                    <span>
                      {t("components.LessonCard.resultsSummary.passedAttemptsCount")}: {passedAttemptsCount}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.bestAttempt}>
                    <span className={cn(classes.resultsSummaryPassed)}>
                      {t("components.LessonCard.resultsSummary.notStarted")}
                    </span>
                  </div>
                  <div className={classes.attemptsCount}>
                    <span>
                      {t("components.LessonCard.resultsSummary.attemptsCount")}: {attemptsCount}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className={classes.resultsSnippet}>
              {bestAttempt && (
                <div className={classes.resultsWidget}>
                  {bestAttempt.passed ? (
                    <CheckmarkFilledCircleIcon className={cn(classes.resultIcon, classes.success)}/>
                  ) : (
                    <CloseCircleOutlineIcon className={cn(classes.resultIcon, classes.fail)}/>
                  )}
                  <span className={classes.bestScore}>{bestAttempt.score}/100</span>
                </div>
              )}
              <IconButton as={ReactLink}
                size="l"
                className={classes.resultsButton}
                icon={StatsChartIcon}
                href={reverse("playerResults", {lessonId: lesson.id})}
              />
            </div>
          </div>
        )}
      </div>
      <Link
        as={isShared ? "a" : ReactLink}
        className={cn(classes.link, {[classes.disabled]: !canPlay})}
        {...linkProps}
      >
        {lesson.name ? (
          <span className={classes.name} translate="no">{lesson.name}</span>
        ) : (
          <span className={cn(classes.name, classes.notSpecified)}>{t("common.notSpecified")}</span>
        )}
        <span className={classes.lessonMode}>{mode}</span>
      </Link>
    </div>
  );
}

LessonCard.Skeleton = React.memo(function ({className, ...props}: React.ComponentProps<typeof Skeleton.Block>) {
  return (
    <div className={cn(classes.root, classes.skeleton, className)}  {...props}>
      <Skeleton.Block className={classes.card} height={160}/>
      <Skeleton.Text className={classes.name} height={23} width={160}/>
      <Skeleton.Text className={classes.lessonMode} height={14} width={200}/>
    </div>
  )
});


LessonCard.fragments = {
  root: gql`
    fragment LessonCard on Lesson {
      id
      name
      accessToken
      canPlay
      hasPassed
      mode
    }
  `,
  course: gql`
    fragment LessonCardCourse on Course {
      id
      accessToken
    }
  `,
  state: gql`
    fragment LessonCardState on LessonEducationState {
      status
      bestAttempt {
        passed
        score
        time
      }
      attemptsCount
      passedAttemptsCount
    }
  `
}
