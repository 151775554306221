import React from "react";
import cn from "classnames";
import classes from "./skeleton.module.css";

type props = React.ComponentProps<"div"> & { width?: number, height?: number }

const SkeletonText = React.memo(({className, width, height, style: styleProp, ...props}: props) => {
  const style = React.useMemo(() => ({
    ...(styleProp || {}),
    backgroundPositionX: width ? -width : undefined,
    width: width,
    height: height || "auto",
  }), [width, height, styleProp]);

  return (
    <div className={cn(classes.text, className)} style={style} {...props}>
      <span className={classes.textInner}>...</span>
    </div>
  )
});

const SkeletonBlock = React.memo(({className, width, height, style: styleProp, ...props}: props) => {
  const style = React.useMemo(() => ({
    ...(styleProp || {}),
    backgroundPositionX: width ? -width : undefined,
    width: width ?? undefined,
    height: height ?? undefined,
  }), [width, height, styleProp])

  return (
    <div className={cn(classes.block, className)} style={style} {...props}></div>
  )
});

const Skeleton = {
  Text: SkeletonText,
  Block: SkeletonBlock,
}

export default Skeleton;
