import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {LanguageCode} from "./schema";


export default function initI18n() {
  i18n
    .use(initReactI18next)
    .init({
      lng: (() => {
        let lang = localStorage.getItem("lang");

        if (!lang) {
          const browserLang = (navigator.languages && navigator.languages[0]) || navigator.language;

          if (["ru", "ru-ru", "uk-ua", "tt-ru", "be-by"].includes(browserLang.toLowerCase())) {
            lang = "ru";
          } else {
            lang = "en";
          }
        }

        document.documentElement.lang = lang;

        return lang;
      })(),
      resources: {
        en: {
          translation: require("./locales/en/main.json"),
          tours: require("./locales/en/tours.json")
        },
        ru: {
          translation: require("./locales/ru/main.json"),
          tours: require("./locales/ru/tours.json")
        },
      },
      react: {
        transKeepBasicHtmlNodesFor: ["br", "b", "i", "u"]
      }
    });

  i18n.on("languageChanged", (lang) => {
    document.documentElement.lang = lang;
    localStorage.setItem("lang", lang);
  });

  return i18n;
}

export function initTestingI18n() {
  i18n
    .use(initReactI18next)
    .init({
      lng: "en",
      fallbackLng: "en",
      ns: ["translations"],
      defaultNS: "translations",
      resources: {en: {translations: {}}},
    });

  i18n.on("languageChanged", (lang) => localStorage.setItem("lang", lang));

  return i18n;
}

export function getLanguages() {
  return [
    {
      lang: "en",
      languageCode: LanguageCode.EN_US,
      verboseName: "English",
      translationAvailable: true,
    },
    {
      lang: "ru",
      languageCode: LanguageCode.RU_RU,
      verboseName: "Русский",
      translationAvailable: true,
    },
  ]
}

export function getLanguageCode(lang: string): LanguageCode {
  if (lang === "cimode") {
    return LanguageCode.EN_US;
  }
  const languageCode = getLanguages().find((language) => (
    language.lang === lang
  ))?.languageCode;

  if (languageCode === undefined) {
    throw new Error("Language not implemented yet")
  }

  return languageCode;
}
