import {PlayableAction, PlayerReduxState} from "../types";
import {isAnyUserInputAction} from "../utils";
import getCurrentMaxIndex from "./getCurrentMaxIndex";
import getCurrentScenario from "./getCurrentScenario";

export default function getNavigateToActions(state: PlayerReduxState): {
  action: PlayableAction,
  index: number,
  available: boolean
}[] {
  const indexedActions = getCurrentScenario(state).actions.map((action, index) => ({
    action, index
  }))
  .filter(({action}) => isAnyUserInputAction(action))
  .map(({action, index}) => ({
    action,
    index,
    available: index <= getCurrentMaxIndex(state)!
  }))
  return indexedActions;
}
