import {useCallback, useEffect, useState} from "react";

export type useFullscreenModeOpts = {
  hotkey?: string
}

export default function useFullscreenMode(opts?: useFullscreenModeOpts): [boolean, () => void] {
  const [allowFullscreen, setFullscreenAllowed] = useState<boolean>(true);

  const toggleFullscreen = useCallback(() => {
    if (!isFullscreen()) {
      requestFullscreen(document.body).catch(() => {
        setFullscreenAllowed(false);
      })
    } else {
      exitFullscreen();
    }
  }, []);

  const hotkey = opts?.hotkey;
  useEffect(() => {
    if (!hotkey) {
      return;
    }

    const onHotkey = (e: KeyboardEvent) => {
      if (e.code === hotkey) {
        toggleFullscreen();
      }
    }

    window.addEventListener("keydown", onHotkey);
    return () => {
      window.removeEventListener("keydown", onHotkey);

      if (isFullscreen()) exitFullscreen();
    }
  }, [hotkey, toggleFullscreen]);

  return [allowFullscreen, toggleFullscreen]
}


function isFullscreen(): boolean {
  // @ts-ignore
  return !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement);
}

function requestFullscreen(element: HTMLElement): Promise<void> {
  if (element.requestFullscreen) {
    return element.requestFullscreen()
    // @ts-ignore
  } else if (element.webkitRequestFullscreen) {
    // @ts-ignore
    return element.webkitRequestFullscreen();
    // @ts-ignore
  } else if (element.mozRequestFullScreen) {
    // @ts-ignore
    return element.mozRequestFullScreen();
  }

  return Promise.reject();
}

function exitFullscreen(): Promise<void> {
  if (document.exitFullscreen) {
    return document.exitFullscreen()
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    // @ts-ignore
    return document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.mozExitFullScreen) {
    // @ts-ignore
    return document.mozExitFullScreen();
  }

  return Promise.reject();
}
