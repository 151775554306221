import React from "react";
import {useTranslation} from "react-i18next";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Container from "../../ui/container";
import Layout, {Header, Main} from "../../components/Layout";
import classes from "./lesson-not-available.module.css";

type props = {
  reason?: "not-found" | "limits" | "expired"
}

export default function PlayerLessonNotAvailableScene({reason}: props) {
  const {t} = useTranslation();

  let reasonMessage;

  switch (reason) {
    case "limits":
      reasonMessage = t("playerLessonNotAvailable.limitsExceed");
      break;
    case "expired":
      reasonMessage = t("playerLessonNotAvailable.expired");
      break;
    case "not-found":
    default:
      reasonMessage = t("playerLessonNotAvailable.defaultReason");
  }

  return (
    <>
      <ReactDocumentTitle title={t("playerLessonNotAvailable.title")}/>
      <Layout className={classes.layoutRoot}>
        <Header type='transparent'/>
        <Main>
          <Container>
            <div className={classes.root}>
              <h1 className={classes.h1}>{t("playerLessonNotAvailable.h1")}</h1>
              <p className={classes.desc}>{reasonMessage}</p>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
