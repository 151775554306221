import {useCallback, useRef, useState} from "react";

type ClickEvent<T extends Element> = React.MouseEvent<T, MouseEvent> & React.TouchEvent<T>
type ClickEventHandler<T extends Element> = React.MouseEventHandler<T> | React.TouchEventHandler<T>

type opts<T extends Element> = {
  onClick?: ClickEventHandler<T>,
  onLongPress?: ClickEventHandler<T>,
  onLongRelease?: ClickEventHandler<T>,
  shouldPreventDefault?: boolean,
  delay?: number
}

export default function useLongPress<T extends Element>(
  {
    onClick,
    onLongPress,
    onLongRelease,
    shouldPreventDefault = true,
    delay = 300,
  }: opts<T> = {}
) {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const target = useRef<EventTarget>();

  const start = useCallback<ClickEventHandler<T>>((event: ClickEvent<T>) => {
    if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
        passive: false
      });
      target.current = event.target;
    }
    timeout.current = setTimeout(() => {
      onLongPress && onLongPress(event);
      setLongPressTriggered(true);
    }, delay);
  }, [onLongPress, delay, shouldPreventDefault]);

  const clear = useCallback(
      (event, shouldTriggerClick = true) => {
          timeout.current && clearTimeout(timeout.current);
          if (!longPressTriggered) {
            shouldTriggerClick && onClick && onClick(event);
          } else {
            onLongRelease && onLongRelease(event)
          }
          setLongPressTriggered(false);
          if (shouldPreventDefault && target.current) {
              target.current.removeEventListener("touchend", preventDefault);
          }
      },
      [shouldPreventDefault, onClick, onLongRelease, longPressTriggered]
  );

  return {
    onMouseDown: (e: ClickEvent<T>) => start(e),
    onTouchStart: (e: ClickEvent<T>) => start(e),
    onMouseUp: (e: ClickEvent<T>) => clear(e),
    onMouseLeave: (e: ClickEvent<T>) => clear(e, false),
    onTouchEnd: (e: ClickEvent<T>) => clear(e)
  };
}

// const isTouchEvent = (event: ClickEvent) => {
//   return event.nativeEvent instanceof TouchEvent;
// };

const preventDefault: EventListener = (event: Event) => {
  if (event instanceof TouchEvent) {
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  } else {
    return
  }
};
