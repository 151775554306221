import React, {forwardRef, useMemo} from "react";
import {mapObject, stopEventHandlerPropagation} from "../utils";
import Scroll from "./scroll";
import classes from "./textarea.module.css";
import cn from "classnames";

type TextareaType = React.ForwardRefExoticComponent<React.ComponentProps<"textarea"> & {
  adaptive?: boolean,
  stopPropagation?: boolean
} & React.RefAttributes<HTMLTextAreaElement>>

const Textarea: TextareaType = forwardRef(
  ({className: extraClassName, adaptive, stopPropagation, ...props}, ref) => {
    const className = cn(classes.root, {[classes.adaptive]: adaptive}, extraClassName);

    const {onChange, onKeyUp, onKeyDown} = props

    const stopPropagationProps = useMemo(() => (
      mapObject({
        onChange,
        onKeyUp,
        onKeyDown
      }, stopEventHandlerPropagation)
    ), [onChange, onKeyUp, onKeyDown])

    return (
      <Scroll as="textarea" ref={ref} className={className} {...props} {...stopPropagationProps}/>
    )
  }
)

export default Textarea;
