/* eslint-disable max-lines */
import {gql} from "@apollo/client";
import {QueryData as AccountInfoQueryData} from "../../components/account/AccountInfo";
import MembersFilterForm from "../../components/account/MembersFilterForm";
import MembersTableTable from "../../components/account/members-table/MembersTable.table";

import {
  Course,
  EditorAccessType,
  EducationMembersPaginatedList,
  Group,
  Lesson,
  LessonParticipationSummary,
  QueryGetAccountMembersArgs,
  SelectedDashboardData,
  User
} from "../../schema";


export type PickedLesson = Pick<Lesson, "__typename" | "id" | "name">
export type PickedCourse = Pick<Course, "__typename" | "id" | "name"> & {
  lessons: Pick<Lesson, "id">[]
}

export type QueryData = AccountInfoQueryData & {
  users: User[] | undefined,
  educationItems: (PickedLesson | PickedCourse)[],
  dashboard: SelectedDashboardData,
  flatLessons: (PickedLesson | PickedCourse)[],
  groups: (Pick<Group, "id" | "name"> & {
    users: Pick<User, "id">[]
  })[]
  editorAccessType: EditorAccessType,
}

export type PaginatedListQueryVars = Omit<QueryGetAccountMembersArgs, "skip" | "first"> & {
  offset: QueryGetAccountMembersArgs["skip"],
  limit: QueryGetAccountMembersArgs["first"],
}

export type PaginatedListQueryData = {
  paginatedList: EducationMembersPaginatedList
}

export type ParticipationsSummaryQueryData = {
  participationsSummary: LessonParticipationSummary
}

export type UsersExportUrlWithFiltersQueryData = {
  usersExportUrlWithFilters: string
}

/* eslint-disable max-len */
const query = gql`
  query GetDashboardDataQuery($dataType: DashboardDataType!, $filter: DashboardDataFilterInput!) {
    educationItems: getLessons {
      __typename

      ... on Lesson {
        ...MembersTableLessons
      }

      ... on Course {
        ...MembersTableCourses
        ...MembersFilterFormCourses
      }
    }

    flatLessons: getLessons (flatMode: true) {
      __typename

      ... on Lesson {
        ...MembersFilterFormLessons
      }
    }
    
    editorAccessType: getEditorAccessType
    
    groups: getGroups {
      id
      name
      users {
        id
      }
      ...MembersFilterFormGroups
    }
  
    dashboard: getDashboardData(dataType: $dataType, filter: $filter) {
      __typename
      totalTime
      assigned
      started
      passed
      noHints
      byScore
      notFinished
      badWord
      avgScore
      avgFeedbackScore
      sum
      byLesson {
        name
        assigned
        started
        passed
        avgScore
        avgFeedbackScore
        avgTime
        sum
      }
    }
  }

  ${MembersTableTable.fragments.courses}
  ${MembersTableTable.fragments.lessons}
  ${MembersFilterForm.fragments.courses}
  ${MembersFilterForm.fragments.lessons}
  ${MembersFilterForm.fragments.groups}
`;
/* eslint-enable max-len */

export default query;