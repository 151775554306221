import React, {ComponentProps, useMemo} from "react";
import {gql} from "@apollo/client";
import {Cell, Column, useExpanded, useTable} from "react-table";
import {Lesson, LessonCourse} from "../../../schema";
import {useTranslation} from "react-i18next";
import LessonEditDropdown from "./LessonEditDropdown";
import LessonTableItem from "./LessonTableItem";
import CourseTableItem from "./CourseTableItem";
import LanguageIcon from "../../../ui/languageicon";
import {formatRelativeDateTime} from "../../../time";
import classes from "./LessonsTable.module.css";
import CourseEditDropdown from "./CourseEditDropdown";

type CellDataType = LessonCourse;

type LessonDropdownProps = ComponentProps<typeof LessonEditDropdown>
type CourseDropdownProps = ComponentProps<typeof CourseEditDropdown>

type GenericParams = {
  courses: LessonDropdownProps["courses"],
  currentCourse?: LessonDropdownProps["currentCourse"],
  onLessonCopy: LessonDropdownProps["onCopy"],
  onLessonDelete: LessonDropdownProps["onDelete"],
  onLessonMove: LessonDropdownProps["onMove"],
}

export type LessonParams = GenericParams & {
  data: Lesson[],
  onCourseCopy?: undefined,
  onCourseDelete?: undefined,
  optionsDisabled?: boolean
}

export type CourseParams = GenericParams & {
  data: LessonCourse[],
  optionsDisabled?: boolean,
  onCourseCopy: CourseDropdownProps["onCopy"],
  onCourseDelete: CourseDropdownProps["onDelete"]
}

export type Params = LessonParams | CourseParams

function useLessonsTableTable(
  data: Params["data"],

  courses: Params["courses"],
  currentCourse: Params["currentCourse"],
  optionsDisabled: Params["optionsDisabled"],
  onLessonCopy: Params["onLessonCopy"],
  onLessonDelete: Params["onLessonDelete"],
  onLessonMove: Params["onLessonMove"],

  onCourseCopy: Params["onCourseCopy"],
  onCourseDelete: Params["onCourseDelete"]
) {
  const {t, i18n} = useTranslation();

  const columns = useMemo<(Column<CellDataType>)[]>(() => ([
    {
      id: "lesson",
      Header: () => t("components.LessonsTable.lesson"),
      Cell: ({row}: Cell<CellDataType>) => {
        switch (row.original.__typename) {
          case "Lesson":
            return (
              <LessonTableItem
                lesson={row.original}
              />
            )
          case "Course":
            return (
              <CourseTableItem
                course={row.original}
              />
            )
          default:
            console.error(`Unsupported data type "${row.original.__typename}" in lesson (name) column`)
            return null;
        }
      },
    },

    {
      id: "language",
      Header: () => t("components.LessonsTable.language"),
      Cell: ({row}: Cell<CellDataType>) => {
        switch (row.original.__typename) {
          case "Lesson":
          case "Course":
            return (
              <span>
                <LanguageIcon
                  value={row.original.languageCode}
                  height={16}
                />
              </span>
            )
          default:
            console.error(`Unsupported data type "${row.original.__typename}" in language column`)
            return null;
        }
      }
    },

    {
      id: "lastUpdatedAt",
      Header: () => t("components.LessonsTable.lastUpdatedAt"),
      Cell: ({row}: Cell<CellDataType>) => {
        switch (row.original.__typename) {
          case "Lesson":
          case "Course":
            return (
              <span className={classes.dateTime}>
                {formatRelativeDateTime(row.original.lastUpdatedAt, i18n.language)}
              </span>
            )
          default:
            console.error(`Unsupported data type "${row.original.__typename}" in lastUpdatedAt column`)
            return null;
        }
      }
    },

    {
      id: "options",
      Header: () => null,
      Cell: ({row}: Cell<CellDataType>) => {
        switch (row.original.__typename) {
          case "Lesson":
            return (
              <LessonEditDropdown
                lesson={row.original}
                currentCourse={currentCourse}
                courses={courses}
                onCopy={onLessonCopy}
                onDelete={onLessonDelete}
                onMove={onLessonMove}
                disabled={optionsDisabled}
              />
            )
          case "Course":
            return (
              <CourseEditDropdown
                course={row.original}
                onCopy={onCourseCopy!}
                onDelete={onCourseDelete!}
                disabled={optionsDisabled}
              />
            )
          default:
            console.error(`Unsupported data type "${row.original.__typename}" in lastUpdatedAt column`)
            return null;
        }
      },
    },
  ]), [
    t, i18n, currentCourse, courses, optionsDisabled,
    onLessonCopy, onLessonDelete, onLessonMove, onCourseCopy, onCourseDelete
  ]);

  return useTable<CellDataType>({
    columns: columns,
    data: data,
  }, useExpanded);
}

const LessonsTableTable = {
  use: useLessonsTableTable,
  fragments: {
    root: gql`
      fragment LessonsTableTable on LessonCourse {
        __typename

        ... on Lesson {
          id
          ...LessonsTableTableLesson
        }

        ... on Course {
          id
          ...LessonsTableTableCourse
        }
      }

      fragment LessonsTableTableCourse on Course {
        name
        languageCode
        # createdAt
        lastUpdatedAt
        lessons {
          id
          ...LessonsTableTableLesson
        }
      }
      
      fragment LessonsTableTableLesson on Lesson {
        languageCode
        lastUpdatedAt
        ...LessonTableItem
        ...LessonEditDropdown
      }
      ${LessonTableItem.fragments.root}
      ${LessonEditDropdown.fragments.root}
    `,
    courses: gql`
      fragment LessonsTableTableCourses on Course {
        ...LessonEditDropdownCourses
      }
      ${LessonEditDropdown.fragments.courses}
    `
  }
}

export default LessonsTableTable
