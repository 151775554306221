import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import useLessonModeTranslation from "../../../hooks/translation/useLessonModeTransaltion";
import {useTour} from "../../../providers/tour";
import {reverse} from "../../../routing";
import {gql} from "@apollo/client";
import {isEditorIndexTourMock} from "../../../scenes/editor/index.tour";
import {Lesson} from "../../../schema";

import ReactLink from "../../ReactLink";
import Skeleton from "../../../ui/skeleton";

import cn from "classnames";
import classes from "./LessonTableItem.module.css";

type props = React.ComponentProps<typeof ReactLink> & {
  lesson: Pick<Lesson, "mode" | "name" | "id">
}

export default function LessonTableItem({className, lesson, ...props}: props) {
  const {t} = useTranslation();

  const {mock: tourMock} = useTour();
  const mock = useMemo(() => {
    if (tourMock && isEditorIndexTourMock(tourMock)) {
      return tourMock.getBy.lesson.get(lesson.id);
    }
  }, [tourMock, lesson.id])


  const mode = useLessonModeTranslation(lesson.mode)

  return (
    <ReactLink
      className={cn(classes.root, mock?.classes.item, className)}
      title={lesson.name}
      href={mock?.href ?? reverse("editorLessonEdit", {id: lesson.id})}
      {...props}
    >
      <span className={cn(classes.name, {[classes.notSpecified]: !!lesson.name})} translate="no">
        {lesson.name ?? t("common.notSpecified")}
      </span>
      <div className={classes.info}>
        <span className={classes.mode}>{mode}</span>
      </div>
    </ReactLink>
  )
}

LessonTableItem.Skeleton = React.memo(function ({className, ...props}: React.ComponentProps<typeof Skeleton.Block>) {
  return (
    <Skeleton.Block
      className={cn(classes.root, className)}
      height={60}
      {...props}
    />
  )
})

LessonTableItem.fragments = {
  root: gql`
    fragment LessonTableItem on Lesson {
      id
      name
      mode
    }
  `
}
