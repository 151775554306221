import React, {forwardRef} from "react";

import cn from "classnames";
import classes from "./checkbox.module.css";
import useAdaptiveClasses from "../hooks/useAdaptiveClasses";

type props = React.ComponentProps<"input"> & {
  adaptive?: boolean
  size?: "s" | "m" | "l"
};

const Checkbox = forwardRef(({className, adaptive, size, ...props}: props, ref: any) => {
  const ac = useAdaptiveClasses(classes, adaptive)
  return (
    <>
      <label className={cn(ac.root, {[ac[size ?? ""]]: size}, className)}>
        <input
          ref={ref}
          className={ac.input}
          type='checkbox'
          {...props}
        />

        <div className={ac.box}>
          <svg className={ac.icon} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d={"M0 8.80078C0 5.02955 0 3.14393 1.17157 1.97235C2.34315 0.800781 4.22876 0.800781 8 " +
              "0.800781C11.7712 0.800781 13.6569 0.800781 14.8284 1.97235C16 3.14393 16 5.02955 16 8.80078C16 " +
              "12.572 16 14.4576 14.8284 15.6292C13.6569 16.8008 11.7712 16.8008 8 16.8008C4.22876 16.8008 " +
              "2.34315 16.8008 1.17157 15.6292C0 14.4576 0 12.572 0 8.80078Z"}
              fill="currentColor"
            />

            <path className={ac.checkmark} d="M4.83594 9.04716L7.28038 11.1341L11.5026 5.80078" stroke="#fff"
                  strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>

        <span className={ac.alt}>[check]</span>
      </label>
    </>
  );
});

export default Checkbox;
