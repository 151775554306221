import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router";
import {path, reverse} from "../routing";

import PageNotFoundScene from "./system/page-not-found";
import {useCurrentAccount, useCurrentUser} from "../App.context";
import AnalyticsScene from "./analytics/analytics";


const AnalyticsBundle = React.memo(function () {
  const history = useHistory();
  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;

  useEffect(() => {
    if (!userId || !accountId) {
      history.push(reverse("analytics"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (!userId || !accountId) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={path("analytics")} component={AnalyticsScene}/>
      <Route component={PageNotFoundScene}/>
    </Switch>
  )
})

export default AnalyticsBundle;
