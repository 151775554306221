/* eslint-disable max-lines */
import React, {useMemo} from "react";
import {
  ActionResult,
  Avatar,
  RandomAvatarAction,
} from "../../../schema";
import SessionActionDisplay from "./SessionActionDisplay";
import {gql} from "@apollo/client";
import {getTextWithStressMarks} from "../../Phrase";
import AvatarIcon from "../../AvatarIcon";

type props = React.ComponentProps<"div"> & {
  action: RandomAvatarAction,
  avatar: Avatar,
  result: ActionResult,
}

const SessionRandomAvatarActionDisplay = function ({action, avatar, result, className, ...props}: props) {

  const selectedBranch = useMemo(() => {
    if (result.branchId !== undefined) {
      return action.branches.find(branch => (branch.id === result.branchId))
    } else {
      return undefined
    }
  }, [action, result])

  const avatarAction = selectedBranch?.avatarAction;
  let text = "";

  text = useMemo(
    () => avatarAction ? getTextWithStressMarks(avatarAction.text) : "", [avatarAction]
  )

  return (
    <SessionActionDisplay variant="avatar" icon={(
      <AvatarIcon adaptive avatar={avatar}/>
    )} {...props}>
      {text}
    </SessionActionDisplay>
  )
}

SessionRandomAvatarActionDisplay.fragments = {
  root: gql`
    fragment SessionRandomAvatarActionDisplay on Action {
      id
      ... on RandomAvatarAction {
        id
        branches {
          avatarAction {
            id
            text
          }
        }
      }
    }`,

  actionResult: gql`
    fragment SessionRandomAvatarDisplayActionResult on ActionResult {
      branchId
    }
  `,
}

export default SessionRandomAvatarActionDisplay;
