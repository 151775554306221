import React, {ComponentProps} from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import cn from "classnames";

import {Account} from "../../schema";
import Skeleton from "../../ui/skeleton";

import classes from "./AccountItem.module.css";

type props = ComponentProps<"button"> & {
  account: Account
}

export default function AccountItem({className, account, ...props}: props) {
  const {t} = useTranslation();

  const membershipType = ({
    "Editor": t("components.AccountItem.MembershipTypeEditor"),
    "Student": t("components.AccountItem.MembershipTypeStudent"),
  } as any)?.[account.membershipType ?? "NotImplemented"] ?? account.membershipType;

  return (
    <button className={cn(classes.root, className, {[classes.active]: account.hasActiveMembership})} {...props}>
      <span className={classes.name}>{account.name}</span>
      <div className={classes.row}>
        ID #{account.id}, {
          account.hasActiveMembership
            ? membershipType
            : t("components.AccountItem.HasNotActiveMembership", {membershipTypeName: membershipType})
        }
      </div>
    </button>
  )
}

AccountItem.Skeleton = React.memo(function ({className}: { className?: string }) {
  return (
    <div className={cn(classes.root, classes.skeleton, className)}>
      <h3 className={classes.name}><Skeleton.Text width={200}/></h3>
      <Skeleton.Block
        className={classes.card}
      />
    </div>
  )
});

AccountItem.fragments = {
  root: gql`
    fragment AccountItem on Account {
      id
      name
      membershipType
      hasActiveMembership
    }
  `
}
