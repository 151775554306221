import {PlayerReduxState, UserInputDisplayMode} from "../types";
import isRecognizing from "./isRecognizing";
import isErrorState from "./isErrorState";
import isIntroStage from "./isIntroStage";
import getCurrentActionDisplayMode from "./getCurrentActionDisplayMode";

export default function canSkip(state: PlayerReduxState): boolean {
  return !isRecognizing(state) && !isErrorState(state) && !isIntroStage(state) && (
    getCurrentActionDisplayMode(state) !== UserInputDisplayMode.HINT_TEXT
  );
}
