import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router";
import {path, reverse} from "../routing";

import PageNotFoundScene from "./system/page-not-found";

import EditorIndexScene from "./editor/index";
import EditorLessonEditScene from "./editor/lesson-edit";
import EditorCourseEditScene from "./editor/course-edit";
import {useAudio} from "../providers/audio";
import {AudioPlayback} from "../libs/audio";
import {isBrowserSupported} from "../browser";
import BrowserNotSupportedScene from "./system/browser-not-supported";
import {useCurrentAccount, useCurrentUser} from "../App.context";


const EditorBundle = React.memo(function () {
  const history = useHistory();

  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;

  const browserSupported = React.useMemo(() => isBrowserSupported(), []);

  useEffect(() => {
    if (!userId || !accountId) {
      history.push(reverse("login") + "?_next=" + encodeURIComponent(history.location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const {audioContext, audioManager} = useAudio();

  useEffect(() => {
    audioManager.installPlayback("editor", new AudioPlayback(audioContext));
    return () => audioManager.uninstallPlayback("editor");
  }, [audioContext, audioManager]);

  if (!userId || !accountId) {
    return null;
  }

  if (!browserSupported) {
    return (
      <BrowserNotSupportedScene/>
    )
  }

  return (
    <Switch>
      <Route exact path={path("editorLessonList")} component={EditorIndexScene}/>
      <Route exact path={path("editorLessonEdit")} component={EditorLessonEditScene}/>

      <Route exact path={path("editorCourseEdit")} component={EditorCourseEditScene}/>

      <Route component={PageNotFoundScene}/>
    </Switch>
  )
})

export default EditorBundle;
