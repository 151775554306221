/* eslint-disable max-lines */
import React, {ChangeEventHandler, ComponentProps, useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../hooks/useFormState";
import useVisibility from "../../hooks/useVisibility";
import {useLazyQuery} from "@apollo/client";

import without from "lodash/without";
import {getMapByObjectProperty, getArrayWithoutCopiesByObjectProperty, parseEmails} from "../../utils";
import {QueryData, usersQuery} from "./UsersSelect.graphql";

import {Group, QueryGetUsersByMixedDataArgs, User} from "../../schema";

import Link, {DefaultProps as LinkDefaultProps} from "../../ui/link";
import Textarea from "../../ui/textarea";
import Scroll from "../../ui/scroll";
import Checkbox from "../../ui/checkbox";
import ListSelector, {ListSelectorDisplayType, useListSelectorDisplayType} from "../ListSelector";
import {InformationIcon} from "../../ui/icons";
import PopupPortal from "../../ui/popupportal";
import Widget from "../../ui/widget";
import UserItem from "./UserItem";
import Button from "../../ui/button";
import Modal, {ModalContent, ModalFooter, ModalHeader} from "../../ui/modal";
import Spinner from "../../ui/spinner";

import cn from "classnames"
import classes from "./UsersSelect.module.css";

type PickedUser = Pick<User, "id" | "fullName" | "name" | "email"> & {
  groups: Pick<Group, "id" | "name">[]
}
type PickedGroup = Pick<Group, "id" | "name"> & {
  users: Pick<User, "id">[]
}

type WithoutNewUsersProps = {
  createNewUsers?: false,
  onSubmit?: (selectedUsersIds: User["id"][]) => void,
  onSelectChange?: (selectedUsersIds: User["id"][]) => void,
}
type WithNewUsersProps = {
  createNewUsers: true,
  onSubmit?: (selectedUsersIds: User["id"][], emails: string[]) => void
  onSelectChange?: (selectedUsersIds: User["id"][], emails: string[]) => void,
}

export type Props = Omit<ComponentProps<"div">, "children" | "onSubmit"> & {
  users: PickedUser[]
  groups: PickedGroup[]
  showTabs?: TAB[]
  initialValues?: Partial<Pick<FormState, "usersIds" | "groupsIds">>
  submitDisabled?: boolean
} & (WithoutNewUsersProps | WithNewUsersProps)

enum TAB {
  ListSelector = "listSelector",
  PlainTextInput = "textInput",
  Groups = "groupsSelector"
}

type FormState = {
  usersIds: User["id"][]
  groupsIds: Group["id"][]
  plainText: string
}

const horizontalMediaQuery = "(min-width: 640px)";

export default function UsersSelect({
  users, groups, initialValues, createNewUsers, onSubmit, onSelectChange, showTabs, submitDisabled, className, ...props
}: Props) {
  const {t} = useTranslation();
  // const visibleTabs = useMemo(() => new Set(showTabs), [showTabs])
  const [currentTab, setCurrentTab] = useState<TAB>(
    showTabs ? Object.values(TAB).find((tab) => showTabs.includes(tab))! : TAB.ListSelector
  );

  const formState = useFormState<FormState>({
    initialValues: {
      usersIds: initialValues?.usersIds ?? [],
      groupsIds: initialValues?.groupsIds ?? [],
      plainText: "",
    },
    preventDefault: true,
  });

  const {usersIds, groupsIds, plainText} = formState.values;

  const emails = useMemo<string[]>(() => parseEmails(plainText, true), [plainText])

  const onUsersSelect = useCallback((selectedItemsIds: string[]) => {
    formState.setValues({usersIds: selectedItemsIds})
  }, [formState])

  const onGroupSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const groupId = e.currentTarget.getAttribute("data-group-id")!;
    const checked = e.currentTarget.checked;
    formState.setValues((values) => ({
      groupsIds: checked
        ? values.groupsIds.concat(groupId)
        : without(values.groupsIds, groupId)
    }))
  }, [formState])

  const onTextInput = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((e) => {
    formState.setValues({plainText: e.currentTarget.value})
  }, [formState])

  const mappedUsersById = useMemo(() => getMapByObjectProperty(users, "id"), [users]);
  const mappedGroupsById = useMemo(() => getMapByObjectProperty(groups, "id"), [groups]);
  const mappedUsersByEmail = useMemo(() => getMapByObjectProperty(users, "email"), [users]);

  const usersSelected = useMemo(() => (
    usersIds.map(userId => mappedUsersById.get(userId)!)
  ), [usersIds, mappedUsersById]);

  const selectedGroups = useMemo(() => (
    groupsIds.map(groupId => mappedGroupsById.get(groupId)!)
  ), [groupsIds, mappedGroupsById]);
  const usersFromGroups = useMemo(() => (
    selectedGroups.flatMap((group) => group.users).map(({id}) => mappedUsersById.get(id)!)
  ), [selectedGroups, mappedUsersById]);

  const [usersSelectedByPlainText, usersNotFoundEmais] = useMemo(() => (
    emails.reduce<[PickedUser[], string[]]>(([found, notFound], email) => {
      const user = mappedUsersByEmail.get(email);
      if (user) {
        return [
          found.concat(user),
          notFound
        ]
      } else {
        return [
          found,
          notFound.concat(email)
        ]
      }
    }, [[], []])
  ), [emails, mappedUsersByEmail]);

  const usersNotFoundEmaisFiltered = useMemo(() => (
    Array.from(new Set(usersNotFoundEmais))
  ), [usersNotFoundEmais]);

  const allSelectedUsers = useMemo(() => {
    const allSelectedUsers = usersSelected.concat(usersFromGroups, usersSelectedByPlainText ?? []);
    return getArrayWithoutCopiesByObjectProperty(allSelectedUsers, "id");
  }, [usersSelected, usersFromGroups, usersSelectedByPlainText]);

  useEffect(() => {
    if (!onSelectChange) {
      return;
    }
    const ids = getIds(allSelectedUsers);
    if (createNewUsers) {
      (onSelectChange as WithNewUsersProps["onSelectChange"])!(ids, usersNotFoundEmais);
    } else {
      (onSelectChange as WithoutNewUsersProps["onSelectChange"])!(ids);
    }
  }, [allSelectedUsers, usersNotFoundEmais, createNewUsers, onSelectChange])


  const [query, {data}] = useLazyQuery<QueryData, QueryGetUsersByMixedDataArgs>(usersQuery, {
    notifyOnNetworkStatusChange: true
  });

  const {users: confirmedUsers, emails: confirmedEmails} = data?.mixed ?? {};

  const [confirmationScreenVisible, showConfirmationScreen, hideConfirmationScreen] = useVisibility();

  const onSubmitButtonClick = useCallback(() => {
    query({variables: formState.values});
    showConfirmationScreen();
  }, [formState.values, query, showConfirmationScreen]);

  const [usersListVisible, showUsersList, hideUsersList] = useVisibility();

  const disableUsersListButton = (allSelectedUsers.length === 0) && (usersNotFoundEmais.length === 0);

  const showCtrlShiftHint = useListSelectorDisplayType(horizontalMediaQuery) === ListSelectorDisplayType.HORIZONTAL;

  return (
    <div className={cn(classes.root, className)} {...props}>
      <div className={classes.tabsWrapper}>
        <Tab value={TAB.ListSelector} currentTab={currentTab} setCurrentTab={setCurrentTab}>
          {t("components.UsersSelect.listSelector.tabName")}
        </Tab>
        <Tab value={TAB.Groups} currentTab={currentTab} setCurrentTab={setCurrentTab}>
          {t("components.UsersSelect.groupsSelector.tabName")}
        </Tab>
        <Tab value={TAB.PlainTextInput} currentTab={currentTab} setCurrentTab={setCurrentTab}>
          {t("components.UsersSelect.plainText.tabName")}
        </Tab>
      </div>

      {currentTab === TAB.ListSelector && (
        <>
          <ListSelector<Pick<User, "id" | "fullName">>
            items={users}
            nameField="fullName"
            onChange={onUsersSelect}
            horizontalMediaQuery={horizontalMediaQuery}

            restTitle={t("components.UsersSelect.allUsers")}
            pickedTitle={t("components.UsersSelect.selectedUsers")}

            selectedItemsIds={formState.values.usersIds}
          />
          {showCtrlShiftHint && (
            <span className={classes.smallPrint}>
              <InformationIcon/>{t("components.UsersSelect.useCtrlShift")}
            </span>
          )}
        </>
      )}

      {currentTab === TAB.Groups && (
        <>
          <span className={classes.helpText}>{t("components.UsersSelect.groupsSelector.helpText")}</span>
          <Scroll className={classes.groupsSelect}>
            {groups.map(({id, name}) => (
              <label key={id} className={classes.checkboxLabel}>
                <Checkbox
                  checked={formState.values.groupsIds.includes(id)}
                  onChange={onGroupSelect}
                  data-group-id={id}
                />
                <span className={classes.checkboxText}>{name}</span>
              </label>
            ))}
          </Scroll>
        </>
      )}

      {currentTab === TAB.PlainTextInput && (
        <>
          <span className={classes.helpText}>{t("components.UsersSelect.plainText.helpText")}</span>
          <Textarea
            className={classes.plainText}
            name="plainText"
            rows={15}
            required
            placeholder={t("components.UsersSelect.plainText.placeholder")}
            value={formState.values.plainText}
            onChange={onTextInput}
          />
        </>
      )}

      <SelectionSummary
        users={allSelectedUsers}
        emails={usersNotFoundEmaisFiltered}
        createNewUsers={createNewUsers}
      />

      <Link type="button"
        className={classes.showListLink}
        onClick={showUsersList}
        disabled={disableUsersListButton}
      >
        {t("components.UsersSelect.showSelectedUsersList")}
      </Link>
      <span className={classes.smallPrint}><InformationIcon/>{t("components.UsersSelect.summaryPreview")}</span>

      <div className={classes.bottom}>
        <Button
          onClick={onSubmitButtonClick}
          type="button"
          color="success"
          disabledColor="secondary"
          disabled={submitDisabled}
        >
          {t("components.UsersSelect.continue")}
        </Button>
      </div>

      {usersListVisible && (
        <PopupPortal as="div" center>
          <Widget className={classes.usersListWidget} showCloseButton onCloseButtonClick={hideUsersList}>
            <Widget.Title>{t("components.UsersSelect.usersList.title")}</Widget.Title>
            <UsersList users={allSelectedUsers} emails={usersNotFoundEmaisFiltered} createNewUsers={createNewUsers}/>
          </Widget>
        </PopupPortal>
      )}

      {confirmationScreenVisible && (
        <ConfirmationModal
          users={confirmedUsers}
          emails={confirmedEmails ?? undefined}
          onClose={hideConfirmationScreen}
          onSubmit={onSubmit}
          createNewUsers={createNewUsers}
        />
      )}
    </div>
  )
}

type TabProps = Omit<LinkDefaultProps, "className" | "disabled" | "onClick"> & {
  value: TAB,
  currentTab: TAB,
  setCurrentTab: (tab: TAB) => void
}

function Tab({value, currentTab, setCurrentTab, ...props}: TabProps) {
  return (
    <Link type="button"
      className={classes.tab}
      disabled={currentTab === value}
      onClick={() => setCurrentTab(value)}
      {...props}
    />
  )
}

type SelectionSummaryProps = Omit<ComponentProps<"span">, "children"> & {
  users: any[]
  emails?: any[]
  createNewUsers?: boolean
}

function SelectionSummary({users, emails, createNewUsers, className, ...props}: SelectionSummaryProps) {
  const {t} = useTranslation();
  return (
    <span className={cn(classes.summaryPreview, className)} {...props}>
      <span>{t("components.UsersSelect.summary.usersSelected", {
        selected: users.length
      })}</span>
      <span>{t("components.UsersSelect.summary.deactivatedNote")}</span>
      {emails && (emails.length > 0) && (createNewUsers ? (
        <span>{t("components.UsersSelect.summary.newUsers", {
          newUsersCount: emails.length
        })}</span>
      ) : (
        <span>{t("components.UsersSelect.summary.usersNotFound", {
          notFoundCount: emails.length
        })}</span>
      ))}
    </span>
  )
}

type ConfirmationModalProps = Omit<ComponentProps<typeof Modal>, "children" | "onSubmit">
  & Pick<(WithoutNewUsersProps | WithNewUsersProps), "createNewUsers" | "onSubmit">
  & {
    users?: UsersListProps["users"]
    emails?: UsersListProps["emails"]
  }

function ConfirmationModal({users, emails, createNewUsers, onSubmit, ...props}: ConfirmationModalProps) {
  const {t} = useTranslation();

  const [selectedUsersIds, setSelectedUsersIds] = useState<User["id"][]>([]);

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  useEffect(() => {
    if (users) {
      setSelectedUsersIds(getIds(users));
    }
  }, [users]);

  useEffect(() => {
    if (emails) {
      setSelectedEmails(emails);
    }
  }, [emails]);

  const onSubmitButtonClick = useCallback(() => {
    if (createNewUsers) {
      (onSubmit as WithNewUsersProps["onSubmit"])!(selectedUsersIds, emails ?? []);
    } else {
      (onSubmit as WithoutNewUsersProps["onSubmit"])!(selectedUsersIds);
    }
  }, [createNewUsers, onSubmit, selectedUsersIds, emails]);

  const onUserSelect = useCallback<NonNullable<UsersListProps["onUserSelect"]>>((userId, checked) => {
    setSelectedUsersIds((ids) => checked
      ? ids.concat(userId)
      : without(ids, userId)
    )
  }, []);

  const onEmailSelect = useCallback<NonNullable<UsersListProps["onEmailSelect"]>>((email, checked) => {
    setSelectedEmails((emails) => checked
      ? emails.concat(email)
      : without(emails, email)
    )
  }, []);

  return (
    <PopupPortal center>
      <Modal size="m" {...props}>
        <ModalHeader>
          <span>{t("components.UsersSelect.ConfirmationModal.title")}</span>
        </ModalHeader>
        <ModalContent>
          {users ? (
            <>
              <span>{t("components.UsersSelect.ConfirmationModal.helpText")}</span>
              <SelectionSummary createNewUsers={createNewUsers} users={selectedUsersIds} emails={selectedEmails}/>
              <UsersList
                withCheckboxes
                createNewUsers={createNewUsers}
                users={users}
                emails={emails}
                selectedUsersIds={selectedUsersIds}
                onUserSelect={onUserSelect}
                selectedEmails={selectedEmails}
                onEmailSelect={onEmailSelect}
              />
            </>
          ) : (
            <div className={classes.spinnerWrapper}>
              <Spinner className={classes.spinner}/>
            </div>
          )}
        </ModalContent>
        <ModalFooter className={classes.confirmationModalFooter}>
          <Button type="button" color="success" onClick={onSubmitButtonClick}>
            {t("components.UsersSelect.ConfirmationModal.submit")}
          </Button>
        </ModalFooter>
      </Modal>
    </PopupPortal>
  )
}

type UsersListProps = Omit<ComponentProps<"div">, "children"> & {
  users: PickedUser[]
  emails?: string[]
  createNewUsers?: boolean
  withCheckboxes?: boolean

  selectedUsersIds?: User["id"][]
  onUserSelect?: (changedId: User["id"], newValue: boolean) => void

  selectedEmails?: string[]
  onEmailSelect?: (changedEmail: string, newValue: boolean) => void
}

function UsersList({
  users, emails, createNewUsers, withCheckboxes,
  selectedUsersIds = [], onUserSelect: exOnUserSelect,
  selectedEmails = [], onEmailSelect: exOnEmailSelect,
  className, ...props
}: UsersListProps) {
  const {t} = useTranslation();

  const onUserSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const userId = e.currentTarget.getAttribute("data-id")!;
    const checked = e.currentTarget.checked;
    exOnUserSelect && exOnUserSelect(userId, checked);
  }, [exOnUserSelect])

  const onEmailSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const email = e.currentTarget.getAttribute("data-id")!;
    const checked = e.currentTarget.checked;
    exOnEmailSelect && exOnEmailSelect(email, checked);
  }, [exOnEmailSelect])

  return (
    <Scroll className={cn(classes.usersListWrapper, className)} {...props}>
      {emails && (emails.length > 0) && (
        <>
          <span className={classes.usersListHeader}>
            {createNewUsers
              ? t("components.UsersSelect.usersList.newUsers")
              : t("components.UsersSelect.usersList.notFound")
            }
          </span>
          {emails.map(email => (
            <label
              key={email}
              className={cn(
                classes.usersListItem,
                {[classes.withCheckbox]: withCheckboxes},
                createNewUsers ? classes.new : classes.notFound
              )}
            >
              {email}
              {withCheckboxes && createNewUsers && (
                <Checkbox
                  checked={selectedEmails.includes(email)}
                  onChange={onEmailSelect}
                  data-id={email}
                />
              )}
            </label>
          ))}
        </>
      )}
      <span className={classes.usersListHeader}>
        {t("components.UsersSelect.usersList.selectedUsers")}
      </span>
      {users?.map(user => (
        <label key={user.id} className={cn(classes.usersListItem, {[classes.withCheckbox]: withCheckboxes})}>
          <UserItem user={user}/>
          {withCheckboxes && (
            <Checkbox
              checked={selectedUsersIds.includes(user.id)}
              onChange={onUserSelect}
              data-id={user.id}
            />
          )}
        </label>
      ))}
    </Scroll>
  )
}

function getIds(arrayOfObjects: {id: string}[]) {
  return arrayOfObjects.map(({id}) => id);
}
