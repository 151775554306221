import {gql, useMutation} from "@apollo/client";
import {MutationHookOptions} from "@apollo/client/react/types/types";
import {loginUpdateCache} from "./common.graphql";
import {AppAccountFragment, AppUserFragment} from "../../App.graphql";


export function useLoginWithProviderMutation(opts?: MutationHookOptions) {
  return useMutation(loginMutation, {
    ...opts,

    update: (cache, mutationResult) => {
      if (mutationResult.data?.login) {
        loginUpdateCache(cache, {
          user: mutationResult.data?.login.user,
          account: mutationResult.data?.login.account
        });
      }

      opts?.update && opts.update(cache, mutationResult, {});
    }
  });
}

const loginMutation = gql`
  mutation LoginWithProviderMutation($provider: String!, $token: String!) {
    login: loginWithProvider(provider: $provider, token: $token) {
      sessionId
      account { id ...AppAccount }
      user { id ...AppUser }
    }
  }

  ${AppUserFragment}
  ${AppAccountFragment}

`;
