/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {StoreHelpers} from "react-joyride";
import {reverse} from "../../routing";
import ReactTour, {TourStep} from "../../components/ReactTour";
import {queryData} from "./index.graphql";
import {createDummyCourse, createDummyLesson} from "../../dummies";
import {getLanguageCode} from "../../i18n";
import {useTour} from "../../providers/tour";
import {Course, EditorAccessType, Lesson, LessonMode} from "../../schema";
import {getTargetsClassNamesWithPrefix, TourMock, targetClassNamesToSelectors, getTourSubname} from "../../tours";

export const NAME: "editorIndex" = "editorIndex";
export const SUBNAMES = {
  openLesson: getTourSubname(NAME, "openLesson")
}

type TargetNames = [
  "addLessonButton", "addCourseButton", "lessonTable", "lessonTableHeader", "lesson", "lessonItem", "lessonDropdown",
  "lessonDropdownContent", "course", "courseDropdown", "courseDropdownContent", "courseItem", "filterForm"
]
type TargetName = TargetNames[number]

const targetNames: TargetNames = [
  "addLessonButton", "addCourseButton", "lessonTable", "lessonTableHeader", "lesson", "lessonItem", "lessonDropdown",
  "lessonDropdownContent", "course", "courseDropdown", "courseDropdownContent", "courseItem", "filterForm"
]

export type Targets = Record<TargetName, TourStep["target"]>

export const classes = getTargetsClassNamesWithPrefix(targetNames, "tour_editor_index_");

type props = {
  loaded: boolean,
  targets?: Partial<Targets>
}

export default function EditorIndexTour({loaded, targets: componentTargets, ...props}: props) {
  const {t} = useTranslation("tours");
  const {end: endTour, runningTourName, setMock} = useTour();

  const onTourEnd = useCallback(() => {
    return endTour();
  }, [endTour])

  useEffect(() => () => {
    endTour();
  }, [endTour])

  const [helpers, setHelpers] = useState<StoreHelpers>();

  const targets = useMemo(() => ({
    ...targetClassNamesToSelectors(classes),
    ...componentTargets
  }), [componentTargets])

  const getMockBy = useMemo<EditorIndexTourMock["getBy"]>(() => ({
    lesson: new Map([[ids.lessons[0].id, {
      classes: {
        row: classes.lesson,
        dropdown: classes.lessonDropdown,
        dropdownContent: classes.lessonDropdownContent,
        item: classes.lessonItem
      },
      eventHandlers: {
        dropdownClick: helpers?.next
      },
      href: reverse("editorLessonEdit", {id: "tour"})
    }]]),
    course: new Map([[ids.courses[0].id, {
      classes: {
        row: classes.course,
        dropdown: classes.courseDropdown,
        dropdownContent: classes.courseDropdownContent,
        item: classes.courseItem
      },
      eventHandlers: {
        dropdownClick: helpers?.next
      },
      href: reverse("editorCourseEdit", {id: "tour"})
    }]])
  }), [helpers])

  useEffect(() => {
    setMock({
      tour: NAME,
      getBy: getMockBy
    })
  }, [getMockBy, setMock])

  const steps = useMemo<TourStep[]>(() => {
    switch (runningTourName) {
      case "editorIndex_openLesson":
        return [
          {
            title: t("editorIndex.backToEditorIndex.title"),
            content: t("editorIndex.backToEditorIndex.content"),
            target: "body",
            placement: "center",
          },
          {
            title: t("editorIndex.editLesson.title"),
            content: t("editorIndex.editLesson.content"),
            target: targets.lessonItem,
            placement: "right",
            spotlightClicks: true,
          },
        ]
      case undefined:
        console.error("Tour (editor index) is mounted, but runningTourName is undefined")
        // falls through
      case "editorIndex":
        return [
          {
            title: t("editorIndex.start.title"),
            content: t("editorIndex.start.content"),
            target: "body",
            placement: "center",
          },
          {
            title: t("editorIndex.lessonTable.title"),
            content: t("editorIndex.lessonTable.content"),
            target: targets.lessonTable,
            placement: "right-start",
          },
          {
            title: t("editorIndex.lessonTableHeader.title"),
            content: t("editorIndex.lessonTableHeader.content"),
            target: targets.lessonTableHeader,
            placement: "right",
          },
          {
            title: t("editorIndex.lesson.title"),
            content: t("editorIndex.lesson.content"),
            target: targets.lesson,
            placement: "right",
          },
          {
            title: t("editorIndex.lessonDropdown.title"),
            content: t("editorIndex.lessonDropdown.content"),
            target: targets.lessonDropdown,
            placement: "right",
            disableNextButton: true,
            spotlightClicks: true,
          },
          {
            title: t("editorIndex.lessonDropdownContent.title"),
            content: t("editorIndex.lessonDropdownContent.content"),
            target: targets.lessonDropdownContent,
            placement: "right",
          },
          {
            title: t("editorIndex.course.title"),
            content: t("editorIndex.course.content"),
            target: targets.course,
            placement: "right",
          },
          {
            title: t("editorIndex.courseDropdown.title"),
            content: t("editorIndex.courseDropdown.content"),
            target: targets.courseDropdown,
            placement: "right",
            disableNextButton: true,
            spotlightClicks: true,
          },
          {
            title: t("editorIndex.courseDropdownContent.title"),
            content: t("editorIndex.courseDropdownContent.content"),
            target: targets.courseDropdownContent,
            placement: "right",
          },
          {
            title: t("editorIndex.filterForm.title"),
            content: t("editorIndex.filterForm.content"),
            target: targets.filterForm,
            placement: "left-start",
          },
          {
            title: t("editorIndex.addCourse.title"),
            content: t("editorIndex.addCourse.content"),
            target: targets.addCourseButton,
            placement: "bottom",
          },
          {
            title: t("editorIndex.addLesson.title"),
            content: t("editorIndex.addLesson.content"),
            target: targets.addLessonButton,
            placement: "bottom",
          },
          {
            title: t("editorIndex.editCourse.title"),
            content: t("editorIndex.editCourse.content"),
            target: targets.courseItem,
            placement: "right",
            spotlightClicks: true,
          }
        ]
      default:
        throw Error(`Tour (editor index) is mounted, but running unsupported tour name${runningTourName}`)
    }
}, [t, targets, runningTourName])

  return (
    <ReactTour
      continuous
      run={loaded}
      steps={steps}
      onTourEnd={onTourEnd}
      getHelpers={setHelpers}
      scrollToFirstStep
      disableOverlayClose
      disableCloseOnEsc
      showSkipButton
      hideCloseButton
    />
  );
}

const ids = {
  lessons: [{id: "editorIndexTour_Lesson_1"}, {id: "editorIndexTour_Lesson_2"}],
  courses: [{
    id: "editorIndexTour_Course_1",
    lessons: [{id: "editorIndexTour_Lesson_3"}, {id: "editorIndexTour_Lesson_4"}, {id: "editorIndexTour_Lesson_5"}]
  }]
}

export function useMockData(): queryData {
  const {t, i18n} = useTranslation("tours");
  const languageCode = getLanguageCode(i18n.language);

  const lessons = useMemo(() => [
    createDummyLesson({
      name: t("editorIndex.mockData.lessons.1.name"), // "Урок для начинающих волшебников"
      id: ids.lessons[0].id,
      languageCode,
      lastUpdatedAt: Date.UTC(2020, 10, 16, 8, 38)/1000
    }),
    createDummyLesson({
      name: t("editorIndex.mockData.lessons.2.name"), // "Тест по уроку для начинающих волшебников"
      id: ids.lessons[1].id,
      languageCode,
      lastUpdatedAt: Date.UTC(2020, 10, 16, 8, 36)/1000,
      mode: LessonMode.CHOICE_BASED_STUDY
    })
  ], [languageCode, t]);

  const courses = useMemo(() => [
    createDummyCourse({
      name: t("editorIndex.mockData.course.1.name"), // "Курс продвинутого волшебника"
      id: ids.courses[0].id,
      languageCode,
      lastUpdatedAt: Date.UTC(2021, 8, 16, 15, 28)/1000,
      lessons: [
        createDummyLesson({
          name: t("editorIndex.mockData.course.1.lessons.1.name"), // "1. Использование волшебной палочки"
          id: ids.courses[0].lessons[0].id,
          languageCode
        }),
        createDummyLesson({
          name: t("editorIndex.mockData.course.1.lessons.2.name"), // "2. Базовые заклинания"
          id: ids.courses[0].lessons[1].id,
          languageCode
        }),
        createDummyLesson({
          name: t("editorIndex.mockData.course.1.lessons.3.name"), // "3. Зельеварение"
          id: ids.courses[0].lessons[2].id,
          languageCode
        })
      ]
    })
  ], [languageCode, t]);
  return {
    items: [...lessons, ...courses],
    courses,
    editorAccessType: EditorAccessType.FULL_ACCESS
  };
}

type MockByLesson = TourMock<"item" | "dropdown" | "dropdownContent" | "row", "dropdownClick"> & {
  href: string
}
type MockByCourse = TourMock<"item" | "dropdown" | "dropdownContent" | "row", "dropdownClick"> & {
  href: string
}

export type EditorIndexTourMock = {
  tour: "editorIndex",
  getBy: {
    lesson: Map<Lesson["id"], MockByLesson>
    course: Map<Course["id"], MockByCourse>
  }
}

export function isEditorIndexTourMock(mock: any): mock is EditorIndexTourMock {
  return mock?.tour === NAME;
}
