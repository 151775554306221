import React, {forwardRef, useEffect} from "react";
import {Avatar, Lesson} from "../../../schema";
import {useTranslation} from "react-i18next";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {PlayerReduxState} from "../../../redux/player/types";
import {getCurrentAction, isErrorState, isHintConfirmation, isIntroStage} from "../../../redux/player/selectors";

// Displays
import Display from "./display/Display";
import PermissionDisplay from "./display/PermissionDisplay";
import IntroDisplay from "./display/IntroDisplay";
import SystemActionDisplay from "./display/SystemActionDisplay";
import AvatarActionDisplay from "./display/AvatarActionDisplay";
import UserInputActionDisplay from "./display/UserInputActionDisplay";
import ChoiceUserInputActionDisplay from "./display/ChoiceUserInputActionDisplay";
import HintConfirmationDisplay from "./display/HintConfirmationDisplay";

// Other
import isEqual from "lodash/isEqual";
import UserInputErrorDisplay from "./display/UserInputErrorDisplay";
import {useMediaRecorder} from "../../../providers/mediaRecorder";
import {useAudioPreloader} from "../../../providers/audioPreloader";
import {FSImageRef} from "../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../ui/fullscreen/video";
import selectRandomAvatarBranch from "../../../redux/player/actions/selectRandomAvatarBranch";


type props = {
  lesson: Lesson,
  avatar: Avatar,
  avatarLoaded?: boolean,
  openFullscreenMedia: () => void,
}

type VideoCallStepDisplayType = React.ForwardRefExoticComponent<
  props & React.RefAttributes<FSImageRef | FSVideoRef>
> & {
  selector?: (state: PlayerReduxState) => {
    type: string | undefined;
    isError: boolean;
    isHintConfirmation: boolean;
  }
}

const VideoCallStepDisplay: VideoCallStepDisplayType = forwardRef(({
                                               lesson,
                                               avatar,
                                               avatarLoaded,
                                               openFullscreenMedia,
                                             }, ref) => {
  const {t} = useTranslation();
  const {hasPermission} = useMediaRecorder();
  const {isIntroLoaded: introAudioLoaded} = useAudioPreloader();
  const {type, isError, isHintConfirmation} = useSelector(VideoCallStepDisplay.selector!, isEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === "RandomAvatarAction") {
      dispatch(selectRandomAvatarBranch());
    }
  }, [dispatch, type])

  if (!hasPermission) {
    return (<PermissionDisplay/>)
  }

  if (isError) {
    return (<UserInputErrorDisplay/>)
  }

  if (isHintConfirmation) {
    return (
      <HintConfirmationDisplay/>
    )
  }

  if (!avatarLoaded) {
    return (
      <Display>
        <Display.Title title={t("player.loading.title")}/>
        <Display.Content>{t("player.loading.avatar")}</Display.Content>
      </Display>
    )
  }

  switch (type) {
    case "Intro":
      if (!introAudioLoaded) {
        return (
          <Display>
            <Display.Title title={t("player.loading.title")}/>
            <Display.Content>{t("player.loading.lesson")}</Display.Content>
          </Display>
        )
      }
      return (
        <IntroDisplay
          lesson={lesson}
        />
      )

    case "AvatarAction":
      return (
        <AvatarActionDisplay avatarName={avatar.name} openFullscreenMedia={openFullscreenMedia} ref={ref}/>
      )

    case "SystemAction":
      return (
        <SystemActionDisplay openFullscreenMedia={openFullscreenMedia} ref={ref}/>
      )

    case "UserInputAction":
      return (
        <UserInputActionDisplay/>
      )

    case "ChoiceUserInputAction":
      return (
        <ChoiceUserInputActionDisplay/>
      )

    case "RandomAvatarAction":
      return (
        <div/>
      )

    default:
      throw new Error(`NotImplemented: Step type ${type} not implemented in VideoCall mode`);
  }
})

export default VideoCallStepDisplay;

VideoCallStepDisplay.selector = (state: PlayerReduxState) => {
  const isIntro = isIntroStage(state);

  return {
    type: isIntro ? "Intro" : getCurrentAction(state)?.__typename,
    isError: isErrorState(state),
    isHintConfirmation: isHintConfirmation(state)
  }
}
