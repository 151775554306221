import React, {ComponentProps, useMemo} from "react";
import {useFormState} from "../../../hooks/useFormState";
import {useTranslation} from "react-i18next";
import {Group} from "../../../schema";

import Input from "../../../ui/input";
import Button from "../../../ui/button";

import classes from "./AddGroupModal.module.css";

export type QueryData = {
  groups?: Group[]
}

export type ContentProps =  Omit<ComponentProps<"form">, "children" | "onSubmit"> & QueryData & {
  onAddGroup?: (name: string) => void
}

function AddGroupModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.AddGroupModal.title")}</span>
}

 function AddGroupModalContent({groups, onAddGroup, ...props}: ContentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    groupName: string,
  }>({
    initialValues: {
      groupName: "",
    },

    preventDefault: true,
    onSubmit: ({groupName}) => {
      onAddGroup && onAddGroup(groupName);
    }
  });

  const haveGroupWithSameName = useMemo(() => {
    if (!groups) {
      return undefined;
    }
    return groups.some(({name}) => formState.values.groupName === name)
  }, [groups, formState.values.groupName])

  const submitDisabled = haveGroupWithSameName;

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler} {...props}>
      <p className={classes.helpText}>{t("components.AddGroupModal.helpText")}</p>
      <Input
        className={classes.input}
        name="groupName"
        required
        placeholder={t("components.AddGroupModal.placeholder")}
        onChange={formState.changeHandler}
      />

      {haveGroupWithSameName && (
        <p className={classes.warning}>{t("components.AddGroupModal.warning.sameName")}</p>
      )}

      <div className={classes.bottom}>
        <Button type="submit" color="success" disabledColor="secondary" disabled={submitDisabled}>
          {t("components.AddGroupModal.submit")}
        </Button>
      </div>
    </form>
  )
}

const AddGroupModal = {
  Header: AddGroupModalHeader,
  Content: AddGroupModalContent,
}

export default AddGroupModal;
