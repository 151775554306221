import React from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames"
import classes from "./BaseUserIcon.module.css"

export default function BaseUserIcon({className: extraClassName, ...props}: React.ComponentProps<"div">) {
  const {t} = useTranslation();

  return (
    <div className={cn(classes.root, extraClassName)} {...props}>
      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
        <circle fill="currentColor" r="50%" cx="50%" cy="50%"/>
        <text textAnchor="middle" x="50%" y="80" fill="#fff" fontFamily="Helvetica" fontSize="52" fontWeight="bold">
          {t("common.userLetter")}
        </text>
      </svg>
    </div>
  )
}
