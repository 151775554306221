import React, {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import ReactMarkdown from "react-markdown";

import Widget from "../../ui/widget";
import Scroll from "../../ui/scroll";

import cn from "classnames";
import classes from "./GuideWidget.module.css"

type props = Omit<ComponentProps<typeof Widget>, "children" | "as"> & {
  guide: string | undefined
}

export default function GuideWidget({className, guide, ...props}: props) {
  const {t} = useTranslation();

  return (
    <Widget className={cn(classes.root, className)} showCloseButton {...props}>
      <Widget.Title>{t("components.GuideWidget.header")}</Widget.Title>
      <Scroll className={classes.content}>
        {guide ? (
          <ReactMarkdown>
            {guide}
          </ReactMarkdown>
        ) : <span>{t("components.GuideWidget.loading")}</span>}
      </Scroll>
    </Widget>
  )
}