/* eslint-disable max-lines */
import {RECORDER_AUDIO_CONSTRAINTS, RECORDER_VIDEO_CONSTRAINTS} from "./settings";
import {TypedItem} from "./types";
import {register} from "extendable-media-recorder";
import {connect} from "extendable-media-recorder-wav-encoder";


export function timeout(ms: number) {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function isSafe(url: string): boolean {
  return (url?.[0] === "/") && url?.[1] !== "/";
}

export function isIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export enum ExternalConnectionType {
  IFRAME = "iframe",
  OPENER = "opener",
}

export function getExternalConnectionType() {
  if (window.opener) {
    return ExternalConnectionType.OPENER
  } else if (window.self !== window.top) {
    return ExternalConnectionType.IFRAME
  }
  return null;
}


export function bindEventListener(node: EventTarget, ...args: Parameters<EventTarget["addEventListener"]>) {
  node.addEventListener(...args);

  return () => {
    node.removeEventListener(...args);
  }
}

export function fillArrayInRange(min: number = 0, max: number = 100, step: number = 1) {
  return Array.from({length: (max - min) / step + 1}, (v, k) => min + k * step)
}

export function deltaTimestampFromMinutes(minutes: number) {
  return Math.round(minutes * 60)
}

export function minutesToString(minutes: number) {
  return `${Math.trunc(minutes)}:${("0" + Math.trunc(minutes % 1 * 60)).slice(-2)}`
}

export function minutesFromDeltaTimestamp(deltaTimestamp: number, asString: boolean = false) {
  const minutes = deltaTimestamp / 60;

  if (asString) {
    return minutesToString(minutes)
  }
  return minutes
}

export function postMessageOpener(data: any) {
  if (window.opener) {
    window.opener.postMessage(data, "*");
  } else {
    window.parent.postMessage(data, "*");
  }
}

export class NamedError extends Error {
  constructor(name: string, message: string) {
    super();
    this.message = message
    this.name = name
  }
}

export type MimeType = {
  type: string,
  subtype: string
}

export function parseMimeType(mimeType: string): MimeType {
  const parseResult = mimeType.split("/")

  if (parseResult.length !== 2) {
    throw new Error(`Media type "${mimeType}" is not MIME-type`)
  }
  return {
    type: parseResult[0],
    subtype: parseResult[1]
  }
}

export function getFormatFromUrl(url: string) {
  // eslint-disable-next-line no-useless-escape
  const result = /(?:\/.*\.)([^\/]*)(?:\/?)(?!.)/i.exec(url)

  return result && result[1].toLowerCase();
}

export enum PlayableMediaType {
  AUDIO = "audio",
  VIDEO = "video",
  IMAGE = "image"
}

export function megabytesToBytes(megabytes: number) {
  return megabytes * 1048576
}

export function generateURL(path: string, params?: URLSearchParams) {
  const url = new URL(path, window.location.origin);

  if (params) {
    url.search = params.toString();
  }
  return url;
}

export enum BooleanForm {
  UNDEFINED = "",
  FALSE = "FALSE",
  TRUE = "TRUE",
}

export const booleanFormEnumToValue = (value: BooleanForm) => {
  switch (value) {
    case BooleanForm.FALSE:
      return false;
    case BooleanForm.TRUE:
      return true;
    case BooleanForm.UNDEFINED:
    default:
      return undefined;
  }
};
export const booleanFormValueToEnum = (value: boolean | undefined) => {
  if (value === undefined) {
    return BooleanForm.UNDEFINED;
  }

  return value ? BooleanForm.TRUE : BooleanForm.FALSE;
}

export async function getUserMedia(mediaType: "audio" | "video"): Promise<[MediaStream, "audio" | "video"]> {
  try {
    await register(await connect());
  } catch (e: any) {
    // pass
  }

  if (mediaType === "video") {
    const hasWebcam = await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => devices.some(device => device.kind === "videoinput"));

    if (!hasWebcam) {
      mediaType = "audio";
    }
  }

  const audioStream = await navigator.mediaDevices.getUserMedia(RECORDER_AUDIO_CONSTRAINTS);

  if (mediaType === "video") {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia(RECORDER_VIDEO_CONSTRAINTS);

      releaseUserMediaStream(audioStream);
      return [videoStream, "video"];
    } catch (e: any) {
      if (e.name !== "NotAllowedError" && e.name !== "SecurityError") {
        console.warn(e)
        throw e;
      }
    }
  }
  return [audioStream, "audio"];
}

export function releaseUserMediaStream(mediaStream: MediaStream) {
  mediaStream.getTracks().forEach(track => track.stop());
}

export function devBindToWindow(params: Record<string, any>) {
  Object.entries(params).forEach(([key, value]) => {
    // @ts-ignore
    window[key] = value;
  })
}

export function devTillWindowResolve(name?: string) {
  return new Promise((resolve) => {
    devBindToWindow(name
      ? {resolve: {[name]: resolve}}
      : {resolve: resolve}
    )
  })
}

export function countValidMapValues<K, V>(
  map: Map<K, V>,
  predicate: (value: V, index: number, array: V[]) => boolean
): number {
  return Array.from(map.values()).filter(predicate).length
}

export type KeysOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V? P: never]: any
}


export function search<T extends {[key: string]: V}, V>(
  array: Array<T>, key: KeysOfType<T, V>, searchItem: V
) {
  return array.filter((item: T) => (item[key] === searchItem))
}
export function searchString<T extends {[key: string]: string}>(
  array: T[], key: KeysOfType<T, string>, searchQuery: string
) {
  return array.filter((item: T) => (item[key].toLowerCase().includes(searchQuery.toLowerCase())))
}

export function separateByTypename<T extends TypedItem<string>>(collection: T[]) {
  const map = new Map<T["__typename"], T[]>()
  for (const item of collection) {
    if (item.__typename !== undefined) {
      map.set(item.__typename, (map.get(item.__typename) ?? []).concat(item))
    }
  }
  return map;
}

export function queryByTypenames<T extends TypedItem<string>>(collection: T[], typenames: string[]) {
  const separated = separateByTypename(collection);
  return typenames.map((typename) => separated.get(typename) ?? [])
}

export function getById<T extends {id: string}>(items: T[], id: string) {
  return items.find(item => item.id === id);
}

export function stopEventHandlerPropagation(handler?: React.EventHandler<React.SyntheticEvent>) {
  return function stopPropagation(e: React.SyntheticEvent) {
    e.stopPropagation()
    handler && handler(e)
  }
}

export function mapObject<T extends Object, U>(
  object: T,
  callbackfn: (value: T[keyof T], key: keyof T, object: T) => U
) {
  const keys = Object.keys(object) as (keyof T)[];

  return keys.reduce<Record<keyof T, U>>((result, key) => {
    result[key] = callbackfn(object[key], key, object);
    return result;
  }, {} as Record<keyof T, U>)
}

export function getHashCode(str: string) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function intToHSL(hue: number, saturation?: number, lightness?: number) {
  return `hsl(${
    hue % 360
  },${
    (saturation ?? 100) % 101
  }%,${
    (lightness ?? 40) % 101
  }%)`;
}

type IdentifiedItem = {
  id: any
}

export function isSameId(value: IdentifiedItem, other: IdentifiedItem) {
  return value.id === other.id;
}

export async function isMicAccessible(useGetUserMedia?: boolean) {
  try {
    if (useGetUserMedia) {
      await navigator.mediaDevices.getUserMedia({audio: true})
      return true
    } else {
      const devices = await navigator.mediaDevices.enumerateDevices()
      return devices.some((device => device.kind === "audioinput"));
    }
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export function copyTextToClipboard(text: string) {
  return new Promise<void>((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(resolve, reject);
    } else {
      reject()
    }
  })
}

export function copyCanvasContentsToClipboard(canvas: HTMLCanvasElement) {
  return new Promise<void>((resolve, reject) => {
    if (navigator.clipboard) {
      canvas.toBlob((blob) => {
        if (blob) {
          const data = [
            new ClipboardItem(
              {[blob.type]: blob}
            ),
          ];

          navigator.clipboard.write(data).then(resolve, reject);
        }
      });
    } else {
      reject()
    }
  })
}

export function getCanvasContentAsPNG(canvas: HTMLCanvasElement, downloadable?: boolean) {
  const dataURL = canvas.toDataURL("image/png")
  if (downloadable) {
    return dataURL.replace(/^data:image\/[^;]/, "data:application/octet-stream")
  }
  return dataURL;
}

export function bindTimeout(...args: Parameters<typeof setTimeout>) {
  const timeoutID = setTimeout(...args)

  return function resetTimeout() {
    clearTimeout(timeoutID);
  }
}

export function bindInterval(...args: Parameters<typeof setInterval>) {
  const intervalID = setInterval(...args)

  return function resetInterval() {
    clearInterval(intervalID);
  }
}

export function reorder<T extends any>(list: T[], startIndex: number, endIndex?: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (endIndex !== undefined) {
    result.splice(endIndex, 0, removed);
  } else {
    result.push(removed);
  }

  return result;
}

// Splitting RegExp is something tough in JS
// eslint-disable-next-line max-len, no-control-regex, no-useless-escape
export const emailRegExp = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm

export function isValidEmail(email: string): boolean {
  return emailRegExp.test(email);
}

export function parseEmails(text: string, caseInsensitive: boolean = false) {
  const regExp = caseInsensitive ? new RegExp(emailRegExp.source, "gmi") : emailRegExp;
  let m;
  const emails: string[] = [];
  while ((m = regExp.exec(text)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regExp.lastIndex) {
        regExp.lastIndex++;
      }

      emails.push(caseInsensitive ? m[0].toLowerCase() : m[0])
  }
  return emails;
}

export function getMapByObjectProperty<T extends {}>(array: T[], property: keyof T) {
  return new Map(
    array.map((item) => (
      [item[property], item]
    ))
  )
}

export function getArrayWithoutCopiesByObjectProperty<T extends {}>(array: T[], property: keyof T) {
  const mapByProperty = getMapByObjectProperty(array, property);
  return Array.from(mapByProperty.values());
}
