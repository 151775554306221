import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useTour} from "../../../providers/tour";
import {reverse} from "../../../routing";
import {gql} from "@apollo/client";
import {Course} from "../../../schema";
import {isEditorIndexTourMock} from "../../../scenes/editor/index.tour";

import ReactLink from "../../ReactLink";
import Skeleton from "../../../ui/skeleton";
import {FolderOutlineIcon} from "../../../ui/icons";

import cn from "classnames";
import classes from "./CourseTableItem.module.css";

type props = React.ComponentProps<typeof ReactLink> & {
  course: Pick<Course, "name" | "id">
}

export default function CourseTableItem({className, course, ...props}: props) {
  const {t} = useTranslation();

  const {mock: tourMock} = useTour();
  const mock = useMemo(() => {
    if (tourMock && isEditorIndexTourMock(tourMock)) {
      return tourMock.getBy.course.get(course.id);
    }
  }, [tourMock, course.id])

  return (
    <ReactLink
      className={cn(classes.root, mock?.classes.item, className)}
      title={course.name}
      href={mock?.href ?? reverse("editorCourseEdit", {id: course.id})}
      {...props}
    >
      <span className={cn(classes.name, {[classes.notSpecified]: !!course.name})} translate="no">
        <FolderOutlineIcon className={classes.icon}/>
        {course.name ?? t("common.notSpecified")}
      </span>
    </ReactLink>
  )
}

CourseTableItem.Skeleton = React.memo(function ({className, ...props}: React.ComponentProps<typeof Skeleton.Block>) {
  return (
    <Skeleton.Block
      className={cn(classes.root, className)}
      height={60}
      {...props}
    />
  )
})

CourseTableItem.fragments = {
  root: gql`
    fragment CourseTableItem on Course {
      id
      name
    }
  `
}
