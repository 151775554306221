import React, {useMemo} from "react";
import Joyride, {Styles, TooltipRenderProps, Step, Props as JoyrideProps} from "react-joyride";
import {useLocale} from "../tours";
import Button from "../ui/button";
import Link from "../ui/link";

import Widget from "../ui/widget";

import cn from "classnames";
import classes from "./ReactTour.module.css";

export const defaultStyles: Styles = {
  options: {
    primaryColor: "var(--color-brand-alt)",
  },
}

const floaterStyles = {
  arrow: {
    margin: 20
  }
}

export type TourStep = Step & {
  disableNextButton?: boolean
  disableFinishButton?: boolean
}

type props = Omit<JoyrideProps, "steps"> & Pick<Step, "hideCloseButton"> & {
  onTourEnd?: JoyrideProps["callback"]
  steps: TourStep[]
}

export default function ReactTour({steps, hideCloseButton, callback, onTourEnd, ...props}: props) {
  const locale = useLocale();

  // Modifying callback to add onTourEnd "event"
  const modifiedCallback = useMemo<JoyrideProps["callback"]>(() => {
    if (!onTourEnd) {
      return callback;
    }
    return (data) => {
      callback && callback(data);
      const {type} = data;
      if (type === "tour:end") {
        onTourEnd && onTourEnd(data);
      }
    }
  }, [callback, onTourEnd])

  // Used to apply global modifier to every step
  // For some reason hideCloseButton is not prop of Joyride (unlike for example showSkipButton)
  const modifiedSteps = useMemo<JoyrideProps["steps"]>(() => {
    if (!hideCloseButton) {
      return steps;
    }

    return steps.map(
      (item) => ({
        ...item,
        hideCloseButton: item.hideCloseButton ?? hideCloseButton
      })
    )
  }, [steps, hideCloseButton])

  return (
    <Joyride
      locale={locale}
      tooltipComponent={Tooltip}
      floaterProps={{styles: floaterStyles}}
      styles={defaultStyles}
      callback={modifiedCallback}
      steps={modifiedSteps}
      {...props}
    />
  );
}

type TooltipProps = Omit<TooltipRenderProps, "step"> & {
  step: TourStep
}

const Tooltip = ({
  continuous, index, isLastStep, size, step, backProps, closeProps, primaryProps, skipProps, tooltipProps,
}: TooltipProps) => (
  <Widget.WithRef
    className={classes.tooltip}
    {...tooltipProps}
    showCloseButton={!step.hideCloseButton}
    closeButtonColor="secondary"
    onCloseButtonClick={closeProps.onClick as () => void}
  >
    {step.title && (
      <Widget.Title className={cn(classes.title, {[classes.withCloseButton]: !step.hideCloseButton})}>
        {step.title}
      </Widget.Title>
    )}
    <Widget.Description className={classes.content}>{step.content}</Widget.Description>
    {!step.hideFooter && (
      <div className={classes.footer}>
        {(step.showSkipButton && !isLastStep) && (
          <Link className={classes.skipButton} {...skipProps}>
            {skipProps.title}
          </Link>
        )}
        <div className={classes.navigation}>
          {(index > 0 && !step.hideBackButton) && (
            <Link {...backProps}>
              {backProps.title}
            </Link>
          )}
          {continuous && (
            <Button
              color="primary"
              disabledColor="secondary"
              disabled={step.disableNextButton}
              {...primaryProps}
            >
              {primaryProps.title}
              {step.showProgress && ` (${index+1}/${size})`}
            </Button>
          )}
          {!continuous && (
            <Button
              color="primary"
              disabledColor="secondary"
              disabled={step.disableFinishButton}
              {...closeProps}
            >
              {closeProps.title}
              {step.showProgress && ` (${index+1}/${size})`}
            </Button>
          )}
        </div>
      </div>
    )}
  </Widget.WithRef>
);
