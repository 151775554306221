import {useMemo} from "react";
import {Step} from "react-joyride";
import {useTranslation} from "react-i18next";
import {mapObject} from "./utils";
import {Nullable} from "./types";

/**
 * Replaces class names with corresponding selectors.
 *
 * Made for compatibility with classes object imported from *.module.css files
 *
 * @param {Record<T, Step["target"]>}  targets Tour steps targets, some of which are class names
 *
 * @return {Record<T, Step["target"]>} Tour steps targets, which are only selectors or HTMLElements.
 */
export function targetClassNamesToSelectors<T extends string>(targets: Record<T, Step["target"]>) {
  return mapObject(targets, (item) => {
    if (typeof item === "string" && item.includes("_")) {
      return `.${item}`;
    }
    return item;
  })
}

export function getTargetsClassNamesWithPrefix<T extends string>(
  targetsClassNames: T[], prefix: string
): Record<T, string > {
  return Object.fromEntries(targetsClassNames.map((item) => (
    [item, `${prefix}${item}`]
  ))) as  Record<T, string>
}

export function useLocale() {
  const {t} = useTranslation("tours");
  return useMemo(() => ({
    back: t("common.back"),
    close: t("common.close"),
    last: t("common.last"),
    next: t("common.next"),
    open: t("common.open"),
    skip: t("common.skip")
  }), [t]);
}

export type TourMock<Classes extends string, EventHandlers extends string> = (
  TourClassesMock<Classes> & TourEventListenersMock<EventHandlers>
)

export type TourClassesMock<Classes extends string> = {
  classes: Record<Classes, string>
}

export type TourEventListenersMock<EventHandlers extends string> = {
  eventHandlers: Record<EventHandlers, React.EventHandler<React.SyntheticEvent> | undefined>
}

export function getDataWithMock<T extends {}>(data: Nullable<T>, mockData: Partial<T>, tourIsRunning: boolean) {
  return tourIsRunning ? mockData : data ?? ({} as T)
}

export function getTourSubname<N extends string, T extends string>(name: N, subname: T) {
  return (name + "_" + subname) // as `${N}_${T}`;
}

type ToursState = {
  completedTours: Set<string>
}

function createToursState(partialState: Partial<ToursState>): ToursState {
  return {
    completedTours: new Set(),

    ...partialState
  }
}

function getToursStoredState() {
  const toursStorageItem = localStorage.getItem("tours");
  try {
    if (toursStorageItem) {
      return JSON.parse(toursStorageItem, (key, value) => (
        key === "completedTours" ? new Set(value) : value
      )) as ToursState
    }
  } catch (e) {
    console.error(e);
  }
  return undefined;
}

function setToursStoredState(toursState: ToursState) {
  localStorage.setItem("tours", JSON.stringify(toursState, (key, value) => (
    key === "completedTours" ? Array.from(value as Set<string>) : value
  )))
}

export function markTourAsCompleted(name: string) {
  let toursState = getToursStoredState();

  if (!toursState) {
    toursState = createToursState({
      completedTours: new Set([name])
    })
  } else {
    toursState.completedTours.add(name)
  }

  if (toursState) {
    setToursStoredState(toursState)
  }
}

export function runTourOnFirstOpen(
  name: string,
  runHandler: (name: string) => Promise<boolean>,
  requiresCompletion?: string[]
) {
  let toursState = getToursStoredState();

  const isTourCompleted = toursState?.completedTours.has(name);
  const isPreviousCompleted = requiresCompletion?.every((name) => (
    toursState?.completedTours.has(name)
  )) ?? true

  if (!isTourCompleted && isPreviousCompleted) {
    runHandler(name);
  }
}
