import {gql} from "@apollo/client";
import {Course, LessonEducationState, SelectedDashboardData} from "../../schema";
import LessonCard, {QueryData as LessonCardQueryData} from "../../components/LessonCard";

export type QueryVars = {
  courseId: Course["id"]
}

export type QueryData = {
  course: Pick<Course, "__typename" | "id" | "name" | "accessToken"> & LessonCardQueryData["course"] & {
    lessons: LessonCardQueryData["lesson"][]
  },
  lessonsStates: (
    Pick<LessonEducationState, "__typename" | "lessonId" | "resultDescription"> & LessonCardQueryData["lessonState"]
  )[] | null,
  courseResultsByUser: SelectedDashboardData
}

export const query = gql`
  query PlayerCourseSceneQuery($courseId: ID!) {
    course: getCourse(courseId: $courseId) {
      __typename
      id
      name
      accessToken
      lessons {
        ...LessonCard
      }
      ...LessonCardCourse
    }
    
    lessonsStates: getLessonsEducationState(courseId: $courseId) {
      __typename
      lessonId
      resultDescription
      ...LessonCardState
    }

    courseResultsByUser: getCourseResultsByUser(courseId: $courseId) {
      __typename
      byLesson {
        name
        avgScore
        sum
      }
    }
  }
  ${LessonCard.fragments.root}
  ${LessonCard.fragments.course}
  ${LessonCard.fragments.state}
`;
