import {ApolloCache, gql, MutationFunctionOptions, useApolloClient, useMutation} from "@apollo/client";
import {useCallback} from "react";
import {addIdentity, rewriteCacheQuery} from "../../apollo/utils";
import {Group, GroupInput, MutationAddGroupArgs, MutationDeleteGroupArgs, User} from "../../schema";

export type QueryVars = {}

export type QueryData = {
  groups: Group[],
  users: User[] | undefined,
}

export const query = gql`
  query AccountMembersSceneQuery {
    groups: getGroups {
      id
      name
      code
      users {
        id
      }
    }

    users: getCurrentAccountUsers {
      id
      fullName
      name
      email
      groups {
        id
        name
      }
    }
  }
`;

export function useAddGroupMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{group: Group}, MutationAddGroupArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
    return client.mutate({
      mutation: addGroupMutation,
      update: (cache, mutationResult) => {
        const group = mutationResult.data?.group;

        if (!group) {
          return;
        }

        addGroupToCachedGroups(cache, group)
      },
      ...opts
    });
  }, [client])];
}

const addGroupMutation = gql`
  mutation AddGroupMutation($data: GroupInput!) {
    group: addGroup(data: $data) {
      id
      name
      users {
        id
      }
    }
  }
`;

function addGroupToCachedGroups(
  cache: ApolloCache<any>,
  item: Pick<Group, "__typename" | "id">
) {
  rewriteCacheQuery(cache, {
    query: gql`query {
      groups: getGroups {
        id
        __typename
      }
    }`
  }, (cacheData: {
    groups: Group[]
  }) => {
    if (!cacheData.groups) return;
    return addIdentity(cacheData, "groups", item)
  })
}

export function useDeleteGroupMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{deleted: boolean}, MutationDeleteGroupArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
    const groupId = opts.variables?.id;

    return client.mutate({
      mutation: deleteGroupMutation,
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.deleted;

        if (!result) {
          return;
        }

        cache.evict({id: `Group:${groupId}`});
        cache.gc();
      },
      ...opts
    });
  }, [client])];
}

const deleteGroupMutation = gql`
  mutation DeleteGroupMutation($id: ID!) {
    deleted: deleteGroup(id: $id)
  }
`;

export function useUpdateGroupMutation() {
  return useMutation<{
    group: Group
  }, {
    groupId: Group["id"],
    data: GroupInput
  }>(updateGroupMutation);
}

const updateGroupMutation = gql`
  mutation UpdateGroupMutation($groupId: ID!, $data: GroupInput!) {
    group: updateGroup(id: $groupId, data: $data) {
      id
      name
      code
      users {
        id
      }
    }
  }
`;

export function useDeactivateGroupMembersMutation() {
  return useMutation<{
    group: Group
  }, {
    id: Group["id"]
  }>(deactivateGroupMembersMutation);
}

const deactivateGroupMembersMutation = gql`
  mutation DeactivateGroupMembersMutation($id: ID!) {
    deactivated: deactivateGroupMembers(groupId: $id) {
      id
      name
      code
      users {
        id
        hasActiveMembership
      }
    }
  }
`;