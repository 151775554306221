import React from "react";

import cn from "classnames";
import classes from "./PlaceholderIcon.module.css";

const PlaceholderIcon = React.memo(function ({className, ...props}: React.ComponentProps<"svg">) {
  return (
    <svg className={cn(classes.root, className)} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
      <circle fill="currentColor" r="50%" cx="50%" cy="50%"/>
    </svg>
  )
});

export default PlaceholderIcon;
