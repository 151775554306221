import React from "react";
import {Lesson} from "../../../schema";

import cn from "classnames"
import classes from "./Lesson.module.css";

import {useSelector} from "react-redux";
import {PlayerReduxState} from "../../../redux/player/types";
import {isEqual} from "lodash";
import {getLevel, getMaxLevel} from "../../../redux/player/selectors";
import {formatDuration} from "../../../time";

type props = React.ComponentProps<"span">

export function Name({lessonName, className, ...props}: props & {
  lessonName: Lesson["name"]
}) {
  return (
    <span className={cn(className, classes.root, classes.name)} {...props}>
      {lessonName}
    </span>
  );
}

export function Level({className, ...props}: props) {
  const {level, maxLevel} = useSelector(Level.selector, isEqual);

  return (
    <span className={cn(className, classes.root)} {...props}>
      {level}/{maxLevel}
    </span>
  );
}

export function Timer({timeLimit, className, ...props}: props & {timeLimit?: number}) {

  if (timeLimit !== undefined) {
    return (
      <span className={cn(className, classes.root)} {...props}>
        {formatDuration(timeLimit)}
      </span>
    )
  }
  return <></>
}

Level.selector = (state: PlayerReduxState) => ({
  level: getLevel(state),
  maxLevel: getMaxLevel(state),
})
