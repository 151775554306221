import {gql, useMutation} from "@apollo/client";
import AccountItem from "../../components/login/AccountItem";
import {MutationHookOptions} from "@apollo/client/react/types/types";
import {loginUpdateCache} from "./common.graphql";
import {AppAccountFragment, AppUserFragment} from "../../App.graphql";

export const query = gql`
  query AvailableAccountsListQuery {
    accounts: getAvailableAccounts {
      id
      ...AccountItem
    }
  }

  ${AccountItem.fragments.root}
`;


export function useSelectAccountMutation(opts?: MutationHookOptions) {
  return useMutation(selectAccountMutation, {
    ...opts,

    update: (cache, mutationResult) => {
      if (mutationResult.data?.login) {
        loginUpdateCache(cache, {
          user: mutationResult.data?.login.user,
          account: mutationResult.data?.login.account
        });
      }

      opts?.update && opts.update(cache, mutationResult, {});
    }
  });
}

const selectAccountMutation = gql`
  mutation LoginSelectAccountMutation($accountId: ID!) {
    login: loginSelectAccount(accountId: $accountId) {
      sessionId
      account { id ...AppAccount}
      user { id ...AppUser }
    }
  }

  ${AppUserFragment}
  ${AppAccountFragment}
`;
