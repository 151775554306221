import {ComponentProps, ComponentPropsWithRef, ElementType, forwardRef} from "react";
import {IconType} from "./icons";

export type ListActionProps = {
  icon?: IconType,
  title?: string,
  notCloseOnClick?: boolean,
}

type AsAnyComponentProps<Props extends Object, Type extends ElementType> = {
  as: Type
} & Props


// Better not to use this type - all props will be any type
export type AsAnyComponent<
  Type extends ElementType,
  Props extends Object
> = AsAnyComponentProps<Props, Type> & ComponentPropsWithRef<Type>

// If you create not too generic component use this to save typing for custom props
export type AsAnyComponentWithDefault<
  Type extends ElementType,
  Props extends Object,
  Default extends keyof JSX.IntrinsicElements,
  Keys extends PropertyKey = keyof {}
> = (
  AsAnyComponentProps<Props, Type> & Omit<ComponentProps<Type>, keyof AsAnyComponentProps<Props, Type> | Keys>
) | (
  AsAnyComponentProps<Props, Default> & Omit<ComponentProps<Default>, keyof AsAnyComponentProps<Props, Default> | Keys>
)

export type AsSpecificComponent<
  Type extends ElementType,
  As extends ElementType
> = Omit<ComponentProps<Type>, "as"> & Omit<ComponentProps<As>, keyof ComponentProps<Type>>

export interface ListAction {
  (props: AsAnyComponentWithDefault<ElementType, ListActionProps, "button">): JSX.Element
  defaultProps: Omit<Object, "as"> & {
    as: "button"
  }
}

export function forwardRefWithDefaultProps<T, P = {}>(
  render: React.ForwardRefRenderFunction<T, P>, defaultProps: Partial<P>
) {
  const typedRender = render as React.ForwardRefRenderFunction<T, (
    Omit<P, keyof typeof defaultProps> & Partial<Pick<P, keyof typeof defaultProps>>
  )>
  const result = forwardRef(typedRender);
  result.defaultProps = defaultProps;
  return result;
}
