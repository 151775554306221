import React from "react";
import Display from "./Display"


type props = {
  title: React.ReactNode,
  children: React.ReactNode,
  translateContent?: "yes" | "no",
  mediaPreview?: React.ReactNode,
}

export default function ActionDisplay({
  title, children, translateContent, mediaPreview
}: props) {

  return (
    <Display>
      <Display.Title title={title}/>
      {children && (
        <Display.Content translate={translateContent}>
          {children}
        </Display.Content>
      )}
      {mediaPreview}
    </Display>
  );
}