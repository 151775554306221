import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useTour} from "../../../providers/tour";

import {gql} from "@apollo/client";
import {isEditorIndexTourMock} from "../../../scenes/editor/index.tour";
import {Course, Lesson, LessonInput} from "../../../schema";

import {useModal} from "../../ModalProvider";
import MoveLessonModal from "./MoveLessonModal";
import EditItemDropdown from "../../EditItemDropdown";

type props = Pick<React.ComponentProps<typeof MoveLessonModal.Content>, "courses" | "lesson"> & {
  lesson: Pick<Lesson, "id" | "name">,
  currentCourse?: Pick<Course, "id">,
  disabled?: boolean,
  onCopy: (lessonId: Lesson["id"]) => void,
  onDelete: (lessonId: Lesson["id"]) => void,
  onMove: (lessonId: string, data: Pick<LessonInput, "courseId">) => void,
}

export default function LessonEditDropdown(
  {lesson, courses, currentCourse, disabled, onCopy, onDelete, onMove}: props
) {
  const {t} = useTranslation();

  const {mock: tourMock} = useTour();

  const mock = useMemo(() => {
    if (tourMock && isEditorIndexTourMock(tourMock)) {
      return tourMock.getBy.lesson.get(lesson.id)
    }
  }, [lesson, tourMock]);
  const onDropdownClick = mock?.eventHandlers.dropdownClick;

  const currentCourseId = currentCourse?.id;

  const onRefClick = useCallback(() => {
    onDropdownClick && setTimeout(onDropdownClick);
  }, [onDropdownClick]);

  const copyHandler = useCallback(() => {
    onCopy && onCopy(lesson.id);
  }, [onCopy, lesson.id]);

  const deleteHandler = useCallback(() => {
    onDelete && onDelete(lesson.id);
  }, [onDelete, lesson.id]);

  const {add: addModal} = useModal();

  const onMoveLessonClick = useCallback(() => {
    const moveCourse = (data: Pick<LessonInput, "courseId">) => {
      onMove(lesson.id, data)
      modal.remove()
    }

    const modal = addModal({
      header: <MoveLessonModal.Header/>,
      content: (
        <MoveLessonModal.Content
          lesson={lesson}
          initialId={currentCourseId}
          courses={courses}
          onMove={moveCourse}
        />
      )
    })
  }, [lesson, currentCourseId, courses, onMove, addModal]);

  return (
    <EditItemDropdown
      className={mock?.classes.dropdownContent}
      buttonClassName={mock?.classes.dropdown}
      onRefClick={onRefClick}
    >
      <EditItemDropdown.Action title={t("common.copy")} onClick={copyHandler} disabled={disabled}/>
      <EditItemDropdown.Action
        title={t("components.LessonEditDropdown.move")}
        onClick={onMoveLessonClick}
        disabled={disabled}/>
      <EditItemDropdown.Action title={t("common.delete")} onClick={deleteHandler} disabled={disabled}/>
    </EditItemDropdown>
  )
}

LessonEditDropdown.fragments = {
  root: gql`
    fragment LessonEditDropdown on Lesson {
      id
      name
      ...MoveLessonModal
    }
    ${MoveLessonModal.fragments.root}
  `,
  courses: gql`
    fragment LessonEditDropdownCourses on Course {
      ...MoveLessonModalCourses
    }
    ${MoveLessonModal.fragments.courses}
  `
}
