import {PlayerReduxState} from "../types";
import getCurrentAction from "./getCurrentAction";
import isRecognizing from "./isRecognizing";
import isErrorState from "./isErrorState";
import {isAnyUserInputAction} from "../utils";
import {getPreviousAction} from "./getPreviousAction";

export default function canRepeat(state: PlayerReduxState): boolean {
  if (isRecognizing(state) || isErrorState(state)) {
    return false;
  }
  const action = getCurrentAction(state);

  if (!action || !isAnyUserInputAction(action)) {
    return false;
  }

  const prevAction = getPreviousAction(state);

  return !(!prevAction || isAnyUserInputAction(prevAction));
}
