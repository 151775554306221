import {gql, useMutation} from "@apollo/client";
import {MutationRequestLicensesArgs} from "../../schema";

export function useRequestLicenses() {
  return useMutation<{ success: boolean }, MutationRequestLicensesArgs>(buyMutation);
}

const buyMutation = gql`
  mutation RequestLicensesMutation(
    $count: Int!, $duration: Int!, $languageCode: LanguageCode!, $customerType: CustomerType!,
    $name: String, $legalAddress: String, $phoneNumber: String, $tin: Int, $trrc: Int
  ) {
    status: requestLicenses(
      count: $count, duration: $duration, languageCode: $languageCode, customerType: $customerType,
      name: $name, legalAddress: $legalAddress, phoneNumber: $phoneNumber, tin: $tin, trrc: $trrc
    )
  }
`;
