/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import useSearchParamsState from "../../hooks/useSearchParamsState";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {useCurrentAccount, useCurrentUser} from "../../App.context";
import {getById, getMapByObjectProperty, isValidEmail, queryByTypenames, separateByTypename} from "../../utils";
import {reverse} from "../../routing";
import query, {
  paginatedListQuery, participationsSummaryQuery,
  PickedCourse, PickedLesson,
  QueryData,
  ParticipationsSummaryQueryData,
  PaginatedListQueryData, PaginatedListQueryVars,
  useAddUsersMutation,
  useInviteParticipantsMutation,
  useSetUsersActivityMutation,
  useUpdateMembershipMutation,
  useUpdateParticipationMutation,
  useArchiveParticipationMutation,
  useSetReportsReceiveMutation,
  usersExportUrlWithFiltersQuery,
  UsersExportUrlWithFiltersQueryData,
  useSetExtraEmailMutation,
  useImportMembersCsvMutation,
  usersExportUrlWithFiltersQueryXls
} from "./members.graphql";
import {
  QueryGetParticipationsSummaryArgs,
  EducationMemberFilterInput,
  EducationMemberRow,
  LessonCourse,
  LanguageCode,
  User,
  EditorAccessType,
} from "../../schema";
import {TABLE_ROWS_PER_PAGE} from "../../settings";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import MembersFilterForm from "../../components/account/MembersFilterForm";
import MembersTable from "../../components/account/members-table/MembersTable";
import Paginator from "../../ui/paginator";
import Checkbox from "../../ui/checkbox";
import AssignModal from "../../components/account/AssignModal";
import {useModal} from "../../components/ModalProvider";
import Button from "../../ui/button";
import DropdownList from "../../ui/dropdownlist";
import {CaretDown} from "../../ui/icons";
import AddMembersModal from "../../components/account/AddMembersModal";
import MembersActivityModal from "../../components/account/MembersActivityModal";
import AccountInfo from "../../components/account/AccountInfo";
import ParticipationActivityModal from "../../components/account/ParticipationsActivityModal";
import ParticipationsSummary from "../../components/account/ParticipationsSummary";
import ReactLink from "../../components/ReactLink";
import Link from "../../ui/link";
import Input from "../../ui/input";

import classes from "./members.module.css";
import {analyticsSendEvent} from "../../libs/analytics";
import DeleteModal from "../../components/editor/DeleteModal";
import {useFormState} from "../../hooks/useFormState";
import {getLanguageCode} from "../../i18n";
import ImportMembersModal from "../../components/account/ImportMembersModal";
import {DemoAccessExpiredWidget} from "../../components/system/DemoAccessExpiredWidget";
import Textarea from "../../ui/textarea";

const SEARCH_PARAMS_CONFIG: {
  lessonId:   { type: "string"  };
  courseId:   { type: "string"  };
  groupsIds:  { type: "string"  },
  email:      { type: "string"  };
  username:   { type: "string"  };
  active:     { type: "boolean" };
  completed:  { type: "boolean" };
  daysAmount:  { type: "integer" };
  adminFilter:  { type: "boolean" };
} = {
  "lessonId":   {type: "string"},
  "courseId":   {type: "string"},
  "groupsIds":  {type: "string"},
  "email":      {type: "string"},
  "username":   {type: "string"},
  "active":     {type: "boolean"},
  "completed":  {type: "boolean"},
  "daysAmount":  {type: "integer"},
  "adminFilter":{type: "boolean"},
}

export type InviteParticipants = (
  id: LessonCourse["id"], type: LessonCourse["__typename"], usersIds?: User["id"][], emails?: string[]
) => void
export type AddMembers = (emails: string[], names: string[], languageCode?: LanguageCode) => void
export type SetUsersActivity = (emails: string[], isActive: boolean) => void

type formState = {
  email: string,
}

export default function AccountMembersScene() {
  const {t, i18n} = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [rawFilter, setFilter] = useSearchParamsState(SEARCH_PARAMS_CONFIG);

  const filter = useMemo(() => ({
    ...rawFilter,
    groupsIds: rawFilter.groupsIds !== "" ? rawFilter.groupsIds.split(" ") : undefined,
    daysAmount: rawFilter.daysAmount ?? 0
  }), [rawFilter]);

  const setMemebrsFilter = useCallback((data: EducationMemberFilterInput) => {
    const serializedData = {
      ...data,
      lessonId: data.lessonId ?? "",
      courseId: data.courseId ?? "",
      email: data.email ?? "",
      username: data.username ?? "",
      groupsIds: data.groupsIds ? data.groupsIds.join(" ") : "",
      daysAmount: data.daysAmount ?? 0,
      adminFilter: data.adminFilter ?? ""
    } as Parameters<typeof setFilter>[0];

    setFilter(serializedData);
  }, [setFilter]);

  const filterLessonId = filter.lessonId ?? undefined;
  const filterCourseId = filter.courseId ?? undefined;
  const [members, setMembers] = useState<EducationMemberRow[]>([])

  const {data, loading: fetching} = useQuery<QueryData>(query);

  const {
    educationItems: items,
    participationsExportUrl,
    usersExportUrl,
    participationsExportUrlXls,
    usersExportUrlXls,
    contracts,
    membersSummary,
    account,
    users,
    groups,
    editorAccessType
  } = data ?? {};

  const isFullAccess = editorAccessType === EditorAccessType.FULL_ACCESS
  const isDemoExpired = editorAccessType === EditorAccessType.EXPIRED_DEMO_ACCESS

  const {data: listData, loading: loadingList} = useQuery<
    PaginatedListQueryData, PaginatedListQueryVars
  >(paginatedListQuery, {
    variables: {
      offset: (currentPage - 1) * TABLE_ROWS_PER_PAGE,
      limit: TABLE_ROWS_PER_PAGE,
      filter
    },
    fetchPolicy: "cache-and-network",
  })

  const {data: urlData, loading: loadingUrl} = useQuery<
  UsersExportUrlWithFiltersQueryData
>(usersExportUrlWithFiltersQuery, {
  variables: {
    filter
  }
})

  const {data: urlDataXls} = useQuery<
  UsersExportUrlWithFiltersQueryData
>(usersExportUrlWithFiltersQueryXls, {
    variables: {
      filter
    }
  })

  const paginatedList = listData?.paginatedList;
  const usersExportUrlWithFilters = urlData?.usersExportUrlWithFilters;
  const usersExportUrlWithFiltersXls = urlDataXls?.usersExportUrlWithFilters;

  const {data: participationsSummaryData, loading: loadingParticipationsSummary} = useQuery<
    ParticipationsSummaryQueryData, QueryGetParticipationsSummaryArgs
  >(participationsSummaryQuery, {
    variables: {
      lessonId: filter.lessonId,
      courseId: filter.courseId,
      groupsIds: filter.groupsIds ?? []
    }
  })

  const participationsSummary = participationsSummaryData?.participationsSummary;

  const flatLessons = useMemo(() => (
    data?.flatLessons
      ? separateByTypename(data.flatLessons).get("Lesson") as PickedLesson[]
      : undefined
  ), [data?.flatLessons])

  const [lessons, courses] = useMemo(() => {
    if (!items) {
      return [undefined, undefined]
    }
    return queryByTypenames(items, ["Lesson", "Course"]) as [PickedLesson[], PickedCourse[]];
  }, [items])

  const currentItem = useMemo(() => {
    if ((flatLessons === undefined) || (courses === undefined)) {
      return;
    }
    if (filter.lessonId) {
      return getById(flatLessons, filter.lessonId);
    }
    if (filter.courseId) {
      return getById(courses, filter.courseId);
    }
  }, [flatLessons, courses, filter.lessonId, filter.courseId])

  const mappedGroupsById = useMemo(() => groups ? getMapByObjectProperty(groups, "id") : undefined, [groups]);

  const currentGroups = useMemo(() => {
    if ((mappedGroupsById === undefined) || (filter.groupsIds === undefined)) {
      return;
    }
    return filter.groupsIds.map(id => mappedGroupsById.get(id)!);
  }, [mappedGroupsById, filter.groupsIds])

  const loading = !data && fetching;

  const [applyUpdateMembershipMutation] = useUpdateMembershipMutation();
  const [applyUpdateParticipationMutation] = useUpdateParticipationMutation();
  const [applyArchiveParticipationMutation] = useArchiveParticipationMutation();
  const [applySetReportsReceiveMutation] = useSetReportsReceiveMutation();
  const [applySetExtraEmailMutation] = useSetExtraEmailMutation();

  const onSetUserStatus = useCallback((userId: string, isActive: boolean) => {
    applyUpdateMembershipMutation({
      variables: {
        userId,
        data: {
          isActive
        }
      }
    });
  }, [applyUpdateMembershipMutation]);

  const onSetParticipationStatus = useCallback((
      userId: string | undefined,  status: boolean, lessonId?: string, courseId?: string, emails?: string[]
    ) => {
    applyUpdateParticipationMutation({
      variables: {
        userId,
        lessonId,
        courseId,
        status,
        emails,
      }
    });
  }, [applyUpdateParticipationMutation]);

  const onParticipationDelete = useCallback((
    userId: string | undefined, lessonId?: string, courseId?: string, emails?: string[]
  ) => {
  applyArchiveParticipationMutation({
    variables: {
      userId,
      lessonId,
      courseId,
      emails,
    },
    refetchQueries: ["GetAccountMembersQuery"]
  });
}, [applyArchiveParticipationMutation]);

  const [applyAddMembersMutation] = useAddUsersMutation();
  const [applySetUsersActivityMutation] = useSetUsersActivityMutation();
  const [applyInviteParticipantsMutation] = useInviteParticipantsMutation();
  const [applyImportMembersCsvMutation] = useImportMembersCsvMutation();

  const onAddMembers: AddMembers = useCallback((emails, names, languageCode) => {
    applyAddMembersMutation({
      variables: {
        emails,
        names,
        languageCode
      }
    });
  }, [applyAddMembersMutation]);

  const onSetActivity: SetUsersActivity = useCallback((emails, isActive) => {
    applySetUsersActivityMutation({
      variables: {
        emails,
        isActive
      }
    });
  }, [applySetUsersActivityMutation]);


  const onAssign: InviteParticipants = useCallback((id, type, usersIds, emails) => {
    applyInviteParticipantsMutation({
      variables: {
        usersIds,
        emails,
        lessonId: type === "Lesson" ? id : undefined,
        courseId: type === "Course" ? id : undefined,
      },
      refetchQueries: ["GetAccountMembersQuery"]
    });
  }, [applyInviteParticipantsMutation]);

  const {add: addModal} = useModal();
  const onAddMembersButtonClick = useCallback(() => {
    const addMembers = (emails: string[], names: string[], languageCode?: LanguageCode) => {
      onAddMembers(emails, names, languageCode)
      modal.remove()
    }

    const modal = addModal({
      header: <AddMembersModal.Header/>,
      content: (
        <AddMembersModal.Content
            onAddMembers={addMembers}
        />
      )
    })
  }, [addModal, onAddMembers]);

  const onParticipationDeleteButtonClick = useCallback(
    (userId: string | undefined, lessonId?: string, courseId?: string, emails?: string[]) => {
    const onDelete = () => {
      analyticsSendEvent("membersParticipationDelete", {
        userId: userId,
        lessonId: lessonId,
      });
      onParticipationDelete(userId, lessonId, courseId, emails);
      modal.remove();
    };

  const onCancel = () => modal.remove();

  const modal = addModal({
    id: `deleteParticipation_${lessonId}`,
    header: t("members.deleteParticipation"),
    content: t("members.deleteParticipationConfirmation"),
    footer: <DeleteModal.Footer onConfirm={onDelete} onCancel={onCancel}/>
  });
}, [t, onParticipationDelete, addModal]);


  const onMembersActivityButtonClick = useCallback(() => {
    const setActivity = (emails: string[], isActive: boolean) => {
      onSetActivity(emails, isActive)
      modal.remove()
    }

    const modal = addModal({
      header: <MembersActivityModal.Header/>,
      content: (
        <MembersActivityModal.Content
          onSetUsersActivity={setActivity}
        />
      )
    })
  }, [addModal, onSetActivity]);

  const onImportMembers = useCallback((attachment: File, sendWelcomeEmails: boolean, overrideActive: boolean) => {
    applyImportMembersCsvMutation({
      variables: {
        attachment: attachment,
        sendWelcomeEmails: sendWelcomeEmails,
        overrideActive: overrideActive,
        emailLanguage: getLanguageCode(i18n.language),
      },
      refetchQueries: ["GetAccountMembersQuery"]
    }).then(({data}) => {
      addModal({
        id: "ImportCSVResult",
        header: t("components.members.ImportMembersModal.result"),
        content: (
          <Textarea
            className={classes.input}
            name="result"
            rows={6}
            readOnly
            value={data?.result}
          />
        )
      }, true)
    });
  }, [applyImportMembersCsvMutation, addModal, t, i18n.language]);

  const onImportMembersButtonClick = useCallback(() => {
    const importMembers = (attachment: File, sendWelcomeEmails: boolean, overrideActive: boolean) => {
      onImportMembers(attachment, sendWelcomeEmails, overrideActive)
      modal.remove()
    }

    const modal = addModal({
      header: <ImportMembersModal.Header/>,
      content: (
        <ImportMembersModal.Content
          onImportMembers={importMembers}
        />
      )
    })
  }, [addModal, onImportMembers]);


  const onParticipationsActivityButtonClick = useCallback(() => {
    const setActivity = (emails: string[], isActive: boolean, lessonId?: string, courseId?: string) => {
      onSetParticipationStatus(undefined, isActive, lessonId, courseId, emails)
      modal.remove()
    }
    const deleteParticipation = (emails: string[], lessonId?: string, courseId?: string) => {
      onParticipationDeleteButtonClick(undefined, lessonId, courseId, emails)
      modal.remove()
    }

    const modal = addModal({
      header: <ParticipationActivityModal.Header/>,
      content: (
        <ParticipationActivityModal.Content
          lessons={flatLessons}
          courses={courses}
          params={{
            onAssign: setActivity,
            onDelete: deleteParticipation
          }}
        />
      )
    })
  }, [addModal, flatLessons, courses, onSetParticipationStatus, onParticipationDeleteButtonClick]);

  const onAssignButtonClick = useCallback(() => {
    if (! users || !groups) {
      return;
    }
    const assignLessonToUser: InviteParticipants = (id, type, usersIds, emails) => {
      onAssign(id, type, usersIds, emails)
      modal.remove()
    }

    const id = (filterLessonId ?? filterCourseId)!;
    const type = filterLessonId ? "Lesson" : filterCourseId ? "Course" : undefined;

    const modal = addModal({
      size: "l",
      header: <AssignModal.Header/>,
      content: (
        <AssignModal.Content
          initialId={id}
          initialType={type}
          lessons={lessons}
          courses={courses}
          params={{
            multipleUsers: true,
            onAssign: assignLessonToUser,
            users: users,
            groups: groups
          }}
        />
      )
    })
  }, [lessons, courses, users, groups, addModal, onAssign, filterLessonId, filterCourseId]);

  useEffect(() => {
    if (!loading) {
      setMembers(paginatedList?.items ?? [])
    }
  }, [loading, paginatedList])

  const itemsCount = Math.min(members.length, TABLE_ROWS_PER_PAGE);
  const totalItems = paginatedList?.countItems ?? 0;

  const totalPages = Math.ceil(totalItems / TABLE_ROWS_PER_PAGE);

  const dropdownManageMenu = useMemo(() => (
    <>
      <DropdownList.Action title={t("members.addUsers")} onClick={onAddMembersButtonClick}/>
      <DropdownList.Action title={t("members.membersActivity")} onClick={onMembersActivityButtonClick}/>
      <DropdownList.Action title={t("members.participationsActivity")} onClick={onParticipationsActivityButtonClick}/>
    </>
  ), [t, onAddMembersButtonClick, onMembersActivityButtonClick, onParticipationsActivityButtonClick])

  const dropdownCSVMenu = useMemo(() => (
    <>
      <DropdownList.Action as="a" title={t("members.reports.sessions")} href={participationsExportUrl}/>
      <DropdownList.Action as="a" title={t("members.reports.users")} href={usersExportUrl}/>
      <DropdownList.Action as="a" title={t("members.reports.sessionsXls")} href={participationsExportUrlXls}/>
      <DropdownList.Action as="a" title={t("members.reports.usersXls")} href={usersExportUrlXls}/>
    </>
  ), [t, participationsExportUrl, usersExportUrl, participationsExportUrlXls, usersExportUrlXls])

  useEffect(() => {
    if ((currentPage > totalPages) && (totalPages !== 0)) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage])

  const [receiveReports, setReceiveReports] = useState(useCurrentAccount()?.receiveReports ?? false);

  const toggleReceiveReports = useCallback(() => {
    setReceiveReports(!receiveReports)

    applySetReportsReceiveMutation({
      variables: {
        status: !receiveReports,
      },
      refetchQueries: ["AppQuery"]
    });
  }, [receiveReports, applySetReportsReceiveMutation]);

  const formState = useFormState<formState>({
    initialValues: {
      email: useCurrentUser()?.extraEmail ?? "",
    },
    preventDefault: true,
    onChange: () => {
      var validEmail: string | undefined = undefined;
      if (isValidEmail(formState.values.email)) {
        validEmail = formState.values.email;
      }

      if (validEmail) {
        applySetExtraEmailMutation({
          variables: {
            email: validEmail,
          },
          refetchQueries: ["AppQuery"]
        });
      }
    }
  });

  return (
    <>
      <ReactDocumentTitle title={t("members.title")}/>

      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <span>{t("breadcrumbs.members")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("members.h1")}</h1>
              <div className={classes.topRightMenu}>
                <Button className={classes.menuButton} onClick={onImportMembersButtonClick} disabled={!isFullAccess}>
                  {t("components.ImportMembersModal.button")}
                </Button>
                <Button onClick={onAssignButtonClick} className={classes.first} disabled={!isFullAccess}>
                  {t("members.assign")}
                </Button>
                <DropdownList rounded dropShadow
                  placement="bottom-end"
                  offset={[0, 8]}
                  className={classes.secondA}
                  content={dropdownManageMenu}
                >
                  <Button className={classes.menuButton} disabled={!isFullAccess}>
                    {t("members.manage")} <CaretDown/>
                  </Button>
                </DropdownList>
                <DropdownList rounded dropShadow
                  placement="bottom-end"
                  offset={[0, 8]}
                  className={classes.secondB}
                  content={dropdownCSVMenu}
                >
                  <Button
                    className={classes.menuButton}
                    disabled={!isFullAccess}
                  >
                    {t("members.reports.title")}
                    <CaretDown/>
                  </Button>
                </DropdownList>
                {isFullAccess ? (
                  <Button className={classes.third} as={ReactLink} href={reverse("accountMembersGroups")}>
                    {t("members.groups")}
                  </Button>
                ) : (
                  <Button className={classes.third} disabled>
                    {t("members.groups")}
                  </Button>
                )}
              </div>
            </div>

            {!loading && (
              <AccountInfo contracts={contracts!} membersSummary={membersSummary!} account={account!}/>
            )}

            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loadingParticipationsSummary && participationsSummary && (
                  <ParticipationsSummary
                    className={classes.summary}
                    currentItem={currentItem}
                    currentGroups={currentGroups}
                    participationsSummary={participationsSummary}
                  />
                )}
                <div className={classes.tableRoot}>
                  {!loading && !loadingList ? (
                    <MembersTable
                      members={members}
                      lessons={lessons}
                      courses={courses}
                      onSetStatus={onSetUserStatus}
                      onSetParticipationStatus={onSetParticipationStatus}
                      onAssign={onAssign}
                      onParticipationDelete={onParticipationDeleteButtonClick}
                      unambiguousSubRow={!!filter.lessonId}
                    />
                  ) : (
                    <MembersTable.Skeleton/>
                  )}
                </div>

                <div className={classes.paginatorRow}>
                  <div className={classes.paginatorHelpText}>
                    {t("common.paginatorShowingItems", {
                      items: itemsCount,
                      totalItems
                    })}
                  </div>

                  {(totalPages > 1) && (
                    <Paginator
                      className={classes.paginator}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  )}
                </div>
              </div>
              <div className={classes.contentAside}>
                <MembersFilterForm
                  initialValues={filter}
                  onSave={setMemebrsFilter}
                  lessons={flatLessons}
                  courses={courses}
                  groups={groups}
                />
                {!loadingUrl && usersExportUrlWithFilters && (
                  <div className={classes.downloadLink}>
                    <span className="mr-1">{t("members.reports.usersWithFilters")}</span>
                    <Link
                      as="a"
                      href={usersExportUrlWithFilters}
                    >
                      {"CSV"}
                    </Link>
                    <span>{" / "}</span>
                    <Link
                      as="a"
                      href={usersExportUrlWithFiltersXls}
                    >
                      {"XLS"}
                    </Link>
                  </div>
                )}
                <div className={classes.formItem}>
                  <Checkbox
                    checked={receiveReports}
                    onChange={toggleReceiveReports}
                    disabled={!isFullAccess}
                  />
                  <span className={classes.checkboxText}>{t("members.receiveReports")}</span>
                </div>
                <span className={classes.formSubItem}>{t("members.extraEmail")}</span>
                <Input
                  name="email"
                  displaySize="sm"
                  placeholder='example@gmail.com'
                  value={formState.values.email ?? undefined}
                  onChange={formState.changeHandler}
                  disabled={!isFullAccess}
                />
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
      {isDemoExpired && <DemoAccessExpiredWidget/>}
    </>
  )
}
