import {PlayerReduxState} from "../types";
import {getLesson, isExamStage, isIntroStage, isPreview} from ".";
import {LessonMode} from "../../../schema";

export default function canSkipLevel(state: PlayerReduxState): boolean | null | undefined {
  const lesson = getLesson(state);
  const preview = isPreview(state);
  const examStage = isExamStage(state);
  const isIntro = isIntroStage(state);

  return isIntro
    && (preview || lesson.hasPassed)
    && !examStage
    && lesson.mode !== LessonMode.INTERVIEW
    && lesson.mode !== LessonMode.QUIZ
}