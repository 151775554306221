import React, {ComponentProps, useLayoutEffect, useMemo, useRef, useState} from "react";
import {bindEventListener} from "../../../utils";

const TARGET_ASPECT_RATIO = 16 / 9

export type Props = Omit<ComponentProps<"div">, "children" | "style"> & {
  itemsCount: number,
  integer?: boolean,
  waitUntilMeasured?: boolean,
  children: (size: {
    width: number,
    height: number,
  }) => React.ReactNode,
};

const AbstractVideoArea = ({integer, itemsCount, waitUntilMeasured, children, ...props}: Props) => {
  const [showChildren, setChildrenVisibility] = useState(!waitUntilMeasured)

  const measurerRef = useRef<HTMLDivElement>(null);

  const [blockSize, setBlockSize] = useState<{
    width: number,
    height: number
  }>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    const handler = () => {
      if (measurerRef.current) {
        setBlockSize({
          width: measurerRef.current.offsetWidth,
          height: measurerRef.current.offsetHeight
        });
        setChildrenVisibility(true)
      }
    };

    handler();
    return bindEventListener(window, "resize", handler)
  }, []);

  const {width, height} = useMemo(() => {
    let {width, height} = calculateBoxSize(blockSize, itemsCount, TARGET_ASPECT_RATIO);

    if (integer) {
      width = Math.floor(width);
      height = Math.floor(height);
    }

    return {width, height}
  }, [blockSize, itemsCount, integer]);

  return (
    <>
      <div ref={measurerRef} style={{
        position: "absolute",
        width: "100%",
        height: "100%"
      }} {...props}/>

      {showChildren && children && children({
        width,
        height
      })}
    </>
  )
}

export default AbstractVideoArea;

function calculateBoxSize(
  containerSize: { width: number, height: number },
  childrenCount: number,
  childrenAspectRatio: number
): { width: number, height: number } {
  if (containerSize.width === 0 || containerSize.height === 0) {
    return {
      width: 0,
      height: 0
    }
  }

  // Aspect ratio of root container
  const aspectRatio = containerSize.width / containerSize.height

  // Number of rows and columns for boxes of max size with respect to target aspect ratio
  const rows = childrenCount === 1 ? 1 : Math.floor(Math.sqrt((childrenCount / (aspectRatio / childrenAspectRatio))));
  const columns = Math.ceil(childrenCount / rows)

  // Size of individual cell
  let wrapperSizes = {
    width: containerSize.width / columns,
    height: containerSize.height / rows
  }

  if (wrapperSizes.width / childrenAspectRatio > wrapperSizes.height) {
    wrapperSizes.width = wrapperSizes.height * childrenAspectRatio;
  } else {
    wrapperSizes.height = wrapperSizes.width / childrenAspectRatio;
  }

  return wrapperSizes;
}
