import React, {useCallback, useEffect, useRef, useState} from "react";

type args<S> = {
  initialValues: S,
  preventDefault?: boolean,
  onChange?: (values: S) => void,
  onSubmit?: (values: S) => void,
};

type FormState<S> = {
  values: S;
  setValues: (
    valuesOrCallback: Partial<S> | ((values: S) => Partial<S>)
  ) => void;
  changeHandler: React.FormEventHandler;
  submitHandler: (e?: React.FormEvent) => void;
}

export function useFormState<S>({initialValues, onChange, onSubmit, preventDefault}: args<S>): FormState<S> {
  const [state, setState] = useState<S>(initialValues);
  const stateRef = useRef(state);

  stateRef.current = state;

  const initialMountRef = useRef(true);

  const onSubmitRef = useRef(onSubmit);

  onSubmitRef.current = onSubmit;

  const onChangeRef = useRef(onChange);

  onChangeRef.current = onChange;
  useEffect(() => {
    if (initialMountRef.current) {
      initialMountRef.current = false;
    } else {
      onChangeRef.current && onChangeRef.current(state);
    }
  }, [state]);

  const changeHandler = useCallback<FormState<S>["changeHandler"]>((e) => {
    const el = e.currentTarget ?? e.target;
    const name = el.getAttribute("name");

    if (!isElementWithValue(el)) {
      console.error("Set change handler to non-input element", el);
      return;
    }
    if (!name) {
      console.error("Set change handler to element without name attribute", el);
      return;
    }

    const value = isCheckableInput(el)
      ? (el.checked ? el.value : undefined)
      : el.value;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const setValues = useCallback<FormState<S>["setValues"]>(valuesOrCallback => {
    if (typeof valuesOrCallback === "function") {
      setState((prevState) => ({
        ...prevState,
        ...valuesOrCallback(prevState)
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        ...valuesOrCallback
      }));
    }
  }, []);

  const submitHandler = useCallback<FormState<S>["submitHandler"]>(e => {
    if (e && preventDefault) {
      e.preventDefault();
    }

    onSubmitRef.current && onSubmitRef.current(stateRef.current);
  }, [preventDefault]);

  return {
    values: state,
    setValues,
    changeHandler,
    submitHandler,
  }
}

function isElementWithValue(el: Element): el is (
  HTMLInputElement | HTMLOptionElement | HTMLButtonElement | HTMLTextAreaElement | HTMLSelectElement
) {
  // eslint-disable-next-line no-prototype-builtins
  return ["INPUT", "OPTION", "BUTTON", "TEXTAREA", "SELECT"].includes(el.nodeName) || el.hasOwnProperty("value");
}

function isCheckableInput(el: Element): el is HTMLInputElement {
  // eslint-disable-next-line no-prototype-builtins
  return el.hasOwnProperty("checked");
}
