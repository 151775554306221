import {DetailedSessionFeedbackInput, MutationEducationSessionSubmitFeedbackArgs, Scalars} from "../../../schema";
import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {isResultStage} from "../selectors";
import {sessionSubmitFeedback} from "../graphql";

export default function submitFeedback(opts: {
  data: DetailedSessionFeedbackInput | null,
  score: Scalars["Int"] | null,
  message: Scalars["String"] | null
  contacts: Scalars["String"] | null
}) {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    if (!isResultStage(state)) {
      console.warn("submitFeedback: wrong state");
      return;
    }

    state.client.mutate<{ ok: Scalars["Boolean"] }, MutationEducationSessionSubmitFeedbackArgs>({
      mutation: sessionSubmitFeedback,
      variables: {
        data: opts.data,
        sessionId: state.sessionId!,
        score: opts.score,
        message: opts.message,
        contacts: opts.contacts,
      }
    }).then((result) => {
      if (result?.data?.ok) {
        dispatch({
          type: "update",
          payload: {
            feedbackSent: true,
          }
        })
      }
    }).catch(console.error);
  }
}
