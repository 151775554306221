import React from "react"

import cn from "classnames"
import classes from "./closemodalbutton.module.css"

type props = {
  onClose?: React.MouseEventHandler<SVGPathElement>,
  color?: "secondary" | "primary" | "brand" | "success" | "danger"
}

export default function CloseModalButton({onClose, color}: props) {
  return (
    <svg className={cn(classes.root, color && classes[color])} viewBox="0 0 44 68" xmlns="http://www.w3.org/2000/svg">
      <path onClick={onClose} className={classes.inner}
            d="M0 -2C0 38.3927 45 32.8943 45 68L45 -2L0 -2Z" fill="currentColor"/>
      <path className={classes.cross}
            d="M21.6992 14.7002L27.2992 20.3002" stroke="currentColor"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path className={classes.cross}
            d="M27.3008 14.7002L21.7008 20.3002" stroke="currentColor"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
