import React, {useCallback} from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {InviteParticipants} from "../../../scenes/account/members";
import {Course, EducationMemberRow, Lesson, LessonCourse} from "../../../schema";
import {useModal} from "../../ModalProvider";

import EditItemDropdown from "../../EditItemDropdown";
import AssignModal from "../AssignModal";

type PickedLesson = Pick<Lesson, "id" | "name">
type PickedCourse = Pick<Course, "id" | "name"> & {
  lessons: Pick<Lesson, "id">[]
}

type props = {
  member: Pick<EducationMemberRow, "user">,
  lessons?: PickedLesson[],
  courses?: PickedCourse[],
  onAssign?: InviteParticipants,
  onSetStatus?: (userId: string, isActive: boolean) => void,
}

export default function MemberEditDropdown({member, lessons, courses, onAssign, onSetStatus}: props) {
  const {t} = useTranslation();
  let {user} = member;

  const {add: addModal} = useModal();

  const onAssignButtonClick = useCallback((e: React.MouseEvent) => {
    const assignToUser = (id: string, type: LessonCourse["__typename"]) => {
      onAssign && onAssign(id, type, [user.id])
      modal.remove()
    }

    const modal = addModal({
      header: <AssignModal.Header/>,
      content: (
        <AssignModal.Content
          lessons={lessons}
          courses={courses}
          params={{onAssign: assignToUser}}
        />
      )
    });
  }, [lessons, courses, onAssign, user.id, addModal]);

  const toggleStatus = useCallback(() => {
    onSetStatus && onSetStatus(user.id, !user.hasActiveMembership)
  }, [onSetStatus, user]);

  return (
    <EditItemDropdown>
      {user.hasActiveMembership && (
        <EditItemDropdown.Action title={t("components.MemberEditDropdown.assign")} onClick={onAssignButtonClick}/>
      )}
      <EditItemDropdown.Action
        title={user.hasActiveMembership
          ? t("components.MemberEditDropdown.deactivate")
          : t("components.MemberEditDropdown.activate")
        }
        onClick={toggleStatus}
      />
    </EditItemDropdown>
  )
}

MemberEditDropdown.fragments = {
  root: gql`
    fragment MemberEditDropdown on EducationMemberRow {
      user {
        id
        name
        fullName
        email
        hasActiveMembership
      }
    }
  `,
  lessons: gql`
    fragment MemberEditDropdownLessons on Lesson {
      ...AssignModalLessons
    }
    ${AssignModal.fragments.lessons}
  `,
  courses: gql`
    fragment MemberEditDropdownCourses on Course {
      ...AssignModalCourses
    }
    ${AssignModal.fragments.courses}
  `
}
