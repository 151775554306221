import {gql, useMutation} from "@apollo/client";
import {MutationHookOptions} from "@apollo/client/react/types/types";

export const AppUserFragment = gql`fragment AppUser on User {
  id
  __typename
  name
  fullName
  email
  isStaff
  isDeveloper
  isExperimental
  experimentalMode
  hasActiveMembership
  accountsCount
  extraEmail
  isEmailActivated
}`;

export const AppAccountFragment = gql`fragment AppAccount on Account {
  id
  __typename
  name
  code
  hasActiveMembership
  receiveReports
  textLogo
}`

export const AppQuery = gql`query AppQuery {
  user: getCurrentUser { ...AppUser }
  account: getCurrentAccount { ...AppAccount }
}

${AppUserFragment}
${AppAccountFragment}
`

export function useSetExperimentalModeMutation(opts?: MutationHookOptions) {
  return useMutation(SetExperimentalModeMutation, {
    ...opts,

    update: (cache, mutationResult) => {
      opts?.update && opts.update(cache, mutationResult, {});
    }
  });
}


export const SetExperimentalModeMutation = gql`
  mutation SetExperimentalModeMutation ($state: Boolean!) {
    user: setExperimentalMode(experimentalMode: $state){
      id ...AppUser
    }
  }

  ${AppUserFragment}
`;
