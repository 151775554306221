import React from "react";
import {useSelector} from "react-redux";
import Display from "./Display"
import IntroMessage from "../../IntroMessage";
import {Lesson} from "../../../../schema";
import {PlayerReduxState} from "../../../../redux/player/types";
import {getLevel} from "../../../../redux/player/selectors";
import isEqual from "lodash/isEqual";

type props = {
  lesson: Lesson
}

const IntroDisplay = ({lesson}: props) => {
  const {level} = useSelector(IntroDisplay.selector, isEqual);

  return (
    <Display>
      <Display.Title
        title={<IntroMessage.Title lesson={lesson} level={level}/>}
      />

      <Display.Content>
        <IntroMessage lesson={lesson} level={level}/>
      </Display.Content>
    </Display>
  );
};

export default React.memo(IntroDisplay);

IntroDisplay.selector = (state: PlayerReduxState) => ({
  level: getLevel(state)
});
