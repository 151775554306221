/* eslint-disable max-lines */
import React, {useCallback, useMemo, useRef, useState} from "react";
import useSearchParamsState from "../../hooks/useSearchParamsState";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {queryByTypenames, separateByTypename} from "../../utils";

import query, {
  PickedCourse, PickedLesson,
  QueryData,
} from "./analytics.graphql";
import {
  LessonCourse,
  LanguageCode,
  User,
  EditorAccessType,
  DashboardDataFilterInput,
  DashboardDataType,
  QueryGetDashboardDataArgs,
} from "../../schema";

import {Card} from "@tremor/react";
import {useScreenshot} from "../../hooks/useScreenshot";
import {ChartMeasurementUnits, ChartVisualization} from "../../types";
import {DemoAccessExpiredWidget} from "../../components/system/DemoAccessExpiredWidget";
import DashboardFilterForm from "../../components/analytics/DashboardFilterForm";
import DashboardChart from "../../components/analytics/DashboardChart";
import {formatDurationHrs} from "../../time";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import Button from "../../ui/button";
import classes from "./analytics.module.css";

const SEARCH_PARAMS_CONFIG: {
  lessonsIds:   { type: "string"  };
  coursesIds:   { type: "string"  };
  groupsIds:  { type: "string"  },
  email:      { type: "string"  };
  active:     { type: "boolean" };
  daysAmount:  { type: "integer" };
} = {
  "lessonsIds":   {type: "string"},
  "coursesIds":   {type: "string"},
  "groupsIds":  {type: "string"},
  "email":      {type: "string"},
  "active":     {type: "boolean"},
  "daysAmount":  {type: "integer"},
}

type ExtendedFilterInput = {
  dataType: DashboardDataType,
  visualization: ChartVisualization,
  measurementUnits: ChartMeasurementUnits,
} & DashboardDataFilterInput

export type InviteParticipants = (
  id: LessonCourse["id"], type: LessonCourse["__typename"], usersIds?: User["id"][], emails?: string[]
) => void
export type AddMembers = (emails: string[], names: string[], languageCode?: LanguageCode) => void
export type SetUsersActivity = (emails: string[], isActive: boolean) => void

export default function AnalyticsScene() {
  const {t} = useTranslation();

  const [rawFilter, setFilter] = useSearchParamsState(SEARCH_PARAMS_CONFIG);
  const [dataType, setDataType] = useState<DashboardDataType>(DashboardDataType.STARTED_TO_ALL);
  const [visualization, setVisualization] = useState<ChartVisualization>(ChartVisualization.BAR);
  const [measurementUnits, setMeasurementUnits] = useState<ChartMeasurementUnits>(ChartMeasurementUnits.ABS);

  const filter = useMemo(() => ({
    ...rawFilter,
    groupsIds: rawFilter.groupsIds !== "" ? rawFilter.groupsIds.split(" ") : undefined,
    lessonsIds: rawFilter.lessonsIds !== "" ? rawFilter.lessonsIds.split(" ") : undefined,
    coursesIds: rawFilter.coursesIds !== "" ? rawFilter.coursesIds.split(" ") : undefined,
    daysAmount: rawFilter.daysAmount ?? 0
  }), [rawFilter]);

  const setDashboardFilter = useCallback((data: ExtendedFilterInput) => {
    const serializedData = {
      lessonsIds: data.lessonsIds ? data.lessonsIds.join(" ") : "",
      coursesIds: data.coursesIds ? data.coursesIds.join(" ") : "",
      groupsIds: data.groupsIds ? data.groupsIds.join(" ") : "",
      daysAmount: data.daysAmount ?? 0,
      email: data.email ?? "",
      active: data.active ?? undefined,
    } as Parameters<typeof setFilter>[0];

    setDataType(data.dataType);
    setVisualization(data.visualization);
    setMeasurementUnits(data.measurementUnits);
    setFilter(serializedData);
  }, [setFilter, setDataType]);

  const {data, loading: fetching} = useQuery<QueryData, QueryGetDashboardDataArgs>(query, {
    variables: {
      dataType: dataType ?? DashboardDataType.STARTED_TO_ALL,
      filter: filter
    }
  });

  const {
    dashboard,
    educationItems: items,
    groups,
    editorAccessType
  } = data ?? {};

  const isFullAccess = editorAccessType === EditorAccessType.FULL_ACCESS
  const isDemoExpired = editorAccessType === EditorAccessType.EXPIRED_DEMO_ACCESS

  const flatLessons = useMemo(() => (
    data?.flatLessons
      ? separateByTypename(data.flatLessons).get("Lesson") as PickedLesson[]
      : undefined
  ), [data?.flatLessons])

  const [courses] = useMemo(() => {
    if (!items) {
      return [undefined]
    }
    return queryByTypenames(items, ["Course"]) as [PickedCourse[]];
  }, [items])


  const loading = !data && fetching;

  const screenshotRef = useRef(null)
  const {takeScreenShot} = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  })
  const download = (image: string) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = dataType + ".jpg"
    a.click();
  }
  const downloadScreenShot = () => {
    takeScreenShot(screenshotRef.current!).then(download)
  }

  return (
    <>
      <ReactDocumentTitle title={t("analytics.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <span>{t("breadcrumbs.analytics")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("analytics.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <Card className="mb-2 flex flex-row">
                    {t("analytics.totalTime")}
                    <b className="pl-[5px]">{formatDurationHrs(dashboard?.totalTime)}</b>
                  </Card>
                )}
                <div ref={screenshotRef}>
                  <DashboardChart
                    data={data?.dashboard}
                    dataType={dataType}
                    visualization={visualization}
                    measurementUnits={measurementUnits}
                  />
                </div>
                <div className={classes.afterChart}>
                <Button
                  className={classes.downloadButton}
                  color="primary"
                  onClick={downloadScreenShot}
                  disabled={!isFullAccess}
                >
                  {t("analytics.downloadChart")}
                </Button>
                </div>
              </div>
              <div className={classes.contentAside}>
                <DashboardFilterForm
                  initialValues={filter}
                  onSave={setDashboardFilter}
                  lessons={flatLessons}
                  courses={courses}
                  groups={groups}
                  disabled={!isFullAccess}
                />
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
      {isDemoExpired && <DemoAccessExpiredWidget/>}
    </>
  )
}
