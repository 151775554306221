import {isSafe} from "../../utils";
import {reverse} from "../../routing";
import {History} from "history";

export function keepNextUrl(history: History, url: string) {
  const params = new URLSearchParams(history.location.search);
  const next = params.get("_next");

  if (next) {
    url += (url.includes("?") ? "&" : "?") + "_next=" + encodeURIComponent(next);
  }

  return url;
}

export function redirectToNextUrl(history: History, next?: string | null) {
  const params = new URLSearchParams(history.location.search);
  next = next ?? params.get("_next");
  if (!next || !isSafe(next)) {
    next = reverse("home");
  }

  history.push(next);
}
