import React from "react";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import classes from "../account/AssignModal.module.css";
import {useTranslation} from "react-i18next";
import {CutWordsDictionary, CutWordsDictionaryInput} from "../../schema";
import Input from "../../ui/input";

type contentProps = React.ComponentProps<"div"> & {
  dict?: CutWordsDictionary
  onSave?: (data: CutWordsDictionaryInput) => void
  onDelete?: () => void
}

function CutWordsDictionaryModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.AddMembersModal.AddMembersModalTitle")}</span>
}

 function CutWordsDictionaryModalContent({dict, onSave, onDelete}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    name: string,
    cutWordPairs: string,
  }>({
    initialValues: {
      name: dict?.name ?? "",
      cutWordPairs: dict?.cutWordPairs ?? "",
    },

    preventDefault: true,

    onSubmit: (data) => {
      onSave && onSave(data);
    }
  });

  const submitDisabled = undefined

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.CutWordsDictionaryModal.nameHelpText")}</p>
        <Input
          className={classes.input}
          name="name"
          value={formState.values.name ?? ""}
          required
          maxLength={36}
          placeholder={t("components.CutWordsDictionaryModal.namePlaceholder")}
          onChange={formState.changeHandler}
        />

        <p className={classes.helpText}>{t("components.CutWordsDictionaryModal.phrasesHelpText")}</p>
        <Textarea
          className={classes.input}
          name="cutWordPairs"
          value={formState.values.cutWordPairs}
          rows={8}
          required
          placeholder={t("components.CutWordsDictionaryModal.phrasesPlaceholder")}
          onChange={formState.changeHandler}
        />
      </>

      <div className={classes.bottom}>
        <Button type="submit" color={submitDisabled ? "secondary" : "success"} disabled={submitDisabled}>
          {t("components.CutWordsDictionaryModal.submit")}
        </Button>
        {dict && (
          <Button type="button" color="danger" onClick={onDelete}>
            {t("components.CutWordsDictionaryModal.delete")}
          </Button>
        )}
      </div>
    </form>
  )
}

const CutWordsDictionaryModal = {
  Header: CutWordsDictionaryModalHeader,
  Content: CutWordsDictionaryModalContent,
}

export default CutWordsDictionaryModal;
