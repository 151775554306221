import {PlayableAction, PlayerReduxState} from "../types";
import {isAnyUserInputAction} from "../utils";
import getCurrentScenario from "./getCurrentScenario";

export default function getRepeatFromAction(state: PlayerReduxState, index?: number): PlayableAction | null {
  if (index === undefined) {
    index = state.index
  }

  for (let i = index - 1; i >= 0; i--) {
    const action = getCurrentScenario(state).actions[i];

    if (isAnyUserInputAction(action)) {
      return getCurrentScenario(state).actions[i + 1];
    }
  }

  return getCurrentScenario(state).actions?.[0] ?? null;
}
