import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Group} from "../../../schema";

import {QueryData as RenameGroupModalQueryData} from "./RenameGroupModal";
import {QueryData as EditGroupMembersModalQueryData}  from "./EditGroupMembersModal";
import {DeleteGroupModalQueryData} from "../../../scenes/account/groups";

import EditItemDropdown from "../../EditItemDropdown";

type PickedGroup = Pick<Group, "id">
  & RenameGroupModalQueryData["group"]
  & EditGroupMembersModalQueryData["group"]
  & DeleteGroupModalQueryData["group"]

export type Props =  {
  group: PickedGroup,
  onRenameGroup: (group: PickedGroup) => void,
  onEditGroupMembers: (group: PickedGroup) => void,
  onDeactivateMembers: (group: PickedGroup) => void,
  onDeleteGroup: (group: PickedGroup) => void,
}

export default function GroupEditDropdown(
  {group, onDeleteGroup, onRenameGroup, onDeactivateMembers, onEditGroupMembers}: Props
) {
  const {t} = useTranslation();

  const renameGroup = useCallback(() => {
    onRenameGroup(group)
  }, [onRenameGroup, group])

  const editGroupMembers = useCallback(() => {
    onEditGroupMembers(group);
  }, [onEditGroupMembers, group])

  const deactivateMembers = useCallback(() => {
    onDeactivateMembers(group)
  }, [onDeactivateMembers, group])

  const deleteGroup = useCallback(() => {
    onDeleteGroup(group)
  }, [onDeleteGroup, group])

  return (
    <EditItemDropdown>
      <EditItemDropdown.Action title={t("components.GroupEditDropdown.rename")} onClick={renameGroup}/>
      <EditItemDropdown.Action title={t("components.GroupEditDropdown.editMembers")} onClick={editGroupMembers}/>
      <EditItemDropdown.Action title={t("components.GroupEditDropdown.deactivateMembers")}
        onClick={deactivateMembers}
      />
      <EditItemDropdown.Action title={t("common.delete")} onClick={deleteGroup}/>
    </EditItemDropdown>
  )
}
