import React, {useCallback} from "react";

import cn from "classnames"
import classes from "./actionsheet.module.css";
import {ListAction} from ".";


type props = Omit<React.ComponentProps<"div">, "onClick"> & {
  onClose: NonNullable<React.ComponentProps<"div">["onClick"]>,
  animated?: boolean,
}

export default function ActionSheet({children, className, onClose, animated, ...props}: props) {

  return (
    <div onClick={onClose} className={cn(classes.root, {[classes.animated]: animated}, className)} {...props}>
      {children}
    </div>
  )
}

const Action: ListAction = ({
  className, as: Component, title, icon: Icon, notCloseOnClick, onClick: onClickAction, ...props
}) => {

  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (notCloseOnClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClickAction && onClickAction(e)
  }, [notCloseOnClick, onClickAction])

  return (
    <Component
      className={cn(classes.action, className)}
      onClick={onClick}
      {...props}
    >
      {Icon && <Icon className={classes.icon}/>}
      <span className={classes.text}>{title}</span>
    </Component>
  )
}

Action.defaultProps = {
  as: "button"
}

const List = ({className, scrollable, ...props}: React.ComponentProps<"div"> & {
  scrollable?: boolean,
}) => (
  <div
    className={cn(classes.list, {[classes.scrollable]: scrollable}, className)}
    {...props}
  />
)

type buttonProps = Omit<React.ComponentProps<"button">, "children"> & {
  title?: string,
}

const Button = ({className, title, ...props}: buttonProps) => (
  <button
    className={cn(classes.button, className)}
    {...props}
  >
    {title}
  </button>
)

type headerProps = Omit<React.ComponentProps<"div">, "children"> & {
  title?: string,
  description?: string,
}

const Header = ({className, title, description, ...props}: headerProps) => (
  <div
    className={cn(classes.header, className)}
    {...props}
  >
    {title && <b>{title}</b>}
    {description}
  </div>
)

ActionSheet.Action = Action;
ActionSheet.List = List;
ActionSheet.Button = Button;
ActionSheet.Header = Header;
