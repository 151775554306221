import React from "react";
import {useFormState} from "../../hooks/useFormState";
import {useTranslation} from "react-i18next";
import Button from "../../ui/button";
import {HelpCircleIcon} from "../../ui/icons";
import {BooleanForm} from "../../utils";
import Select from "../../ui/select";
import WithTooltip from "../../ui/tooltip";
import FileInput from "../editor/FileInput";

import classes from "./ImportMembersModal.module.css";

type contentProps = React.ComponentProps<"div"> & {
  onImportMembers?: (attachment: File, sendWelcomeEmails: boolean, overrideActive: boolean) => void
}

function ImportMembersModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.ImportMembersModal.title")}</span>
}

 function ImportMembersModalContent({onImportMembers}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    attachment: File | undefined
    sendWelcomeEmails: BooleanForm
    deactivateCurrent: BooleanForm
  }>({
    initialValues: {
      attachment: undefined,
      sendWelcomeEmails: BooleanForm.TRUE,
      deactivateCurrent: BooleanForm.FALSE,
    },

    preventDefault: true,
    onSubmit: (data) => {
      const sendEmails = data.sendWelcomeEmails === BooleanForm.TRUE ? true : false;
      const deactivateCurrent = data.deactivateCurrent === BooleanForm.TRUE ? true : false;
      if (data.attachment) {
        onImportMembers && onImportMembers(data.attachment, sendEmails, deactivateCurrent)
      }
    }
  });

  const submitDisabled = formState.values.attachment === undefined;

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
      <p className={classes.helpText}>
        {t("components.ImportMembersModal.attachment.helpText")}
        {" "}
        <WithTooltip
          className={classes.help}
          as='span'
          helpText={t("components.ImportMembersModal.attachment.tooltip")}
        >
          <HelpCircleIcon/>
        </WithTooltip>
      </p>

        <FileInput
          className={classes.helpText}
          variant="csv"
          name="attachment"
          onDelete={formState.changeHandler}
          setValues={formState.setValues}
        />
      </>

      <div className={classes.settings}>
        <>
          <p className={classes.helpText}>{t("components.ImportMembersModal.deactivateCurrent.helpText")}</p>
          <Select
            className={classes.input}
            name="deactivateCurrent"
            value={formState.values.deactivateCurrent}
            onChange={formState.changeHandler}
          >
            <option value={BooleanForm.FALSE}>
              {t("components.ImportMembersModal.no")}
            </option>
            <option value={BooleanForm.TRUE}>
                {t("components.ImportMembersModal.yes")}
            </option>
          </Select>
        </>
        <>
          <p className={classes.helpText}>{t("components.ImportMembersModal.sendWelcomeEmails.helpText")}</p>
          <Select
            className={classes.input}
            name="sendWelcomeEmails"
            value={formState.values.sendWelcomeEmails}
            onChange={formState.changeHandler}
          >
            <option value={BooleanForm.TRUE}>
              {t("components.ImportMembersModal.yes")}
            </option>
            <option value={BooleanForm.FALSE}>
                {t("components.ImportMembersModal.no")}
            </option>
          </Select>
        </>
      </div>

      <Button
        type="submit"
        className={classes.button}
        color="success"
        disabledColor="secondary"
        disabled={submitDisabled}
      >
        {t("components.MembersActivityModal.apply")}
      </Button>
    </form>
  )
}

const ImportMembersModal = {
  Header: ImportMembersModalHeader,
  Content: ImportMembersModalContent,
}

export default ImportMembersModal;
