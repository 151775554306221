import React, {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {Group, User} from "../../../schema";
import {gql} from "@apollo/client";
import {reverse} from "../../../routing";

import Table from "../../../ui/table";
import GroupEditDropdown, {Props as GroupEditDropdownProps} from "./GroupEditDropdown";
import ReactLink from "../../ReactLink";

import cn from "classnames"
import classes from "./GroupsTable.module.css";

export type QueryData = {
  groups: (Pick<Group, "id" | "name" | "code"> & {
    users: Pick<User, "id" | "fullName">[]
  })[]
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  groups: QueryData["groups"]
} & Pick<GroupEditDropdownProps, "onRenameGroup" | "onEditGroupMembers" | "onDeactivateMembers" | "onDeleteGroup">

export default function GroupsTable({
  groups, onRenameGroup, onEditGroupMembers, onDeactivateMembers, onDeleteGroup, className, ...props
}: Props) {
  const {t} = useTranslation();

  return (
    <Table className={cn(classes.root, className)} {...props}>
      <Table.Head>
        <Table.Row>
          <Table.Cell>{t("components.GroupsTable.name")}</Table.Cell>
          <Table.Cell>{t("components.GroupsTable.code")}</Table.Cell>
          <Table.Cell/>
          <Table.Cell>{t("components.GroupsTable.usersCount")}</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {groups.map((group) => (
          <Table.Row key={group.id}>
            <Table.Cell>
              <ReactLink
                className={classes.groupName}
                href={reverse("accountMembers") + "?groupsIds=" + encodeURIComponent(group.id)}
              >{group.name}</ReactLink>
            </Table.Cell>
            <Table.Cell>{group.code}</Table.Cell>
            <Table.Cell>
                <GroupEditDropdown
                  group={group}
                  onRenameGroup={onRenameGroup}
                  onEditGroupMembers={onEditGroupMembers}
                  onDeactivateMembers={onDeactivateMembers}
                  onDeleteGroup={onDeleteGroup}
                />
            </Table.Cell>
            <Table.Cell>{group.users?.length ?? 0}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

GroupsTable.fragments = {
  root: gql`
    fragment GroupsTable on Group {
      id
      name
      code
      users {
        id
        fullName
      }
    }
  `,
}
