import React from "react";
import WithTooltip from "../ui/tooltip";
import {ChatboxEllipsesOutlineIcon, StarIcon} from "../ui/icons";
import {useTranslation} from "react-i18next";
import classes from "./FeedbackWidget.module.css";


export type FeedbackWidgetProps = React.ComponentProps<"div"> & {
  score?: number | null,
  text?: string | null
}

export default function FeedbackWidget({score, text, ...props}: FeedbackWidgetProps) {
  const {t} = useTranslation();

  let jsx = ((score ?? 0) > 0)
    ? <>{score}/5 <StarIcon className={classes.star}/></>
    : t("components.FeedbackWidget.notRated");

  if (text) {
    jsx = (
      <WithTooltip
        as='span'
        className={classes.root}
        helpText={t("components.FeedbackWidget.text", {text})}
        {...props}
      >
        {jsx} <ChatboxEllipsesOutlineIcon/>
      </WithTooltip>
    )
  } else {
    jsx = (
      <span className={classes.root}>{jsx}</span>
    )
  }

  return jsx;
}
