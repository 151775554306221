import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getCurrentAction, getCurrentScenario} from "../selectors";
import {analyticsSendEvent} from "../../../libs/analytics";
import {MutationEducationSessionSelectRandomAvatarPhraseArgs, Scalars} from "../../../schema";
import {sessionSelectRandomAvatarPhraseMutation} from "../graphql";

export default function selectRandomAvatarBranch() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();
    const actionId = getCurrentAction(state)?.id;

    analyticsSendEvent("selectRandomAvatarBranch", {
      actionId: actionId,
      level: state.level
    });

    state.client.mutate<{branchId: Scalars["ID"] }, MutationEducationSessionSelectRandomAvatarPhraseArgs>({
    mutation: sessionSelectRandomAvatarPhraseMutation,
    variables: {
      actionId: actionId!,
      level: state.level,
      scenarioId: getCurrentScenario(state).id,
      sessionId: state.sessionId!,
    }
    }).then((result) => {

      if (result.data && result.data.branchId) {
        dispatch({
          type: "update",
          payload: {
            "branchId": result.data.branchId,
            "branchIndex": -1
          }
        });
        dispatch({type: "next"});
      } else {
        throw new Error("Cannot select random avatar phrase");
      }
    })
  }
}