import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {analyticsSendEvent} from "../../libs/analytics";
import {useModal} from "../ModalProvider";

import Widget from "../../ui/widget";
import Button from "../../ui/button";

import classes from "./DemoAccessExpiredWidget.module.css"
import ReactLink from "../ReactLink";
import PurchaseLicensesModal from "../home/PurchaseLicensesModal";
import {useCurrentUser} from "../../App.context";

type props = Omit<React.ComponentProps<typeof Widget>, "as" | "children">

export function DemoAccessExpiredWidget({...props}: props) {
  const {t} = useTranslation();
  const user = useCurrentUser();

  const {add: addModal} = useModal();
  const onBuyClick = useCallback(() => {
    analyticsSendEvent("openPurchaseModal", {
      from: "HomeScene"
    });

    addModal({
      header: <PurchaseLicensesModal.Header/>,
      content: (
        <PurchaseLicensesModal.Content
          isEmailActivated={user!.isEmailActivated!}
        />
      )
    })
  }, [user, addModal]);


  return (
    <Widget
      className={classes.root}
      {...props}
    >
      <Widget.Title>{t("components.system.DemoAccessExpiredWidget.title")}</Widget.Title>
      <Widget.Description>{t("components.system.DemoAccessExpiredWidget.description")}</Widget.Description>
      <Button as={ReactLink} className={classes.buyButton} onClick={onBuyClick} color="success">
        {t("components.system.DemoAccessExpiredWidget.buy")}
      </Button>
    </Widget>
  )
}

export default function WithDemoAccessExpiredWidget({children, showWidget, ...props}: props & {
  children: React.ReactNode,
  showWidget?: boolean
}) {
  return (
    <>
      {children}
      {showWidget && (
        <DemoAccessExpiredWidget {...props}/>
      )}
    </>
  )
}