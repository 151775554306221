import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {LessonEducationStatus} from "../../../schema";
import {CheckmarkCircleIcon, CrossCircleIcon, PlayCircleOutlineIcon} from "../../../ui/icons";
import cn from "classnames"
import classes from "./StatusDisplay.module.css"

type props = React.ComponentProps<"div"> & {
  status: LessonEducationStatus,
  passed?: boolean
}

export default function StatusDisplay({status, passed, className, ...props}: props) {
  const {t} = useTranslation();

  const statusDisplay = useMemo(() => {
    switch (status) {
      case LessonEducationStatus.NOT_STARTED:
        return (
          <>
            <PlayCircleOutlineIcon className={cn(classes.statusIcon, classes.disabled)}/>
            <span>{t("lmsPlay.status.notStarted")}</span>
          </>
        )
      case LessonEducationStatus.ACTIVE:
        return (
          <>
            <PlayCircleOutlineIcon className={cn(classes.statusIcon, classes.disabled)}/>
            <span>{t("lmsPlay.status.active")}</span>
          </>
        )
      case LessonEducationStatus.COMPLETE:
        return passed ? (
          <>
            <CheckmarkCircleIcon className={cn(classes.statusIcon, classes.positive)}/>
            <span>{t("lmsPlay.status.passed")}</span>
          </>
        ) : (
          <>
            <CrossCircleIcon className={cn(classes.statusIcon, classes.negative)}/>
            <span>{t("lmsPlay.status.failed")}</span>
          </>
        )
      default:
        throw new Error(`Not supported LessonEducationStatus: "${status}"`)
    }
  }, [status, passed, t])

  return (
    <div className={cn(classes.root, className)} {...props}>
      {statusDisplay}
    </div>
  )
}