/* eslint-disable max-lines */
// Hooks
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {
  query, queryData, queryVars,
  useAddCourseMutation, useAddLessonMutation,
  useCopyCourseMutation,
  useCopyLessonMutation, useDeleteCourseMutation,
  useDeleteLessonMutation, useMoveLessonMutation
} from "./index.graphql";
import {useHistory} from "react-router";
import useSearchParamsState from "../../hooks/useSearchParamsState";
import {useTour} from "../../providers/tour";

// Libs, types and consts
import {reverse} from "../../routing";
import {getLanguageCode} from "../../i18n";
import {Course, EditorAccessType, LanguageCode, Lesson, LessonFilterInput, LessonInput, LessonMode
} from "../../schema";
import {analyticsSendEvent} from "../../libs/analytics";
import {TABLE_ROWS_PER_PAGE} from "../../settings";
import {getDataWithMock, runTourOnFirstOpen} from "../../tours";

// Components
import {useModal} from "../../components/ModalProvider";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Button from "../../ui/button";
import Paginator from "../../ui/paginator";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Breadcrumbs from "../../ui/breadcrumbs";
import {AddIcon} from "../../ui/icons";
import LessonsTable from "../../components/editor/lessons-table/LessonsTable";
import LessonsFilterForm from "../../components/editor/lessons-table/LessonsFilterForm";
import DeleteModal from "../../components/editor/DeleteModal";
import {DemoAccessExpiredWidget} from "../../components/system/DemoAccessExpiredWidget";
import EditorIndexTour, {
  classes as tourClasses,
  useMockData,
  NAME as tourName,
  SUBNAMES as subToursNames
} from "./index.tour";
import {SUBNAMES as layoutTourSubnames} from "../../components/Layout.tour";

// Classes
import cn from "classnames";
import classes from "./index.module.css";

const SEARCH_PARAMS_CONFIG: {
  name: { type: "string" };
  lessonMode: { type: "string" };
  languageCode: { type: "string" };
} = {
  "name": {type: "string"},
  "lessonMode": {type: "string"},
  "languageCode": {type: "string"},
}

export default function EditorIndexScene() {
  const history = useHistory();

  const {t, i18n} = useTranslation();
  const [filter, setFilter] = useSearchParamsState(SEARCH_PARAMS_CONFIG);

  const {runningTourName, bindToPage: bindTourToPage, run: runTour} = useTour();
  const tourMode = !!runningTourName && [tourName, subToursNames.openLesson].includes(runningTourName);
  const mockData = useMockData();

  useEffect(() => {
    analyticsSendEvent("editorView");
  }, []);

  useEffect(() => bindTourToPage(tourName, subToursNames.openLesson), [bindTourToPage])

  useEffect(() => {
    runTourOnFirstOpen(tourName, runTour, [layoutTourSubnames.header])
  }, [runTour])

  const {data, loading: fetching} = useQuery<queryData, queryVars>(query, {
    variables: {
      filter: {
        ...filter,
        lessonMode: filter.lessonMode === "" ? null : filter.lessonMode as LessonMode,
        languageCode: filter.languageCode === "" ? null : filter.languageCode as LanguageCode,
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [addLesson] = useAddLessonMutation();
  const [addCourse] = useAddCourseMutation();
  const [copyLesson] = useCopyLessonMutation();
  const [deleteLesson] = useDeleteLessonMutation();
  const [copyCourse] = useCopyCourseMutation();
  const [deleteCourse] = useDeleteCourseMutation();
  const [moveLesson] = useMoveLessonMutation();

  const onAddLesson = useCallback(() => {
    addLesson({
      variables: {
        data: {
          name: t("editorIndex.newLessonName"),
          languageCode: getLanguageCode(i18n.language)
        }
      }
    }).then(({data}) => {
      const lesson = data?.lesson!;

      analyticsSendEvent("editorAddLesson", {
        lessonId: lesson.id
      });
      history.push(reverse("editorLessonEdit", {
        id: lesson.id
      }))
    })
  }, [history, addLesson, t, i18n.language]);

  const onAddCourse = useCallback(() => {
    addCourse({
      variables: {
        data: {
          name: t("editorIndex.newCourseName"),
          languageCode: getLanguageCode(i18n.language)
        }
      }
    }).then(({data}) => {
      const course = data?.course!;

      analyticsSendEvent("editorAddCourse", {
        courseId: course.id
      });
    })
  }, [addCourse, t, i18n.language]);

  const onLessonCopy = useCallback((lessonId: Lesson["id"]) => {
    copyLesson({
      variables: {id: lessonId}
    }).then(({data}) => {
      const lesson = data?.lesson!;

      analyticsSendEvent("editorCopyLesson", {
        lessonId: lesson.id
      });
    })
  }, [copyLesson]);

  const onCourseCopy = useCallback((courseId: Course["id"]) => {
    copyCourse({
      variables: {id: courseId}
    }).then(({data}) => {
      const course = data?.course!;

      analyticsSendEvent("editorCopyCourse", {
        courseId: course.id
      });
    })
  }, [copyCourse]);

  const {add: addModal} = useModal();
  const onLessonDelete = useCallback((lessonId: Lesson["id"]) => {
    const onDelete = () => {
      analyticsSendEvent("editorLessonDelete", {
        lessonId: lessonId,
      });
      deleteLesson({
        variables: {id: lessonId}
      });
      modal.remove();
    };

    const onCancel = () => modal.remove();

    const modal = addModal({
      id: `deleteLesson_${lessonId}`,
      header: t("editorIndex.deleteLesson"),
      content: t("editorIndex.deleteLessonConfirmation"),
      footer: <DeleteModal.Footer onConfirm={onDelete} onCancel={onCancel}/>
    });
  }, [t, deleteLesson, addModal]);

  const onCourseDelete = useCallback((courseId: Course["id"]) => {
    const onDelete = () => {
      analyticsSendEvent("editorCourseDelete", {
        courseId: courseId,
      });
      deleteCourse({
        variables: {id: courseId}
      });
      modal.remove()
    };

    const onCancel = () => modal.remove();

    const modal = addModal({
      id: `deleteCourse_${courseId}`,
      header: t("editorIndex.deleteCourse"),
      content: t("editorIndex.deleteCourseConfirmation"),
      footer: <DeleteModal.Footer onConfirm={onDelete} onCancel={onCancel}/>
    });
  }, [t, deleteCourse, addModal]);

  const onLessonMove = useCallback((lessonId: string, data: Pick<LessonInput, "courseId">) => {
    analyticsSendEvent("editorLessonUpdate", {lessonId});

    moveLesson({
      variables: {id: lessonId, data}
    })
  }, [moveLesson]);

  const {items, courses} = useMemo(() => (
    getDataWithMock(data, mockData, tourMode)
  ), [data, mockData, tourMode]);
  const loading = !tourMode && (!items && fetching);
  const isDemoExpired = data?.editorAccessType === EditorAccessType.EXPIRED_DEMO_ACCESS;

  const [currentPage, setCurrentPage] = useState(1);

  const itemsOnPage = Math.min(items?.length ?? 0, TABLE_ROWS_PER_PAGE);
  const totalItems = items?.length ?? 0;

  const totalPages = Math.ceil(totalItems / TABLE_ROWS_PER_PAGE);

  useEffect(() => {
    if ((currentPage > totalPages) && (totalPages !== 0)) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage])

  const currentPageItems = useMemo(() => (
    items?.slice((currentPage - 1) * TABLE_ROWS_PER_PAGE, currentPage * TABLE_ROWS_PER_PAGE)
  ), [items, currentPage])

  return (
    <>
      <ReactDocumentTitle title={t("editorIndex.title")}/>

      {tourMode && <EditorIndexTour key={runningTourName} loaded={!loading}/>}
      <Layout data-testid="editorIndexScene">
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <span>{t("breadcrumbs.editor")}</span>
            </Breadcrumbs>

            <div className={classes.header}>
              <h1 className={classes.h1}>{t("editorIndex.h1")}</h1>
              <div className={classes.topRightMenu}>
                {!loading && (
                  <>
                    <Button
                      className={cn(classes.addButton, tourClasses.addCourseButton)}
                      onClick={onAddCourse}
                      color="primary"
                      disabled={isDemoExpired}
                    >
                      <AddIcon/>{t("editorIndex.addCourse")}
                    </Button>
                    <Button
                      className={cn(classes.addButton, tourClasses.addLessonButton)}
                      onClick={onAddLesson}
                      color="primary"
                      disabled={isDemoExpired}
                    >
                      <AddIcon/>{t("editorIndex.addLesson")}
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className={classes.content}>
              <div className={classes.contentMain}>
                <div className={classes.tableRoot}>
                  {(!loading && currentPageItems && courses) ? (
                    <LessonsTable
                      className={tourClasses.lessonTable}
                      data={currentPageItems}
                      courses={courses}
                      manageDisabled={isDemoExpired}
                      onLessonCopy={onLessonCopy}
                      onLessonDelete={onLessonDelete}
                      onCourseCopy={onCourseCopy}
                      onCourseDelete={onCourseDelete}
                      onLessonMove={onLessonMove}
                    />
                  ) : (
                    <LessonsTable.Skeleton/>
                  )}
                </div>

                <div className={classes.paginatorRow}>
                  <div className={classes.paginatorHelpText}>
                    {t("common.paginatorShowingItems", {
                      items: itemsOnPage,
                      totalItems
                    })}
                  </div>

                  {(totalPages > 1) && (
                    <Paginator
                      className={classes.paginator}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  )}
                </div>
              </div>
              <div className={classes.contentAside}>
                <LessonsFilterForm
                  className={tourClasses.filterForm}
                  initialValues={filter as LessonFilterInput}
                  onSave={setFilter as (data: LessonFilterInput) => void}
                />
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
      {isDemoExpired && <DemoAccessExpiredWidget/>}
    </>
  )
}
