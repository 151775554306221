import {PlayableAction} from "./types";
import {
  ChoiceUserInputAction,
  ChoiceUserInputBranch,
  RandomAvatarActionBranch,
  Scalars,
  UserInputAction
} from "../../schema";
import {phraseToText} from "../../components/Phrase";
import {isChoiceUserInputAction, isUserInputAction} from "../../types";

export function isAnyUserInputAction(action: PlayableAction): action is (ChoiceUserInputAction | UserInputAction) {
  return isUserInputAction(action) || isChoiceUserInputAction(action);
}

export function isFreeSpeech(action: PlayableAction): boolean {
  switch (action.__typename) {
    case "UserInputAction":
      return action.freeSpeech ? action.freeSpeech : false;
    case "ChoiceUserInputAction":
      return false;
    default:
      return false;
  }
}

export function getTextFromAction(action: PlayableAction): string {
  let text: string;

  switch (action.__typename) {
    case "UserInputAction":
      text = action.hintText !== "" ? action.hintText : action.expectedText;
      break;
    case "AvatarAction":
    case "SystemAction":
      text = action.text;
      break;
    case "ChoiceUserInputAction":
      text = action.branches[0].userInput.expectedText;
      break;
    default:
      throw new Error(`getTextFromAction not implemented for type ${action.__typename}`);
  }

  return phraseToText(text);
}

export function getBranch(rootAction: PlayableAction, branchId: Scalars["ID"]):
  ChoiceUserInputBranch | RandomAvatarActionBranch | undefined {
  if (rootAction.__typename === "ChoiceUserInputAction") {
    return rootAction.branches.find(b => b.id === branchId);
  }

  if (rootAction.__typename === "RandomAvatarAction") {
    return rootAction.branches.find(b => b.id === branchId);
  }

  return undefined;
}