import React from "react";
import {gql} from "@apollo/client";

import {UserInputAction} from "../../../schema";
import Bubble from "../Bubble";
import cn from "classnames";
import classes from "./UserInputBubble.module.css";
import Phrase, {PhraseVariant} from "../../Phrase";
import {useTranslation} from "react-i18next";

type props = React.ComponentProps<typeof Bubble> & {
  action: UserInputAction,
  onPlayClick?: React.MouseEventHandler,
}

export default function UserInputBubble({action, className, onPlayClick, ...props}: props) {
  const {t} = useTranslation();

  return (
    <Bubble className={cn(classes.root, className)} {...props}>
      <Bubble.Content>
        {action.freeSpeech
        ? <i>{t("components.UserInputBubble.freeSpeech")}:</i>
        : action.hintText && (
          <>
            {action.hintText}
            <i>{t("components.UserInputBubble.expectedText")}:</i>
          </>
        )}
        <Phrase
          text={action.expectedText}
          variant={PhraseVariant.DEFAULT}
          placeholder={(
            <Bubble.Placeholder>{t("components.UserInputBubble.placeholder")}</Bubble.Placeholder>
          )}
        />
      </Bubble.Content>
    </Bubble>
  )
}


UserInputBubble.fragments = {
  root: gql`
    fragment UserInputBubble on UserInputAction {
      id
      hintText
      expectedText
      freeSpeech
      freeSpeechMinTime
      freeSpeechMaxTime
    }
  `,
}
