/* eslint-disable max-lines */
// Hooks
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useMediaRecorder} from "../../../providers/mediaRecorder";
import {useTranslation} from "react-i18next";

// Libs
import bindHotkey from "../../../libs/bindHotkey";
import {analyticsSendEvent} from "../../../libs/analytics";

// Types
import {Avatar, ErrorAudio, Lesson, LessonInputMode, LessonMode} from "../../../schema";
import {FSImageRef} from "../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../ui/fullscreen/video";

// Components
import Container from "../../../ui/adaptivecontainer";
import Layout, {Header, Main} from "../../Layout";
import AvatarPlayer, {AvatarPlayerRef} from "../AvatarPlayer";
import ControlsBar from "../ControlsBar";
import ContentDisplay from "./ContentDisplay";
import {Level as LessonLevel, Name as LessonName, Timer as LessonTimer} from "./Lesson";
import Button from "../../../ui/button";
import ReactLink from "../../ReactLink";
import Scroll from "../../../ui/scroll";
import {ProgressBar} from "@tremor/react";

// Classes
import cn from "classnames"
import classes from "./ModernPlayer.module.css";

// Redux
import {PlayerReduxState} from "../../../redux/player/types";
import {canRecord, getCurrentAction, getCurrentScenario} from "../../../redux/player/selectors";
import {changeRecognizingState} from "../../../redux/player/actions";
import {useDispatch, useSelector} from "react-redux";
import {floor, isEqual} from "lodash";


type props = {
  lesson: Lesson,
  avatar: Avatar,
  gestures: string[],
  initialMode: "2D" | "3D",
  headless?: boolean,
  backUrl?: string,
  allowFullscreen?: boolean,
  toggleFullscreen?: () => void,
  errorsAudio?: ErrorAudio[],
}

export default function ModernPlayer({
  allowFullscreen, toggleFullscreen, errorsAudio, lesson, initialMode, avatar, gestures, headless, backUrl
}: props) {
  const {t} = useTranslation();

  const [avatarLoaded, setAvatarLoaded] = useState<boolean>();
  const avatarPlayerRef = useRef<null | AvatarPlayerRef>()
  const onAvatarLoaded = useCallback(() => setAvatarLoaded(true), []);
  const onAvatarUnloaded = useCallback(() => setAvatarLoaded(false), []);

  const {
    index,
    maxIndex,
    isMainScenario,
    actionId,
    canRecord,
    recognitionEngine,
    recognitionPipeline,
    currentBackground
  } = useSelector(ModernPlayer.selector, isEqual);

  const fsRef = useRef<FSImageRef | FSVideoRef>(null)

  const openFullscreenMedia = useCallback(() => {
    analyticsSendEvent("playerOpenFullscreenMedia")

    setMediaIsViewed(true)
    fsRef.current?.enterFullscreen();
  }, [fsRef]);

  const [mediaIsViewed, setMediaIsViewed] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    setMediaIsViewed(false)
  }, [actionId])

  const {
    recorderReady,
    isRecording,
    record,
    stopRecord,
    mediaType
  } = useMediaRecorder();

  const complete = useCallback(() => {
    if (!isRecording) {
      return
    }
    analyticsSendEvent("playerStopRecord", {
      trigger: "hotkey"
    })
    const hasId = stopRecord();
    if (hasId) {
      dispatch(changeRecognizingState(true));
    }
  }, [isRecording, dispatch, stopRecord]);

  const startRecord = useCallback(() => {
    if (isRecording) {
      return
    }
    analyticsSendEvent("playerStartRecord", {
      trigger: "hotkey",
      mediaType
    })

    record(recognitionEngine!, recognitionPipeline);
  }, [isRecording, mediaType, recognitionEngine, recognitionPipeline, record])

  useEffect(() => {
    if (lesson.inputMode === LessonInputMode.VOICE && canRecord && recorderReady) {
      return bindHotkey(32, startRecord, complete)
    }

    return () => {}
  }, [canRecord, recorderReady, lesson, startRecord, complete]);

  const [isControlsBarUpButtonShow, setControlsBarUpButtonShow] = useState(false)
  const [isControlsBarUpper, setControlsBarUpper] = useState(false)

  useEffect(() => {
    if (lesson.id === "3076" || lesson.id === "3078" || lesson.id === "3079") {
      setControlsBarUpButtonShow(true)
    }
  }, [lesson])

  useEffect(() => {
    if (avatar.id === "none") {
      setAvatarLoaded(true);
    }
  }, [avatar.id])

  const upControlsBar = useCallback(() => {
    setControlsBarUpButtonShow(false);
    setControlsBarUpper(true)
  }, [])

  const controlsUpButton = useMemo(() => (
      <Button
        className={cn(classes.backBtn, classes.upControlsBarButton, classes.hideOnDesktop)}
        onClick={upControlsBar} color='danger'
      >
        {t("player.upControlsBar")}
      </Button>
  ), [t, upControlsBar])

  const extra = useMemo(() => (
    <Button as={ReactLink} className={cn(classes.backBtn, classes.hideOnMobile)} href={backUrl} color='danger'>
      {lesson.mode === LessonMode.QUIZ ? t("player.endLessonAlt") : t("player.endLesson")}
    </Button>
  ), [lesson.mode, backUrl, t])

  const [timeLimit, setTimeLimit] = useState<number | undefined>(
    lesson.timeLimit && lesson.timeLimit > 0 ? lesson.timeLimit*60 : undefined
  );

  const updateTimeLimit = useCallback(() => {
    if (timeLimit && timeLimit > 0) {
      setTimeLimit(timeLimit - 1)
    }
  }, [timeLimit, setTimeLimit])

  useEffect(() => {
    setTimeout(() => {
      updateTimeLimit()
    }, 1000)
  }, [updateTimeLimit])

  const lessonInfoClassName = currentBackground ? classes.infoAlt: undefined;

  return (
    <Layout
      className={cn(classes.layoutRoot, {
        [classes.layoutRootWithBgAlt]: currentBackground,
        [classes.layoutRootWithBg]: !!lesson?.background && !currentBackground
      })}
      style={
        currentBackground || lesson.background ? {
          backgroundImage: `url(${currentBackground ?? lesson.background})`
        } : undefined
      }
      player
    >
      <div className={classes.blurredBackgroundWrapper}>
        <img
          className={classes.blurredBackground}
          alt=''
          src={currentBackground ?? (lesson?.background ? lesson.background : undefined)}
        />
      </div>
      {!headless && (
        <Header
          className={classes.header}
          type={lesson?.background ? "shaded" : "transparent"}
          hideLinks
          adaptive
          extra={extra}
        />
      )}
      <Main className={classes.layout}>
        <Container className={classes.root}>
          {avatar.id !== "none" && (
            <div className={classes.playerContainer}>
              <div className={classes.player}>
                  <AvatarPlayer
                    className={classes.avatar}
                    ref={(x) => avatarPlayerRef.current = x}
                    initialMode={initialMode}
                    avatar={avatar}
                    gestures={gestures}
                    hideLoaderOnMobile
                    hideSwitchOnMobile
                    onLoaded={onAvatarLoaded}
                    onUnloaded={onAvatarUnloaded}
                  />
              </div>
            </div>
          )}
          <div className={classes.contentContainer}>
            <div className={classes.lessonContainer}>
              {isControlsBarUpButtonShow && controlsUpButton}
              <LessonName className={lessonInfoClassName} lessonName={lesson.name}/>
              <LessonLevel className={lessonInfoClassName}/>
              <LessonTimer className={lessonInfoClassName} timeLimit={timeLimit}/>
            </div>
            <Scroll className={
              avatar.id !== "none"
              ? classes.content
              : cn(classes.content, classes.noAvatar)
            }>
              <ContentDisplay
                lesson={lesson}
                avatar={avatar}
                avatarLoaded={avatarLoaded}
                openFullscreenMedia={openFullscreenMedia}
                ref={fsRef}
                errorsAudio={errorsAudio}
              />
            </Scroll>
            {!isMainScenario && (
              <span className="text-white mb-[4px] text-xs">{t("player.additionalScenario")}</span>
            )}
            <ProgressBar
              className="w-2/3" color="green" value={floor(index / (maxIndex !== 0 ? maxIndex : 1) * 100)}>
            </ProgressBar>
            <ControlsBar
              allowFullscreen={allowFullscreen}
              toggleFullscreen={toggleFullscreen}
              className={cn(classes.controlsBar, isControlsBarUpper ? classes.controlsBarUpper : undefined)}
              openFullscreenMedia={openFullscreenMedia}
              mediaIsViewed={mediaIsViewed}
              avatarLoaded={avatarLoaded}
              switchAvatarMode={avatarPlayerRef.current?.switchMode}
              avatarMode={avatarPlayerRef.current?.mode}
              backUrl={backUrl}
              recordingClassName={classes.recording}
            />
          </div>

        </Container>
      </Main>
    </Layout>
  )
}

ModernPlayer.selector = (state: PlayerReduxState) => ({
  index: state.index,
  maxIndex: (getCurrentScenario(state).actions).length,
  isMainScenario: getCurrentScenario(state).isMain,
  actionId: getCurrentAction(state)?.id,
  canRecord: canRecord(state),
  recognitionEngine: state.recognitionEngine,
  recognitionPipeline: state.recognitionPipeline,
  currentBackground: state.currentBackground,
});
