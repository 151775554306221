import React from "react";
import {useTranslation} from "react-i18next";
import Button from "../../ui/button";
import PopupPortal from "../../ui/popupportal";

import classes from "./LessonResumeWidget.module.css";
import Widget from "../../ui/widget";

type props = React.ComponentProps<"div"> & {
  onResume?: () => void
  onRestart?: () => void
}

const LessonResumeWidget = ({onResume, onRestart}: props) => {
  const {t} = useTranslation();

  return (
      <PopupPortal as="div" center>
        <Widget style={{maxWidth: "unset"}}>
          <Widget.Title>{t("components.LessonResumeModal.title")}</Widget.Title>
          <div className={classes.content}>
            <Button
              size='l'
              color="brand"
              className={classes.btn}
              onClick={onRestart}
            >
              {t("components.LessonResumeModal.restart")}
            </Button>
            <Button
              size='l'
              color="primary"
              className={classes.btn}
              onClick={onResume}
            >
              {t("components.LessonResumeModal.resume")}
            </Button>
          </div>
        </Widget>
      </PopupPortal>
  );
};

export default LessonResumeWidget;
