import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {MutationEducationSessionStartArgs} from "../../../schema";
import {sessionStartMutation} from "../graphql";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function start() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    state.client.mutate<any, MutationEducationSessionStartArgs>({
      mutation: sessionStartMutation,
      variables: {
        lessonId: state.lessonId,
        preview: state.isPreview,
        externalUserId: state.externalUserId,
        utm: state.utm,
      }
    }).then((result) => {
      if (!result || !result.data || !result.data.sessionStartResult) {
        throw new Error("Cannot start education session");
      }

      analyticsSendEvent("playerStart", {
        sessionId: result.data.sessionStartResult.sessionId,
        lessonId: state.lessonId,
        preview: state.isPreview,
        externalUserId: String(state.externalUserId),
        recognitionEngine: result.data.sessionStartResult.recognitionEngine ?? undefined
      });

      dispatch({
        type: "start",
        payload: {
          sessionId: result.data.sessionStartResult.sessionId,
          recognitionEngine: result.data.sessionStartResult.recognitionEngine ?? undefined
        }
      });
    }).catch(() => {
      dispatch({
        type: "update",
        payload: {
          sessionStartFailed: true,
        }
      });
    });
  }
}
