import React from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";

import {Course} from "../schema";
import ReactLink from "./ReactLink";
import {reverse} from "../routing";
import Skeleton from "../ui/skeleton";

import classes from "./CourseCard.module.css";
import {gql} from "@apollo/client";

type props = React.ComponentProps<typeof ReactLink> & {
  course: Pick<Course, "id" | "name" | "canPlay">
}

export default function CourseCard({className, course, ...props}: props) {
  const {t} = useTranslation();

  return (
    <div className={cn(classes.root, className)}>
      <ReactLink
        title={course.name}
        className={cn(classes.playArea, {[classes.disabled]: !course.canPlay})}
        href={course.canPlay ? reverse("playerCourse", {id: course.id}) : undefined}
        {...props}
      >
        <div className={classes.background}>
          <div className={cn(classes.card, classes.cardGradient)}>
          </div>
          <div className={classes.description}>
            <h1 className={classes.name} translate="no">{course.name ? course.name :
              <span className={classes.notSpecified}>{t("common.notSpecified")}</span>}</h1>
          </div>
        </div>
      </ReactLink>
    </div>
  );
}

CourseCard.Skeleton = React.memo(function ({className}: { className?: string }) {
  return (
    <div className={cn(classes.root, className)}>
      <Skeleton.Block height={120} className={classes.root}/>
    </div>
  )
});

CourseCard.fragments = {
  root: gql`
    fragment CourseCard on Course {
      id
      name
      canPlay
    }
  `
}
