import React, {useMemo} from "react";
import cn from "classnames";
import classes from "./input.module.css";
import {mapObject, stopEventHandlerPropagation} from "../utils";

type props = React.ComponentProps<"input"> & {
  displaySize?: "sm" | "md"
  stopPropagation?: boolean
}

const Input = React.forwardRef<HTMLInputElement, props>(({
  className: extraClassName, displaySize, stopPropagation, ...props
}, ref: any) => {
  const className = cn(classes.root, classes[displaySize ?? "md"], {}, extraClassName);

  const {onChange, onKeyUp, onKeyDown} = props;

  const stopPropagationProps = useMemo(() => {
    if (!stopPropagation) {
      return;
    }
    return mapObject({
      onChange,
      onKeyUp,
      onKeyDown
    }, stopEventHandlerPropagation)
  }, [stopPropagation, onChange, onKeyUp, onKeyDown])

  return (
    <input className={className} ref={ref} {...props} {...stopPropagationProps}/>
  )
});

export default Input;
