import React from "react";
import {AsAnyComponentWithDefault} from ".";

import Portal from "./portal";

import cn from "classnames"
import classes from "./popupportal.module.css";


type props =  {
  center?: boolean
}

type PopupPortalPropsType<T extends React.ElementType> = AsAnyComponentWithDefault<T, props, "div">

export default function PopupPortal<T extends React.ElementType>({
  as: Component, center, className, ...props
}: PopupPortalPropsType<T>) {

  return (
    <Portal>
      <Component className={cn(classes.root, {[classes.center]: center}, className)} {...props}/>
    </Portal>
  )
}

PopupPortal.defaultProps = {
  as: "div"
}
