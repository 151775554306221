/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import initI18n from "./i18n";
import "./base.css";

import App from "./App";
import {AMPLITUDE_API_KEY, BACKEND, DEV, SENTRY_DSN} from "./settings";
import {analyticsSetUp} from "./libs/analytics";
import ErrorBoundary from "./providers/errorBoundary";
import {ApolloClient} from "@apollo/client";
import {createHttpLink, createInMemoryCache} from "./apollo/client";
import {createBrowserHistory} from "history";
import {devBindToWindow, isIframe} from "./utils";
import AudioProvider from "./providers/audio";
import AudioPreloadProvider from "./providers/audioPreloader";

const query = new URLSearchParams(window.location.search);

if (query.has("_profile")) {
  const profile = query.get("_profile");

  if (profile && profile.includes("/") && profile.length > 40) {
    localStorage.setItem("profile", profile);
  }

  query.delete("_profile");
  window.location.search = query.toString();
}

analyticsSetUp(AMPLITUDE_API_KEY);

if (!DEV) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    tracesSampleRate: 1.0,
  });
}

initI18n();

const client = new ApolloClient({
  cache: createInMemoryCache(),
  link: createHttpLink(BACKEND)
});

devBindToWindow({client})

const history = createBrowserHistory();

const isExternal = query.get("external") === "1" || isIframe();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AudioPreloadProvider>
        <AudioProvider>
          <App
            client={client}
            history={history}
            persistStorage={localStorage}
            isExternal={isExternal}
          />
        </AudioProvider>
      </AudioPreloadProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
