import {getCurrentAction} from "../selectors";
import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {analyticsSendEvent} from "../../../libs/analytics";
import getCurrentActionAttempts from "../selectors/getCurrentActionAttempts";

export default function skipError() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    const action = getCurrentAction(state)!;

    const showHint = getCurrentActionAttempts(state) === 2;

    analyticsSendEvent("playerSkipError", {
      sessionId: state.sessionId!,
      actionId: action.id,
      showHint
    });

    if (showHint && !state.isAutoUseHintOffered) {
      dispatch({
        type: "hint",
        payload: {
          actionId: action.id,
          isAutoUseHintOffered: true
        }
      });
    }

    dispatch({
      type: "update",
      payload: {
        lastUserInputResult: null,
      }
    })
  }
}