import React, {useCallback, useState} from "react";

import Textarea from "../../ui/textarea";

import classes from "./LimitedTextarea.module.css";


type props = Omit<React.ComponentProps<typeof Textarea>, "maxLength" | "value" | "onInput"> & {
  maxLength: number,
  value?: string,
} & ({
  filters: string[],
  limitTextOnChange?: undefined,
} | {
  filters?: undefined,
  limitTextOnChange?: (value: string, maxLength: number) => {
    value: string
    length: number,
  }
})

export default function LimitedTextarea({
  className, maxLength, filters, limitTextOnChange, value: rawValue, onChange, ...props
}: props) {
  const [length, setLength] = useState<number>(
    (() => {
      if (rawValue) {
        if (limitTextOnChange) {
          return limitTextOnChange(rawValue, maxLength).length
        }
        if (filters) {
          return limitTextWithFilters(rawValue, maxLength, filters).length
        }
      }
      return 0;
    })()
  );

  const onTextChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((e) => {
    e.persist();

    const currentValue = e.currentTarget.value;

    const {length, value: newValue} = (() => {
      if (filters) {
        return limitTextWithFilters(currentValue, maxLength, filters)
      }
      if (limitTextOnChange) {
        return limitTextOnChange(currentValue, maxLength)
      }
      return {value: currentValue, length: currentValue?.length ?? 0}
    })();
    setLength(length);

    e.currentTarget.value = newValue ?? "";

    onChange && onChange(e);
  }, [filters, maxLength, onChange, limitTextOnChange])

  return (
    <>
      <Textarea
        value={rawValue}
        maxLength={(!filters && !limitTextOnChange) ? maxLength : undefined}
        onChange={onTextChange}
        {...props}
      />
      <div className={classes.rhelper}>
        <span className={length === maxLength ? classes["enough"] : undefined}>
          {length}
        </span>/{maxLength}
      </div>
    </>
  )
}

function limitTextWithFilters(value: string, maxLength: number, filters: string[]) {
  const regexp = new RegExp(`(.*?)(?:${
    filters.map(item => `(${item})`).join("|")
  }|\n|$)`, "mg")
  let m;
  let length = 0
  let text = ""

  while (((m = regexp.exec(value ?? "")) !== null) && (length < maxLength)) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regexp.lastIndex) {
      regexp.lastIndex++;
    }
    if (length + m[1].length > maxLength) {
      text += m[0].slice(0, maxLength - length)
      length = maxLength
    } else {
      length += m[1].length
      text += m[0]
    }
  }

  return {length, value: text};
}
