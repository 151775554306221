import React, {forwardRef, useCallback, useImperativeHandle, useState} from "react";

type props = React.ComponentProps<"div"> & {
  adaptive?: boolean
  preview?: React.ComponentProps<"div">["children"]
}

export type AbstractFullscreenRef = {
  exitFullscreen: () => void
  enterFullscreen: () => void
}

const AbstractFullscreen = forwardRef<AbstractFullscreenRef, props>(({
  preview, children, ...props
}, ref) => {
  const [isFullscreen, setFullscreen] = useState(false);

  const exitFullscreen = useCallback(() => {
    setFullscreen(false)
  }, []);

  const enterFullscreen = useCallback(() => {
    setFullscreen(true)
  }, []);

  useImperativeHandle(ref, () => ({
    exitFullscreen,
    enterFullscreen
  }), [exitFullscreen, enterFullscreen]);

  return (
    <div
      onClick={enterFullscreen}
      {...props}
    >
      {preview}
      {isFullscreen && children}
    </div>
  );
})

export default AbstractFullscreen
