import React, {useEffect} from "react";
import Layout, {Header, Main} from "../components/Layout";
import ReactDocumentTitle from "../components/ReactDocumentTitle";
import {useTranslation} from "react-i18next";
import Container from "../ui/container";
import {useQuery} from "@apollo/client";
import {query} from "./home.graphql";
import HomeLessonCard from "../components/HomeLessonCard";
import PlaythroughsWidget from "../components/home/PlaythroughsWidget";
import ReactLink from "../components/ReactLink";
import {isUserEditor, isUserStudent} from "../types";

import classes from "./home.module.css";
import {useHistory} from "react-router";
import {reverse} from "../routing";
import {FlaskIcon} from "../ui/icons";
import {isBrowserSupported} from "../browser";
import BrowserNotSupportedScene from "./system/browser-not-supported";
import {EditorAccessType} from "../schema";
import {DemoAccessExpiredWidget} from "../components/system/DemoAccessExpiredWidget";

export default function HomeScene() {
  const {t} = useTranslation();
  const history = useHistory();

  const browserSupported = React.useMemo(() => isBrowserSupported(), []);

  const {data} = useQuery(query, {fetchPolicy: "cache-first"});

  const loaded = !!data;
  const account = data?.account;
  const user = data?.user;
  const contracts = data?.contracts;
  const accessType = data?.editorAccessType;
  const isDemoExpired = accessType === EditorAccessType.EXPIRED_DEMO_ACCESS;
  const demoTimeRemaining = user?.demoDaysRemaining ?? 0;
  const isEmailActivated = user?.isEmailActivated;

  const loggedIn = account && user;

  useEffect(() => {
    if (loaded && !loggedIn) {
      history.push(reverse("login"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, loaded, loggedIn]);


  useEffect(() => {
    if (loggedIn && isUserStudent(user)) {
      history.push(reverse("player"));
    }
  }, [history, user, loggedIn]);

  if (!browserSupported) {
    return (<BrowserNotSupportedScene/>)
  }

  if (!loaded || !loggedIn) {
    return null;
  }

  return (
    <>
      <ReactDocumentTitle title={t("home.title")}/>

      <Layout data-testid="homeScene" className={classes.layout}>
        <Header type='transparent'/>

        <Main>
          <Container className={classes.root}>
            <div className={classes.hello}>
              <div className={classes.helloTop}>
                <h1 className={classes.h1}>
                  {user?.name ? t("home.helloUser", {name: user?.name}) : t("home.hello")}
                  {" "}{user.isDeveloper && <FlaskIcon/>}
                </h1>
                <p className={classes.quote}>{t("home.quote")}</p>
              </div>

              {isUserEditor(user) && (
                <PlaythroughsWidget
                  account={account}
                  contracts={contracts}
                  accessType={accessType}
                  demoTimeRemaining={demoTimeRemaining}
                  isEmailActivated={isEmailActivated || accessType === EditorAccessType.FULL_ACCESS}
                />
              )}

              {!!user && !!user.email && (
                <div className={classes.helloBottom}>
                  <div className={classes.userEmailLabel}>{t("home.userEmail")}</div>
                  <div className={classes.userEmailValue}>{user.email}</div>
                </div>
              )}
            </div>

            <div className={classes.cards}>
              <HomeLessonCard
                as={ReactLink}
                href={reverse("editor")}
                className={classes.card}
                headline={t("home.editorCard.headline")}
                image={require("../assets/home-lesson/card-editor.svg").default}
                title={t("home.editorCard.title")}
                description={t("home.editorCard.description")}
              />

              <HomeLessonCard
                className={classes.card}
                as={ReactLink}
                href={reverse("player")}
                headline={t("home.playerCard.headline")}
                image={require("../assets/home-lesson/card-player.svg").default}
                title={t("home.playerCard.title")}
                description={t("home.playerCard.description")}
              />
            </div>
          </Container>
        </Main>
      </Layout>
      {isDemoExpired && <DemoAccessExpiredWidget/>}
    </>
  )
}
