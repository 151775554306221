import {PlayableAction, PlayerReduxState} from "../types";
import isResultStage from "./isResultStage";
import isIntroStage from "./isIntroStage";
import getCurrentScenario from "./getCurrentScenario";

export default function getCurrentAction(state: PlayerReduxState): PlayableAction | null {
  if (isResultStage(state) || isIntroStage(state)) {
    return null;
  }

  const action = getCurrentScenario(state).actions[state.index];

  if (!action) {
    console.warn("getCurrentAction: wrong state (1)");
    return null;
  }

  if (state.branchId) {
    if (action.__typename !== "ChoiceUserInputAction" && action.__typename !== "RandomAvatarAction") {
      console.error("getCurrentAction: wrong state (2)")
      return action;
    }

    if (state.branchIndex === undefined) {
      console.error("getCurrentAction: wrong state (3)");
      return null;
    }

    if (state.branchIndex === -1) {
      return action;
    }

    let branch = undefined;
    let branchActions: PlayableAction[]  = [];

    if (action.__typename === "ChoiceUserInputAction") {
      branch = action.branches.find(b => b.id === state.branchId);
      if (branch) {
        branchActions = [branch.avatarReaction!, branch.systemReaction!].filter(a => !!a)
      } else {
        console.error("getCurrentAction: wrong state (branch not found)")
      }
    }

    if (action.__typename === "RandomAvatarAction") {
      branch = action.branches.find(b => b.id === state.branchId);
      if (branch) {
        branchActions = [branch.avatarAction!].filter(a => !!a)
      } else {
        console.error("getCurrentAction: wrong state (branch not found)")
      }
    }

    if (!branch) {
      console.error("getCurrentAction: wrong state (4)")
      return null;
    }

    return branchActions[state.branchIndex] ?? null;
  }

  return action;
}
