import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useModal} from "../ModalProvider";
import {useSendBugReport} from "../../hooks/telemetry";
import {useErrorContext} from "../../providers/errorBoundary";

import Button from "../../ui/button";
import BugReportModal from "./BugReportModal";
import ScreenErrorBoundary from "./ScreenErrorBoundary";

type props = React.PropsWithChildren<{}>

export function ErrorBoundaryWithBugReport({children}: props) {
  const {t} = useTranslation();

  const {bindErrorListener} = useErrorContext();

  const {add: addModal} = useModal();

  const sendBugReport = useSendBugReport();

  const [errorCode, setErrorCode] = useState<string>();

  useEffect(() => bindErrorListener((e) => setErrorCode(e.error.message), true), [bindErrorListener])

  const onSendButtonClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    const onBugReportSend = (message: string, attachment?: File, contact?: string) => {
      sendBugReport({
        message,
        attachment,
        contact,
        location: "errorBoundary",
        errorReason: errorCode
      })
      modal.remove();
    }

    const modal = addModal({
      header: <BugReportModal.Header/>,
      content: (
        <BugReportModal.Content
          onBugReportSend={onBugReportSend}
        />
      ),
      stopCloseOnOuterClick: true,
    })
  }, [addModal, sendBugReport, errorCode]);

  const sendButton = useMemo(() => (
    <Button onClick={onSendButtonClick} color="brand">
      {t("components.system.ErrorBoundaryWithBugReport.sendReport")}
    </Button>
  ), [onSendButtonClick, t])

  return (
    <ScreenErrorBoundary extra={sendButton} stopFallthrough>
      {children}
    </ScreenErrorBoundary>
  )
}