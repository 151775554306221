import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import classes from "./browser-not-supported.module.css";
import {isMobile} from "../../browser";
import ErrorScreen from "./generic-error-screen";
import {analyticsSendEvent} from "../../libs/analytics";

export default function BrowserNotSupportedScene() {
  const {t} = useTranslation();

  const mobile = React.useMemo(() => isMobile(), []);

  const desc = t("browserNotSupported.desc");
  const mobileDesc = t("browserNotSupported.mobileDesc");

  useEffect(() => {
    analyticsSendEvent("browserNotSupported", {
      reason: "browserNotSupported"
    });
  }, []);

  return (
    <ErrorScreen
      testId="browserNotSupportedScene"
      documentTitle={t("browserNotSupported.title")}
      title={t("browserNotSupported.h1")}
      description={mobile ? mobileDesc : desc}
    >
      {!mobile && (
        <div className={classes.links}>
          <a target="_blank" rel="noreferrer noopener" className={classes.link}
             href="https://google.com/chrome/">
            <img src={require("../../assets/browsers/chrome.svg").default} alt="Google Chrome"/>
          </a>

          <a target="_blank" rel="noreferrer noopener" className={classes.link}
             href="https://www.mozilla.org/firefox/new/">
            <img src={require("../../assets/browsers/firefox.svg").default} alt="Firefox"/>
          </a>

          <a target="_blank" rel="noreferrer noopener" className={classes.link} href="https://www.opera.com/">
            <img src={require("../../assets/browsers/opera.svg").default} alt="Opera"/>
          </a>
        </div>
      )}
    </ErrorScreen>
  )
}
