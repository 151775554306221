import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import Textarea from "../../ui/textarea";
import Button from "../../ui/button";
import {AddIcon, CheckmarkIcon, CrossIcon, DeleteIcon} from "../../ui/icons";
import ClickableDiv from "../../ui/clickableDiv";

import cn from "classnames";
import classes from "./PhrasesEdit.module.css";
import IconButton from "../../ui/iconbutton";

type props = React.ComponentProps<"div"> & {
  textareaProps?: Partial<Omit<React.ComponentProps<typeof Textarea>, "onChange" | "value">>,
  value: string[],
  onValueChange?: (value: string[]) => void,
}

export default function PhrasesEdit({value, onValueChange, className, textareaProps, ...props}: props) {
  const {t} = useTranslation();

  const [phrases, setPhrases] = useState([...value]);
  const updateValue = useCallback((newValue: string[]) => {
    setPhrases(newValue);
    onValueChange && onValueChange(newValue);
  }, [onValueChange]);

  useEffect(() => {
    setPhrases([...value])
  }, [value]);

  const [phrase, setPhrase] = useState<string>("");
  const [currentPhraseIndex, setCurrenPhraseIndex] = useState<number>(); // -1 for new phrase
  const onPhraseChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((e) => {
    setPhrase(e.target.value)
  }, []);

  const savePhrase = useCallback(() => {
    if ((phrase === "") || (currentPhraseIndex === undefined)) {
      return;
    }
    if (currentPhraseIndex === -1) {
      phrases.push(phrase);
    } else {
      phrases[currentPhraseIndex] = phrase;
    }
    updateValue(phrases);
    setCurrenPhraseIndex(undefined);
    setPhrase("");
  }, [currentPhraseIndex, phrase, phrases, updateValue]);

  const removePhrase = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const index = parseInt(e.currentTarget.getAttribute("data-id")!);
    phrases.splice(index, 1);
    updateValue(phrases);
  }, [phrases, updateValue]);

  const editPhrase = useCallback((e: React.MouseEvent) => {
    const index =  parseInt(e.currentTarget.getAttribute("data-id")!);
    setCurrenPhraseIndex(index);
    setPhrase(phrases[index]);
  }, [phrases]);

  const addPhrase = useCallback(() => {
    setCurrenPhraseIndex(-1);
  }, []);

  const cancelEditPhrase = useCallback(() => {
    setCurrenPhraseIndex(undefined);
    setPhrase("")
  }, []);

  const isEditingPhrase = currentPhraseIndex !== undefined;
  const isNewPhrase = currentPhraseIndex === -1;

  const phraseInput = useMemo(() => {
    if (currentPhraseIndex === undefined) {
      return;
    }

    const isPhraseChanged = isNewPhrase
      ? phrase !== ""
      : phrase !== phrases[currentPhraseIndex];

    const {className, ...pickedTextareaProps} = textareaProps ?? {};

    return (
      <React.Fragment key={`edit-${currentPhraseIndex}`}>
        <div className={classes.inputWrapper}>
          <Textarea
            className={cn(classes.input, textareaProps?.className)}
            id="phrase"
            value={phrase}
            autoFocus
            onChange={onPhraseChange}
            {...pickedTextareaProps}
          />
          <div className={classes.controls}>
            <Button type="button" color="success" title={t("common.save")}
              className={classes.saveButton} onClick={savePhrase}
            ><CheckmarkIcon/></Button>
            <Button type="button" color="danger" title={t("common.cancel")}
              className={classes.saveButton} onClick={cancelEditPhrase}
            ><CrossIcon/></Button>
          </div>
        </div>
        {isPhraseChanged && (
          <span className={classes.notSavedMessage}>
            {t("components.PhrasesEdit.notSavedMessage")}
          </span>
        )}
      </React.Fragment>
    )
  }, [
    isNewPhrase, currentPhraseIndex, phrases, phrase, onPhraseChange, savePhrase, t,
    textareaProps, cancelEditPhrase
  ]);

  return (
    <>
      <div className={cn(classes.phrasesWrapper, className)} {...props}>
        {!isEditingPhrase && (
          <Button type="button" className={classes.addButton} onClick={addPhrase}>
            <AddIcon/>
            {t("common.add")}
          </Button>
        )}
        {isNewPhrase && phraseInput}
        {phrases.map((phrase, index) => index === currentPhraseIndex ? phraseInput : (
          <ClickableDiv className={classes.phrase} key={index} data-id={index} onClick={editPhrase}>
            {phrase}
            <IconButton
              size="s" icon={DeleteIcon}
              title={t("common.delete")}
              className={classes.deleteButton}
              data-id={index}
              type="button"
              onClick={removePhrase}
            />
          </ClickableDiv>
        )).reverse()}
      </div>
    </>
  )
}
