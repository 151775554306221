import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import ErrorScreen from "./generic-error-screen";
import {analyticsSendEvent} from "../../libs/analytics";

export default function PageNotFoundScene() {
  const {t} = useTranslation();

  useEffect(() => {
    analyticsSendEvent("pageNotFound");
  }, []);

  return (
    <ErrorScreen
      testId='notFoundScene'
      documentTitle={t("system.pageNotFound.title")}
      title={t("system.pageNotFound.h1")}
      description={t("system.pageNotFound.desc")}
    >
      <ErrorScreen.HomepageLink/>
    </ErrorScreen>
  )
}
