import React from "react";
import Container from "../../ui/container";
import Logo from "../../ui/logo";
import {CONTACT_EMAIL, COPYRIGHT} from "../../settings";
import LangSelector from "../LangSelector";

import classes from "./LoginLayout.module.css";
import cn from "classnames";

export default function LoginLayout({className, ...props}: React.ComponentProps<"div">) {

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.left}>
          <div className={classes.lheader}>
            <Logo className={classes.logo} variant='white'/>

            <div className={classes.lheaderMenu}>
              <a className={classes.lheaderMenuItem} href={"mailto:" + CONTACT_EMAIL}>
                {CONTACT_EMAIL}
              </a>

              <LangSelector className={classes.lheaderMenuItem}/>
            </div>
          </div>

          <div className={classes.lfooter}>
            <div className={classes.lfooterCopyright}>
              {COPYRIGHT}
            </div>
          </div>
        </div>

        <div
          className={cn(classes.right, className)}
          {...props}
        />
      </Container>
    </div>
  )
}
