import React, {forwardRef, ReactEventHandler, useCallback, useImperativeHandle, useRef} from "react";
import {useCurrentUser} from "../../App.context";
import {isIPhone} from "../../browser";

import AbstractFullscreen, {AbstractFullscreenRef} from ".";
import {PlayIcon} from "../icons";
import FullscreenVideo from "../fullscreenvideo";

import classes from "./video.module.css";
import cn from "classnames";

type commonVideoProps = Pick<React.ComponentProps<"video">, "src" | "children">

type props = Omit<React.ComponentProps<"div">, "children" | "ref"> & commonVideoProps & {
  videoProps?: Omit<React.ComponentProps<"video">, "onClick" | "src" | "children">
  exitOnEnded?: boolean
  adaptive?: boolean
}

export type FSVideoRef = AbstractFullscreenRef

const FSVideo = forwardRef<FSVideoRef, props>(({
  src, className, children, exitOnEnded, adaptive, videoProps, ...props
}, ref) => {
  const fsRef = useRef<AbstractFullscreenRef>(null);

  useImperativeHandle(ref, () => fsRef.current ?? {
    exitFullscreen: () => {},
    enterFullscreen: () => {}
  }, [])

  const onClose = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fsRef.current?.exitFullscreen();
  }, []);

  const commonProps: commonVideoProps = {src, children};
  const {className: videoClassName, onEnded: videoOnEnded, ...pickedVideoProps} = videoProps ?? {};

  const onEnded = useCallback<ReactEventHandler<HTMLVideoElement>>((e) => {
    if (exitOnEnded) {
      fsRef.current?.exitFullscreen();
    }
    videoOnEnded && videoOnEnded(e);
  }, [exitOnEnded, videoOnEnded])

  const preview = (
    <>
      <video
        className={cn(classes.video, videoClassName)}
        {...commonProps}
        {...pickedVideoProps}
      />
      <PlayIcon className={cn(classes.playIcon, {[classes.adaptive]: adaptive})}/>
    </>
  )

  const isDeveloper = !!useCurrentUser()?.isDeveloper;
  if (isDeveloper && isIPhone()) {
    return (
      <video
        className={cn(classes.video, videoClassName, className)}
        controls
        {...commonProps}
        {...pickedVideoProps}
      />
    )
  }

  return (
    <AbstractFullscreen
      preview={preview}
      className={cn(classes.root, className)}
      ref={fsRef}
      {...props}
    >
      <FullscreenVideo {...commonProps} onClose={onClose} onEnded={onEnded}/>
    </AbstractFullscreen>
  );
})

export default FSVideo
