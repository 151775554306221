import React from "react";
import {useTranslation} from "react-i18next";
import ErrorWidget from "../../system/GenericErrorWidget";

type props = Omit<React.ComponentProps<typeof ErrorWidget>, "title" | "description">

export default function FileIsBrokenWidget(props: props) {
  const {t} = useTranslation();

  return (
    <ErrorWidget
      variant="error"
      title={t("system.fileIsBroken.title")}
      description={t("system.fileIsBroken.description")}
      {...props}
    />
  )
}
