import {PlayerReduxState} from "../types";
import {isAnyUserInputAction} from "../utils";
import getCurrentAction from "./getCurrentAction";
import getLevel from "./getLevel";
import isExamStage from "./isExamStage";

export default function canNavigate(state: PlayerReduxState): boolean {
  const action = getCurrentAction(state);

  if (!action || !isAnyUserInputAction(action)) {
    return false;
  }

  return ((getLevel(state) === 1) && !isExamStage(state));
}
