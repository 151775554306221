/* eslint-disable max-lines */
// Hooks, libs
import React, {ComponentProps, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {isEqual} from "lodash";
import {useTranslation} from "react-i18next";
import {useMediaRecorder} from "../../providers/mediaRecorder";
import {analyticsSendEvent} from "../../libs/analytics";
import {isUserInputAction} from "../../types";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {passUserInput, changeRecognizingState} from "../../redux/player/actions";
import {canRecord, getCurrentAction, isRecognizing} from "../../redux/player/selectors";
import {PlayerReduxState} from "../../redux/player/types";
import {AsSpecificComponent} from "../../ui";

// Components
import Button from "../../ui/button";
import {MicIcon, RefreshIcon} from "../../ui/icons";
import Timer from "./Timer";
import tippy, {Content, Instance as TippyJS} from "tippy.js";
import {bindTimeout} from "../../utils";

import classes from "./MicButton.module.css";

import cn from "classnames"

enum MicProblems {
  SpeakingToTurnedOffMic = "speakingToTurnedOffMic",
  SilentToTurnedOnMic = "silentToTurnedOnMic"
}

type props = Omit<AsSpecificComponent<typeof Button, "button">,
  "onRecordStart" | "onRecordStop" | "onClick" | "onLongPress" | "onLongRelease" | "color" | "as"
> & {
  onRecordStart?: () => void,
  onRecordStop?: () => void,
  recordingClassName?: ComponentProps<typeof Button>["className"],
  isMicAlertMuted?: boolean,
  applyTimeout?: boolean,
  muteMicAlert: () => void,
}

export default function MicButton({
  onRecordStart, onRecordStop, className, recordingClassName, isMicAlertMuted, applyTimeout,
  muteMicAlert, children, disabled: forceDisabled, ...props
}: props) {
  const {t} = useTranslation();

  const ref = useRef<HTMLButtonElement>(null);
  const tippyRef = useRef<TippyJS>();
  const recordTimeout = 40000;

  const {
    isFreeSpeech,
    canRecord,
    isRecognizing,
    recognitionEngine,
    recognitionPipeline,
  } = useSelector(MicButton.selector, isEqual);

  const {
    recorderReady,
    isRecording,
    resultState,
    getResult,
    record,
    stopRecord,
    mediaType,

    isUserSpeaking,
  } = useMediaRecorder();

  const dispatch = useDispatch();

  const [allowRecordTimeout, setAllowRecordTimeout] = useState<boolean>(false)
  const [isRecordStarted, setRecordStarted] = useState<boolean>(false)

  const stop = useCallback(() => {
    setAllowRecordTimeout(false)
    if (!isRecording) {
      return
    }

    analyticsSendEvent("playerStopRecord", {
      trigger: "micButton"
    })

    setRecordStarted(false)
    const hasId = stopRecord();
    if (hasId) {
      dispatch(changeRecognizingState(true));
    }
    onRecordStop && onRecordStop()
  }, [isRecording, dispatch, stopRecord, onRecordStop]);

  useEffect(() => {
    if (resultState === "ready") {
      const data = getResult();
      if (data) {
        if (data.isError) {
          stop();
        }
        dispatch(passUserInput({
          audioFileId: mediaType === "audio" ? data?.id : null,
          videoFileId: mediaType === "video" || recognitionPipeline === "old" ? data?.id : null,
          fileUploadData: data.isError ? {
            id: "",
            recognizedText: "",
            uuid: "",
            duration: 0,
            isError: true,
          } : data
        }))

      }
    }
  }, [resultState, recognitionPipeline, dispatch, getResult, stop, mediaType])

  const onRecordTimeout = useCallback(() => {
    if (applyTimeout && allowRecordTimeout) {
      stop();
    }
  }, [applyTimeout, allowRecordTimeout, stop])

  const start = useCallback(() => {
    if (isRecording) {
      return
    }

    analyticsSendEvent("playerStartRecord", {
      trigger: "micButton",
      mediaType
    })

    setRecordStarted(true)
    record(recognitionEngine!, recognitionPipeline);
    onRecordStart && onRecordStart()
    applyTimeout && setAllowRecordTimeout(true);
  }, [applyTimeout, recognitionEngine, recognitionPipeline, isRecording, mediaType, record, onRecordStart])

  const disabled: boolean = forceDisabled ?? (!canRecord || !recorderReady)

  const styleProps = useMemo<{
    className: string
    color?: ComponentProps<typeof Button>["color"]
    disabledColor?: ComponentProps<typeof Button>["disabledColor"]
  }>(() => {
    if (recordingClassName) {
      return {
        className: cn(
          className,
          {[recordingClassName]: isRecording,
          [classes.speakAnimation] : isRecording && isUserSpeaking}
        )
      }
    }
    return {
      className: className,
      color: isRecording ? "success" : "primary",
      disabledColor: "secondary"
    }
  }, [recordingClassName, className, isRecording, isUserSpeaking])

  const problem = useMemo(() => {
    if (!isRecording && isUserSpeaking && !disabled) {
      return MicProblems.SpeakingToTurnedOffMic;
    }
    if (isRecording && !isUserSpeaking) {
      return MicProblems.SilentToTurnedOnMic;
    }
  }, [isRecording, isUserSpeaking, disabled])

  useEffect(() => {
    if (applyTimeout && allowRecordTimeout) {
      return bindTimeout(() => {
        onRecordTimeout();
    }, recordTimeout)
  }
  }, [applyTimeout, allowRecordTimeout, onRecordTimeout])

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (problem === undefined) {
      return;
    }

    tippyRef.current?.destroy();
    tippyRef.current = undefined;

    const content: Content = {
      [MicProblems.SpeakingToTurnedOffMic]: t("player.pleaseTurnOnMic"),
      [MicProblems.SilentToTurnedOnMic]: t("player.cantHearYou")
    }[problem]

    const tippyDiv = document.createElement("div");
    const tippyButton = document.createElement("button");

    tippyDiv.textContent = content;
    tippyDiv.className = classes.tippyDiv;
    tippyButton.className = classes.tippyButton;
    tippyButton.innerText = "✖";
    tippyDiv.appendChild(tippyButton);

    const instance = tippy(ref.current, {
      content: tippyDiv,
      trigger: "manual",
      hideOnClick: "toggle",
      delay: 250,

      theme: "transparent-l"
    });

    tippyRef.current = instance;

    setTimeout(() => {
      if (tippyRef.current === instance && !isMicAlertMuted) {
        instance.show();
      }
    }, problem === "silentToTurnedOnMic" ? 2500 : 500)

    function closeTippy() {
      if (!isMicAlertMuted) {
        instance.hide();
        muteMicAlert();
        setTimeout(() => {
          if (tippyRef.current === instance) {
            instance.destroy();
            tippyRef.current = undefined;
          }
        }, 500)
      }

      return;
    }

    tippyButton.onclick = closeTippy;

    return () => {
      instance.hide();
      setTimeout(() => {
        if (tippyRef.current === instance) {
          instance.destroy();
          tippyRef.current = undefined;
        }
      }, 500)
    }

  }, [t, ref, problem, isMicAlertMuted, muteMicAlert]);

  return (
    <Button
      disabled={forceDisabled ?? (
        !canRecord || !recorderReady || isRecognizing
      )}

      onClick={isRecordStarted ? stop : start}
      onLongPress={start}
      onLongRelease={stop}

      innerRef={ref}

      {...styleProps}
      {...props}
    >
      {(isFreeSpeech && isRecording) ? (
        <Timer/>
      ) : (!isRecognizing ? (
          <MicIcon/>
        ) : (
          <RefreshIcon/>
        ))
      }
    </Button>
  );
}

MicButton.selector = (state: PlayerReduxState) => {
  const currentAction = getCurrentAction(state)

  return {
    isFreeSpeech: isUserInputAction(currentAction) && currentAction.freeSpeech,
    isRecognizing: isRecognizing(state),
    canRecord: canRecord(state),
    recognitionEngine: state.recognitionEngine,
    recognitionPipeline: state.recognitionPipeline,
  }
}
