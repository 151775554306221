import React, {createContext, useCallback, useContext, useMemo} from "react";
import useMapState from "../hooks/useMapState";
import {analyticsSendEvent} from "../libs/analytics";

type PromblemsWatcherContext = {
  state: Map<string, number>,
  isFailed: boolean,
  failReasons: Array<string>
  setLimits: (reasonsSet: Record<string, number>) => void,
  raise: (key: string) => boolean,
  reset: (key: string) => boolean,
  resetAllFailReasons: () => boolean,
}

const Context = createContext<PromblemsWatcherContext>(null!);

export default function PromblemsWatcherProvider({children}: React.PropsWithChildren<{}>) {
  const [limits, , setLimitMap] = useMapState<string, number>();
  const [problemsState, setPromblemsStateMap, setPromblemsStateMapByArray] = useMapState<string, number>();
  const setLimits = useCallback<PromblemsWatcherContext["setLimits"]>((reasonsSet) => {
    setLimitMap(prev => prev.concat(Object.entries(reasonsSet)))
    setPromblemsStateMapByArray(prev => prev.concat(Object.entries(reasonsSet).map(([key,]) => [key, 0])))
  }, [setLimitMap, setPromblemsStateMapByArray])

  const raise = useCallback<PromblemsWatcherContext["raise"]>((key) => {
    analyticsSendEvent("problemsWatcherRaise", {key})

    let prevValue: number | undefined;
    setPromblemsStateMap((prevMap) => {
      prevValue = prevMap.get(key)

      if (prevValue !== undefined) {
        return new Map(prevMap.set(key, prevValue + 1))
      }
      return prevMap
    })
    return prevValue !== undefined
  }, [setPromblemsStateMap])

  const reset = useCallback<PromblemsWatcherContext["reset"]>((key) => {
    let hasValue: boolean = false;
    setPromblemsStateMap((prevMap) => {
      hasValue = prevMap.has(key)

      if (hasValue) {
        return new Map(prevMap.set(key, 0))
      }
      return prevMap
    })
    return hasValue
  }, [setPromblemsStateMap])

  const failReasons = useMemo(() => {
    return Array.from(limits).filter(([key, value]) => (problemsState.get(key) ?? 0) >= value).map(([key,]) => key)
  }, [limits, problemsState])

  const resetAllFailReasons = useCallback<PromblemsWatcherContext["resetAllFailReasons"]>(() => {
    if (failReasons.length === 0) {
      return false;
    }
    setPromblemsStateMapByArray(prev => prev.map(([key, value]) => (
      [key, failReasons.includes(key) ? 0 : value]
    )))
    return true;
  }, [failReasons, setPromblemsStateMapByArray])

  const ctx = useMemo(() => ({
    state: problemsState,
    setLimits,
    raise,
    reset,
    isFailed: failReasons.length > 0,
    failReasons,
    resetAllFailReasons
  }), [problemsState, setLimits, raise, reset, failReasons, resetAllFailReasons]);

  return (
    <Context.Provider value={ctx}>
      {children}
    </Context.Provider>
  )
}

export function useProblemsWatcher() {
  return useContext(Context);
}
