import React, {ComponentProps, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {Group, LessonCourse, LessonParticipationSummary} from "../../schema";

import {HelpCircleIcon} from "../../ui/icons";
import WithTooltip from "../../ui/tooltip";

import classes from "./ParticipationsSummary.module.css";

export type Props = Omit<ComponentProps<"div">, "children"> & {
  currentItem?: Pick<LessonCourse, "__typename" | "name">
  currentGroups?: Pick<Group, "name">[]
  participationsSummary: LessonParticipationSummary
}

export default function ParticipationsSummary({currentItem, currentGroups, participationsSummary, ...props}: Props) {
  const {t} = useTranslation();

  const {items: summaryItems, helpText: summaryHelpText} = useMemo<{
    items: {
      name: string,
      value: number
    }[],
    helpText: string
  } | undefined>(() => {
    const summary = participationsSummary;
    if (!summary) {
      return undefined;
    }

    if (currentItem?.__typename === "Lesson") {
      return {
        items: [{
          name: t("members.summary.lesson.assignments"),
          value: summary.assignments
        }, {
          name: t("members.summary.lesson.started"),
          value: summary.started
        }, {
          name: t("members.summary.lesson.finished"),
          value: summary.finished
        }, {
          name: t("members.summary.lesson.passed"),
          value: summary.passed
        }],
        helpText: t("members.summary.lesson.helpText")
      };
    } else {
      return {
        items: [{
          name: t("members.summary.default.assignments"),
          value: summary.assignments
        }, {
          name: t("members.summary.default.started"),
          value: summary.started
        }, {
          name: t("members.summary.default.finished"),
          value: summary.finished
        }, {
          name: t("members.summary.default.passed"),
          value: summary.passed
        }],
        helpText: t("members.summary.default.helpText")
      }
    }
  }, [t, participationsSummary, currentItem]) ?? {}

  const groupsNames = useMemo(() => (
    currentGroups?.map(({name}) => `«${name}»`).join(", ")
  ), [currentGroups])

  return (
    <div {...props}>
      {currentItem && (
        <h2 className={classes.itemName}>{currentItem.name}</h2>
      )}
      {currentGroups && (
        <h4 className={classes.groupsNames}>{t("members.summary.groups", {groupsNames})}</h4>
      )}
      {summaryItems?.map((item, index, {length}) => (
        <React.Fragment key={index}>
          <span className={classes.summaryItem}>
            {item.name}: {item.value}
          </span>
          {(index + 1 !== length) && " "}
        </React.Fragment>
      ))}
      <WithTooltip className={classes.help} as='span' helpText={summaryHelpText}>
        <HelpCircleIcon/>
      </WithTooltip>
    </div>
  )
}
