import React, {useEffect, useRef, useState} from "react"
import tippy, {Content, Instance as TippyJS, Placement} from "tippy.js";
import {AsAnyComponentWithDefault} from ".";

import cn from "classnames"
import "tippy.js/dist/tippy.css";
import "./tooltip.css";
import classes from "./tooltip.module.css"

type props = {
  placement?: Placement,
  showAfter?: number
  helpText: React.ReactNode
  children: React.ReactNode
}

type WithTooltipProps<T extends React.ElementType> = AsAnyComponentWithDefault<T, props, "div">

export default function WithTooltip<T extends React.ElementType>({
                                                                   as: Component,
                                                                   showAfter,
                                                                   placement,
                                                                   className,
                                                                   helpText,
                                                                   children,
                                                                   ...extra
                                                                 }: WithTooltipProps<T>) {
  const [ref, setRef] = useState<Element>();

  const tippyRef = useRef<TippyJS>();

  useEffect(() => {
    if (!ref) {
      tippyRef.current = undefined;
      return;
    }

    // @ts-ignore: tippy Single target variant doesn;t recognized by tslint. TODO: Replace with @tippyjs/react
    const instance = tippy(ref, {
      content: helpText,
      placement: placement,
      allowHTML: true,
      delay: [showAfter, 0]
    });

    tippyRef.current = instance;

    return () => {
      instance.destroy();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    tippyRef.current && helpText && tippyRef.current.setContent(helpText as Content);
  }, [helpText]);

  useEffect(() => {
    tippyRef.current && tippyRef.current.setProps({
      placement: placement,
      delay: [showAfter ?? null, 0]
    })
  }, [placement, showAfter]);

  return (
    <Component ref={(ref) => setRef(ref ?? undefined)} className={cn(classes.root, className)} {...extra}>
      {children}
    </Component>
  )
}

WithTooltip.defaultProps = {
  as: "div",
  showAfter: 300,
}
