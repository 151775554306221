import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Button from "../../ui/button";
import LoginLayout from "../../components/login/LoginLayout";

import {useFormState} from "../../hooks/useFormState";
import Input from "../../ui/input";
import {useQuery} from "@apollo/client";
import {useHistory, useParams} from "react-router";
import {ResetPasswordSetPasswordQuery, useResetPasswordSetPasswordMutation} from "./reset-password.graphql";
import {reverse} from "../../routing";
import {redirectToNextUrl} from "./common";
import {useSession} from "../../App.context";
import classes from "./reset-password.module.css";

export default function LoginResetPasswordScene() {
  const {t} = useTranslation();
  const history = useHistory();
  const {hash} = useParams() as {hash: string};

  const {data: queryData} = useQuery(ResetPasswordSetPasswordQuery, {
    fetchPolicy: "network-only",
    variables: {hash}
  });
  const loaded = !!queryData;
  const hashExist = !loaded || (loaded && !!queryData?.user)

  const [mutate, {loading: mutationInProgress}] = useResetPasswordSetPasswordMutation();

  const {set: setSessionId} = useSession();

  useEffect(() => {
    if (!hashExist) {
      history.push(reverse("loginForgotPassword"))
    }
  }, [history, hashExist])

  const formState = useFormState({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: ""
    },
    preventDefault: true,
    onSubmit: (data) => {
      if (!isValid) {
        return;
      }

      mutate({
        variables: {
          hash,
          newPassword: data.newPassword,
        }
      })
        .then(({data}) => {
          const userId = data?.login?.user?.id;
          const accountId = data?.login?.account?.id;

          setSessionId(data?.login?.sessionId);

          if (!userId) {
            history.push(reverse("login"));
            return;
          }

          if (!accountId) {
            history.push(reverse("loginSelectAccount"));
            return;
          }

          redirectToNextUrl(history);
        })
        .catch(console.error);
    }
  })

  const isValid = formState.values.newPassword === formState.values.newPasswordConfirm;

  if (!hashExist) {
    return null;
  }

  return (
    <>
      <ReactDocumentTitle title={t("loginResetPassword.title")}/>

      <LoginLayout className={classes.root}>
        <h1 className={classes.h1}>{t("loginResetPassword.h1")}</h1>
        <p className={classes.p}>{t("loginResetPassword.message")}</p>

        <form method="post" onSubmit={formState.submitHandler}>
          <Input
            className={classes.input}
            disabled={mutationInProgress}
            required
            name="newPassword"
            type="password"
            placeholder={t("loginResetPassword.newPassword")}
            onChange={formState.changeHandler}
          />

          <Input
            className={classes.input}
            disabled={mutationInProgress}
            name="newPasswordConfirm"
            type="password"
            placeholder={t("loginResetPassword.newPasswordConfirmation")}
            onChange={formState.changeHandler}
          />

          {(formState.values.newPassword && !isValid) && (
            <div className={classes.errorMsg}>{t("loginResetPassword.passwordDidntMatch")}</div>
          )}

          <div className={classes.buttonRow}>
            <Button disabled={mutationInProgress || !isValid} type="submit">{t("common.submit")}</Button>
          </div>
        </form>
      </LoginLayout>
    </>
  )
}
