import React from "react";
import isEqual from "lodash/isEqual";

// Hooks
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

// Components
import Container from "../../../ui/adaptivecontainer";
import Button from "../../../ui/button";
import ReactLink from "../../ReactLink";
import ControlsBar from "../ControlsBar";

// Classes
import classes from "./BottomBar.module.css";
import cn from "classnames";

// Redux
import {
  getLevel,
  getMaxLevel,
} from "../../../redux/player/selectors";
import {PlayerReduxState} from "../../../redux/player/types";
import {fillArrayInRange} from "../../../utils";

type props = React.ComponentProps<typeof ControlsBar> & {
  lessonName: string,
  backUrl?: string,
}

const BottomBar = ({lessonName, backUrl, ...props}: props) => {
  const {t} = useTranslation();

  const {
    level,
    maxLevel,
  } = useSelector(BottomBar.selector, isEqual);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.optionalBlock}>
          <div className={classes.lessonName} translate="no">{lessonName}</div>

          {maxLevel > 1 && (
            <div className={classes.levels}>
              {fillArrayInRange(1, maxLevel).map(levelNumber => (
                <div key={levelNumber} className={cn(classes.level, {
                  [classes.levelActive]: level === levelNumber,
                  [classes.levelUpcoming]: level! < levelNumber,
                })}>{levelNumber}</div>
              ))}
            </div>
          )}
        </div>

        <div className={classes.mainContainer}>
          <ControlsBar
            className={classes.controlsBar}
            backUrl={backUrl}
            {...props}
          />
        </div>
        <div className={classes.optionalBlock}>
          <Button as={ReactLink} className={classes.backBtn} href={backUrl} color='danger'>
            {t("player.endLesson")}
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default BottomBar;

BottomBar.selector = (state: PlayerReduxState) => {

  return {
    level: getLevel(state),
    maxLevel: getMaxLevel(state),
  }
}
