import React from "react";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import classes from "./AssignModal.module.css";
import {useTranslation} from "react-i18next";
import {LanguageCode} from "../../schema";
import {emailRegExp} from "../../utils";
import {getLanguageCode} from "../../i18n";

type contentProps = React.ComponentProps<"div"> & {
  onAddMembers?: (emails: string[], names: string[], languageCode?: LanguageCode) => void
}

function AddMembersModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.AddMembersModal.AddMembersModalTitle")}</span>
}

 function AddMembersModalContent({onAddMembers}: contentProps) {
  const {t, i18n} = useTranslation();
  const language = getLanguageCode(i18n.language)

  const formState = useFormState<{
    members: string,
  }>({
    initialValues: {
      members: "",
    },

    preventDefault: true,
    onSubmit: (data) => {
      const names = [], emails = [];

      const emailsAndNamesRegex = new RegExp(`\\s*(${emailRegExp.source})\\s*(?:,\\s*(.*?)\\s*)?(?:\n|$)`, "gmi")

      let m;

      while ((m = emailsAndNamesRegex.exec(data.members)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === emailsAndNamesRegex.lastIndex) {
          emailsAndNamesRegex.lastIndex++;
        }

        emails.push(m[1]);
        names.push(m[6] ?? "");
      }

      onAddMembers && onAddMembers(emails, names, language);
    }
  });

  const submitDisabled = undefined

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.AddMembersModal.helpText")}</p>
        <Textarea
          className={classes.input}
          name="members"
          rows={8}
          required
          placeholder={t("components.AddMembersModal.placeholder")}
          onChange={formState.changeHandler}
        />
      </>

      <div className={classes.bottom}>
        <Button type="submit" color={submitDisabled ? "secondary" : "success"} disabled={submitDisabled}>
          {t("components.AddMembersModal.submit")}
          </Button>
      </div>
    </form>
  )
}

const AddMembersModal = {
  Header: AddMembersModalHeader,
  Content: AddMembersModalContent,
}

export default AddMembersModal;
