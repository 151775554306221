import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Scalars, UserInputAction, UserInputActionInput} from "../../../schema";
import {gql} from "@apollo/client";
import {useFormState} from "../../../hooks/useFormState";
import {INPUT_PHRASE_MAX_LENGTH} from "../../../settings";

import classes from "./forms.module.css";
import cn from "classnames";

import Button from "../../../ui/button";
import WithTooltip from "../../../ui/tooltip";
import {HelpCircleIcon} from "../../../ui/icons";
import Checkbox from "../../../ui/checkbox";
import Select from "../../../ui/select";
import {deltaTimestampFromMinutes, fillArrayInRange, minutesFromDeltaTimestamp, minutesToString} from "../../../utils";
import LimitedTextarea from "../LimitedTextarea";
import PhrasesEdit from "../PhrasesEdit";
import Link from "../../../ui/link";
import {supportedPhraseTags} from "../../Phrase";

const FREE_SPEECH_MIN_TIME_CHOICES = fillArrayInRange(0, 5, .5);
const FREE_SPEECH_MAX_TIME_CHOICES = fillArrayInRange(.5, 5, .5);

type props = {
  action: UserInputAction,
  phraseLimit?: Scalars["Int"],
  onSubmit?: (data: UserInputActionInput) => void,
  testId?: string
}

type formState = Omit<UserInputActionInput, "beforeActionId" | "freeSpeech"> & {
  expectedText: NonNullable<UserInputActionInput["expectedText"]>,
  hintText: NonNullable<UserInputActionInput["hintText"]>,
  analogTexts: string[],
  freeSpeech?: "on"
}

export default function UserInputActionEditForm({action, phraseLimit, onSubmit, testId}: props) {
  const {t} = useTranslation();

  const [hintTextVisibility, setHintTextVisibility] = useState(!!action.hintText)
  const showHintTextArea = useCallback(() => setHintTextVisibility(true), [])

  const [analogTextsVisibility, setAnalogTextsVisibility] = useState(action.analogTexts.length !== 0)
  const showAnalogTextsArea = useCallback(() => setAnalogTextsVisibility(true), [])

  const formState = useFormState<formState>({
    initialValues: {
      expectedText: action.rawExpectedText ?? "",
      hintText: action.hintText ?? "",
      analogTexts: action.analogTexts,
      freeSpeech: action.freeSpeech ? "on" : undefined,
      freeSpeechMinTime: minutesFromDeltaTimestamp(action.freeSpeechMinTime)
        ?? FREE_SPEECH_MIN_TIME_CHOICES[0],
      freeSpeechMaxTime: minutesFromDeltaTimestamp(action.freeSpeechMaxTime)
        ?? FREE_SPEECH_MAX_TIME_CHOICES[FREE_SPEECH_MAX_TIME_CHOICES.length - 1],
    },
    preventDefault: true,
    onSubmit: (data) => {
      onSubmit && onSubmit({
        ...data,
        hintText: data.freeSpeech ? "" : data.hintText,
        freeSpeech: data.freeSpeech === "on",
        freeSpeechMinTime: deltaTimestampFromMinutes(data.freeSpeechMinTime),
        freeSpeechMaxTime: deltaTimestampFromMinutes(data.freeSpeechMaxTime),
      })
    }
  })

  const minTimeLessThanMax = formState.values.freeSpeechMinTime < formState.values.freeSpeechMaxTime

  const isFreeSpeech = formState.values.freeSpeech === "on"

  const updatePhrases = useCallback((analogTexts: string[]) => {
    formState.setValues({analogTexts})
  }, [formState])

  const analogTextsTextareaProps = useMemo(() => ({
    maxLength: phraseLimit ?? INPUT_PHRASE_MAX_LENGTH,
    rows: 4,
    autoComplete: "off",
    placeholder: t("components.ActionEditForm.analogTexts.placeholder")
  }), [t, phraseLimit])

  return (
    <form data-testid={`UserInputActionEditForm:${testId}`} method='post' onSubmit={formState.submitHandler}>
      <div className={classes.row}>
        <label className={classes.label} htmlFor="expectedText">
          {isFreeSpeech
            ? t("components.ActionEditForm.freeSpeechText.title")
            : t("components.ActionEditForm.expectedText.title")}
          {" "}
          <WithTooltip
            className={classes.help} as='span'
            helpText={isFreeSpeech
              ? t("components.ActionEditForm.freeSpeechText.help")
              : t("components.ActionEditForm.expectedText.help")}
          >
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <LimitedTextarea
          maxLength={phraseLimit ?? INPUT_PHRASE_MAX_LENGTH}
          filters={supportedPhraseTags}
          id="expectedText"
          name="expectedText"
          rows={4}
          value={formState.values.expectedText!}
          onChange={formState.changeHandler}
          placeholder={isFreeSpeech
            ? t("components.ActionEditForm.freeSpeechText.placeholder")
            : t("components.ActionEditForm.expectedText.placeholder")}
        />
      </div>

      {!isFreeSpeech && !hintTextVisibility && (
        <div className={classes.row}>
          <Link onClick={showHintTextArea}>
            {t("components.ActionEditForm.hintText.showArea")}
          </Link>
        </div>
      )}

      {!isFreeSpeech && hintTextVisibility && (
        <div className={classes.row}>
          <label className={classes.label} htmlFor="hintText">
            {t("components.ActionEditForm.hintText.title")}{" "}
            <WithTooltip className={classes.help} as='span' helpText={t("components.ActionEditForm.hintText.help")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </label>
          <LimitedTextarea
            maxLength={phraseLimit ?? INPUT_PHRASE_MAX_LENGTH}
            id="hintText"
            name="hintText"
            rows={4}
            value={formState.values.hintText!}
            onChange={formState.changeHandler}
            placeholder={t("components.ActionEditForm.hintText.placeholder")}
          />
        </div>
      )}

      {!isFreeSpeech && !analogTextsVisibility && (
        <div className={classes.row}>
          <Link onClick={showAnalogTextsArea}>
            {t("components.ActionEditForm.analogTexts.showArea")}
          </Link>
        </div>
      )}

      {!isFreeSpeech && analogTextsVisibility && (
        <>
          <div className={classes.row}>
            <label className={classes.label} htmlFor="analogTexts">
              {t("components.ActionEditForm.analogTexts.title")}{" "}
              <WithTooltip className={classes.help} as='span'
                           helpText={t("components.ActionEditForm.analogTexts.help")}
              ><HelpCircleIcon/></WithTooltip>
            </label>
            <div className={formState.values.analogTexts.length > 7 ? classes.yScroll : ""}>
              <PhrasesEdit
                value={formState.values.analogTexts}
                onValueChange={updatePhrases}
                textareaProps={analogTextsTextareaProps}
              />
            </div>
          </div>
        </>
      )}

      <div className={classes.row}>
        <label>
          <Checkbox
            checked={isFreeSpeech}
            name="freeSpeech"
            onChange={formState.changeHandler}
          />
          <span className={classes.checkboxText}>
              {t("components.ActionEditForm.freeSpeech.title")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                         helpText={t("components.ActionEditForm.freeSpeech.help")}>
                <HelpCircleIcon/>
              </WithTooltip>
            </span>
        </label>
      </div>

      {isFreeSpeech && (<>
        <div className={cn(classes.row, classes.namedSelectRow)}>
          <span>{t("components.ActionEditForm.freeSpeech.minTime")}</span>
          <Select name="freeSpeechMinTime" value={formState.values.freeSpeechMinTime}
                  onChange={formState.changeHandler}>
            {FREE_SPEECH_MIN_TIME_CHOICES.map((value, key) =>
              <option value={value} key={key}>{minutesToString(value)}</option>
            )}
          </Select>
        </div>

        <div className={cn(classes.row, classes.namedSelectRow)}>
          <span>{t("components.ActionEditForm.freeSpeech.maxTime")}</span>
          <Select name="freeSpeechMaxTime" value={formState.values.freeSpeechMaxTime}
                  onChange={formState.changeHandler}>
            {FREE_SPEECH_MAX_TIME_CHOICES.map((value, key) =>
              <option value={value} key={key}>{minutesToString(value)}</option>
            )}
          </Select>
        </div>
      </>)}

      {!minTimeLessThanMax && (<div className={classes.row}>
        <span className={classes.errorMessage}>{t("components.ActionEditForm.freeSpeech.minMustBeLess")}</span>
      </div>)}

      <div className={classes.row}>
        <Button
          color={minTimeLessThanMax ? "success" : undefined}
          disabled={formState.values.freeSpeechMinTime >= formState.values.freeSpeechMaxTime}
        >{t("common.save")}</Button>
      </div>
    </form>
  )
}


UserInputActionEditForm.fragments = {
  root: gql`
    fragment UserInputActionEditForm on UserInputAction {
      id
      rawExpectedText
      hintText
      analogTexts
      freeSpeech
      freeSpeechMinTime
      freeSpeechMaxTime
    }
  `
}
