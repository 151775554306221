import React, {ComponentProps, Ref} from "react";

import cn from "classnames";
import classes from "./table.module.css";

export default function Table({className: classNameEx, innerRef, ...props}: ComponentProps<"table"> & {
  innerRef?: Ref<HTMLTableElement>
}) {
  return (<table className={cn(classes.root, classNameEx)} {...props} ref={innerRef}/>)
}

Table.defaultProps = {
  cellPadding: 0,
  cellSpacing: 0,
}

Table.Head = function TableHead({className: classNameEx, innerRef, ...props}: ComponentProps<"thead"> & {
  innerRef?: Ref<HTMLTableSectionElement>
}) {
  return (<thead className={cn(classes.thead, classNameEx)} {...props} ref={innerRef}/>)
}

Table.Body = function TableBody({className: classNameEx, innerRef, ...props}: ComponentProps<"tbody"> & {
  innerRef?: Ref<HTMLTableSectionElement>
}) {
  return (<tbody className={cn(classes.tbody, classNameEx)} {...props} ref={innerRef}/>)
}

Table.Row = function TableRow({className: classNameEx, innerRef, ...props}: ComponentProps<"tr"> & {
  innerRef?: Ref<HTMLTableRowElement>
}) {
  return (<tr className={cn(classes.tr, classNameEx)} {...props} ref={innerRef}/>)
}

Table.Cell = function TableCell({className: classNameEx, innerRef, ...props}: ComponentProps<"td"> & {
  innerRef?: Ref<HTMLTableCellElement>
}) {
  return (<td className={cn(classes.td, classNameEx)} {...props} ref={innerRef}/>)
}
