import React from "react";
import {AsAnyComponentWithDefault, forwardRefWithDefaultProps} from ".";

import CloseModalButton from "./closemodalbutton";

import cn from "classnames";
import classes from "./widget.module.css";


type props = {
  dropShadow?: boolean

  showCloseButton?: boolean
  closeButtonColor?: React.ComponentProps<typeof CloseModalButton>["color"]
  onCloseButtonClick?: React.ComponentProps<typeof CloseModalButton>["onClose"]
}

type WidgetPropsType<T extends React.ElementType> = AsAnyComponentWithDefault<T, props, "div">

export default function Widget<T extends React.ElementType>({
  children, className, as: Component, dropShadow, showCloseButton, closeButtonColor, onCloseButtonClick, ...props
}: WidgetPropsType<T>) {
  return (
    <Component className={cn(classes.root, {[classes.dropShadow]: dropShadow}, className)} {...props}>
      {showCloseButton && <CloseModalButton color={closeButtonColor} onClose={onCloseButtonClick}/>}
      {children}
    </Component>
  )
}

Widget.defaultProps = {
  as: "div"
}

const WithRef = forwardRefWithDefaultProps(<T extends React.ElementType>({
  children, className, as: Component, dropShadow, showCloseButton, closeButtonColor, onCloseButtonClick, ...props
}: WidgetPropsType<T>, ref: any) => (
  <Component className={cn(classes.root, {[classes.dropShadow]: dropShadow}, className)} {...props} ref={ref}>
    {showCloseButton && <CloseModalButton color={closeButtonColor} onClose={onCloseButtonClick}/>}
    {children}
  </Component>
), {
  as: "div" as React.ElementType
})

type textProps = React.ComponentProps<"span">

const Title = ({className, ...props}: textProps) => (
  <span className={cn(classes.title, className)} {...props}/>
)

const Description = ({className, ...props}: textProps) => (
  <span className={cn(classes.description, className)} {...props}/>
)

Widget.Title = Title
Widget.Description = Description
Widget.WithRef = WithRef
