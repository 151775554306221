import React from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../hooks/useFormState";

import {CourseInput, Course, LanguageCode} from "../../schema";
import {getLanguages} from "../../i18n";
import {classes as tourClasses} from "../../scenes/editor/course-edit.tour";

import WithTooltip from "../../ui/tooltip";
import {HelpCircleIcon} from "../../ui/icons";
import Select from "../../ui/select";
import LessonsTableTable from "./lessons-table/LessonsTable.table";

import cn from "classnames";
import classes from "./CourseCustomizeForm.module.css";

type props = {
  className?: string,
  course: Course,
  onSave: (data: CourseInput) => void,
}

type formState = {
  languageCode: LanguageCode
}

export default function CourseCustomizeForm({className, course, onSave}: props) {
  const {t} = useTranslation();

  const formState = useFormState<formState>({
    initialValues: {
      languageCode: course.languageCode
    },
    preventDefault: true,
    onChange: onSave
  });

  return (
    <form className={className} method="post" onSubmit={formState.submitHandler} autoComplete="off">
      <div className={cn(classes.formItem, tourClasses.language)}>
        <label className={classes.label}>
          {t("components.CourseCustomizeForm.language.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.CourseCustomizeForm.language.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                      helpText={t("components.CourseCustomizeForm.language.helpText")}>
          <Select name="languageCode" value={formState.values.languageCode ?? undefined}
                  onChange={formState.changeHandler}>
            {getLanguages().map(lang => (
              <option key={lang.lang} value={lang.languageCode}>{lang.verboseName}</option>
            ))}
          </Select>
        </WithTooltip>
      </div>
      </form>
  )
}

CourseCustomizeForm.fragments = {
  root: gql`fragment CourseCustomizeForm on Course {
    __typename
    id
    name
    languageCode
    isInitial
    lessons {
      ...LessonsTableTable
    }
  }

  ${LessonsTableTable.fragments.root}`
}
