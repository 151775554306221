import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../time";

import Button from "../../ui/button"
import {useModal} from "../ModalProvider";

import classes from "./PlaythroughsWidget.module.css";
import {Account, Contract, EditorAccessType} from "../../schema";
import {gql} from "@apollo/client";
import {analyticsSendEvent} from "../../libs/analytics";
import PurchaseLicensesModal from "./PurchaseLicensesModal";


type props = {
  account: Account
  contracts: Contract[] | undefined
  accessType: EditorAccessType
  demoTimeRemaining: number
  isEmailActivated?: boolean
}

export default function PlaythroughsWidget({
  account, contracts, accessType, demoTimeRemaining, isEmailActivated
}: props) {
  const {t, i18n} = useTranslation();

  const {add: addModal} = useModal();
  const onBuyMoreClick = useCallback(() => {
    analyticsSendEvent("openPurchaseModal", {
      from: "HomeScene"
    });

    let modal = addModal({
      header: <PurchaseLicensesModal.Header/>,
      content: (
        <PurchaseLicensesModal.Content
          isEmailActivated={isEmailActivated}
          onBuy={() => modal.remove()}
        />
      )
    })
  }, [isEmailActivated, addModal]);

  const contractsSummary = useMemo(() => {
    if (!contracts || contracts.length < 1) {
      return undefined
    }

    let licensesCount = 0
    let finalDate: number = contracts[0].expireAt

    contracts.forEach(item => {
      if (item.isActive) {
        licensesCount += item.licensesCount;
        if (item.expireAt > finalDate) {
          finalDate = item.expireAt;
        }
      }
    })

    return {
      count: licensesCount,
      expireAt: formatDate(finalDate, i18n.language)
    }
  }, [i18n.language, contracts])


  const widget = useMemo(() => {
    switch (accessType) {
      case EditorAccessType.DEMO_ACCESS:
        return (
          <div>
            <span>{t("components.Playthroughs.demo", {count: demoTimeRemaining ?? 0})}</span>
            <Button
              className={classes.button}
              color="danger"
              onClick={onBuyMoreClick}
            >{t("components.Playthroughs.buyLicenses")}</Button>
          </div>
        )
      case EditorAccessType.EXPIRED_DEMO_ACCESS:
        return (
          <div>
            <span>{t("components.Playthroughs.demoExpired")}</span>
            <Button
              className={classes.button}
              color="danger"
              onClick={onBuyMoreClick}
            >{t("components.Playthroughs.buyLicenses")}</Button>
          </div>
        )
      case EditorAccessType.FULL_ACCESS:
        return (
          <div>
            {contractsSummary ? (
              <div className={classes.info}>
                <span>{t("components.Playthroughs.licensesCount", {count: contractsSummary.count})}</span>
                <span>{t("components.Playthroughs.expireAt")} {contractsSummary.expireAt}</span>
              </div>
            ) : (
              <div className={classes.info}>
                <span>{t("components.Playthroughs.left", {count: account.attemptsRemains ?? 0})}</span>
              </div>
            )}

            <Button
              className={classes.buttonAlt}
              color="primary"
              onClick={onBuyMoreClick}
            >{t("components.Playthroughs.buyMoreLicenses")}</Button>
          </div>
        )
      default:
        console.error("Unsupported EditorAccessType")
        return null;
    }
  }, [t, accessType, account.attemptsRemains, demoTimeRemaining, contractsSummary, onBuyMoreClick])

  return (
    <div className={classes.root}>
      {widget}
    </div>
  )
}

PlaythroughsWidget.fragments = {
  account: gql`
    fragment PlaythroughsWidgetAccount on Account {
      id
      attemptsRemains
    }
  `,
}
