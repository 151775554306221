import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getCurrentAction, getLevel} from "../selectors";
import {sessionSkipMutation} from "../graphql";
import {MutationEducationSessionSkipArgs, Scalars} from "../../../schema";
import {isAnyUserInputAction} from "../utils";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function skipAction() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    const action = getCurrentAction(state);

    if (!action) {
      console.warn("passIntro: wrong player state");
      return;
    }

    analyticsSendEvent("playerSkip", {
      sessionId: state.sessionId!,
      level: getLevel(state),
      actionId: action.id,
    });

    if (action && isAnyUserInputAction(action)) {
      state.client.mutate<{ ok: Scalars["Boolean"] }, MutationEducationSessionSkipArgs>({
        mutation: sessionSkipMutation,
        variables: {
          sessionId: state.sessionId!,
          level: getLevel(state),
          actionId: action.id,
        }
      }).catch(console.error);
    }

    dispatch({type: "next"});
  }
}
