import React, {SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {bindEventListener} from "../utils";

type props = React.ComponentProps<"img">;

const ReactImage = ({alt, onLoad, style: extraStyle, ...props}: props) => {
  const measurerRef = useRef<HTMLImageElement>(null);
  const [blockSize, setBlockSize] = useState<[number, number]>([0, 0]);
  const [imageAspectRatio, setImageAspectRatio] = useState<number>(0);

  useEffect(() => bindEventListener(window, "resize", () => {
    if (measurerRef.current) {
      setBlockSize([
        measurerRef.current.offsetWidth,
        measurerRef.current.offsetHeight
      ]);
    }
  }), []);

  const onImageLoad = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    setBlockSize([e.currentTarget.offsetWidth, e.currentTarget.offsetHeight]);
    setImageAspectRatio(e.currentTarget.naturalWidth / e.currentTarget.naturalHeight);
    onLoad && onLoad(e);
  }, [onLoad]);

  const style = useMemo(() => {
    if (!imageAspectRatio) {
      return {
        width: "100%",
        height: "100%",
        ...extraStyle
      }
    }

    let wrapperSizes = {
      width: blockSize[0],
      height: blockSize[1]
    }

    if (blockSize[0] / blockSize[1] > imageAspectRatio) {
      wrapperSizes.width = wrapperSizes.height * imageAspectRatio;
    } else {
      wrapperSizes.height = wrapperSizes.width / imageAspectRatio;
    }

    return {
      ...wrapperSizes,
      ...extraStyle
    }
  }, [blockSize, imageAspectRatio, extraStyle])

  return (
    <img
      ref={measurerRef}
      onLoad={onImageLoad}
      style={style}
      alt={alt}
      {...props}
    />
  )
}

export default ReactImage;
