/* eslint-disable max-lines */
import React, {useCallback, useContext, useEffect, useState} from "react";
import {DEV, RECORDER_AUDIO_MIME_TYPES, RECORDER_VIDEO_MIME_TYPES, WEBSOCKET_URL} from "../settings";
import {bindEventListener, getUserMedia, releaseUserMediaStream} from "../utils";
import createVad from "voice-activity-detection";
import {MediaRecorder} from "extendable-media-recorder";
import createFileUploadWS, {FileUpload, FileUploadData} from "../libs/fileuploadWS";
import {LanguageCode, Lesson} from "../schema";

type VAD = ReturnType<typeof createVad>;

const MediaRecorderContext = React.createContext<{
  mediaType: "audio" | "video",
  hasPermission: undefined | boolean,
  recorderReady: boolean,
  isRecording: boolean,
  resultState: string,
  getResult: () => FileUploadData | undefined,
  mediaStream?: MediaStream,
  isUserSpeaking: boolean,
  voiceActivityValue: number | undefined,
  lang?: LanguageCode,
  accountId?: string,
  isShared?: boolean,

  toggleRecorder: (state: boolean) => void,
  record: (recognitionEngine: string, recognitionPipeline?: string) => void,
  stopRecord: () => boolean,
}>(null!);

type props = React.PropsWithChildren<{
  lesson: Lesson,
  isShared: boolean,
  mediaType: "audio" | "video",
  noTrackFocus?: boolean
}>

type MediaRecorderEx = MediaRecorder & {
  uploadFile: FileUpload
}

export default function MediaRecorderProvider(
  {lesson, isShared, noTrackFocus, mediaType: initMediaType, children}: props
) {
  const [_hasFocus, setHasFocus] = useState(() => document.visibilityState === "visible");
  const hasFocus = noTrackFocus ? _hasFocus : true;

  const [hasPermission, setPermissionState] = useState<boolean | undefined>(undefined);

  const [mediaType, setMediaType] = useState(initMediaType);
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [recorder, setRecorder] = useState<MediaRecorderEx>();
  const hasRecorder = !!recorder;

  const [requireRecorder, toggleRecordRequired] = useState<boolean>(false);
  const [isRecording, setRecordState] = useState<boolean | undefined>(undefined);
  const [resultState, setResultState] = useState<string>("inactive");

  const [isUserSpeaking, setUserSpeaking] = useState<boolean>(false);
  const [voiceActivityValue, setVoiceActivityValue] = useState<number>()
  const [sampleRate, setSampleRate] = useState<number>()

  useEffect(() => {
    if (mediaStream === undefined) {
      return;
    }
    let vad: VAD;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const onVoiceStart = () => setUserSpeaking(true);
    const onVoiceStop = () => setUserSpeaking(false);
    vad = createVad(audioContext, mediaStream, {
      avgNoiseMultiplier: 1.5,

      onVoiceStart,
      onVoiceStop,
      onUpdate: setVoiceActivityValue
    })

    return () => {
      vad.destroy();
      setVoiceActivityValue(undefined);
    }
  }, [mediaStream])

  // do not record when window not active, prevent problem with phones audio
  useEffect(() => bindEventListener(document, "visibilitychange", () => {
    if (isRecording) {
      return;
    }

    setHasFocus(document.visibilityState === "visible");
  }), [isRecording]);

  useEffect(() => {
    if (
      (!hasFocus) || (hasPermission === false) || ((hasPermission !== undefined) && !requireRecorder)
    ) return;

    let mounted = true;

    setPermissionState(undefined);
    getUserMedia(mediaType).then(([stream, mediaType]) => {
      if (!mounted) return;
      setPermissionState(true);
      setMediaType(() => mediaType);
      setMediaStream(stream);
      setSampleRate(stream.getAudioTracks()[0].getSettings().sampleRate)
    }).catch(() => {
      if (!mounted) return;
      setPermissionState(false);
      setMediaStream(undefined);
    });

    return () => {
      mounted = false;
      setMediaStream(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaType, hasFocus, requireRecorder]);

  useEffect(() => {
    if (!requireRecorder && mediaStream) {
      releaseUserMediaStream(mediaStream);
    }
  }, [mediaStream, requireRecorder]);

  // media stream effect
  useEffect(() => {
    if (!mediaStream) {
      setRecorder(undefined);
    }

    return () => {
      if (mediaStream) {
        releaseUserMediaStream(mediaStream);
      }
    }
  }, [mediaStream]);

  // media recorder effect
  useEffect(() => {
    if (!recorder) {
      setRecordState(false);
    }

    return () => {
      if (recorder) {
        destroyMediaRecorder(recorder);
      }
    }
  }, [recorder]);

  const setResultReady = useCallback(() => {
      setResultState("ready");
  }, [])

  useEffect(() => {
    if (!requireRecorder) {
      if (recorder) {
        destroyMediaRecorder(recorder);
      }
      return;
    }
    if (!mediaStream || hasRecorder) {
      return;
    }

    const mediaRecorder = createMediaRecorder(mediaStream, getMimeType(mediaType));

    setRecorder(mediaRecorder);
    setRecordState(false);
  }, [recorder, mediaStream, hasRecorder, mediaType, requireRecorder, lesson, isShared, setResultReady]);

  const record = useCallback((recognitionEngine: string, pipeline?: string) => {
    if (!recorder) {
      throw new Error("NoRecorder");
    }

    if (recorder.state !== "recording") {
      recorder.uploadFile = createFileUploadWS(
        DEV ? WEBSOCKET_URL : `${window.location.origin.replace("https", "wss")}/ws`,
        lesson.languageCode,
        recognitionEngine,
        lesson.id,
        lesson.accessToken,
        setResultReady,
        getMimeType(mediaType).replace("/", "_"),
        pipeline,
        sampleRate
      );
      recorder.ondataavailable = (e) => {
        recorder.uploadFile.chunk(e.data);
      };

      setRecordState(true);
      recorder?.start(200);
    }

  }, [recorder, lesson.languageCode, lesson.id, lesson.accessToken, mediaType, sampleRate, setResultReady])

  const stopRecord = useCallback(() => {
    if (!recorder) {
      return false;
    }

    if (!recorder.uploadFile.data.id) {
      recorder.stop();
      setRecordState(false);
      setResultState("inactive");
      setRecorder(undefined);
      return false;
    }

    setTimeout(() => {
      recorder.stop();
      // eslint-disable-next-line quotes
      recorder.uploadFile.chunk(new Blob(['{"eof" : 1}']));
    }, 175);
    setRecordState(false);
    setResultState("pending");
    return true;
  }, [recorder]);

  const getResult = useCallback(() => {
    if (!recorder) {
      return;
    }

    setResultState("inactive");
    const data = recorder?.uploadFile.data;
    setRecorder(undefined);
    return data;
  }, [recorder])

  const toggleRecorder = useCallback((state: boolean) => {
    if (state) {
      toggleRecordRequired(true);
    } else {
      setRecorder(undefined);
      toggleRecordRequired(false);
    }
  }, []);

  return (
    <MediaRecorderContext.Provider value={{
      mediaType,
      hasPermission,
      isRecording: !!isRecording,
      resultState: resultState,
      getResult,
      mediaStream,
      isUserSpeaking,
      voiceActivityValue,

      recorderReady: !!hasPermission && hasRecorder && !!mediaStream,
      toggleRecorder,
      record,
      stopRecord,
    }}>
      {children}
    </MediaRecorderContext.Provider>
  )
}

export function useMediaRecorder() {
  return useContext(MediaRecorderContext);
}

function getMimeType(mediaType: "audio" | "video") {
  let mimeTypes;

  switch (mediaType) {
    case "audio":
      mimeTypes = RECORDER_AUDIO_MIME_TYPES;
      break;

    case "video":
      mimeTypes = RECORDER_VIDEO_MIME_TYPES;
      break;

    default:
      throw new Error(`NotImplemented: media type "${mediaType}" not supported`);
  }

  const mimeType = mimeTypes.find(mime => MediaRecorder.isTypeSupported(mime))

  if (!mimeType) {
    throw new Error(`Cannot start ${mediaType} record: no mime type supported by browser`);
  }

  return mimeType;
}

const createMediaRecorder = (mediaStream: MediaStream, mimeType: string) => {
  const mediaRecorder = new MediaRecorder(mediaStream, {
    mimeType: mimeType,
  }) as MediaRecorderEx;

  return mediaRecorder;
}

const destroyMediaRecorder = (mediaRecorder: MediaRecorderEx) => {
  if (mediaRecorder.state !== "inactive") {
    mediaRecorder.stop();
  }

  if (mediaRecorder.uploadFile) {
    mediaRecorder.uploadFile.done().then(() => {
      mediaRecorder.uploadFile.destroy();
    })
  }
}
