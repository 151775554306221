import React, {forwardRef, useImperativeHandle, useLayoutEffect, useRef} from "react";


import {InformationIcon} from "../../../../ui/icons";
import FSImage, {FSImageRef} from "../../../../ui/fullscreen/image";
import FSVideo, {FSVideoRef} from "../../../../ui/fullscreen/video";

import cn from "classnames";
import classes from "./Content.module.css";

type props = Omit<React.ComponentProps<"div">, "title"> & {
  variant?: "default" | "info" | "avatar" | "system" | "user" | "userHint" | "help" | "error" | "incorrectChoice",
  title?: React.ReactNode,
  isClickable?: boolean
};

function Content({children, className: extraClassName, variant, title, ...props}: props) {
  return (
    <div className={cn(classes.content, variant && classes[variant], extraClassName)} {...props}>
      {title && <Content.Title translate="yes">{title}</Content.Title>}
      {variant === "help" && <InformationIcon className={classes.helpIcon}/>}
      {children}
    </div>
  )
}

Content.Title = React.memo(
  function ({className, ...props}: React.ComponentProps<"span">) {
    return (
      <span className={cn(classes.title, className)} {...props}/>
    )
  }
);

Content.Extra = React.memo(function ({className, ...props}: React.ComponentProps<"div">) {
  return (
    <div className={cn(classes.extra, className)} {...props}/>
  )
});

Content.Row = React.memo(function ({className, ...props}: React.ComponentProps<"div">) {
  return (
    <div className={cn(classes.row, className)} {...props}/>
  )
});

type imageProps = Omit<React.ComponentProps<"div">, "ref"> & Pick<React.ComponentProps<"img">, "alt" | "src"> & {
  openable?: boolean
}

Content.Image = React.memo(forwardRef<FSImageRef, imageProps>(
  function ({className, alt, src, openable, ...props}, ref) {
    return openable ? (
      <FSImage
        className={cn(classes.media, className)}
        ref={ref}
        src={src}
        alt={alt ?? ""}
        adaptive
        useReactImage={false}
        imgProps={props}
      />
    ) : (
      <img className={cn(classes.media, classes.fit, className)} alt={alt ?? ""} src={src} {...props}/>
    )
  }
));

type videoProps = React.ComponentProps<typeof FSVideo>

Content.Video = React.memo(forwardRef<FSVideoRef, videoProps>(
  function ({className, ...props}, ref) {
    return (
      <FSVideo className={cn(classes.media, className)} ref={ref} adaptive exitOnEnded {...props}/>
    )
  }
));

type ContainerProps = React.ComponentProps<"div"> & {
  fadeAway?: boolean
  appear?: boolean
}

Content.Container = forwardRef<HTMLDivElement, ContainerProps>(
  function Container({fadeAway, appear, className, ...props}, exRef) {
    const ref = useRef<HTMLDivElement>(null);

    useImperativeHandle(exRef, () => ref.current!);

    // NOTE: don't use padding on Container, code is made to not measure/transit it
    useLayoutEffect(() => {
      if (appear) {
        const divElement = ref.current;
        if (divElement) {
          const style = divElement.style
          const initialStyle = {
            height: style.height,
            overflow: style.overflow
          }

          const height = divElement.offsetHeight;

          // Making container "invisible"
          style.height = "0";
          style.overflow = "hidden";

          let scrolled = false;

          const scrollIntoView = () => {
            divElement.scrollIntoView({
              block: "start"
            });
            if (!scrolled) {
              window.requestAnimationFrame(scrollIntoView);
            }
          }

          window.requestAnimationFrame(scrollIntoView);

          setTimeout(() => {
            // Setting transition and expanding container to initial size
            style.transition = "height .5s";
            divElement.style.height = `${height}px`;

            setTimeout(() => {
              // Resetting style to initial
              scrolled = true;
              style.overflow = initialStyle.overflow;
              style.height = initialStyle.height;
            }, 500)
          })
        }
      }
    }, [appear])

    return (
      <div className={cn(classes.container, {[classes.fadeAway]: fadeAway}, className)} ref={ref} {...props}/>
    )
  }
)

export default Content;
