import React, {useCallback, useMemo} from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {LessonScenarioActivationMethod, LessonScenarioAfterAction, ScenarioInput} from "../../schema";
import {Scenario} from "../../types";
import {useFormState} from "../../hooks/useFormState";
import classes from "./ScenarioCustomizeForm.module.css";
import LessonAction from "./LessonAction";
import EditableInput from "./EditableInput";
import WithTooltip from "../../ui/tooltip";
import {HelpCircleIcon} from "../../ui/icons";
import PhrasesEdit from "./PhrasesEdit";
import {INPUT_PHRASE_MAX_LENGTH} from "../../settings";
import Link from "../../ui/link";
import Select from "../../ui/select";


type props = {
  scenario: Scenario
  onSave: (data: ScenarioInput) => void,
  onDelete: () => void,
}

type formState = {
  name: string,
  triggerPhrases: string[],
  afterAction: LessonScenarioAfterAction,
  activationMethod: LessonScenarioActivationMethod
}

export default function ScenarioCustomizeForm({scenario, onSave, onDelete}: props) {
  const {t} = useTranslation();

  const formState = useFormState<formState>({
    initialValues: {
      name: scenario.name,
      triggerPhrases: scenario.triggerPhrases,
      afterAction: scenario.afterAction,
      activationMethod: scenario.activationMethod
    },
    preventDefault: true,
    onChange: onSave
  });

  const updatePhrases = useCallback((triggerPhrases: string[]) => {
    formState.setValues({triggerPhrases})
  }, [formState])

  const analogTextsTextareaProps = useMemo(() => ({
    maxLength: INPUT_PHRASE_MAX_LENGTH,
    rows: 4,
    autoComplete: "off",
    placeholder: t("components.ScenarioCustomizeForm.triggerPhrases.placeholder")
  }), [t])

  return (
    <form method="post" onSubmit={formState.submitHandler} autoComplete="off">
      <div className={classes.formItem}>
        <label className={classes.label}>{t("components.ScenarioCustomizeForm.name")}</label>
        <EditableInput.Wrapper multiline className={classes.nameWrapper}>
          <EditableInput
            type="text"
            className={classes.name}
            buttonClassName={classes.nameButtons}
            name="name"
            defaultValue={formState.values.name}
            onChange={formState.changeHandler}
            autoComplete="off"
            maxLength={60}
          />
        </EditableInput.Wrapper>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>{t("components.ScenarioCustomizeForm.activationMethod")}</label>
        <WithTooltip as='span'
          className={classes.help}
          helpText={t("components.ScenarioCustomizeForm.activationMethodHelp")}
        >
          <Select name="activationMethod" value={formState.values.activationMethod} onChange={formState.changeHandler}>
            <option value={LessonScenarioActivationMethod.TRIGGER_PHRASE}>
              {t("types.ScenarioActivationMethod.TRIGGER_PHRASE")}
            </option>
            <option value={LessonScenarioActivationMethod.AVATAR_PHRASE}>
              {t("types.ScenarioActivationMethod.AVATAR_PHRASE")}
            </option>
            <option value={LessonScenarioActivationMethod.USER_INPUT}>
              {t("types.ScenarioActivationMethod.USER_INPUT")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      {scenario.activationMethod === LessonScenarioActivationMethod.TRIGGER_PHRASE && (
        <div className={classes.formItem}>
          <label className={classes.label} htmlFor="triggerPhrases">
            {t("components.ScenarioCustomizeForm.triggerPhrases.title")}{" "}
            <WithTooltip className={classes.help} as='span'
              helpText={t("components.ScenarioCustomizeForm.triggerPhrases.help")}
            ><HelpCircleIcon/></WithTooltip>
          </label>
          <PhrasesEdit
            value={formState.values.triggerPhrases}
            onValueChange={updatePhrases}
            textareaProps={analogTextsTextareaProps}
          />
        </div>
      )}

      <div className={classes.formItem}>
        <label className={classes.label}>{t("components.ScenarioCustomizeForm.afterAction")}</label>
        <WithTooltip as='span'
          className={classes.help}
          helpText={t("components.ScenarioCustomizeForm.afterActionHelp")}
        >
          <Select name="afterAction" value={formState.values.afterAction} onChange={formState.changeHandler}>
            {scenario.activationMethod !== LessonScenarioActivationMethod.AVATAR_PHRASE && (
              <option value={LessonScenarioAfterAction.RETURN}>
                {t("types.ScenarioAfterAction.RETURN")}
              </option>
            )}
            <option value={LessonScenarioAfterAction.NEXT}>
              {t("types.ScenarioAfterAction.NEXT")}
            </option>
            <option value={LessonScenarioAfterAction.INTERRUPT}>
              {t("types.ScenarioAfterAction.INTERRUPT")}
            </option>
            <option value={LessonScenarioAfterAction.FINISH}>
              {t("types.ScenarioAfterAction.FINISH")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <Link type="button" color="danger" onClick={onDelete}>
          {t("components.ScenarioCustomizeForm.deleteScenario")}
        </Link>
      </div>

    </form>
  )
}

ScenarioCustomizeForm.fragments = {
  root: gql`fragment ScenarioCustomizeForm on Scenario {
    __typename
    id
    name
    isMain
    triggerPhrases
    afterAction
    activationMethod
    actions {
      id
      ...LessonAction
    }
  }

  ${LessonAction.fragments.root}`
}
