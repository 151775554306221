import React, {ComponentProps, MouseEventHandler} from "react";
import {useTranslation} from "react-i18next";

import Button from "../../ui/button";

import cn from "classnames";
import classes from "./DeleteModal.module.css";

export type HeaderProps = Omit<ComponentProps<"span">, "children">

function DeleteModalHeader(props: HeaderProps) {
  const {t} = useTranslation();

  return <span {...props}>{t("components.DeleteModal.title")}</span>;
}

export type ContentProps = Omit<ComponentProps<"span">, "children">

function DeleteModalContent(props: ContentProps) {
  const {t} = useTranslation();

  return <span {...props}>{t("components.DeleteModal.helpText")}</span>;
}

export type FooterProps = Omit<ComponentProps<"div">, "children"> & {
  onConfirm: MouseEventHandler<HTMLButtonElement>,
  onCancel: MouseEventHandler<HTMLButtonElement>,
}

function DeleteModalFooter({onCancel, onConfirm, className, ...props}: FooterProps) {
  const {t} = useTranslation();

  return (
    <div className={cn(classes.footer, className)} {...props}>
      <Button color="secondary" onClick={onCancel}>{t("common.cancel")}</Button>
      <Button color="danger" onClick={onConfirm}>{t("common.delete")}</Button>
    </div>
  )
}

const DeleteModal = {
  Header: DeleteModalHeader,
  Content: DeleteModalContent,
  Footer: DeleteModalFooter
}

export default DeleteModal;
