import React, {useRef, useState} from "react";

export default function useAutoResetState<T>(initialValue: T, timeout: number): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(initialValue);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const toggle = React.useCallback((value: T) => {
    if (value !== initialValue) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    setValue(value);

    if (value !== initialValue) {
      timeoutRef.current = setTimeout(() => {
        setValue(initialValue);
        timeoutRef.current = undefined;
      }, timeout);
    }
  }, [initialValue, timeout]);

  return [value, toggle]
}
