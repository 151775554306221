import React, {lazy, Suspense} from "react";
import BabylonAvatarPlayerProps from "./BabylonAvatarPlayer.props";

const BabylonAvatarPlayer = lazy(() => import(/* webpackChunkName: "babylon" */"./BabylonAvatarPlayer"));

export default function BabylonAvatarPlayerLazy(props: BabylonAvatarPlayerProps) {
  return (
    <Suspense fallback={<div/>}>
      <BabylonAvatarPlayer
        {...props}
      />
    </Suspense>
  )
}
