import {useMemo} from "react"
import {useTranslation} from "react-i18next";
import {LessonMode} from "../../schema";

export default function useLessonModeTranslation(lessonMode: LessonMode) {
  const {t} = useTranslation();

  return useMemo(() => {
    switch (lessonMode) {
      case LessonMode.STUDY_THREE_STEP:
        return t("types.LessonMode.STUDY_THREE_STEP")
      case LessonMode.STUDY_TWO_STEP:
        return t("types.LessonMode.STUDY_TWO_STEP")
      case LessonMode.CHOICES_TEST_TWO_STEP:
        return t("types.LessonMode.CHOICES_TEST_TWO_STEP")
      case LessonMode.CHOICE_BASED_STUDY:
        return t("types.LessonMode.CHOICE_BASED_STUDY")
      case LessonMode.INTERVIEW:
        return t("types.LessonMode.INTERVIEW")
      case LessonMode.QUIZ:
        return t("types.LessonMode.QUIZ")
      case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
        return t("types.LessonMode.CHOICES_WITH_CUSTOM_SCORES")
      case LessonMode.CUSTOM_PARAMETERS_TEST:
        return t("types.LessonMode.CUSTOM_PARAMETERS_TEST")
      default:
        throw new Error(`Not supported lesson mode for translation: "${lessonMode}"`)
    }
  }, [lessonMode, t])
}