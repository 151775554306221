import React, {forwardRef, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getTextWithStressMarks} from "../../../Phrase";
import isEqual from "lodash/isEqual";
import {parseMimeType, PlayableMediaType} from "../../../../utils";

import {SystemAction} from "../../../../schema";
import {FSImageRef} from "../../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../../ui/fullscreen/video";

import {PlayerReduxState} from "../../../../redux/player/types";
import {getCurrentAction} from "../../../../redux/player/selectors";

import Display from "./Display";
import ActionDisplay from "./ActionDisplay";

type props = {
  openFullscreenMedia: () => void,
}

type SystemActionDisplayType = React.ForwardRefExoticComponent<
  props & React.RefAttributes<FSImageRef | FSVideoRef>
> & {
  selector?: (state: PlayerReduxState) => {
    action: SystemAction;
  }
}

const SystemActionDisplay: SystemActionDisplayType = forwardRef(({openFullscreenMedia}: props, ref) => {
  const {t} = useTranslation();
  const {action} = useSelector(SystemActionDisplay.selector!, isEqual);

  const text = useMemo(() => getTextWithStressMarks(action.text), [action.text])

  let mediaPreview;

  if (action.media && action.mediaType) {
    const mediaType = parseMimeType(action.mediaType).type;

    const mediaProps = {
      onClick: openFullscreenMedia,
      src: action.media,
      ref: ref,
    }

    switch (mediaType) {
      case PlayableMediaType.IMAGE:
        mediaPreview = (
          <Display.Image {...mediaProps} alt={text} openable={!!action.mediaViewRequired}/>
        )
        break;
      case PlayableMediaType.VIDEO:
        mediaPreview = (
          <Display.Video {...mediaProps}/>
        )
        break;
      default:
        console.error(`Unsupported media type in system bubble: ${mediaType}`)
    }
  }

  return (
    <ActionDisplay
      title={t("player.systemActionTitle")}
      mediaPreview={mediaPreview}
      translateContent="no"
    >
      {text}
    </ActionDisplay>
  )
});

export default SystemActionDisplay;

SystemActionDisplay.selector = (state: PlayerReduxState) => ({
  action: getCurrentAction(state) as SystemAction,
});
