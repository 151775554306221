import {MutationHookOptions} from "@apollo/client/react/types/types";
import {gql, useMutation} from "@apollo/client";
import {loginUpdateCache} from "./common.graphql";
import {AppAccountFragment, AppUserFragment} from "../../App.graphql";
import {LoginSession} from "../../schema";

export const ResetPasswordSetPasswordQuery = gql`
  query LoginResetPassword($hash: String!) {
    user: getResetPasswordUser(hash: $hash) {
      email
    }
  }
`;

type ResetPasswordSetPasswordMutationData = { login: LoginSession }
type ResetPasswordSetPasswordMutationVars = {
  hash: string,
  newPassword: string
}

export function useResetPasswordSetPasswordMutation(
  opts?: MutationHookOptions<ResetPasswordSetPasswordMutationData, ResetPasswordSetPasswordMutationVars>
) {
  return useMutation<ResetPasswordSetPasswordMutationData, ResetPasswordSetPasswordMutationVars>(
    resetPasswordSetPasswordMutation,
    {
      ...opts,

      update: (cache, mutationResult) => {
        if (mutationResult.data?.login) {
          loginUpdateCache(cache, {
            user: mutationResult.data?.login.user,
            account: mutationResult.data?.login.account ?? undefined
          });
        }

        opts?.update && opts.update(cache, mutationResult, {});
      }
    }
  );
}

const resetPasswordSetPasswordMutation = gql`
  mutation ResetPasswordSetPassword($hash: String!, $newPassword: String!) {
    login: resetPasswordSetPassword(hash: $hash, newPassword: $newPassword) {
      sessionId
      account { id ...AppAccount}
      user { id ...AppUser }
    }
  }

  ${AppUserFragment}
  ${AppAccountFragment}
`;

