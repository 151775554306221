import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {ExpandIcon} from "../icons";
import AbstractFullscreen, {AbstractFullscreenRef} from ".";
import PopupPortal from "../popupportal";
import {QRCodeCanvasProps} from "../../libs/qrcode/qrcode";
import QRCode, {QRCodeRef} from "../qrcode";

import cn from "classnames"
import classes from "./qrcode.module.css";

type props = Omit<React.ComponentProps<"div">, "children" | "ref" | "value"> & {
  value: string
  qrProps?: Omit<QRCodeCanvasProps, "value" | "onClick">
}

export type FSQRCodeRef = AbstractFullscreenRef & QRCodeRef

const FSQRCode = forwardRef<FSQRCodeRef, props>(function ({className, value, qrProps, ...props}, ref) {
  const fsRef = useRef<AbstractFullscreenRef>(null);
  const canvasRef = useRef<QRCodeRef>(null);

  const onClose = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fsRef.current?.exitFullscreen();
  }, []);

  const preview = useMemo(() => {
    return (
      <>
        <QRCode value={value} ref={canvasRef} {...qrProps} className={cn(classes.qr, qrProps?.className)}/>
        <ExpandIcon className={classes.expandIcon}/>
      </>
    )
  }, [value, qrProps])

  useImperativeHandle(ref, () => (
    Object.assign(canvasRef.current!, fsRef.current)
  ), [])

  return (
    <AbstractFullscreen
      className={cn(classes.root, className)}
      preview={preview}
      ref={fsRef}
      {...props}
    >
      <PopupPortal
        as={QRCode}
        ref={canvasRef}
        value={value}
        onClick={onClose}
        size={1280}
        style={{
          height: "100%",
          width: "100%"
        }}
      />
    </AbstractFullscreen>
  )
})

export default FSQRCode;
