import React from "react";
import {AsAnyComponentWithDefault} from ".";

export type MutablePropsType<T extends React.ElementType> = AsAnyComponentWithDefault<T, {}, "div">

export default function Mutable<T extends React.ElementType>({as: Component, ...props}: MutablePropsType<T>) {
  return (
    <Component {...props}/>
  )
}

Mutable.defaultProps = {
  as: "div"
}