import React, {useMemo} from "react";

import {SystemAction} from "../../../schema";

import SystemIcon from "../../SystemIcon";
import SessionActionDisplay from "./SessionActionDisplay";
import {gql} from "@apollo/client";
import {getTextWithStressMarks} from "../../Phrase";

type props = React.ComponentProps<"div"> & {
  action: SystemAction
}

const SessionSystemActionDisplay = function ({action, ...props}: props) {
  const text = useMemo(() => getTextWithStressMarks(action.text), [action.text])

  return (
    <SessionActionDisplay variant="system" icon={(
        <SystemIcon adaptive/>
    )} {...props}>
      {text}
    </SessionActionDisplay>
  )
}

SessionSystemActionDisplay.fragments = {
  root: gql`
    fragment SessionSystemActionDisplay on Action {
      id
      ... on SystemAction {
        text
      }
    }
  `
}

export default SessionSystemActionDisplay;
