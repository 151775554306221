import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ActionDisplay from "./ActionDisplay";
import UserInputErrorDisplay from "./UserInputErrorDisplay";
import {UserInputAction} from "../../../../schema";
import {PlayerReduxState, UserInputDisplayMode} from "../../../../redux/player/types";
import {
  getCurrentAction,
  getCurrentActionDisplayMode,
  getCurrentActionPhraseMode,
  isErrorState
} from "../../../../redux/player/selectors";
import isEqual from "lodash/isEqual";
import Phrase, {PhraseVariant} from "../../../Phrase";
import UserInputHelpText from "../../UserInputHelpText";

const UserInputActionDisplay = () => {
  const {t} = useTranslation();
  const {
    action, isError, phraseMode, displayMode
  } = useSelector(UserInputActionDisplay.selector, isEqual);

  if (isError) {
    return (<UserInputErrorDisplay/>)
  }

  return (
    <ActionDisplay
      title={
        <UserInputHelpText action={action} displayMode={displayMode}/>
      }
    >
      {action.freeSpeech && (
        <span>{t("player.userInputActionFreeSpeechTheme")}</span>
      )}

      {displayMode === UserInputDisplayMode.EXPECTED_TEXT ? (
        <Phrase
          text={
            action.expectedText
            .replace("<entername>", t("player.enterName"))
            .replace("<name>", t("player.sessionUserName"))
          }
          variant={phraseMode ?? PhraseVariant.DEFAULT}
        />
      ) : (
        <Phrase
          text={action.hintText}
          variant={PhraseVariant.DEFAULT}
        />
      )}
    </ActionDisplay>
  )
};

export default UserInputActionDisplay;

UserInputActionDisplay.selector = (state: PlayerReduxState) => ({
  action: getCurrentAction(state) as UserInputAction,
  isError: isErrorState(state),
  phraseMode: getCurrentActionPhraseMode(state),
  displayMode: getCurrentActionDisplayMode(state)!
});
