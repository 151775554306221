import React, {forwardRef, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {getTextWithStressMarks} from "../../../Phrase";
import {parseMimeType, PlayableMediaType} from "../../../../utils";

import {AvatarAction} from "../../../../schema";
import {FSImageRef} from "../../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../../ui/fullscreen/video";

import Content from "./Content";
import {useDispatch} from "react-redux";
import setCurrentBackground from "../../../../redux/player/actions/setCurrentBackground";
import {passAction} from "../../../../redux/player/actions";

type props = {
  avatarName?: string
  action: AvatarAction
  openFullscreenMedia?: () => void
}

type AvatarActionContentType = React.ForwardRefExoticComponent<
  props & React.RefAttributes<FSImageRef | FSVideoRef>
>

const AvatarActionContent: AvatarActionContentType = forwardRef(({avatarName, action, openFullscreenMedia}, ref) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const text = useMemo(() => getTextWithStressMarks(action.text), [action.text])

  const {media, mediaType, mediaViewRequired, setMediaAsBackground} = action;

  if (action.setMediaAsBackground) {
    dispatch(setCurrentBackground())
    if (!text) {
      dispatch(passAction())
    }
  }

  const mediaPreview = useMemo(() => {
    if (media && mediaType && (mediaViewRequired !== undefined)) {
      const mimeType = parseMimeType(mediaType).type;

      const mediaProps = {
        onClick: openFullscreenMedia,
        src: media,
        ref,
      }

      switch (mimeType) {
        case PlayableMediaType.IMAGE:
          return (
            <Content.Image {...mediaProps} alt={text} openable={!!mediaViewRequired}/>
          )
        case PlayableMediaType.VIDEO:
          return (
            <Content.Video {...mediaProps}/>
          )
        default:
          console.error(`Unsupported media type in avatar content: ${mimeType}`)
      }
    }
  }, [media, mediaType, mediaViewRequired, text, openFullscreenMedia, ref]);

  return (
    <Content
      title={t("player.avatarActionTitle", {
        name: avatarName ?? t("common.avatarName")
      })}
      variant="avatar"
      translate="no"
    >
      {(text && mediaPreview) ? (
        <Content.Row>
          {text}
          {!setMediaAsBackground && mediaPreview}
        </Content.Row>
      ) : (
        <>
          {text}
          {!setMediaAsBackground && mediaPreview}
        </>
      )}
    </Content>
  )
});

export default AvatarActionContent;
