import React, {useMemo} from "react";
import Select from "../../../ui/select";
import {AvatarGesture} from "../../../schema";
import {useTranslation} from "react-i18next";

type props = {
  name?: string,
  value?: AvatarGesture,
  onChange?: (e: any) => void
}

export default function AvatarGestureSelect({name, value, onChange}: props) {
  const {t} = useTranslation();

  const choices = useMemo(() => {
    const choices = [
      {
        value: AvatarGesture.IDLE,
        label: t("types.AvatarGesture.IDLE"),
      },
      {
        value: AvatarGesture.POINT_RIGHT,
        label: t("types.AvatarGesture.POINT_RIGHT"),
      },
      {
        value: AvatarGesture.SUFFERING,
        label: t("types.AvatarGesture.SUFFERING"),
      },
      {
        value: AvatarGesture.AGREEMENT,
        label: t("types.AvatarGesture.AGREEMENT"),
      },
      {
        value: AvatarGesture.RIGHTLY,
        label: t("types.AvatarGesture.BULLS_EYE"),
      },
      {
        value: AvatarGesture.NARRATIVE,
        label: t("types.AvatarGesture.NARRATIVE"),
      },
      {
        value: AvatarGesture.NO,
        label: t("types.AvatarGesture.NO"),
      },
      {
        value: AvatarGesture.THUMBS_UP,
        label: t("types.AvatarGesture.THUMBS_UP"),
      },
      {
        value: AvatarGesture.CONFUSION,
        label: t("types.AvatarGesture.DONT_KNOW"),
      },
      {
        value: AvatarGesture.STRONG_NO,
        label: t("types.AvatarGesture.STRONG_NO"),
      },
      {
        value: AvatarGesture.ANNOYANCE,
        label: t("types.AvatarGesture.ANNOYANCE"),
      },
      {
        value: AvatarGesture.APATHY,
        label: t("types.AvatarGesture.APATHY"),
      },
      {
        value: AvatarGesture.ASSURANCE,
        label: t("types.AvatarGesture.ASSURANCE"),
      },
      {
        value: AvatarGesture.DESPONDENCY,
        label: t("types.AvatarGesture.DESPONDENCY"),
      },
      {
        value: AvatarGesture.DISCUSSION,
        label: t("types.AvatarGesture.DISCUSSION"),
      },
      {
        value: AvatarGesture.GREETINGS,
        label: t("types.AvatarGesture.GREETINGS"),
      },
      {
        value: AvatarGesture.REGRET,
        label: t("types.AvatarGesture.REGRET"),
      },
      {
        value: AvatarGesture.TRUST,
        label: t("types.AvatarGesture.TRUST"),
      },
    ]

    return choices;
  }, [t]);

  return (
    <Select name={name} value={value} onChange={onChange}>
      {choices.map((choice) => (
        <option
          key={choice.value}
          value={choice.value}
        >{choice.label}</option>
      ))}
    </Select>
  )
}
