import {gql, useMutation} from "@apollo/client";
import {useCallback} from "react";
import {analyticsSendEvent, toEventData} from "../libs/analytics";
import {MutationSendFeedbackArgs} from "../schema";

type ID = string | null

type feedbackData = {
  location?: string
  url?: string
  message: string
  attachment?: File
  contact?: string

  errorReason?: string
  sessionId?: ID
  actionId?: ID
  externalUserId?: ID
}

function useSendFeedbackMutation() {
  return useMutation<{ result: boolean }, MutationSendFeedbackArgs>(sendFeedbackMutation);
}

const sendFeedbackMutation = gql`
  mutation PlayerSendFeedbackMutation(
    $message: String!, $attachment: Upload, $sessionId: ID, $location: String, $errorReason: String, $actionId: ID,
    $contact: String, $externalUserId: String, $url: String
  ) {
    result: sendFeedback(
      message: $message, attachment: $attachment, sessionId: $sessionId, location: $location, errorReason: $errorReason
      actionId: $actionId, contact: $contact, externalUserId: $externalUserId, url: $url
    )
  }
`

export function useSendBugReport() {
  const [sendFeedbackMutation] = useSendFeedbackMutation();
  return useCallback((data: feedbackData) => (
    new Promise<{ result: boolean }>((resolve, reject) => {
      try {
        analyticsSendEvent("sendBugReport", toEventData(data));
      } catch (e) {
        console.warn(e)
      }
      sendFeedbackMutation({
        variables: {
          ...data,
          url: window.location.href
        }
      }).then((data) => {
        if (data.data?.result === true) {
          resolve(data.data)
        } else {
          reject(data.data)
        }
      }).catch((e) => {
        reject(e)
      })
    })
  ), [sendFeedbackMutation]);
}
