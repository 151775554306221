import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useModal} from "../ModalProvider";

import Widget from "../../ui/widget";
import Button from "../../ui/button";
import BugReportModal from "./BugReportModal";

import classes from "./ProblemsWidget.module.css"

type props = Omit<React.ComponentProps<typeof Widget>, "onBugReportSend" | "as" | "children"> & {
  onBugReportSend: React.ComponentProps<typeof BugReportModal.Content>["onBugReportSend"],
  onClose?: () => void,
}

export function ProblemsWidget({title, onClose, onBugReportSend, ...props}: props) {
  const {t} = useTranslation();

  const {add: addModal} = useModal();

  const onSendButtonClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    const sendBugReport: NonNullable<typeof onBugReportSend> = (...args) => {
      onBugReportSend && onBugReportSend(...args)
      modal.remove();
      onClose && onClose();
    }

    const modal = addModal({
      header: <BugReportModal.Header/>,
      content: (
        <BugReportModal.Content
          onBugReportSend={sendBugReport}
        />
      ),
      stopCloseOnOuterClick: true,
    })
  }, [addModal, onBugReportSend, onClose]);

  return (
    <Widget
      className={classes.root}
      showCloseButton
      onCloseButtonClick={onClose}
      {...props}
    >
      <Widget.Title>{t("components.system.ProblemsWidget.title")}</Widget.Title>
      <Widget.Description>{t("components.system.ProblemsWidget.description")}</Widget.Description>
      <Button className={classes.sendButton} onClick={onSendButtonClick} color="primary">
        {t("components.system.ProblemsWidget.sendReport")}
      </Button>
    </Widget>
  )
}

export default function WithProblemsWidget({children, showWidget, ...props}: props & {
  children: React.ReactNode,
  showWidget?: boolean
}) {
  return (
    <>
      {children}
      {showWidget && (
        <ProblemsWidget {...props}/>
      )}
    </>
  )
}