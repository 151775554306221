import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {copyTextToClipboard} from "../../utils";
import {useCurrentUser} from "../../App.context";
import {useModal} from "../ModalProvider";

import Button from "../../ui/button";
import DropdownList from "../../ui/dropdownlist";
import {CheckmarkIcon, ShareIcon} from "../../ui/icons";
import WithTooltip from "../../ui/tooltip";
import CopyQRCode from "../CopyQRCode";
import UTMCreateModal from "./UTMCreateModal";

import classes from "./ShareButton.module.css";

export type props = {
  value: string
  name?: string
  className?: string
  title?: string
  helpText?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function ShareButton(
  {value, name, className, title, helpText: copyHelpText, disabled, onClick}: props
) {
  const {t} = useTranslation();

  const user = useCurrentUser();

  const [isCopied, setCopied] = useState(false);

  const onShareClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    copyTextToClipboard(value).then(() => setCopied(true))
    onClick && onClick(e);
  }, [value, onClick])

  const {add: addModal} = useModal();

  const onCreteUTMClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    addModal({
      header: <UTMCreateModal.Header/>,
      content: (
        <UTMCreateModal.Content
          value={value}
        />
      ),
      size: "xl",
      stopCloseOnOuterClick: true
    })
  }, [value, addModal]);

  const content = useMemo(() => (
    <>
      {isCopied && (
        <span className={classes.info}>
          <span className={classes.copied}>
            {t("components.ShareButton.textCopied")} <CheckmarkIcon className={classes.success}/>
          </span>
          {t("components.ShareButton.copyHelp")}
        </span>
      )}
      {user?.isStaff && (
        <Button className={classes.createUTM} color="brand" onClick={onCreteUTMClick}>
          {t("components.ShareButton.createUTM")}
        </Button>
      )}
      <CopyQRCode className={classes.qr} value={value} fileName={name && `Dailo - ${name}`} qrProps={{size: 192}}/>
    </>
  ), [isCopied, t, value, name, onCreteUTMClick, user?.isStaff])

  const helpText = useMemo(() =>
    isCopied
      ? t("components.ShareButton.copied")
      : copyHelpText,
  [t, isCopied, copyHelpText])

  return (
    <DropdownList
      rounded
      notCloseOnClick
      dropShadow
      className={classes.dropdownMenu}
      placement="bottom-end"
      offset={[0, 8]}

      content={content}
    >
      <WithTooltip className={className} placement='top' helpText={helpText}>
        <Button className={classes.shareButton} onClick={onShareClick} disabled={disabled}>
          <ShareIcon className={classes.shareIcon} fontSize={16}/>
          {title}
        </Button>
      </WithTooltip>
    </DropdownList>
  )
}
