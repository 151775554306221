import React from "react";
import {useTranslation} from "react-i18next";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Button from "../../ui/button";
import LoginLayout from "../../components/login/LoginLayout";
import ReactLink from "../../components/ReactLink";
import {reverse} from "../../routing";
import {useMutation} from "@apollo/client";

import classes from "./forgot-password.module.css";
import {useFormState} from "../../hooks/useFormState";
import Input from "../../ui/input";
import {resetPasswordMutation} from "./forgot-password.graphql";
import {getLanguageCode} from "../../i18n";

export default function LoginForgotPasswordScene() {
  const {t, i18n} = useTranslation();

  const [mutate, result] = useMutation(resetPasswordMutation)

  const isLoading = result.loading;
  const isSubmitted = result.data && !!result.data.ok;
  const isFailed = result.data && !result.data.ok;

  const formState = useFormState({
    initialValues: {
      email: ""
    },
    preventDefault: true,
    onSubmit: (data) => {
      mutate({
        variables: {
          email: data.email,
          lang: getLanguageCode(i18n.language)
        }
      }).catch(console.error)
    }
  })

  return (
    <>
      <ReactDocumentTitle title={t("loginForgotPassword.title")}/>

      <LoginLayout data-testid="loginForgotPasswordScene" className={classes.root}>
        {!isSubmitted ? (
          <>
            <h1 className={classes.h1}>{t("loginForgotPassword.h1")}</h1>
            <p className={classes.p}>{t("loginForgotPassword.message")}</p>

            <form method="post" onSubmit={formState.submitHandler}>
              <Input
                disabled={isLoading}
                required
                name="email"
                type="email"
                placeholder="E-mail address"
                onChange={formState.changeHandler}
              />

              {isFailed && (
                <div className={classes.errorMsg}>
                  {t("loginForgotPassword.emailNotRegistered")}
                </div>
              )}

              <div className={classes.buttonRow}>
                <Button disabled={isLoading} color="primary" type="submit">{t("common.submit")}</Button>
                <Button as={ReactLink} href={reverse("login")}>{t("common.back")}</Button>
              </div>
            </form>
          </>
        ) : (
          <>
            <h1 className={classes.h1}>{t("loginForgotPassword.sentH1")}</h1>
            <p className={classes.p}>{t("loginForgotPassword.sentMessage")}</p>

            <div className={classes.buttonRow}>
              <Button as={ReactLink} href={reverse("login")}>
                {t("common.back")}
              </Button>
            </div>
          </>
        )}
      </LoginLayout>
    </>
  )
}
