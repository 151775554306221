import {ReactNode, useCallback, useMemo, useState} from "react";
import {useHistory} from "react-router";
import {Trans, useTranslation} from "react-i18next";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Input from "../../ui/input";
import Button from "../../ui/button";
import {TERMS_OF_USE_LINK, USER_AGREEMENT_LINK} from "../../settings";
import {reverse} from "../../routing";
import {useFormState} from "../../hooks/useFormState";
import LoginLayout from "../../components/login/LoginLayout";
import classes from "./login.module.css";
import {useSignUpMutation} from "./login.graphql";
import ReactLink from "../../components/ReactLink";
import {analyticsSendEvent} from "../../libs/analytics";
import {SignUpStatus} from "../../schema";
import {getLanguageCode} from "../../i18n";
import cn from "classnames";

export default function SignUpScene() {
  const {t, i18n} = useTranslation();
  const language = getLanguageCode(i18n.language)
  const history = useHistory();
  const [applySignUpMutation] = useSignUpMutation();

  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const inviteToLesson = params.get("_invited");
  const accessKey = params.get("_access_key");
  const inviteToAccount = params.get("_aid");
  const demoAccess = params.get("_demo_access");

  const onLoginClick = useCallback(() => {
    if (demoAccess) {
      history.push(reverse("login"))
    } else {
      history.push(reverse("login")
      + "?_invited=" + inviteToLesson + "&_access_key=" + accessKey + "&_aid=" + inviteToAccount);
    }
  }, [history, inviteToLesson, accessKey, inviteToAccount, demoAccess])

  const onForgotPasswordClick = useCallback(() => {
    analyticsSendEvent("useForgotPasswordLink");
  }, []);

  const [signUpStatusDisplay, setSignUpStatusDisplay] = useState<ReactNode | undefined>(<div/>);

  const loginForm = useFormState({
    initialValues: {
      fullname: "",
      username: "",
      password: "",
      repeatPassword: "",
      languageCode: language,
    },

    preventDefault: true,
    onSubmit: (values) => {
      applySignUpMutation({
        variables: {
          username: values.username,
          fullname: values.fullname,
          password: values.password,
          repeatPassword: values.repeatPassword,
          languageCode: values.languageCode,
          accountId: inviteToAccount,
          isDemo: demoAccess ? true : false,
        }
      }).then(({data}) => {

        const signUpStatus = data?.status;
        let text = "";

        switch (signUpStatus) {
          case SignUpStatus.SUCCESS:
            text = t("signup.status.success")
            break;
          case SignUpStatus.ALREADY_REGISTERED:
            text = t("signup.status.alreadyRegistered")
            break;
          case SignUpStatus.SHORT_PASSWORD:
            text = t("signup.status.shortPassword")
            break;
          case SignUpStatus.SHORT_USERNAME:
            text = t("signup.status.shortUsername")
            break;
          case SignUpStatus.PASSWORDS_ARE_NOT_EQUAL:
            text = t("signup.status.passwordsAreNotEqual")
            break;
          case SignUpStatus.UNKNOWN_ERROR:
          default:
            text = t("signup.status.unknownError")
            break;
        }

        if (signUpStatus === SignUpStatus.SUCCESS) {
          if (demoAccess) {
            analyticsSendEvent("signUpDemo");
            setSignUpStatusDisplay(
              <div className={cn(classes.extraRow, classes.success)}>
                {text} {t("signup.emailActivationNote")}
              </div>
            )
          } else {
            analyticsSendEvent("signUp");
            setSignUpStatusDisplay(<div className={cn(classes.extraRow, classes.success)}>{text}</div>)
            setTimeout(() => {
              history.push(reverse("login")
                + "?_invited=" + inviteToLesson + "&_access_key=" + accessKey + "&_aid=" + inviteToAccount);
            }, 1500)
          }
        } else if (signUpStatus === SignUpStatus.ALREADY_REGISTERED) {
          setSignUpStatusDisplay(
          <div className={cn(classes.extraRow, classes.error)}>
            {text}
            <div className={classes.extraRow}>
              <ReactLink
                className={classes.forgotPasswordLink}
                onClick={onForgotPasswordClick}
                href={reverse("loginForgotPassword")}
              >{t("login.forgotPassword")}</ReactLink>
            </div>
          </div>)
        } else {
          setSignUpStatusDisplay(<div className={cn(classes.extraRow, classes.error)}>{text}</div>)
        }
      });
    }
  });

  return (
    <>
      <ReactDocumentTitle title={t("signup.title")}/>

      <LoginLayout data-testid="signupScene" className={classes.root}>
        <h1 className={classes.h1}>{t("signup.h1")}</h1>

        <div className={classes.firstRow}>
          <ReactLink
            className={classes.firstRowLink}
            onClick={onLoginClick}
          >{t("signup.login")}</ReactLink>
        </div>

        <form onSubmit={loginForm.submitHandler} method="post" className={classes.loginForm}>
        <Input
            required
            pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$|[a-zA-Z0-9._-]{4,}$"
            title="mail@example.com"
            className={classes.loginFormInput}
            name='username'
            value={loginForm.values.username}
            onChange={loginForm.changeHandler}
            placeholder={inviteToLesson && accessKey ? t("login.usernameAlt") : t("login.username")}
          />

          <Input
            required
            className={classes.loginFormInput}
            name='fullname'
            value={loginForm.values.fullname}
            onChange={loginForm.changeHandler}
            placeholder={t("singup.name")}
          />

          <Input
            required
            className={classes.loginFormInput}
            name='password'
            value={loginForm.values.password}
            onChange={loginForm.changeHandler}
            type="password"
            placeholder={t("signup.password")}
          />

          <Input
            required
            className={classes.loginFormInput}
            name='repeatPassword'
            value={loginForm.values.repeatPassword}
            onChange={loginForm.changeHandler}
            type="password"
            placeholder={t("signup.repeatPassword")}
          />

          <div className={classes.loginFormSubmit}>
            <div className={classes.loginFormAcceptTerms}>
              <Trans i18nKey="login.acceptTerms" components={[
                <a href={USER_AGREEMENT_LINK}> </a>,
                <a href={TERMS_OF_USE_LINK}> </a>,
              ]}/>
            </div>
            <div className={classes.loginFormSubmitBtn}>
              <Button
                variant="morph"
                color="brand"
                size='l'
              >
                {t("signup.submit")}
              </Button>
            </div>
          </div>
        </form>
        <div className={classes.extraRow}>
          {signUpStatusDisplay}
        </div>
      </LoginLayout>
    </>
  )
}
