import React, {useCallback, useEffect, useState} from "react";
import Button from "./button";
import Input from "./input";
import {ChevronBackIcon, ChevronForwardCircleIcon, ChevronForwardIcon} from "./icons";
import IconButton from "./iconbutton";

import cn from "classnames";
import classes from "./paginator.module.css";
import useVisibility from "../hooks/useVisibility";

type props = React.ComponentProps<"div"> & {
  currentPage: number,
  totalPages: number,
  onPageChange?: (page: number) => void,
}

export default function Paginator({className, currentPage, totalPages, onPageChange, ...props}: props) {
  const [localValue, setLocalValue] = useState<number>(currentPage);
  const [hasFocus, focus, blur] = useVisibility();

  const isFirstPage = (currentPage === 1);
  const isLastPage = (totalPages === currentPage);

  useEffect(() => setLocalValue(currentPage), [currentPage])

  const onInputChange = useCallback((e) => {
    setLocalValue(parseInt(e.target.value));
  }, [setLocalValue]);

  const onInputKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      try {
        onPageChange && onPageChange(parseInt(e.target.value) || 1);
      } catch (e) {
        setLocalValue(currentPage);
      }
    }
  }, [currentPage, onPageChange]);

  const onPrevClick = useCallback(() => {
    onPageChange && onPageChange(currentPage - 1);
  }, [currentPage, onPageChange]);

  const onNextClick = useCallback(() => {
    onPageChange && onPageChange(currentPage + 1);
  }, [currentPage, onPageChange]);

  const changeToLocalPage = useCallback(() => {
    onPageChange && onPageChange(localValue)
  }, [localValue, onPageChange]);

  const editState = (localValue !== currentPage) || hasFocus;

  return (
    <div className={cn(classes.root, className)} {...props}>
      <IconButton
        icon={ChevronBackIcon}
        className={classes.iconButton}
        disabled={isFirstPage}
        onClick={!isFirstPage ? onPrevClick : undefined}
      />

      <div className={classes.inputWrapper}>
        <Input
          type="number"
          className={classes.input}
          min={1}
          max={totalPages}
          placeholder={currentPage.toString()}
          value={localValue}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onFocus={focus}
          onBlur={blur}
        />
        {editState && (
          <Button className={classes.acceptButton} onClick={changeToLocalPage} disabled={!localValue}>
            <ChevronForwardCircleIcon/>
          </Button>
        )}
      </div>

      {!editState && (
        <span className={classes.total}>/ {totalPages}</span>
      )}

      <IconButton
        icon={ChevronForwardIcon}
        className={classes.iconButton}
        disabled={isLastPage}
        onClick={!isLastPage ? onNextClick : undefined}
      />
    </div>
  )
}
