import React from "react"
import {getTextFromAction} from "../../redux/player/utils"
import Button from "../../ui/button"
import {ArrowForwardIcon} from "../../ui/icons"
import {NavigationListItemProps} from "./NavigationList"

import classes from "./NavigationListElement.module.css"
import cn from "classnames"

export default function NavigationListElement({action, ...props}: NavigationListItemProps) {
  const {disabled} = props;

  return (
    <div className={classes.root}>
      <span className={cn(classes.text, {[classes.disabled]: disabled})}>
        {getTextFromAction(action)}
      </span>
      <Button
        className={classes.button}
        color="primary"
        disabledColor="secondary"
        variant="outlined"
        {...props}
      >
        <ArrowForwardIcon/>
      </Button>
    </div>
  )
}