import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router";
import {Trans, useTranslation} from "react-i18next";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import GoogleSignInButton from "../../components/login/GoogleSignInButton";
import Input from "../../ui/input";
import Button from "../../ui/button";
import {TERMS_OF_USE_LINK, USER_AGREEMENT_LINK} from "../../settings";
import {reverse} from "../../routing";
import {useFormState} from "../../hooks/useFormState";
import LoginLayout from "../../components/login/LoginLayout";
import classes from "./login.module.css";
import {useLoginMutation, useTryInviteUserToLessonMutation} from "./login.graphql";
import useAutoResetState from "../../hooks/useAutoResetState";
import {CloseIcon} from "../../ui/icons";
import Animated from "../../ui/animated";
import ReactLink from "../../components/ReactLink";
import {analyticsSendEvent} from "../../libs/analytics";
import {keepNextUrl, redirectToNextUrl} from "./common";
import {useCurrentAccount, useCurrentUser, useSession} from "../../App.context";
import {useSelectAccountMutation} from "./login-select-account.graphql";
import {isInteger} from "lodash";

export default function LoginScene() {
  const {t} = useTranslation();
  const history = useHistory();

  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;
  const {has: hasSession, set: setSessionId} = useSession();
  const [applyInviteUserToLessonMutation] = useTryInviteUserToLessonMutation()
  const [selectAccount] = useSelectAccountMutation()

  const [urlError, setUrlError] = useState<string | null>(null);
  const logoutHandler = useCallback(() => {
    setSessionId(undefined);
    window.location.href = window.location.origin + reverse("login");
  }, [setSessionId]);

  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const inviteToLesson = params.get("_invited");
  const accessKey = params.get("_access_key");
  const inviteToAccount = params.get("_aid");

  useEffect(() => {
    setUrlError(params.get("error"));
    if (userId && accountId) {
      redirectToNextUrl(history);
    } else if (userId) {
      if (!accountId) {
        if (inviteToLesson && accessKey) {
          history.push(reverse("loginSelectAccount") + "?_invited=" + inviteToLesson + "&_access_key=" + accessKey)
        } else {
          history.push(keepNextUrl(history, reverse("loginSelectAccount")));
        }
        return;
      }
    }
  }, [history, userId, accountId, inviteToLesson, accessKey, params]);

  const [error, setError] = useAutoResetState<boolean>(false, 1000);

  const [login, {loading: loginLoading}] = useLoginMutation({
    update: ((cache, result) => {
      setSessionId(result?.data?.login?.sessionId);
    })
  });

  const loginForm = useFormState({
    initialValues: {
      username: "",
      password: "",
    },

    preventDefault: true,
    onSubmit: (values) => {
      const usernameAsNumber = parseInt(values.username, 10)
      login({
        variables: {
          email: inviteToAccount && isInteger(usernameAsNumber)
            ? values.username + "_aid=" + inviteToAccount
            : values.username,
          password: values.password,
        }
      }).then(({data}) => {
        const userId = data?.login?.user?.id;
        const accountId = data?.login?.account?.id;

        if (userId) {
          analyticsSendEvent("loginWithCredentials");
          if (inviteToLesson && accessKey) {
            applyInviteUserToLessonMutation({
              variables: {
                lessonId: inviteToLesson,
                accessKey: accessKey ? accessKey : ""
              }
            }).then((accountId) => {
              if (accountId) {
                selectAccount({variables: {accountId: accountId.data?.accountId}}).then(() => {
                  history.push(reverse("playerPlay", {id: inviteToLesson}));
                })
              }
            })
          }
        }

        if (!userId) {
          setError(true);
          return;
        }

        if (accountId) {
          if (inviteToLesson && accessKey) {
            history.push(reverse("playerPlay", {id: inviteToLesson}));
          } else {
            redirectToNextUrl(history);
          }
        }
      });
    }
  });

  const onForgotPasswordClick = React.useCallback(() => {
    analyticsSendEvent("useForgotPasswordLink");
  }, []);

  const onSignUpClick = useCallback(() => {
    history.push(reverse("signup")
      + "?_invited=" + inviteToLesson + "&_access_key=" + accessKey + "&_aid=" + inviteToAccount);
  }, [history, inviteToLesson, inviteToAccount, accessKey])

  const errorMessagesMap = useMemo(() => new Map<string, string>([
    ["provider_denied", t("login.error.provider.denied")],
    ["provider_no_access_token", t("login.error.provider.noAccessToken")],
    ["no_provider", t("login.error.noProvider")],
    ["session_inactive", t("login.error.sessionInactive")]
  ]), [t])

  if (urlError) {
    return (
      <>
        <ReactDocumentTitle title={t("login.error.title")}/>

        <LoginLayout data-testid="loginScene" className={classes.root}>
          <h1 className={classes.h1}>{t("login.error.h1")}</h1>
          <p>
            {errorMessagesMap.has(urlError) ? errorMessagesMap.get(urlError) : t("login.error.default")}
          </p>
          {hasSession() && (
            <a href="/#" onClick={logoutHandler}>
              {t("components.Header.logout")}
            </a>
          )}
        </LoginLayout>
      </>
    )
  }

  return (
    <>
      <ReactDocumentTitle title={t("login.title")}/>

      <LoginLayout data-testid="loginScene" className={classes.root}>
        <h1 className={classes.h1}>{t("login.h1")}</h1>
        {inviteToLesson && accessKey && (
          <div className={classes.firstRow}>
            <ReactLink
              className={classes.firstRowLink}
              onClick={onSignUpClick}
              href={reverse("signup")
                + "?_invited=" + inviteToLesson + "&_access_key=" + accessKey + "&_aid=" + inviteToAccount}
            >{t("login.signUp")}</ReactLink>
          </div>
        )}

        {!(inviteToLesson && accessKey) && (
          <div className={classes.social}>
            <GoogleSignInButton/>
          </div>
        )}

        <form onSubmit={loginForm.submitHandler} method="post" className={classes.loginForm}>
          <Input
            required
            pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$|[a-zA-Z0-9._-]{4,}$"
            title={t("login.usernameTip")}
            disabled={loginLoading}
            className={classes.loginFormInput}
            name='username'
            value={loginForm.values.username}
            onChange={loginForm.changeHandler}
            placeholder={inviteToLesson && accessKey ? t("login.usernameAlt") : t("login.username")}
          />

          <Input
            required
            disabled={loginLoading}
            className={classes.loginFormInput}
            name='password'
            value={loginForm.values.password}
            onChange={loginForm.changeHandler}
            type="password"
            placeholder={t("login.password")}
          />

          <div className={classes.loginFormSubmit}>
            <div className={classes.loginFormAcceptTerms}>
              <Trans i18nKey="login.acceptTerms" components={[
                <a href={USER_AGREEMENT_LINK}> </a>,
                <a href={TERMS_OF_USE_LINK}> </a>,
              ]}/>
            </div>
            <div className={classes.loginFormSubmitBtn}>
              {!error ? (
                <Button
                  disabled={loginLoading}
                  variant="morph"
                  color="primary"
                  size='l'
                >
                  {t("login.submit")}
                </Button>
              ) : (
                <Animated type="shake">
                  <Button disabled type="button" variant="morph" color="danger" size='l'><CloseIcon/></Button>
                </Animated>
              )}
            </div>
          </div>
          <div className={classes.extraRow}>
            <ReactLink
              className={classes.forgotPasswordLink}
              onClick={onForgotPasswordClick}
              href={reverse("loginForgotPassword")}
            >{t("login.forgotPassword")}</ReactLink>
          </div>
        </form>
      </LoginLayout>
    </>
  )
}
