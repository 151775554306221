import {useEffect} from "react";
import {useHistory, useParams} from "react-router";
import {reverse} from "../../routing";
import {useLoginWithProviderMutation} from "./login-with-provider.graphql";
import {analyticsSendEvent} from "../../libs/analytics";
import {useSession} from "../../App.context";
import {redirectToNextUrl} from "./common";

export default function LoginWithProviderScene() {
  const history = useHistory();

  const {provider}: {
    provider: string
  } = useParams();

  const [login] = useLoginWithProviderMutation({
    update: ((cache, result) => {
      setSessionId(result?.data?.login?.sessionId);
    })
  });

  const {set: setSessionId} = useSession();

  useEffect(() => {
    if (provider === "google") {
      const params = new URLSearchParams(window.location.hash.substr(1));
      const error = params.get("error");
      const state = params.get("state");

      if (error) {
        history.push(reverse("login") + "?error=provider_denied")
        return;
      }

      const accessToken = params.get("access_token");

      if (!accessToken) {
        history.push(reverse("login") + "?error=provider_no_access_token" + state ? `$${state}` : "")
        return;
      }

      login({
        variables: {
          provider: "google",
          token: accessToken
        }
      })
        .then(({data}) => {
          const userId = data?.login?.user?.id;
          const accountId = data?.login?.account?.id;

          if (userId) {
            analyticsSendEvent("loginWithProvider", {
              provider: "google"
            });
          }

          if (!userId) {
            history.push(reverse("login") + (state ?? ""));
          }

          if (!accountId) {
            history.push(reverse("loginSelectAccount") + (state ?? ""));
            return;
          }

          if (userId && accountId) {
            const params = state ? new URLSearchParams(state) : undefined;
            redirectToNextUrl(history, params?.get("_next"));
          }
        })
        .catch((err) => {
          history.push(reverse("login") + "?error=internal");
        });

      return;
    }

    history.push(reverse("login") + "?error=no_provider")
  }, [history, provider, login, setSessionId]);

  return null;
}
