import {useTranslation} from "react-i18next";

import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button"
import Input from "../../ui/input";

import Spinner from "../../ui/spinner";

import {useRequestLicenses} from "./PurchaseLicensesDetailsModal.graphql";
import classes from "./PurchaseLicensesDetailsModal.module.css";
import {analyticsSendEvent} from "../../libs/analytics";
import {CustomerType, LanguageCode} from "../../schema";
import Select from "../../ui/select";
import Textarea from "../../ui/textarea";

type props = {
  count: number,
  duration: number,
  languageCode: LanguageCode,
  onClose?: () => void
}

type formState = {
  customerType: CustomerType,
  name?: string,
  legalAddress?: string,
  tin?: number,
  trrc?: number,
  phoneNumber?: string,
  correspondenceAddress?: string,
}

function PurchaseLicensesDetailsModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.PurchaseLicensesDetailsModal.title")}</span>;
}

function PurchaseLicensesDetailsModalContent({count, duration, languageCode, onClose}: props) {
  const {t} = useTranslation();
  const [mutate, mutationResult] = useRequestLicenses();

  const formState = useFormState<formState>({
    initialValues: {
      customerType: CustomerType.LEGAL_ENTITY,
      name: "",
      legalAddress: "",
      tin: undefined,
      trrc: undefined,
      phoneNumber: undefined,
      correspondenceAddress: "",
    },
    preventDefault: true,
    onSubmit: (values) => {
      analyticsSendEvent("submitBuyLicensesOrder", {
      });

      mutate({
        variables: {
          count: count,
          duration: duration,
          languageCode: languageCode,
          customerType: values.customerType,
          name: values.name,
          legalAddress: values.legalAddress,
          tin: values.tin,
          trrc: values.trrc,
          phoneNumber: values.phoneNumber,
          correspondenceAddress: values.correspondenceAddress,
        }
      });
    }
  });

  if (mutationResult.loading) {
    return (
      <div className={classes.root}>
        <Spinner className={classes.spinner}/>
        <div className={classes.spinnerTitle}>{t("common.loading")}</div>
      </div>
    )
  }

  if (mutationResult.data) {
    return (
      <div className={classes.root}>
        <div className={classes.message}>{t("components.PurchaseModal.successMessage")}</div>
        <Button onClick={onClose} size='l' color="success">
          {t("common.ok")}
        </Button>
      </div>
    )
  }

  if (mutationResult.error) {
    return (
      <div className={classes.root}>
        <p className={classes.message}>{t("components.PurchaseModal.errorMessage")}</p>
        <Button onClick={onClose} size='l' color="danger">
          {t("common.ok")}
        </Button>
      </div>
    )
  }


  return (
    <form className={classes.root} method='post' onSubmit={formState.submitHandler}>
      <div className={classes.row}>
        <p className={classes.helpText}>{t("components.PurchaseLicensesDetailsModal.customerTypeHelp")}</p>
        <Select name="customerType" value={formState.values.customerType} onChange={formState.changeHandler}>
          <option value={CustomerType.LEGAL_ENTITY}>
            {t("types.PurchaseLicensesDetailsModal.customerType.LEGAL_ENTITY")}
          </option>
          <option value={CustomerType.ENTREPRENEUR}>
            {t("types.PurchaseLicensesDetailsModal.customerType.ENTREPRENEUR")}
          </option>
          <option value={CustomerType.INDIVIDUAL}>
            {t("types.PurchaseLicensesDetailsModal.customerType.INDIVIDUAL")}
          </option>
        </Select>
      </div>

      <div className={classes.row}>
        <Input
          name="name"
          displaySize="sm"
          autoComplete="off"
          placeholder={
            formState.values.customerType === CustomerType.INDIVIDUAL
            ? t("components.PurchaseLicensesDetailsModal.placeholders.name")
            : t("components.PurchaseLicensesDetailsModal.placeholders.companyName")
          }
          value={formState.values.name}
          onChange={formState.changeHandler}
        />
      </div>
      {formState.values.customerType !== CustomerType.INDIVIDUAL && (
        <div className={classes.row}>
          <Input
            name="legalAddress"
            displaySize="sm"
            autoComplete="off"
            placeholder={t("components.PurchaseLicensesDetailsModal.placeholders.legalAddress")}
            value={formState.values.legalAddress}
            onChange={formState.changeHandler}
          />
        </div>
      )}

      {formState.values.customerType !== CustomerType.INDIVIDUAL && (
        <div className={classes.row}>
          <Input
            name="tin"
            type='number'
            displaySize="sm"
            autoComplete="off"
            placeholder={t("components.PurchaseLicensesDetailsModal.placeholders.tin")}
            value={formState.values.tin}
            onChange={formState.changeHandler}
          />
        </div>
      )}

      {formState.values.customerType === CustomerType.LEGAL_ENTITY && (
        <div className={classes.row}>
          <Input
            name="trrc"
            type='number'
            displaySize="sm"
            autoComplete="off"
            placeholder={t("components.PurchaseLicensesDetailsModal.placeholders.trrc")}
            value={formState.values.trrc}
            onChange={formState.changeHandler}
          />
        </div>
      )}

      <div className={classes.row}>
        <Input
          name="phoneNumber"
          type='number'
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          displaySize="sm"
          autoComplete="off"
          placeholder={t("components.PurchaseLicensesDetailsModal.placeholders.phoneNumber")}
          value={formState.values.phoneNumber}
          onChange={formState.changeHandler}
        />
      </div>

      <div className={classes.row}>
        <Textarea
          name="correspondenceAddress"
          value={formState.values.correspondenceAddress}
          rows={4}
          placeholder={t("components.PurchaseLicensesDetailsModal.placeholders.correspondenceAddress")}
          onChange={formState.changeHandler}
        />
      </div>

      <div className={classes.row}>
        <Button className={classes.submitBtn} type='submit' color='success' size='l'>
          {t("components.PurchaseLicensesDetailsModal.submit")}
        </Button>
      </div>


    </form>
  )
}

const PurchaseModal = {
  Header: PurchaseLicensesDetailsModalHeader,
  Content: PurchaseLicensesDetailsModalContent
}

export default PurchaseModal;
