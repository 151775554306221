import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from "react";

import classes from "./image.module.css";
import cn from "classnames";

import {ExpandIcon} from "../icons";
import PopupPortal from "../popupportal";
import ReactImage from "../../components/ReactImage";
import AbstractFullscreen, {AbstractFullscreenRef} from ".";

type imgProps = Omit<React.ComponentProps<"img">, "alt" | "src" | "onClick">

type props = Omit<React.ComponentProps<"div">, "ref"> & Pick<React.ComponentProps<"img">, "src"> & {
  alt: string,
  imgProps?: imgProps,
  fullscreenProps?: imgProps,
  adaptive?: boolean,
  useReactImage?: boolean,
}

export type FSImageRef = AbstractFullscreenRef

const FSImage = forwardRef<FSImageRef, props>(({
  className, alt, src, imgProps, fullscreenProps, adaptive, useReactImage, ...props
}, ref) => {
  const {className: imgClassName, ...imgPropsPicked} = imgProps ?? {}
  const {className: fullscreenClassName, ...fullscreenPropsPicked} = {...fullscreenProps} ?? {}

  const fsRef = useRef<AbstractFullscreenRef>(null);

  useImperativeHandle(ref, () => fsRef.current ?? {
    exitFullscreen: () => {},
    enterFullscreen: () => {}
  }, [])

  const onClose = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fsRef.current?.exitFullscreen();
  }, []);

  const previewProps = useMemo(() => ({
    src, alt,
    ...imgPropsPicked
  }), [src, alt, imgPropsPicked])

  const preview = useMemo(() => (
    <>
      {useReactImage ? (
        <ReactImage
          className={cn(classes.reactImg, imgClassName)}
          {...previewProps}
        />
      ) : (
        <img
          className={cn(classes.img, imgClassName)}
          {...previewProps}
          alt={previewProps.alt} // eslint can't see alt in object
        />
      )}
      <ExpandIcon className={cn(classes.expandIcon, {[classes.adaptive]: adaptive})}/>
    </>
  ), [useReactImage, imgClassName, previewProps, adaptive])

  return (
    <AbstractFullscreen
      className={cn(classes.root, className)}
      preview={preview}
      ref={fsRef}
      {...props}
    >
      <PopupPortal
        as="img"
        className={cn(classes.fullscreen, fullscreenClassName)}
        src={src}
        alt={alt}
        onClick={onClose}
        {...fullscreenPropsPicked}
      />
    </AbstractFullscreen>
  )
})

FSImage.defaultProps = {
  useReactImage: true
}

export default FSImage
