import React from "react";
import {useTranslation} from "react-i18next";
import Display from "./Display"
import {useMediaRecorder} from "../../../../providers/mediaRecorder";

export type Props = {}

export default function PermissionDisplay({...props}: Props) {
  const {t} = useTranslation();
  const {hasPermission} = useMediaRecorder();

  if (hasPermission === false) {
    return (
      <Display {...props}>
        <Display.Title title={t("player.noPermissionTitle")}/>
        <Display.Content>{t("player.noCameraOrMicrophonePermissionMessage")}</Display.Content>
      </Display>
    );
  }

  return null;
}
