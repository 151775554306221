import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router";
import {path, reverse} from "../routing";

import PageNotFoundScene from "./system/page-not-found";
import AccountMembersScene from "./account/members";
import AccountMembersGroupsScene from "./account/groups";
import {useCurrentAccount, useCurrentUser} from "../App.context";


const AccountBundle = React.memo(function () {
  const history = useHistory();
  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;

  useEffect(() => {
    if (!userId || !accountId) {
      history.push(reverse("accountMembers"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (!userId || !accountId) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={path("accountMembers")} component={AccountMembersScene}/>
      <Route exact path={path("accountMembersGroups")} component={AccountMembersGroupsScene}/>

      <Route component={PageNotFoundScene}/>
    </Switch>
  )
})

export default AccountBundle;
