import React, {useEffect} from "react";
import {matchPath, Route, Switch, useHistory, useLocation} from "react-router";
import {path} from "../routing";
import PageNotFoundScene from "./system/page-not-found";

import LoginScene from "./login/login";
import LoginWithProviderScene from "./login/login-with-provider";
import LoginForgotPasswordScene from "./login/forgot-password";
import LoginSelectAccountScene from "./login/login-select-account";
import LoginResetPasswordScene from "./login/reset-password";
import {useCurrentAccount, useCurrentUser} from "../App.context";
import {redirectToNextUrl} from "./login/common";
import SignUpScene from "./login/signup";

const LoginBundle = React.memo(function () {
  const location = useLocation();
  const history = useHistory();
  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;
  const matchLoginSelectAccount = matchPath(location.pathname, {
    path: path("loginSelectAccount")
  });

  useEffect(() => {
    if (userId && accountId && !matchLoginSelectAccount) {
      redirectToNextUrl(history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, matchLoginSelectAccount]);

  if (userId && accountId && !matchLoginSelectAccount) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={path("signup")} component={SignUpScene}/>
      <Route exact path={path("login")} component={LoginScene}/>
      <Route exact path={path("loginSelectAccount")} component={LoginSelectAccountScene}/>
      <Route exact path={path("loginForgotPassword")} component={LoginForgotPasswordScene}/>
      <Route exact path={path("loginResetPassword")} component={LoginResetPasswordScene}/>
      <Route exact path={path("loginWithProvider")} component={LoginWithProviderScene}/>

      <Route component={PageNotFoundScene}/>
    </Switch>
  )
});

export default LoginBundle;
