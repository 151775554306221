export const SENTRY_DSN = "https://06b0cc14af5240df8dc1d2d68d421770@o437276.ingest.sentry.io/5452778";

export const DEV = process.env.NODE_ENV === "development";
export const BACKEND = process.env.REACT_APP_BACKEND ?? "";
export const WEBSOCKET_URL = "ws://127.0.0.1:8000/ws"
export const TITLE_SUFFIX = " | Dailo";

export const CONTACT_EMAIL = "info@dailo.co";
export const USER_AGREEMENT_LINK = "https://cerevrum.com/user_agreement";
export const TERMS_OF_USE_LINK = "https://cerevrum.com/gdpr_ru";
export const COPYRIGHT = "© 2024 Dailo";

export const GOOGLE_OAUTH_CLIENT_ID = "544290788936-4e002jmm86ftnbs0619bashrfm0146oe.apps.googleusercontent.com";

export const PHRASE_MAX_LENGTH = 200;
export const INPUT_PHRASE_MAX_LENGTH = 140;
export const AMPLITUDE_API_KEY = "3e2a7dd4751383db009fd14124698d93";
export const HOTJAR_SITE_ID = "2071819";

export const TABLE_ROWS_PER_PAGE = 25;
export const MIN_PRELOADED_AUDIOS = 3;
export const ERROR_DEFAULT_DURATION = 2000;
export const ERROR_EXTENDED_DURATION = 4000;

export const MAX_LESSON_LENGTH = 100;
export const MAX_MAIN_SCENARIO_LENGTH = 42;

export const RECORDER_AUDIO_MIME_TYPES = [
  "audio/wav",
];

export const RECORDER_AUDIO_CONSTRAINTS = {
  audio: {
    channelCount: {
      exact: 1
    }
  }
}

export const RECORDER_VIDEO_MIME_TYPES = [
  "video/mp4",
  "video/mpeg",
  "video/webm",
];

export const RECORDER_VIDEO_CONSTRAINTS = {
  audio: true,
  video: {
    facingMode: "user",
    width: {min: 320, ideal: 854, max: 854},
    height: {min: 240, ideal: 480, max: 854}
  }
}
