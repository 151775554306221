import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import ErrorScreen from "./generic-error-screen";
import {analyticsSendEvent} from "../../libs/analytics";

export default function PermissionDeniedScene({children}: React.PropsWithChildren<{}>) {
  const {t} = useTranslation();

  useEffect(() => {
    analyticsSendEvent("permissionDenied");
  }, []);

  const params = new URLSearchParams(window.location.search.substr(1));
  const isExternal = params.get("external") === "1"

  return (
    <ErrorScreen
      documentTitle={t("system.permissionDenied.title")}
      title={t("system.permissionDenied.h1")}
      description={t("system.permissionDenied.desc")}
    >
      <ErrorScreen.RefreshButton/>
      {children}
      {!isExternal && <ErrorScreen.HomepageLink/>}
    </ErrorScreen>
  )
}
