import {useEffect} from "react";
import {TITLE_SUFFIX} from "../settings";

type props = {
  title: string,
  noSuffix?: boolean
}

export default function ReactDocumentTitle({title, noSuffix}: props) {
  useEffect(() => {
    document.title = !noSuffix ? title + TITLE_SUFFIX : title;
  }, [title, noSuffix]);

  return null;
}
