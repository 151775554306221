/* eslint-disable max-lines */
import {ApolloCache, gql, MutationFunctionOptions, useApolloClient} from "@apollo/client";
import {useCallback} from "react";
import {
  addIdentity, createOptimisticResponse, findParentOfObject, moveItemInCache, removeIdentity, rewriteCacheQuery
} from "../../apollo/utils";
import {
  Course, Lesson, LessonCourse, LessonFilterInput,
  MutationAddCourseArgs, MutationDeleteCourseArgs,
  MutationAddLessonArgs, MutationDeleteLessonArgs,
  MutationUpdateLessonArgs, MutationCopyLessonArgs, MutationCopyCourseArgs, EditorAccessType
} from "../../schema";
import LessonsTableTable from "../../components/editor/lessons-table/LessonsTable.table";


export type queryVars = {
  filter: LessonFilterInput
}

export type queryData = {
  items: LessonCourse[],
  courses: Course[],
  editorAccessType: EditorAccessType
}

export const query = gql`
  query EditorLessonListSceneQuery($filter: LessonFilterInput) {
    items: getLessons(filter: $filter) {
      ...LessonsTableTable
    }
    courses: getCourses {
      ...LessonsTableTableCourses
    }
    editorAccessType: getEditorAccessType
  }
  ${LessonsTableTable.fragments.root}
  ${LessonsTableTable.fragments.courses}
`;

export function useAddLessonMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{lesson: Lesson}, MutationAddLessonArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
    const courseId = opts.variables?.data.courseId;

    return client.mutate({
      mutation: addLessonMutation,
      update: (cache, mutationResult) => {
        const lesson = mutationResult.data?.lesson;

        if (!lesson) {
          return;
        }

        if (courseId) {
          addLessonToCachedCourse(cache, courseId, lesson)
          return;
        }

        addLessonCourseToCachedLessons(cache, lesson)
      },
      ...opts
    });
  }, [client])];
}


const addLessonMutation = gql`
  mutation AddLessonMutation($data: LessonInput!) {
    lesson: addLesson(data: $data) {
      ...LessonsTableTable
    }
  }
  ${LessonsTableTable.fragments.root}
`;


export function useCopyLessonMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{lesson: Lesson}, MutationCopyLessonArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {

    return client.mutate({
      mutation: copyLessonMutation,
      update: (cache, mutationResult) => {
        const lesson = mutationResult.data?.lesson;
        if (!lesson) {
          return;
        }

        addLessonCourseToCachedLessons(cache, lesson)
      },
      ...opts
    });
  }, [client])];
}

export function useDeleteLessonMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{deleted: boolean}, MutationDeleteLessonArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
    const lessonId = opts.variables?.id;

    if (!lessonId) {
      return;
    }

    return client.mutate({
      mutation: deleteLessonMutation,
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.deleted;

        if (!result) {
          return;
        }

        removeLessonFromCache(cache, {
          __typename: "Lesson",
          id: lessonId
        })
      },
      ...opts
    });
  }, [client])];
}

const copyLessonMutation = gql`
  mutation CopyLessonMutation($id: ID!) {
    lesson: copyLesson(id: $id) {
      ...LessonsTableTable
    }
  }
  ${LessonsTableTable.fragments.root}
`;

const deleteLessonMutation = gql`
  mutation DeleteLessonMutation($id: ID!) {
    deleted: deleteLesson(id: $id)
  }
`;

export function useAddCourseMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{course: Course}, MutationAddCourseArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
    return client.mutate({
      mutation: addCourseMutation,
      update: (cache, mutationResult) => {
        const course = mutationResult.data?.course;

        if (!course) {
          return;
        }

        addLessonCourseToCachedLessons(cache, course)
      },
      ...opts
    });
  }, [client])];
}


const addCourseMutation = gql`
  mutation AddCourseMutation($data: CourseInput!) {
    course: addCourse(data: $data) {
      ...LessonsTableTable
    }
  }
  ${LessonsTableTable.fragments.root}
`;

export function useCopyCourseMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{course: Course}, MutationCopyCourseArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {

    return client.mutate({
      mutation: copyCourseMutation,
      update: (cache, mutationResult) => {
        const course = mutationResult.data?.course;

        if (!course) {
          return;
        }

        addLessonCourseToCachedLessons(cache, course)
      },
      ...opts
    });
  }, [client])];
}

const copyCourseMutation = gql`
  mutation CopyCourseMutation($id: ID!) {
    course: copyCourse(id: $id) {
      ...LessonsTableTable
    }
  }
  ${LessonsTableTable.fragments.root}
`;

export function useDeleteCourseMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{deleted: boolean}, MutationDeleteCourseArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
    const courseId = opts.variables?.id;

    if (!courseId) {
      return;
    }

    return client.mutate({
      mutation: deleteCourseMutation,
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.deleted;

        if (!result) {
          return;
        }

        removeLessonCourseFromCachedLessons(cache, {
          __typename: "Course",
          id: courseId
        })
      },
      ...opts
    });
  }, [client])];
}

const deleteCourseMutation = gql`
  mutation DeleteCourseMutation($id: ID!) {
    deleted: deleteCourse(id: $id)
  }
`;

export function useMoveLessonMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<
    {lesson: Pick<Lesson, "__typename" | "id">},
    MutationUpdateLessonArgs
  >, "fetchPolicy">
  return [useCallback((opts: opts) => {
    const courseId = opts.variables?.data.courseId;

    return client.mutate({
      mutation: updateLessonMutation,
      update: (cache, mutationResult) => {
        const lesson = mutationResult.data?.lesson;

        if (!lesson) {
          return;
        }

        removeLessonFromCache(cache, lesson)

        if (courseId) {
          addLessonToCachedCourse(cache, courseId, lesson)
        } else if (courseId === null) {
          addLessonCourseToCachedLessons(cache, lesson)
        }
      },
      ...opts
    });
  }, [client])];
}

export function useReorderLessonMutation() {
  // return useMutation<
  //   {lesson: Pick<Lesson, "__typename" | "id">},
  //   MutationUpdateLessonArgs
  // >(updateLessonMutation);
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<
    {lesson: Pick<Lesson, "__typename" | "id">},
    MutationUpdateLessonArgs
  >, "fetchPolicy">
  return [useCallback((opts: opts) => {
    const beforeLessonId = opts.variables?.data?.beforeLessonId;
    const lessonID = opts.variables?.id

    const fragmentArgs = {
      id: `Lesson:${lessonID}`,
      fragmentName: "UpdatedLesson",
      fragment: gql`
        fragment UpdatedLesson on Lesson {
        ...LessonsTableTable
        }

        ${LessonsTableTable.fragments.root}
      `
    }

    const optimisticResponseLesson = createOptimisticResponse<Lesson>(
      client, fragmentArgs
    )

    return client.mutate({
      mutation: updateLessonMutation,
      optimisticResponse: {
        lesson: optimisticResponseLesson
      },
      update: (cache, mutationResult) => {

        const lesson = mutationResult.data?.lesson;

        if (!lesson) {
          return;
        }

        if (beforeLessonId !== undefined) {
          moveLessonInCache(cache, lesson, beforeLessonId)
          return;
        }
      },
      ...opts
    });
  }, [client])];
}

const updateLessonMutation = gql`
  mutation UpdateLessonMutation($id: ID!, $data: LessonInput!) {
    lesson: updateLesson(id: $id, data: $data) {
      __typename
      id
    }
  }
`;

// Cache update functions

function removeLessonFromCache(cache: ApolloCache<any>, lesson: Pick<Lesson, "__typename" | "id">) {
  const lessonID = lesson.id
  const parentalCourse = findParentOfObject<Partial<Course>>(cache, lesson, "Course", "lessons")
  if (parentalCourse && cache.identify(parentalCourse)) {
    cache.modify({
      id: cache.identify(parentalCourse),
      fields: {
        lessons(cachedLessons: Lesson[], {readField}) {
          return cachedLessons.filter((lesson) => readField("id", lesson) !== lessonID)
        },
      },
    });
    return;
  }
  removeLessonCourseFromCachedLessons(cache, lesson)
}

function addLessonToCachedCourse(
  cache: ApolloCache<any>,
  courseId: string | null,
  lesson: Pick<Lesson, "__typename" | "id">
) {
  cache.modify({
    id: `Course:${courseId}`,
    fields: {
      lessons(cachedLessons) {
        return [
          ...cachedLessons,
          {...lesson}
        ]
      },
    },
  });
}

function addLessonCourseToCachedLessons(
  cache: ApolloCache<any>,
  item: Pick<LessonCourse, "__typename" | "id">
) {
  rewriteCacheQuery(cache, {
    query: gql`query($filter: LessonFilterInput!) {
      lessons: getLessons(filter: $filter) {
        id
        __typename
      }
    }`,
    variables: {
      filter: {
        languageCode: null,
        lessonMode: null,
        name: ""
      }
    }
  }, (cacheData: {
    lessons: LessonCourse[]
  }) => {
    if (!cacheData.lessons) return;
    return addIdentity(cacheData, "lessons", item)
  })
}

function removeLessonCourseFromCachedLessons(
  cache: ApolloCache<any>,
  item: Pick<LessonCourse, "__typename" | "id">
) {
  rewriteCacheQuery(cache, {
    query: gql`query {
      lessons: getLessons(filter: $filter) {
        id
        __typename
      }
    }`,
    variables: {
      filter: {
        languageCode: null,
        lessonMode: null,
        name: ""
      }
    }
  }, (cacheData: {
    lessons: LessonCourse[]
  }) => {
    if (!cacheData.lessons) return;

    return removeIdentity(cacheData, "lessons", item)
  });
}

function moveLessonInCache(
  cache: ApolloCache<any>, lesson: Pick<Lesson, "__typename" | "id">, beforeLessonId: Lesson["id"] | null
) {
  return moveItemInCache<Course, Lesson>(cache, lesson, beforeLessonId, "Course", "lessons");
}
