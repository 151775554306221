import React from "react";
import cn from "classnames";
import classes from "./select.module.css";

type props = React.ComponentProps<"select"> & {}

export default function Select({className: extraClassName, ...props}: props) {
  const className = cn(classes.root, {}, extraClassName);

  return (
    <select className={className} {...props}/>
  )
}
