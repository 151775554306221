import React, {ComponentProps, memo} from "react";

import classes from "./Timeline.module.css";
import cn from "classnames";
import Skeleton from "../../ui/skeleton";
import {Droppable, DroppableProps} from "react-beautiful-dnd";
import Bubble from "./Bubble";

type props = Pick<ComponentProps<"div">, "className"> & Omit<DroppableProps, "droppableId"> & {
  droppableId?: DroppableProps["droppableId"]
  extra?: React.ReactNode
};

const defaultProps = {
  droppableId: "timeline"
}

export default function Timeline({className, children, extra, ...props}: props & typeof defaultProps) {
  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.line}/>
      <Droppable {...props}>
        {children}
      </Droppable>
      {extra}
    </div>
  )
}

Timeline.defaultProps = defaultProps;

type rowProps = React.ComponentProps<"div"> & {
  narrow?: boolean
  icon?: React.ReactNode,
  extra?: React.ReactNode,
}

Timeline.Row = ({icon, extra, narrow, className, children, ...props}: rowProps) => {
  return (
    <div className={cn(classes.row, {[classes.narrowRow]: narrow}, className)} {...props}>
      {icon && (
        <div className={classes.rowIcon}>
          <div className={classes.eraser}/>
          {icon}
        </div>
      )}

      <div className={classes.rowInner}>
        {children}
      </div>

      {extra && (
        <div className={classes.rowExtra}>
          {extra}
        </div>
      )}
    </div>
  )
}

Timeline.Skeleton = memo(({children, className, ...props}: ComponentProps<"div">) => (
  <div className={cn(classes.root, className)} {...props}>
    <div className={classes.line}/>

    {children}
  </div>
))

Timeline.SkeletonRow = memo(({width}: { width?: number }) => {
  return (
    <Timeline.Row icon={(
      <Skeleton.Block
        width={24}
        height={24}
        className={classes.skeletonIcon}
      />
    )}
    >
      <Bubble.Skeleton
        className={classes.skeletonBubble}
        width={width ?? 150}
      />
    </Timeline.Row>
  )
})
