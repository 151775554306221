import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

import cn from "classnames";
import classes from "./UserVideo.module.css";

import VideoArea from "./VideoArea";
import {useMediaRecorder} from "../../../providers/mediaRecorder";
import BaseUserIcon from "../../BaseUserIcon";

type props = React.ComponentProps<"div"> & {}

const UserVideo = ({className, ...props}: props) => {
  const {t} = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const {hasPermission, mediaStream, mediaType} = useMediaRecorder()

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.srcObject = mediaStream ?? null;
  }, [mediaStream])

  return (
    <VideoArea
      className={cn(classes.root, {[classes.hideOnMobile]: !hasPermission || mediaType !== "video"}, className)}
      {...props}
    >
      {(mediaType === "audio") && (
        <BaseUserIcon className={classes.icon}/>
      )}

      {(hasPermission === false) && (
        <span className={classes.errorMessage}>
            {t("player.cameraPermissionError")}
          </span>
      )}
      {mediaType === "video" && (
        <video
          className={cn(classes.webcam, classes.mirrored, {[classes.off]: !hasPermission})}
          ref={videoRef}
          muted
          autoPlay
          disablePictureInPicture
          playsInline
          controls={false}
        >{t("components.UserVideo.htmlVideoSupportError")}</video>
      )}
    </VideoArea>
  )
}

export default UserVideo;
