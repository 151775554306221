/* istanbul ignore file */
/* eslint-disable max-lines */
import {
  Account,
  Avatar,
  AvatarAction,
  AvatarEmotion,
  AvatarGesture,
  AvatarModelType,
  ChoiceUserInputAction,
  ChoiceUserInputBranch,
  ChoiceUserInputBranchState,
  Course,
  DetailedSessionFeedback,
  Editor,
  Student,
  EducationSessionRecordState,
  EducationSessionResult,
  Gender,
  LanguageCode,
  Lesson,
  LessonBadWordsFilter,
  LessonDisplayMode,
  LessonMode,
  LessonRecognitionAccuracy,
  LessonRecognitionEngine,
  LessonScenarioAfterAction,
  SystemAction,
  UserInputAction,
  ActionResult,
  LessonScenarioActivationMethod,
  RandomAvatarAction,
  RandomAvatarActionBranch,
  LessonInputMode
} from "./schema";
import {createMemoryHistory} from "history";
import {Scenario} from "./types";


function randomId(prefix = "") {
  return prefix + Math.floor(Math.random() * 100000).toString();
}

type createDummyHandler<T> = (params?: Partial<T>) => T;

export const createDummyAccount: createDummyHandler<Account> = function (params = {}) {
  return {
    id: randomId("a"),
    __typename: "Account",
    name: "Account",
    code: "account1",
    allowRetries: false,
    attemptsRemains: 100,
    hasActiveMembership: true,
    receiveReports: false,
    membershipType: "Editor",
    studentsLimit: 100,
    ...params,
  }
}

export const createDummyEditor: createDummyHandler<Editor> = function (params = {}) {
  return {
    id: randomId("u"),
    __typename: "Editor",
    name: "Editor",
    email: "editor@dailo.co",
    extraEmail: "editor2@dailo.co",
    fullName: "Editor",
    isDeveloper: false,
    isExperimental: false,
    experimentalMode: false,
    accountsCount: 1,
    isStaff: false,
    hasActiveMembership: true,
    groups: [],
    isEmailActivated: true,
    demoAccessed: false,
    demoDaysRemaining: 0,
    ...params,
  }
}

export const createDummyStudent: createDummyHandler<Student> = function (params = {}) {
  return {
    id: randomId("u"),
    __typename: "Student",
    name: "Student",
    email: "student@dailo.co",
    extraEmail: "editor2@dailo.co",
    fullName: "Student",
    isDeveloper: false,
    isExperimental: false,
    experimentalMode: false,
    accountsCount: 1,
    isStaff: false,
    hasActiveMembership: true,
    groups: [],
    isEmailActivated: true,
    demoAccessed: false,
    demoDaysRemaining: 0,
    ...params,
  }
}


export const createDummyAvatar: createDummyHandler<Avatar> = function (params = {}) {
  return {
    id: randomId("avatar"),
    __typename: "Avatar",
    name: "Avatar",
    modelType: AvatarModelType.UNKNOWN,
    gender: Gender.MALE,
    previewFull: "",
    preview: "",
    gltfAsset: "",

    ...params
  }
}

export const createDummyLesson: createDummyHandler<Lesson> = function (params = {}) {
  return {
    id: randomId("l"),
    accessToken: "secret",
    __typename: "Lesson",
    mode: LessonMode.STUDY_THREE_STEP,
    displayMode: LessonDisplayMode.DEFAULT,
    inputMode: LessonInputMode.VOICE,
    name: "Lesson 1",
    badWordsFilter: LessonBadWordsFilter.NO,
    avatar: createDummyAvatar(),
    gestures: [],
    background: null,
    canPlay: true,
    hasPassed: false,
    languageCode: LanguageCode.EN_US,
    recognitionEngine: LessonRecognitionEngine.IDENTIFY,
    recognitionAccuracy: LessonRecognitionAccuracy.NORMAL,
    customParameters: [],
    generateVideo: false,
    createdAt: Date.now()/1000,
    lastUpdatedAt: Date.now()/1000,
    participantsExportDownloadUrl: "[participantsExportDownloadUrl]",
    scormDownloadUrl: "[scormDownloadUrl]",

    ...params
  }
}

export const createDummyCourse: createDummyHandler<Course> = function (params = {}) {
  return {
    id: randomId("c"),
    accessToken: "secret",
    __typename: "Course",
    name: "Course 1",
    languageCode: LanguageCode.EN_US,
    createdAt: Date.now()/1000,
    lastUpdatedAt: Date.now()/1000,
    lessons: [],
    canPlay: true,
    hasPassed: false,
    ...params
  }
}

export const createDummyScenario: createDummyHandler<Scenario> = function (params = {}) {
  return {
    id: randomId("sc"),
    __typename: "Scenario",
    name: "Scenario 1",
    actions: [],
    isMain: true,
    triggerPhrases: [],
    afterAction: LessonScenarioAfterAction.RETURN,
    activationMethod: LessonScenarioActivationMethod.TRIGGER_PHRASE,
    ...params,
  }
}

export const createDummyAvatarAction: createDummyHandler<AvatarAction> = function (params = {}) {
  return {
    id: randomId("aa"),
    __typename: "AvatarAction",
    text: "[text]",
    onlyFirstLevel: false,
    emotion: AvatarEmotion.IDLE,
    gesture: AvatarGesture.IDLE,
    audio: "",
    isCustomAudio: false,
    setMediaAsBackground: false,

    ...params,
  }
}


export const createDummySystemAction: createDummyHandler<SystemAction> = function (params = {}) {
  return {
    id: randomId("sa"),
    __typename: "SystemAction",
    text: "[text]",
    onlyFirstLevel: true,
    audio: "",
    isCustomAudio: false,
    media: "",
    mediaType: "",
    mediaViewRequired: false,
    setMediaAsBackground: false,

    ...params,
  }
}

export const createDummyUserInputAction: createDummyHandler<UserInputAction> = function (params = {}) {
  return {
    id: randomId("uia"),
    __typename: "UserInputAction",
    freeSpeech: false,
    expectedText: "[expectedText]",
    hintText: "",
    rawExpectedText: "[rawExpectedText]",
    analogTexts: [],
    freeSpeechMaxTime: 0,
    freeSpeechMinTime: 0,

    ...params,
  }
}


export const createDummyChoiceUserInputAction: createDummyHandler<ChoiceUserInputAction> = function (params = {}) {
  return {
    id: randomId("cuia"),
    __typename: "ChoiceUserInputAction",
    branches: [],
    ...params,
  }
}

export const createDummyChoiceUserInputBranch: createDummyHandler<ChoiceUserInputBranch> = function (params = {}) {
  return {
    id: randomId("cuia"),
    __typename: "ChoiceUserInputBranch",
    state: ChoiceUserInputBranchState.CORRECT,
    userInput: createDummyUserInputAction(),
    avatarReaction: null,
    systemReaction: null,

    ...params,
  }
}

export const createDummyRandomAvatarAction: createDummyHandler<RandomAvatarAction> = function (params = {}) {
  return {
    id: randomId("raa"),
    __typename: "RandomAvatarAction",
    branches: [],
    ...params,
  }
}

export const createDummyRandomAvatarBranch: createDummyHandler<RandomAvatarActionBranch> = function (params = {}) {
  return {
    id: randomId("raab"),
    __typename: "RandomAvatarActionBranch",
    avatarAction: createDummyAvatarAction(),
    targetScenarioId: undefined,
    dropChance: 50,

    ...params,
  }
}

export const createDummyEducationSessionResult: createDummyHandler<EducationSessionResult> = (params) => {
  return {
    sessionId: "0000-0000-0000-0000",
    __typename: "EducationSessionResult",
    record: null,
    recordState: EducationSessionRecordState.NOT_STARTED,
    passed: true,
    requestFeedback: false,
    score: 100,
    time: 300,
    usedHints: 0,
    actionResults: [],
    isContentMatches: true,
    finishedScenariosIds: [],
    parametricResults: [],
    detailedFeedback: createDummyDetailedSessionFeedback(),
    ...params,
  }
}

export const createDummyActionResult: createDummyHandler<ActionResult> = function (params = {}) {
  return {
    __typename: "ActionResult",
    actionId: randomId("a"),
    branchId: randomId("b"),
    duration: 1,
    attempts: 1,
    isSkipped: false,
    usedHint: false,
    passed: true,
    ...params,
  }
}

export const createDummyDetailedSessionFeedback: createDummyHandler<DetailedSessionFeedback> = (params) => ({
  __typename: "DetailedSessionFeedback",
})

export function createDummyHistory() {
  const history = createMemoryHistory();

  history.push("/");
  return history;
}
