import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncMemo from "../../hooks/useAsyncMemo";
import {recordAudio} from "../../libs/audioRecorder";

import cn from "classnames";
import classes from "./AudioRecorder.module.css";
import Button from "../../ui/button";
import {MicIcon, StopIcon} from "../../ui/icons";
import Timer from "../player/Timer";

export type Props = React.ComponentProps<"div"> & {
  onRecordStop?: (audioBlob: Blob, audioUrl: string) => void
}

export default function AudioRecorder({onRecordStop, className, ...props}: Props) {
  const {t} = useTranslation();

  const {start: startRecord, stop: stopRecord} = useAsyncMemo(recordAudio, []) ?? {};
  const [isRecording, setRecording] = useState<boolean>(false);

  const onRecordStartClick = useCallback(() => {
    if (startRecord) {
      if (!isRecording) {
        startRecord();
        setRecording(true);
      } else {
        console.error("Trying to start record, but isRecording!=false");
        return isRecording;
      }
    }
  }, [isRecording, startRecord]);

  const onRecordStopClick = useCallback(async () => {
    if (stopRecord) {
      if (isRecording) {
        stopRecord().then(({audioBlob, audioUrl}) => {
          onRecordStop && onRecordStop(audioBlob, audioUrl);
        });
        setRecording(false);
      } else {
        console.error("Trying to stop record, but isRecording!=true");
      }
    }
  }, [isRecording, stopRecord, onRecordStop]);

  const disableButton = isRecording ? !stopRecord : !startRecord;

  return (
    <div className={cn(classes.root, className)} {...props}>
      <Button type="button"
        className={classes.recordButton}
        color="danger"
        title={isRecording ? t("components.AudioInput.stopRecord") : t("components.AudioInput.startRecord")}
        onClick={isRecording ? onRecordStopClick : onRecordStartClick}
        disabled={disableButton}
      >
        {isRecording ? <StopIcon/> : <MicIcon/>}
      </Button>
      {isRecording ? (
        <Timer className={classes.timer}/>
      ) : (
        <Timer.Placeholder className={classes.timer}/>
      )}
    </div>
  )
}

