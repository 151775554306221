import {gql} from "@apollo/client";
import PlaythroughsWidget from "../components/home/PlaythroughsWidget";

export const query = gql`
  query HomeSceneQuery {
    account: getCurrentAccount {
      id
      ...PlaythroughsWidgetAccount
    }

    user: getCurrentUser {
      id
      __typename
      name
      email
      isDeveloper
      demoAccessed
      demoDaysRemaining
      isEmailActivated
    }

    editorAccessType: getEditorAccessType

    contracts: getAccountContracts {
      expireAt
      licensesCount
      licensesUsed
      isActive
    }
    
  }

  ${PlaythroughsWidget.fragments.account}
`;
