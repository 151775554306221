import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import useSetState from "../hooks/useSetState";
import {analyticsSendEvent} from "../libs/analytics";
import {markTourAsCompleted} from "../tours";

type TourContext = {
  isAvailable: boolean
  isRunning: boolean
  runningTourName: string | undefined

  run: (name?: string, force?: boolean) => Promise<boolean>
  end: (name?: string) => Promise<boolean>
  bindToPage: (currentPageTourName?: string, ...subnames: string[]) => () => void

  mock: any
  setMock: React.Dispatch<any>
}

const Context = createContext<TourContext>(null!);

export default function TourProvider({children}: React.PropsWithChildren<{}>) {
  const [availableToursList, , , addAvailableTour, deleteAvailableTour] = useSetState<string>();
  const [runningTourName, setRunningTourName] = useState<TourContext["runningTourName"]>();
  const [mock, setMock] = useState<any>();
  const [currentPageTour, setCurrentPageTour] = useState<string>();

  const isAvailable = !!currentPageTour;
  const isRunning = runningTourName !== undefined;

  const run = useCallback<TourContext["run"]>(async (name, force) => {
    if (!name) {
      name = currentPageTour;
    }

    const isTourStarted = await new Promise<boolean>((resolve) => {
      setRunningTourName((runningTourName) => {
        if (force || (!runningTourName && name && availableToursList.has(name))) {
          resolve(true);
          return name;
        }

        resolve(false);
        return runningTourName;
      })
    })

    if (isTourStarted && name) {
      markTourAsCompleted(name);
      analyticsSendEvent("tourRun", {name});
    }

    return isTourStarted;
  }, [currentPageTour, availableToursList])

  const end = useCallback<TourContext["end"]>(async (name) => {
    const isTourEnded = await new Promise<boolean>((resolve) => {
      setRunningTourName((runningTourName) => {
        if (runningTourName) {
          if (!name || name === runningTourName) {
            resolve(true);
            return undefined;
          }
        }
        resolve(false);
        return runningTourName;
      })
    })

    if (isTourEnded) {
      analyticsSendEvent("tourEnd", {name: runningTourName});
    }

    return isTourEnded;
  }, [runningTourName])

  const bindToPage = useCallback<TourContext["bindToPage"]>((currentPageTourName, ...subnames) => {
    if (currentPageTourName) {
      addAvailableTour(currentPageTourName);
      setCurrentPageTour(currentPageTourName);
    }
    if (subnames) {
      subnames.forEach((subname) => addAvailableTour(subname));
    }
    return () => {
      if (currentPageTourName) {
        deleteAvailableTour(currentPageTourName);
        setRunningTourName(runningTourName => runningTourName === currentPageTourName ? undefined : runningTourName);
        setCurrentPageTour(undefined);
      }
      if (subnames) {
        subnames.forEach((subname) => deleteAvailableTour(subname));
      }
    }
  }, [addAvailableTour, deleteAvailableTour])

  const ctx = useMemo(() => ({
    isAvailable,
    isRunning,
    runningTourName,

    run,
    end,
    bindToPage,

    mock: isRunning ? mock : undefined,
    setMock
  }), [isAvailable, isRunning, runningTourName, run, end, bindToPage, mock]);

  return (
    <Context.Provider value={ctx}>
      {children}
    </Context.Provider>
  )
}

export function useTour() {
  return useContext(Context);
}
