import React, {useCallback, useEffect, useState} from "react";
import classes from "./StarRating.module.css"
import cn from "classnames"
import {StarIcon, StarOutlineIcon} from "../ui/icons";
import {fillArrayInRange} from "../utils";
import useAdaptiveClasses from "../hooks/useAdaptiveClasses";

const STAR_VALUES = fillArrayInRange(1, 5, 1);

type props = React.ComponentProps<"div"> & {
  onValueChange?: (value: number) => void
  value?: number
  adaptive?: boolean
  disabled?: boolean
}

const Star = ({className, ...props}: React.ComponentProps<"button">) => (
  <button type="button" className={cn(classes.star, className)} {...props}>
    <StarOutlineIcon className={classes.outline}/>
    <StarIcon className={classes.filled}/>
  </button>
)

const StarRating = ({onValueChange, value, adaptive, disabled, className: classNameEx, ...props}: props) => {
  const ac = useAdaptiveClasses(classes, adaptive)

  const [rateValue, setRateValue] = useState(0)

  useEffect(() => {
    value && setRateValue(value)
  }, [value])

  const onStarButtonClick = useCallback((e: React.MouseEvent) => {
    const value = e.currentTarget.getAttribute("data-id");

    value && setRateValue(parseInt(value))
    value && onValueChange && onValueChange(parseInt(value))
  }, [onValueChange])

  return (
    <div className={cn(ac.root, {[classes.selector]: !disabled}, classNameEx)} {...props}>
      {STAR_VALUES.map((value) => (
        <Star
          key={value}
          data-id={value}
          data-is-active={value <= rateValue ? "" : undefined}
          disabled={disabled}
          title={value.toString()}
          className={cn({[classes.active]: value <= rateValue})}
          onClick={onStarButtonClick}
        />
      ))}
    </div>
  );
};


export default StarRating;
