import React from "react";
import {useTranslation} from "react-i18next";
import Display from "./Display"

import classes from "./HintConfirmationDisplay.module.css"

export default function HintConfirmationDisplay() {
  const {t} = useTranslation();

  return (
    <Display className={classes.hideOnMobile}>
      <Display.Title title={t("player.confirmHint.title")}/>
      <Display.Content>{t("player.confirmHint.message")}</Display.Content>
    </Display>
  )
}
