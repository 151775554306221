import React from "react";
import {Route, Switch} from "react-router";
import {path} from "../routing";
import PageNotFoundScene from "./system/page-not-found";

import LmsPlayScene from "./lms/lms-play";

const LmsBundle = React.memo(function () {
  return (
    <Switch>
      <Route exact path={path("lmsPlay")} component={LmsPlayScene}/>
      <Route component={PageNotFoundScene}/>
    </Switch>
  )
});

export default LmsBundle;
