import {ApolloLink, defaultDataIdFromObject, InMemoryCache} from "@apollo/client";
import {createUploadLink} from "apollo-upload-client"
import introspectionResult from "./introspection-result.json";
import {KeyFieldsFunction} from "@apollo/client/cache/inmemory/policies";

export function createHttpLink(origin: string): ApolloLink {
  return createUploadLink({
    uri: origin + "/_/gql/",
  })
}

export function createInMemoryCache() {
  return new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    dataIdFromObject
  });
}

export const dataIdFromObject: KeyFieldsFunction = (object, context) => {
  switch (object.__typename) {
    case "Student":
    case "Editor":
      return "User:" + object.id

    default:
      return defaultDataIdFromObject(object, context);
  }
}
