import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router";
import {useCurrentUser, useCurrentAccount} from "../App.context";
import {getCourseId, getLessonId, path, reverse} from "../routing";
import PageNotFoundScene from "./system/page-not-found";
import {isSharedId} from "../types";

import PlayerIndexScene from "./player";
import PlayerPlayerScene from "./player/player";
import ResultsScene from "./player/results";

import {isBrowserSupported} from "../browser";
import BrowserNotSupportedScene from "./system/browser-not-supported";
import NoMediaRecorderScene from "./system/no-media-recorder";
import PlayerCourseScene from "./player/course";

const PlayerBundle = React.memo(function () {

  const browserSupported = React.useMemo(() => isBrowserSupported("player"), []);

  const history = useHistory();

  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;

  const lessonId = getLessonId();
  const courseId = getCourseId();
  const isIdShared = (lessonId || courseId) && isSharedId(lessonId || courseId!);
  const redirectToLogin = (!lessonId && !courseId) || !isIdShared;

  useEffect(() => {
    if ((!userId || !accountId) && redirectToLogin) {
      history.push(reverse("login") + "?_next=" + encodeURIComponent(history.location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if ((!userId || !accountId) && redirectToLogin) {
    return null;
  }

  if (!browserSupported) {
    return (<BrowserNotSupportedScene/>);
  }

  if (!window.MediaRecorder) {
    return (<NoMediaRecorderScene/>)
  }

  return (
    <Switch>
      <Route exact path={path("player")} component={PlayerIndexScene}/>
      <Route exact path={path("playerResults")} component={ResultsScene}/>
      <Route exact path={path("playerUserResults")} component={ResultsScene}/>
      <Route exact path={path("playerPlay")} component={PlayerPlayerScene}/>
      <Route exact path={path("playerCourse")} component={PlayerCourseScene}/>

      <Route component={PageNotFoundScene}/>
    </Switch>
  )
});

export default PlayerBundle;
