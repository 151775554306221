import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {
  query, QueryData,
  useAddGroupMutation,
  useDeactivateGroupMembersMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation
} from "./groups.graphql";
import {reverse} from "../../routing";
import {useModal} from "../../components/ModalProvider";
import {Group, GroupInput} from "../../schema";
import {analyticsSendEvent} from "../../libs/analytics";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";

import Table from "../../ui/table";
import ReactLink from "../../components/ReactLink";
import {AddIcon} from "../../ui/icons";
import Button from "../../ui/button";

import GroupsTable, {Props as GroupsTableProps} from "../../components/account/groups-table/GroupsTable";
import AddGroupModal from "../../components/account/groups-table/AddGroupModal";
import RenameGroupModal from "../../components/account/groups-table/RenameGroupModal";
import EditGroupMembersModal from "../../components/account/groups-table/EditGroupMembersModal";
import DeleteModal from "../../components/editor/DeleteModal";

import classes from "./groups.module.css";

export type DeleteGroupModalQueryData = {
  group: Pick<Group, "id" | "name" | "code">
}

export default function AccountMembersGroupsScene() {
  const {t} = useTranslation();

  const {data, loading: fetching} = useQuery<QueryData>(query);
  const {groups} = data ?? {};
  const users = data?.users;

  const loading = !data && fetching;

  const {add: addModal} = useModal();

  const [mutateAddGroup] = useAddGroupMutation();
  const [mutateDeactivateMembers] = useDeactivateGroupMembersMutation();
  const [mutateDeleteGroup] = useDeleteGroupMutation();
  const [mutateUpdateGroup] = useUpdateGroupMutation();

  const addGroup = useCallback((name: string) => {
    analyticsSendEvent("membersGroupsAddGroup", {name});
    mutateAddGroup({
      variables: {
        data: {name}
      }
    });
  }, [mutateAddGroup]);

  const onAddGroupButtonClick = useCallback(() => {
    if (!groups) {
      return;
    }

    const onAddGroup = (name: string) => {
      addGroup(name);
      modal.remove();
    }

    const modal = addModal({
      header: <AddGroupModal.Header/>,
      content: <AddGroupModal.Content onAddGroup={onAddGroup} groups={groups}/>
    })
  }, [groups, addGroup, addModal]);

  const deleteGroup = useCallback((groupId: Group["id"]) => {
    analyticsSendEvent("membersGroupsDeleteGroup", {groupId});
    mutateDeleteGroup({
      variables: {
        id: groupId
      }
    });
  }, [mutateDeleteGroup]);

  const onDeleteGroup = useCallback<GroupsTableProps["onDeleteGroup"]>((group) => {
    const onDeleteGroup = () => {
      deleteGroup(group.id)
      modal.remove();
    };

    const onCancel = () => modal.remove();

    const modal = addModal({
      id: `deleteGroup_${group.id}`,
      header: t("membersGroups.deleteGroup"),
      content: t("membersGroups.deleteGroupConfirmation", {name: group.name}),
      footer: <DeleteModal.Footer onConfirm={onDeleteGroup} onCancel={onCancel}/>
    });
  }, [t, deleteGroup, addModal]);

  const updateGroup = useCallback((groupId: Group["id"], data: GroupInput) => {
    mutateUpdateGroup({
      variables: {groupId, data}
    });
  }, [mutateUpdateGroup])

  const renameGroup = useCallback((groupId: Group["id"], name: string, code: string) => {
    analyticsSendEvent("membersGroupsRenameGroup", {groupId, name, code});
    updateGroup(groupId, {name, code});
  }, [updateGroup])

  const onRenameGroup = useCallback<GroupsTableProps["onRenameGroup"]>((group) => {
    if (!groups) {
      return;
    }

    const onRenameGroup = (name: string, code: string) => {
      renameGroup(group.id, name, code);
      modal.remove();
    }

    const modal = addModal({
      id: `renameGroup_${group.id}`,
      header: <RenameGroupModal.Header/>,
      content: <RenameGroupModal.Content onRenameGroup={onRenameGroup} group={group} groups={groups}/>
    })
  }, [groups, renameGroup, addModal]);

  const updateGroupMembers = useCallback((groupId: Group["id"], usersIds: string[]) => {
    analyticsSendEvent("membersGroupsRenameGroup", {
      groupId,
      usersCount: usersIds.length
    });
    updateGroup(groupId, {usersIds});
  }, [updateGroup])

  const onEditGroupMembers = useCallback<GroupsTableProps["onEditGroupMembers"]>((group) => {
    if (!groups || !users) {
      return;
    }

    const onEditGroupMembers = (usersIds: string[]) => {
      updateGroupMembers(group.id, usersIds);
      modal.remove();
    }

    const modal = addModal({
      id: `editGroupMembers_${group.id}`,
      header: <EditGroupMembersModal.Header/>,
      content: (
        <EditGroupMembersModal.Content
          onEditGroupMembers={onEditGroupMembers}
          group={group}
          users={users}
          groups={groups}
        />
      ),
      size: "l"
    })
  }, [groups, users, updateGroupMembers, addModal]);

  const deactivateMembers = useCallback((groupId: Group["id"]) => {
    analyticsSendEvent("membersGroupsDeactivateMembers", {groupId});
    mutateDeactivateMembers({
      variables: {
        id: groupId
      }
    });
  }, [mutateDeactivateMembers]);

  const onDeactivateMembers = useCallback<GroupsTableProps["onDeactivateMembers"]>((group) => {
    const onDeactivateMembers = () => {
      deactivateMembers(group.id)
      modal.remove();
    };

    const onCancel = () => modal.remove();

    const modal = addModal({
      id: `deactivateMembers_${group.id}`,
      header: t("membersGroups.deactivateMembers.title"),
      content: t("membersGroups.deactivateMembers.help", {name: group.name}),
      footer: (
        <div className={classes.modalFooter}>
          <Button color="secondary" onClick={onCancel}>{t("common.cancel")}</Button>
          <Button color="danger" onClick={onDeactivateMembers}>{t("membersGroups.deactivateMembers.confirm")}</Button>
        </div>
      )
    });
  }, [t, deactivateMembers, addModal]);

  return (
    <>
      <ReactDocumentTitle title={t("accountMembersGroups.title")}/>

      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <ReactLink href={reverse("accountMembers")}>
                {t("breadcrumbs.members")}
              </ReactLink>
              <span>{t("breadcrumbs.membersGroups")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("accountMembersGroups.groups")}</h1>
              <div className={classes.topRightMenu}>
                <Button
                  className={classes.addButton}
                  onClick={onAddGroupButtonClick}
                  color="primary"
                >
                  <AddIcon/>{t("accountMembersGroups.addGroup")}
                </Button>
              </div>
            </div>

            {!loading && groups && (
              groups.length > 0 ? (
                <GroupsTable
                  groups={groups}
                  onRenameGroup={onRenameGroup}
                  onEditGroupMembers={onEditGroupMembers}
                  onDeactivateMembers={onDeactivateMembers}
                  onDeleteGroup={onDeleteGroup}
                />
              ) : (
                <div className={classes.emptyState}>{t("accountMembersGroups.emptyState")}</div>
              )
            )}

            {loading && (
              <Table className={classes.root}>
                <Table.Body>
                </Table.Body>
              </Table>
            )}
          </Container>
        </Main>
      </Layout>
    </>
  )
}
