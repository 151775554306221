import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {GOOGLE_OAUTH_CLIENT_ID} from "../../settings";
import {reverse} from "../../routing";

import classes from "./GoogleSignInButton.module.css";
import {useHistory} from "react-router";

const GoogleSignInButton = React.memo(function () {
  const {t} = useTranslation();
  const history = useHistory();

  const href = useMemo(() => {
    const params = new URLSearchParams({
      "client_id": GOOGLE_OAUTH_CLIENT_ID,
      "redirect_uri": window.location.origin + reverse("loginWithProvider", {
        "provider": "google"
      }),
      "response_type": "token",
      "scope": "profile email",
      "state": history.location.search,
      "prompt": "select_account"
    });

    return "https://accounts.google.com/o/oauth2/v2/auth?" + params.toString();
  }, [history]);

  return (
    <a href={href} className={classes.root}>
      <div className={classes.borderLeft}/>
      <div className={classes.borderRight}/>

      <div className={classes.inner}>
        <img className={classes.icon} src={require("../../assets/google.svg").default} alt="via Google"/>
        <span className={classes.text}>{t("components.GoogleSignInButton.text")}</span>
      </div>
    </a>
  )
});

export default GoogleSignInButton;
