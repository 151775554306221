import React, {ElementType, useCallback} from "react"
import {isEqual} from "lodash";

import Scroll from "../../ui/scroll";

import {useDispatch, useSelector} from "react-redux";
import navigate from "../../redux/player/actions/navigate";
import {getNavigateToActions} from "../../redux/player/selectors";
import {PlayableAction, PlayerReduxState} from "../../redux/player/types";

import classes from "./NavigationList.module.css";

export type NavigationListItemProps = Pick<React.ComponentProps<"button">, "onClick" | "disabled"> & {
  action: PlayableAction
}

export type NavigationListItemType = ElementType<NavigationListItemProps>

type props = {
  as: NavigationListItemType
  onNavigate?: (e: React.MouseEvent) => void
  testId?: string
}

export default function NavigationList({onNavigate, as: Component, testId}: props) {
  const {indexedActions} = useSelector(NavigationList.selector, isEqual);

  const dispatch = useDispatch();

  const onNavigateClick = useCallback((e) => {
    const index = parseInt(e.currentTarget.getAttribute("data-id"));

    onNavigate && onNavigate(e);
    dispatch(navigate(index));
  }, [onNavigate, dispatch]);

  return (
    <Scroll data-testid={testId} className={classes.content}>
      {indexedActions.map(({action, index, available}) => (
        <Component
          data-id={index}
          key={index}
          onClick={onNavigateClick}
          action={action}
          disabled={!available}
        />
      ))}
    </Scroll>
  )
}

NavigationList.selector = (state: PlayerReduxState) => {
  return {
    indexedActions: getNavigateToActions(state),
  }
}
