import React, {useCallback, useMemo} from "react";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import LoginLayout from "../../components/login/LoginLayout";
import {reverse} from "../../routing";
import {Account} from "../../schema";
import AccountItem from "../../components/login/AccountItem";
import {query, useSelectAccountMutation} from "./login-select-account.graphql";
import classes from "./login.module.css";
import {redirectToNextUrl} from "./common";
import {useCurrentUser, useSession} from "../../App.context";

export default function LoginSelectAccount() {
  const history = useHistory();
  const {t} = useTranslation();

  const userId = useCurrentUser()?.id;
  const {set: setSessionId} = useSession();
  const authenticated = !!userId;

  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const inviteToLesson = params.get("_invited");
  const accessKey = params.get("_access_key");
  const accountId = params.get("_aid");

  const logoutHandler = useCallback(() => {
    setSessionId(undefined);
    window.location.href = window.location.origin + reverse("login") + "?_aid=" + accountId;
  }, [setSessionId, accountId]);

  const {data, loading} = useQuery(query);
  const [selectAccount] = useSelectAccountMutation();

  const accounts: Account[] = data?.accounts;

  const onSelect = useCallback((e) => {
    const accountId = e.currentTarget.getAttribute("data-id");

    selectAccount({variables: {accountId}}).then(() => {
      if (inviteToLesson && accessKey) {
        history.push(reverse("playerPlay", {id: inviteToLesson}));
      } else {
        redirectToNextUrl(history);
      }
    });
  }, [history, inviteToLesson, accessKey, selectAccount]);

  return (
    <>
      <ReactDocumentTitle title={t("loginSelectAccount.title")}/>

      <LoginLayout className={classes.root}>
        <h1 className={classes.h1}>{t("loginSelectAccount.h1")}</h1>

        {loading && (
          <>
            <AccountItem.Skeleton/>
            <AccountItem.Skeleton/>
            <AccountItem.Skeleton/>
          </>
        )}

        {!loading && (
          <div>
            {accounts.map((account) => (
              <AccountItem
                key={account.id}
                data-id={account.id}
                account={account}
                onClick={account.hasActiveMembership ? onSelect : undefined}
              />
            ))}
            {authenticated && (
              <div className={classes.extraRow}>
                <a href="/#" onClick={logoutHandler} className={classes.center}>
                  {t("components.Header.logout")}
                </a>
              </div>
            )}
          </div>
        )}
      </LoginLayout>
    </>
  )
}
