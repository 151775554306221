import {gql} from "@apollo/client";
import {UsersByMixedData} from "../../schema";

export type QueryData = {
  mixed: UsersByMixedData
}

export const usersQuery = gql`
  query UserSelectComponentQuery($usersIds: [ID!], $groupsIds: [ID!], $plainText: String) {
    mixed: getUsersByMixedData(usersIds: $usersIds, groupsIds: $groupsIds, plainText: $plainText) {
      users {
        id
        fullName
        name
        email
        groups {
          id
          name
        }
      }
      emails
    }
  }
`;