import React from "react";
import {useTranslation} from "react-i18next";

import Button from "../../ui/button";
import Widget from "../../ui/widget";

import classes from "./GenericErrorWidget.module.css";
import cn from "classnames";

type props = React.ComponentProps<"div"> & {
  variant?: "error" | "warning" | "default"
  title?: string,
  description?: string,
  onClose?: () => void,
}

export default function ErrorWidget({children, variant, title, description, onClose, className, ...props}: props) {
  const {t} = useTranslation();

  return (
    <Widget className={cn(classes.root, classes[variant ?? "default"], className)} {...props}>
      <Widget.Title>{title ?? t("system.genericErrorWidget.title")}</Widget.Title>
      <Widget.Description>{description ?? t("system.genericErrorWidget.description")}</Widget.Description>
      {children}
      <Button className={classes.closeButton} onClick={onClose}>{t("common.close")}</Button>
    </Widget>
  )
}
