import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useFormState} from "../../hooks/useFormState";
import {TERMS_OF_USE_LINK} from "../../settings";

import Button from "../../ui/button";
import Input from "../../ui/input";
import Textarea from "../../ui/textarea";
import FileInput from "../editor/FileInput";

import classes from "./BugReportModal.module.css";

type props = {
  onBugReportSend?: (message: string, attachment?: File, contact?: string) => void,
}

type formState = {
  message: string,
  attachment?: File,
  contact?: string
}

function BugReportModalHeader() {
  const {t} = useTranslation();
  return <span>{t("components.system.BugReportModal.title")}</span>
}

function BugReportModalContent({onBugReportSend}: props) {
  const {t} = useTranslation();

  const formState = useFormState<formState>({
    initialValues: {
      message: "",
    },
    preventDefault: true,
    onSubmit: (data) => {
      onBugReportSend && onBugReportSend(
        data.message,
        data.attachment,
        data.contact
      )
    }
  })

  return (
    <form method='post' onSubmit={formState.submitHandler} className={classes.root}>
      <span className={classes.description}>
        {t("components.system.BugReportModal.description")}
      </span>
      <Textarea
        className={classes.message}
        stopPropagation
        name="message"
        rows={6}
        required
        placeholder={t("components.system.BugReportModal.message.placeholder")}
        value={formState.values.message}
        onChange={formState.changeHandler}
      />
      <span className={classes.attachmentDescription}>
        {t("components.system.BugReportModal.attachment")}
      </span>
      <div className={classes.attachment}>
        <FileInput
          className={classes.attachmentPreview}
          variant="image"
          name="attachment"
          onDelete={formState.changeHandler}
          setValues={formState.setValues}
        />
      </div>
      <span className={classes.contactDescription}>
        {t("components.system.BugReportModal.contact.description")}
      </span>
      <Input
        displaySize="sm"
        className={classes.contact}
        stopPropagation
        name="contact"
        placeholder={t("components.system.BugReportModal.contact.placeholder")}
        value={formState.values.contact}
        onChange={formState.changeHandler}
      />
      <span className={classes.acceptTerms}>
        <Trans i18nKey="components.system.BugReportModal.acceptTerms" components={[
          <a href={TERMS_OF_USE_LINK}> </a>,
        ]}/>
      </span>
      <Button className={classes.submitButton} color="success">
        {t("common.submit")}
      </Button>
    </form>
  )
}

const BugReportModal = {
  Content: BugReportModalContent,
  Header: BugReportModalHeader
}

export default BugReportModal
