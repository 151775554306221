import {cargo} from "async";
import {LanguageCode} from "../schema";

export type FileUploadData = {
  id: string | null,
  recognizedText: string | null,
  uuid: string | null,
  duration: number | null,
  isError: boolean,
}

export type FileUpload = {
  data: FileUploadData,
  chunk: (chunk: Blob) => void,
  done: () => Promise<void>,
  destroy: () => void,
}

export default function createFileUploadWS(
  baseEndpoint: string, lang: LanguageCode, engine: string | null,
  lessonId: string | null, accessKey: string | null,
  onGetResult: () => void, contentType?: string, pipeline?: string,
  sampleRate?: number
): FileUpload {
  const file: FileUpload = {
    data: {
      id: null,
      recognizedText: null,
      uuid: null,
      duration: null,
      isError: false,
    },
    chunk: (chunk: Blob) => sendQueue.push(chunk),
    done: () => sendQueue.idle() ? Promise.resolve() : sendQueue.drain(),
    destroy: () => {
      sendQueue.kill();
    },
  }

  const ws = new WebSocket(
    `${baseEndpoint}/${contentType}/${pipeline}/${sampleRate}/${lang}/${engine}/${lessonId}/${accessKey}/`
  );

  ws.onmessage = (e: MessageEvent) => {
    if (e.data === "error") {
      file.data.id = e.data;
      file.data.isError = true;
      ws.close();
      onGetResult();
      return;
    }
    if (e.data === "uploaded") {
      ws.close();
      onGetResult();
      return;
    }
    let json = undefined;
    try {
      json = JSON.parse(e.data)
      if (json["uuid"]) {
        file.data.uuid = json["uuid"]
        file.data.recognizedText = json["text"]
        file.data.duration = json["duration"]
        ws.close();
        onGetResult();
      }
    } catch (error) {
      file.data.id = e.data;
    }
  };

  const sendQueue = cargo<Blob>((blobs, callback) => {
    (async () => {
      await sendData(ws, new Blob(blobs));
    })()
      .then(() => {
        callback()
      })
      .catch((e) => {
        console.error(e);
        setTimeout(() => {
          sendQueue.unshift(blobs);
          callback(e);
        }, 50);

      });
  });

  return file;
}

async function sendData(ws: WebSocket, body: Blob | null = null) {
    if (ws.readyState === ws.OPEN && body) {
      ws.send(body)
    } else {
      throw new Error(
        "Cannot send audio data via websocket. Connection is not established or there is no data in body."
      )
    }
}
