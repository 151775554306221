import React, {useCallback, useMemo} from "react"
import {useTranslation} from "react-i18next";
import {getLanguages} from "../../i18n";
import {analyticsSendEvent} from "../../libs/analytics";

import ActionSheet from "../../ui/actionsheet";

type props = React.ComponentProps<typeof ActionSheet.List>

export default function LanguageActionsList(props: props) {
  const {i18n} = useTranslation();

  const displayableLanguages = useMemo(() => (
    getLanguages().filter((language) => language.translationAvailable)
  ), [])

  const langOnClick = useCallback((e) => {
    const lang = e.currentTarget.getAttribute("data-id");

    i18n.changeLanguage(lang);
    analyticsSendEvent("changeLanguage", {
      languageCode: lang
    });
  }, [i18n]);

  return (
    <ActionSheet.List {...props}>
      {displayableLanguages.map((language) => (
        <ActionSheet.Action
          data-id={language.lang}
          key={language.lang}
          onClick={langOnClick}
          title={language.verboseName}
        />
      ))}
    </ActionSheet.List>
  );
}