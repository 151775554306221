import React, {ComponentProps, useCallback, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Account, Contract, EducationMembersSummary} from "../../schema";
import {formatDate} from "../../time";
import {gql} from "@apollo/client";

import IconButton from "../../ui/iconbutton";
import {CaretDown, HelpCircleIcon} from "../../ui/icons";
import Table from "../../ui/table";
import Dropdown, {DropdownContent, DropdownRef, DropdownReference} from "../../ui/dropdown";
import WithTooltip from "../../ui/tooltip";

import cn from "classnames"
import classes from "./AccountInfo.module.css";

type Props = ComponentProps<"div"> & {
  account: QueryData["account"]
  membersSummary: QueryData["membersSummary"]
  contracts: QueryData["contracts"]
}

export type QueryData = {
  account: Pick<Account, "attemptsRemains">
  membersSummary: Pick<EducationMembersSummary, "active" | "total">
  contracts: Pick<Contract, "expireAt" | "licensesCount" | "licensesUsed" | "isActive">[]
}

export default function AccountInfo({children, account, membersSummary, contracts, className, ...props}: Props) {
  const {t, i18n} = useTranslation();
  const ctxMenuRef = useRef<DropdownRef>(null);

  const [tableVisibility, setTableVisibility] = useState<boolean>();
  const toggleTableVisibility = useCallback(() => {
    if (!ctxMenuRef.current) {
      return;
    }
    if (!ctxMenuRef.current.state) {
      ctxMenuRef.current.open();
      setTableVisibility(true);
    } else {
      ctxMenuRef.current.close();
      setTableVisibility(false);
    }
  }, []);

  const contractsSummary = useMemo(() => {
    if (contracts.length === 1) {
      const {licensesCount, licensesUsed} = contracts[0];
      return t("components.AccountInfo.licensesTill", {
        available: licensesCount - licensesUsed,
        total: licensesCount,
        till: formatDate(contracts[0].expireAt, i18n.language)
      })
    }

    let licensesCount = 0, licensesUsed = 0;

    contracts.forEach(item => {
      if (item.isActive) {
        licensesCount += item.licensesCount;
        licensesUsed += item.licensesUsed;
      }
    })

    return t("components.AccountInfo.licensesAndContracts", {
      available: licensesCount - licensesUsed,
      total: licensesCount,
      contractsCount: contracts.length
    })
    // Hack for licensesAndContracts plurals parsing
    // eslint-disable-next-line no-unreachable
    t("components.AccountInfo.contracts", {count: contracts.length})
  }, [t, i18n.language, contracts])

  const haveContracts = contracts.length > 0;

  const helpText = useMemo(() => (
    t("components.AccountInfo.helpText.title")
    + t("components.AccountInfo.helpText.members")
    + t("components.AccountInfo.helpText.account")
    + (haveContracts ? t("components.AccountInfo.helpText.contracts") : "")
  ), [t, haveContracts])

  return (
    <div className={cn(classes.root, className)} {...props}>
      <div className={classes.wrapper}>
        <WithTooltip as='span'
          className={classes.membersSummary}
          helpText={t("components.AccountInfo.helpText.members")}
        >
          {t("components.AccountInfo.members", membersSummary)}
        </WithTooltip>
        <WithTooltip as='span'
          className={classes.accountSummary}
          helpText={t("components.AccountInfo.helpText.account")}
        >
          {t("components.AccountInfo.account", account)}
        </WithTooltip>
        {haveContracts && (
          <WithTooltip as='span'
            className={classes.contractsSummary}
            helpText={t("components.AccountInfo.helpText.contracts")}
          >
            {contractsSummary}
            <Dropdown ref={ctxMenuRef} stopCloseOnOuterClick>
              <DropdownReference onClick={toggleTableVisibility}>
                <IconButton
                  className={cn(classes.toggleButton, {[classes.checked]: tableVisibility})}
                  size="s"
                  icon={CaretDown}
                />
              </DropdownReference>
              <DropdownContent
                className={classes.tableWrapper}
                offset={[0, 12]}
                {...props}
              >
                <ContractsTable contracts={contracts}/>
              </DropdownContent>
            </Dropdown>
          </WithTooltip>
        )}
        <WithTooltip as='span'
          className={classes.help}
          helpText={helpText}
        >
          <HelpCircleIcon/>
        </WithTooltip>
      </div>
    </div>
  )
  // Hack for account plurals parsing
  // eslint-disable-next-line no-unreachable
  t("components.AccountInfo.playthroughs", {count: account.attemptsRemains})
}

type ContractsTableProps = Omit<ComponentProps<typeof Table>, "children"> & {
  contracts: Contract[]
}

export function ContractsTable({contracts, className, ...props}: ContractsTableProps) {
  const {t, i18n} = useTranslation();

  return (
    <Table className={cn(classes.contractsTable, className)} {...props}>
      <Table.Head>
        <Table.Row>
          <Table.Cell>{t("components.AccountInfo.ContractsTable.available")}</Table.Cell>
          <Table.Cell>{t("components.AccountInfo.ContractsTable.total")}</Table.Cell>
          <Table.Cell>{t("components.AccountInfo.ContractsTable.used")}</Table.Cell>
          <Table.Cell>{t("components.AccountInfo.ContractsTable.till")}</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {contracts.map((contract, index) => (
          <Table.Row key={index} className={cn({[classes.expired]: !contract.isActive})}>
            <Table.Cell className={classes.available}>
              {contract.isActive
                ? (contract.licensesCount - contract.licensesUsed)
                : t("components.AccountInfo.ContractsTable.expired")
              }
            </Table.Cell>
            <Table.Cell className={classes.total}>{contract.licensesCount}</Table.Cell>
            <Table.Cell className={classes.used}>{contract.licensesUsed}</Table.Cell>
            <Table.Cell className={classes.till}>
              {formatDate(contract.expireAt, i18n.language)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

AccountInfo.fragments = {
  account: gql`
    fragment AccountInfoAccount on Account {
      attemptsRemains
    }
  `,
  membersSummary: gql`
    fragment AccountInfoEducationMembersSummary on EducationMembersSummary {
      total
      active
    }
  `,
  contract: gql`
    fragment AccountInfoContract on Contract {
      expireAt
      licensesCount
      licensesUsed
      isActive
    }
  `,
}
