import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getCurrentAction, getLevel, isExamStage, isHintConfirmation} from "../selectors";
import {MutationEducationSessionUseHintArgs, Scalars} from "../../../schema";
import {sessionUseHintMutation} from "../graphql";
import {isAnyUserInputAction} from "../utils";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function hint() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    const action = getCurrentAction(state);

    if (!action || !isAnyUserInputAction(action)) {
      console.warn("useHint: wrong player state");
      return;
    }

    if (isHintConfirmation(state) || !isExamStage(state)) {
      analyticsSendEvent("playerUseHint", {
        sessionId: state.sessionId!,
        level: getLevel(state),
        actionId: action.id
      });

      state.client.mutate<{ ok: Scalars["Boolean"] }, MutationEducationSessionUseHintArgs>({
        mutation: sessionUseHintMutation,
        variables: {
          sessionId: state.sessionId!,
          level: getLevel(state),
          actionId: action.id,
        }
      }).catch(console.error);
    }

    dispatch({
      type: "hint",
      payload: {
        actionId: action.id,
      }
    });
  }
}
