import React from "react";
import {useTranslation} from "react-i18next";

import classes from "./FileUploadingModal.module.css"

import PopupPortal from "../../ui/popupportal";
import Spinner from "../../ui/spinner";
import Widget from "../../ui/widget";


export default function FileUploadingModal() {
  const {t} = useTranslation();

  return (
    <PopupPortal as="div" center>
      <Widget>
        <Widget.Title>{t("system.FileUploadingModal.title")}</Widget.Title>
        <Spinner className={classes.spinner}/>
        <Widget.Description>{t("system.FileUploadingModal.description")}</Widget.Description>
      </Widget>
    </PopupPortal>
  )
}
