import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {reverse} from "../../../routing";
import {Lesson} from "../../../schema";
import Button from "../../../ui/button";
import FSQRCode from "../../../ui/fullscreen/qrcode";
import {generateURL, postMessageOpener} from "../../../utils";
import CopyText from "../../CopyText";
import ReactLink from "../../ReactLink";

export enum StartDisplayMode {
  DEFAULT = "default",
  COPY = "copy",
  OPENER = "opener",
}

type props = React.ComponentProps<"div"> & {
  mode: StartDisplayMode,
  lessonId: Lesson["id"],
  urlParams: URLSearchParams,
  openInNewWindow: (urlString: string) => void,
  canRetry?: boolean,
  canStart?: boolean,
  secondaryStartDisplay?: boolean
}

export default function StartDisplay({
  mode, lessonId, urlParams, openInNewWindow, canRetry, canStart, secondaryStartDisplay, ...props
}: props) {
  const {t} = useTranslation();

  const urlString = useMemo(() => (
    generateURL(reverse("playerPlay", {id: lessonId}), urlParams).toString()
  ), [lessonId, urlParams])

  const onNewWindowClick = useCallback(() => {
    openInNewWindow(urlString)
  }, [openInNewWindow, urlString])

  const onStartClick = useCallback(() => {
    postMessageOpener({type: "Start"});
  }, [])

  const text = (canRetry && !canStart) ? t("lmsPlay.restart") : t("lmsPlay.start")

  const startDisplay = useMemo(() => {
    switch (mode) {
      case StartDisplayMode.DEFAULT:
        return (
          <Button
            as={ReactLink}
            href={reverse("playerPlay", {id: lessonId}) + `?${urlParams}`}
            color="success"
            onClick={onStartClick}
          >{text}</Button>
        )
      case StartDisplayMode.COPY:
        return (
          <>
            <span>
              {!secondaryStartDisplay && (t("lmsPlay.deviceOrBrowserNotSupported") + "\n")}
              {t("lmsPlay.openInAnotherBrowser")}
            </span>
            <FSQRCode value={urlString} qrProps={{size: 256}}/>
            <CopyText onClick={onStartClick} value={urlString}/>
          </>
        )
      case StartDisplayMode.OPENER:
        return (
          <Button
            onClick={() => {
              onNewWindowClick();
              onStartClick();
            }}
            color="success"
          >{text}</Button>
        )
      default:
        throw new Error(`mode ${mode} have no corresponding display`)
    }
  }, [mode, lessonId, onNewWindowClick, onStartClick, t, text, urlParams, urlString, secondaryStartDisplay])

  return (
    <div {...props}>
      {startDisplay}
    </div>
  )
}
