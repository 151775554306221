import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import isEqual from "lodash/isEqual";
import ActionDisplay from "./ActionDisplay";
import {EducationSessionErrorReason} from "../../../../schema";
import Display from "./Display";
import {skipError} from "../../../../redux/player/actions";
import {PlayerReduxState, UserInputDisplayMode} from "../../../../redux/player/types";
import {getCurrentActionDisplayMode, getLastUserInputResult} from "../../../../redux/player/selectors";
import {ERROR_DEFAULT_DURATION, ERROR_EXTENDED_DURATION} from "../../../../settings";


const UserInputErrorDisplay = () => {
  const {t} = useTranslation();
  const {
    isHintTextMode,
    errorReason,
    recognizedText
  } = useSelector(UserInputErrorDisplay.selector, isEqual);
  const dispatch = useDispatch();
  const handleSkipError = useCallback(() => dispatch(skipError()), [dispatch])

  let text: string

  switch (errorReason) {
    case EducationSessionErrorReason.BAD_WORD:
      text = t("player.errorMessages.badWord")
      break;
    case EducationSessionErrorReason.NO_ANSWER:
      text = t("player.errorMessages.noAnswer")
      break;
    case EducationSessionErrorReason.TIME_EXPIRED:
      text = t("player.errorMessages.timeExpired");
      break;
    default:
      console.error(`No realization for EducationSessionErrorReason: ${errorReason} in VideoCall player`)
    // falls through
    case EducationSessionErrorReason.WRONG_ANSWER:
      text = t("player.errorMessages.wrongAnswer");
      break;
    case EducationSessionErrorReason.MISSING_KEYWORD:
      text = t("player.errorMessages.missingKeyword");
      break;
    case EducationSessionErrorReason.WRONG_CHOICE:
      text = t("player.errorMessages.wrongChoice");
      break;
    case EducationSessionErrorReason.DAILO_SPEECH_TIMEOUT:
      text = t("player.errorMessages.dailoSpeechTimeout");
      break;
    case EducationSessionErrorReason.DAILO_SPEECH_HTTP_ERROR:
      text = t("player.errorMessages.dailoSpeechHttpError");
      break;
    case EducationSessionErrorReason.DAILO_STT_ERROR:
      text = t("player.errorMessages.dailoSttError");
      break;
  }

  if (isHintTextMode) {
    text += t("player.errorMessages.expectedTextExtra")
  }

  useEffect(() => {
    const t = setTimeout(() => handleSkipError(), isHintTextMode ? ERROR_EXTENDED_DURATION : ERROR_DEFAULT_DURATION);

    return () => clearTimeout(t);
  }, [handleSkipError, isHintTextMode]);


  return (
    <ActionDisplay title={t("common.error")}>
      {text}
      {recognizedText && (
        <Display.Extra translate="no">
          {t("player.recognizedText")}: {recognizedText}
        </Display.Extra>
      )}
    </ActionDisplay>
  )
}

UserInputErrorDisplay.selector = (state: PlayerReduxState) => ({
  isHintTextMode: getCurrentActionDisplayMode(state) === UserInputDisplayMode.HINT_TEXT ? true : false,
  errorReason: getLastUserInputResult(state)?.errorState,
  recognizedText: getLastUserInputResult(state)?.recognizedText,
})

export default UserInputErrorDisplay;
