import React from "react";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import classes from "../account/AssignModal.module.css";
import {useTranslation} from "react-i18next";
import {HotWordsDictionary, HotWordsDictionaryInput} from "../../schema";
import Input from "../../ui/input";

type contentProps = React.ComponentProps<"div"> & {
  dict?: HotWordsDictionary
  onSave?: (data: HotWordsDictionaryInput) => void
  onDelete?: () => void
}

function HotWordsDictionaryModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.AddMembersModal.AddMembersModalTitle")}</span>
}

 function HotWordsDictionaryModalContent({dict, onSave, onDelete}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    name: string,
    text: string,
  }>({
    initialValues: {
      name: dict?.name ?? "",
      text: dict?.text ?? "",
    },

    preventDefault: true,

    onSubmit: (data) => {
      onSave && onSave(data);
    }
  });

  const submitDisabled = undefined

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.HotWordsDictionaryModal.nameHelpText")}</p>
        <Input
          className={classes.input}
          name="name"
          value={formState.values.name ?? ""}
          required
          maxLength={36}
          placeholder={t("components.HotWordsDictionaryModal.namePlaceholder")}
          onChange={formState.changeHandler}
        />

        <p className={classes.helpText}>{t("components.HotWordsDictionaryModal.phrasesHelpText")}</p>
        <Textarea
          className={classes.input}
          name="text"
          value={formState.values.text}
          rows={8}
          required
          placeholder={t("components.HotWordsDictionaryModal.phrasesPlaceholder")}
          onChange={formState.changeHandler}
        />
      </>

      <div className={classes.bottom}>
        <Button type="submit" color={submitDisabled ? "secondary" : "success"} disabled={submitDisabled}>
          {t("components.CutWordsDictionaryModal.submit")}
        </Button>
        {dict && (
          <Button type="button" color="danger" onClick={onDelete}>
            {t("components.CutWordsDictionaryModal.delete")}
          </Button>
        )}
      </div>
    </form>
  )
}

const HotWordsDictionaryModal = {
  Header: HotWordsDictionaryModalHeader,
  Content: HotWordsDictionaryModalContent,
}

export default HotWordsDictionaryModal;
