import {useMemo} from "react"
import {mapObject} from "../utils"
import cn from "classnames";

export default function useAdaptiveClasses(
  classes: {[key: string]: string;},
  adaptive?: boolean
) {
  return useMemo(() => (
    adaptive
      ? mapObject(classes, (item) => cn(item, classes.adaptive))
      : classes
  ), [adaptive, classes])
}