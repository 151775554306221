import React, {ComponentProps, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../hooks/useFormState";
import useVisibility from "../../hooks/useVisibility";

import IconButton from "../../ui/iconbutton";
import {QRCodeIcon} from "../../ui/icons";
import Input from "../../ui/input";
import Link from "../../ui/link";
import PopupPortal from "../../ui/popupportal";
import Select from "../../ui/select";
import Widget from "../../ui/widget";
import CopyQRCode from "../CopyQRCode";
import CopyText from "../CopyText";

import classes from "./UTMCreateModal.module.css";

type contentProps = Omit<ComponentProps<"div">, "className"> & {
  value: string
}

function UTMCreateModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.UTMCreateModal.title")}</span>
}

function UTMCreateModalContent({value, ...props}: contentProps) {
  const {t} = useTranslation();

  const [termsAndContentVisible, showTermsAndContent] = useVisibility();
  const [sourceInputVisible, showSourceInput, hideSourceInput] = useVisibility();
  const [mediumInputVisible, showMediumInput, hideMediumInput] = useVisibility();
  const [qrCodeWidgetVisible, showQRCodeWidget, hideQRCodeWidget] = useVisibility();

  const [url, setUrl] = useState(new URL(value));

  const formState = useFormState<{
    source: string
    medium: string
    campaign: string
    term: string
    content: string
  }>({
    initialValues: {
      source: "",
      medium: "",
      campaign: "",
      term: "",
      content: ""
    },

    preventDefault: true,
    onChange: (data) => {
      const targetUrl = new URL(value);

      for (const parameter in data) {
        const value = data[parameter as keyof typeof data];
        if (value !== "") {
          targetUrl.searchParams.set(`utm_${parameter}`, value);
        }
      }

      setUrl(targetUrl);
    }
  });

  const {source, medium, campaign} = formState.values;

  const canCopy = [source, medium, campaign].every(haveText);

  return (
    <div className={classes.root} {...props}>
      <p className={classes.helpText}>{t("components.UTMCreateModal.helpText")}</p>

      <p className={classes.helpText}>
        <b>{t("components.UTMCreateModal.source.name")}.</b> {t("components.UTMCreateModal.source.helpText")}
      </p>
      {!sourceInputVisible ? (
        <>
          <Select
            className={classes.input}
            name="source"
            value={formState.values.source}
            onChange={formState.changeHandler}
          >
            <option value="">
              {t("components.UTMCreateModal.source.no")}
            </option>
            <option value="email">
              {t("components.UTMCreateModal.source.email")}
            </option>
          </Select>
          {!haveText(source) && <p className={classes.subText}>{t("components.UTMCreateModal.required")}</p>}
          <Link className={classes.linkButton} onClick={showSourceInput}>
            {t("components.UTMCreateModal.editManually")}
          </Link>
        </>
      ) : (
        <>
          <Input
            className={classes.input}
            name="source"
            value={formState.values.source}
            onChange={formState.changeHandler}
          />
          {!haveText(source) && <p className={classes.subText}>{t("components.UTMCreateModal.required")}</p>}
          <Link className={classes.linkButton} onClick={hideSourceInput}>
            {t("components.UTMCreateModal.editBySelect")}
          </Link>
        </>
      )}

      <p className={classes.helpText}>
        <b>{t("components.UTMCreateModal.medium.name")}.</b> {t("components.UTMCreateModal.medium.helpText")}
      </p>
      {!mediumInputVisible ? (
        <>
          <Select
            className={classes.input}
            name="medium"
            value={formState.values.medium}
            onChange={formState.changeHandler}
          >
            <option value="">
              {t("components.UTMCreateModal.medium.no")}
            </option>
            <option value="email">
              {t("components.UTMCreateModal.medium.email")}
            </option>
          </Select>
          {!haveText(medium) && <p className={classes.subText}>{t("components.UTMCreateModal.required")}</p>}
          <Link className={classes.linkButton} onClick={showMediumInput}>
            {t("components.UTMCreateModal.editManually")}
          </Link>
        </>
      ) : (
        <>
          <Input
            className={classes.input}
            name="medium"
            value={formState.values.medium}
            onChange={formState.changeHandler}
          />
          {!haveText(medium) && <p className={classes.subText}>{t("components.UTMCreateModal.required")}</p>}
          <Link className={classes.linkButton} onClick={hideMediumInput}>
            {t("components.UTMCreateModal.editBySelect")}
          </Link>
        </>
      )}

      <p className={classes.helpText}>
        <b>{t("components.UTMCreateModal.campaign.name")}.</b> {t("components.UTMCreateModal.campaign.helpText")}
      </p>
      <Input
        className={classes.input}
        name="campaign"
        value={formState.values.campaign}
        onChange={formState.changeHandler}
        required
      />
      {!haveText(campaign) && <p className={classes.subText}>{t("components.UTMCreateModal.required")}</p>}

      {!termsAndContentVisible ? (
        <Link className={classes.linkButton} onClick={showTermsAndContent}>
          {t("components.UTMCreateModal.showTermsAndContent")}
        </Link>
      ) : (
        <>
          <p className={classes.helpText}>
            <b>{t("components.UTMCreateModal.content.name")}.</b> {t("components.UTMCreateModal.content.helpText")}
          </p>
          <Input
            className={classes.input}
            name="content"
            value={formState.values.content}
            onChange={formState.changeHandler}
          />

          <p className={classes.helpText}>
            <b>{t("components.UTMCreateModal.term.name")}.</b> {t("components.UTMCreateModal.term.helpText")}
          </p>
          <Input
            className={classes.input}
            name="term"
            value={formState.values.term}
            onChange={formState.changeHandler}
          />
        </>
      )}
      <p className={classes.helpText}>{t("components.UTMCreateModal.copyText.helpText")}</p>
      <div className={classes.copyContainer}>
        <CopyText className={classes.copyText} value={url.toString()} disabled={!canCopy}/>
        <IconButton
          icon={QRCodeIcon}
          size="l"
          className={classes.qrButton}
          disabled={!canCopy}
          onClick={showQRCodeWidget}
        />
      </div>
      {qrCodeWidgetVisible && (
        <PopupPortal center>
          <Widget showCloseButton onCloseButtonClick={hideQRCodeWidget}>
            <Widget.Title>{t("components.UTMCreateModal.qr.title")}</Widget.Title>
            <CopyQRCode value={url.toString()} qrProps={{size: 256}}/>
          </Widget>
        </PopupPortal>
      )}
    </div>
  )
}

function haveText(value: string) {
  return value !== "";
}

const UTMCreateModal = {
  Header: UTMCreateModalHeader,
  Content: UTMCreateModalContent
}

export default UTMCreateModal;
