import {useHistory} from "react-router";
import {useCallback, useEffect, useState} from "react";
import {History} from "history";
import isEqual from "lodash/isEqual";

type SearchParamConfigParam = (
  { type: "boolean", defaultValue?: boolean | null }
  | { type: "string", defaultValue?: string }
  | { type: "integer", defaultValue?: number | null }
  | { type: "float", defaultValue?: number | null }
  )

export type SearchParamsConfig = {
  [key: string]: SearchParamConfigParam
}

type SearchParams<T extends SearchParamsConfig> = {
  [key in keyof T]: ({
    boolean: boolean | null,
    string: string,
    integer: number | null,
    float: number | null
  })[(T[key]["type"])]
}

export default function useSearchParamsState<T extends SearchParamsConfig>(config: T): [
  SearchParams<T>, (newValue: SearchParams<T>) => void
] {
  const history = useHistory();
  const [value, _setValue] = useState<SearchParams<T>>(() => readQueryParams(history, config));

  useEffect(() => {
    return history.listen(() => {
      const newValue = readQueryParams(history, config);

      _setValue((prevValue: any) => {
        if (isEqual(prevValue, newValue)) {
          return prevValue;
        }

        return newValue;
      });
    })
  }, [history, config]);

  const setValue = useCallback((values: SearchParams<T>) => {
    const searchParams = new URLSearchParams(history.location.search);

    Object.keys(values).forEach((key) => {
      const serialized = toURL(config[key], values[key]);

      if (serialized === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, serialized);
      }
    });

    history.push(history.location.pathname + "?" + searchParams.toString() + "#" + history.location.hash);
  }, [history, config]);

  return [value, setValue];
}


function readQueryParams<T extends SearchParamsConfig>(history: History, config: T): SearchParams<T> {
  const searchParams = new URLSearchParams(history.location.search);
  const values: SearchParams<T> = {} as SearchParams<T>;

  Object.keys(config).forEach((key) => {
    values[key as (keyof T)] = fromURL(config[key], searchParams.get(key)) as any;
  });

  return values
}


function toURL(param: SearchParamConfigParam, value: SearchParamConfigParam["defaultValue"]): string | null {
  const type = param.type;

  value = value ?? null;

  if (value === getDefaultValue(param)) {
    return null;
  }

  if (value === null) {
    return "";
  }

  switch (type) {
    case "string":
      return (value as string).toString();
    case "boolean":
      return (value as boolean) ? "1" : "0";
    case "integer":
      return Math.floor((value as number)).toString();
    case "float":
      return (Math.floor((value as number) * 100) / 100).toString();
    default:
      /* istanbul ignore next */
      throw new Error(`${type} cannot be serialized`);
  }
}

function fromURL<T extends SearchParamConfigParam>(param: T, value: string | null): T["defaultValue"] {
  let parsedInt, parsedFloat;
  const type = param.type;

  if (value === null) {
    return getDefaultValue(param);
  }

  switch (type) {
    case "string":
      return value;

    case "boolean":
      switch (value.toLowerCase()) {
        case "":
          return null;

        case "1":
        case "true":
        case "yes":
        case "on":
          return true;

        case "0":
        case "false":
        case "no":
        case "off":
          return false;

        default:
          return param.defaultValue ?? null;
      }

    case "integer":
      if (value === "") {
        return null;
      }

      parsedInt = parseInt(value, 10);
      if (isNaN(parsedInt)) {
        return param.defaultValue ?? null;
      }

      return Math.floor(parsedInt);

    case "float":
      if (value === "") {
        return null;
      }

      parsedFloat = parseFloat(value);
      if (isNaN(parsedFloat)) {
        return param.defaultValue ?? null;
      }

      return parsedFloat;

    default:
      /* istanbul ignore next */
      throw new Error(`${type} cannot be deserialized`);
  }
}

function getDefaultValue<T extends SearchParamConfigParam>(param: T): T["defaultValue"] {
  if (param.type === "string") {
    return param.defaultValue ?? "";
  }

  return param.defaultValue ?? null;
}
