export async function recordAudio(constraints?: MediaStreamConstraints) {
  const stream = await navigator.mediaDevices.getUserMedia({audio: {noiseSuppression: true},  ...constraints});
  const mediaRecorder = new MediaRecorder(stream);
  const audioChunks: Blob[] = [];

  mediaRecorder.addEventListener("dataavailable", event => {
    audioChunks.push(event.data);
  });

  const start = () => mediaRecorder.start();
  const stop = () =>
    new Promise<{
      audioBlob: Blob, audioUrl: string, play: () => void
    }>(resolve => {
      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks, {type: "audio/ogg"});
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        const play = () => audio.play();
        resolve({audioBlob, audioUrl, play});
      });

      mediaRecorder.stop();
    });
  return {start, stop, state: mediaRecorder.state};
}
