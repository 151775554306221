import {useState, useCallback} from "react"

type Show = () => void
type Hide = () => void

export default function useVisibility(initialState?: boolean): [
  boolean, Show, Hide, React.Dispatch<React.SetStateAction<boolean>>
] {
  const [visibility, setVisibility] = useState<boolean>(initialState ?? false)
  const show = useCallback(() => setVisibility(true), [])
  const hide = useCallback(() => setVisibility(false), [])
  return [visibility, show, hide, setVisibility]
}