import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getCurrentAction} from "../selectors";
import {isAvatarAction, isSystemAction} from "../../../types";

export default function setCurrentBackground() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    const action = getCurrentAction(state);

    if (!action) {
      console.warn("reveal: wrong player state");
      return;
    }

    if (isAvatarAction(action) || isSystemAction(action)) {
      if (!action.setMediaAsBackground) {
        return;
      }
      dispatch({
        type: "update",
        payload: {
          currentBackground: action.media,
        }
      });
    }
  }
}
