import {LessonMode} from "../../../schema";
import {PlayerReduxState} from "../types";
import getCurrentAction from "./getCurrentAction";
import isRecognizing from "./isRecognizing";
import isErrorState from "./isErrorState";
import {isAnyUserInputAction} from "../utils";
import {isUserInputAction} from "../../../types";

export default function canUseHint(state: PlayerReduxState): boolean {
  if (isRecognizing(state) || isErrorState(state)) {
    return false;
  }

  const action = getCurrentAction(state);

  if (!action || !isAnyUserInputAction(action)) {
    return false;
  }

  if (isUserInputAction(action) && action.hintText && !state.exposedActions.includes(action.id)) {
    return true;
  }

  switch (state.lesson.mode) {
    case LessonMode.STUDY_THREE_STEP:
    case LessonMode.STUDY_TWO_STEP:
    case LessonMode.CHOICE_BASED_STUDY:
    case LessonMode.CHOICES_TEST_TWO_STEP:
    case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
    case LessonMode.CUSTOM_PARAMETERS_TEST:
      return (state.level !== 1) && !state.exposedActions.includes(action.id);

    case LessonMode.INTERVIEW:
    case LessonMode.QUIZ:
      return false;

    default:
      console.warn("canUseHint: unsupported lesson mode");
      return false;
  }
}
