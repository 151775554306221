import React, {ElementType} from "react"
import {IconType} from "./icons"
import {AsSpecificComponent} from ".";

import Button from "./button";

import cn from "classnames";
import classes from "./iconbutton.module.css";

type props = {
  icon: IconType
}

type IconButtonProps<T extends ElementType> =
  Omit<AsSpecificComponent<typeof Button, T>, "children" | "color" | "disabledColor" | "icon"> & props

const defaultProps = {
  size: "m" as "m"
}

export default function IconButton<T extends ElementType>({
  icon: Icon, className, size, ...props
}: IconButtonProps<T>) {
  return (
    <Button className={cn(classes.root, classes[size], className)} {...props}>
      <Icon/>
    </Button>
  )
}

IconButton.defaultProps = defaultProps
