import React from "react";
import {useTranslation} from "react-i18next";
import WithTooltip from "../../ui/tooltip";
import BaseUserIcon from "../BaseUserIcon";
import classes from "./UserIcon.module.css";

const UserIcon = React.memo(function (props: React.ComponentProps<"div">) {
  const {t} = useTranslation();

  return (
    <WithTooltip
      showAfter={1000}
      helpText={t("components.UserIcon.helpText")}
      {...props}
    >
      <BaseUserIcon className={classes.root}/>
    </WithTooltip>
  )
});

export default UserIcon;
