import {applyMiddleware, createStore} from "redux";
import reduxThunk from "redux-thunk";
import rootReducer from "./rootReducer";
import {PlayerReduxState} from "./types";
import {Lesson, Scalars} from "../../schema";
import rootMiddleware from "./rootMiddleware";
import {Scenario} from "../../types";
import {UTM} from "../../libs/analytics";

export const initialReduxState: Omit<PlayerReduxState,
  "client" | "audioManager" | "lessonId" | "lesson" | "scenarios"> = {
  sessionId: null,
  sessionStartFailed: undefined,
  sessionResumeFailed: undefined,

  isPreview: false,
  isShared: false,
  isExternal: false,
  externalUserId: null,
  recognitionEngine: undefined,
  recognitionPipeline: undefined,

  level: 1,
  index: -1,
  maxIndexes: [-1],

  scenarioIndex: 0,
  mainScenarioLastIndex: -1,

  branchId: undefined,
  branchIndex: undefined,

  exposedActions: [],
  chosenBranches: new Set(),
  isRecognizing: false,
  isHintConfirmation: false,

  interrupted: false,
  interruptReason: null,

  lastUserInputResult: null,
  currentActionAttempts: 0,
  feedbackSent: false,

  currentBackground: null,
  utm: null,
}

type opts = {
  client: PlayerReduxState["client"],
  audioManager: PlayerReduxState["audioManager"],
  toggleRecorder: PlayerReduxState["toggleRecorder"],
  raiseProblem: (key: string) => boolean,
  askForCameraPermission?: boolean,
  lessonId: Scalars["ID"],
  lesson: Lesson,
  scenarios: Scenario[],
  isShared?: boolean,
  isPreview?: boolean
  isExternal?: boolean
  externalUserId?: Scalars["ID"]
  initialState?: Partial<typeof initialReduxState>
  utm?: UTM
}

export type PlayerReduxStore = ReturnType<typeof createPlayerReduxStore>;

export default function createPlayerReduxStore({
                                                 client,
                                                 audioManager,
                                                 toggleRecorder,
                                                 raiseProblem,
                                                 lessonId,
                                                 lesson,
                                                 scenarios,
                                                 isShared,
                                                 isPreview,
                                                 isExternal,
                                                 externalUserId,
                                                 initialState,
                                                 utm
                                               }: opts) {
  return createStore(
    rootReducer,
    {
      client,
      audioManager,
      toggleRecorder,
      raiseProblem,
      lessonId,
      lesson,
      scenarios,
      ...initialReduxState,
      ...initialState,
      isShared: !!isShared,
      isPreview: !!isPreview,
      isExternal: !!isExternal,
      externalUserId: externalUserId ?? null,
      utm: utm ?? null
    },
    applyMiddleware(reduxThunk, rootMiddleware)
  );
}
