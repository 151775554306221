import {PlayerReduxDispatch} from "../types";

export default function changeRecognizingState(state: boolean) {
  return (dispatch: PlayerReduxDispatch) => {
    dispatch({
      type: "update",
      payload: {isRecognizing: state}
    });
    return;
  }
}
