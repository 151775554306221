/* istanbul ignore file */
import Bowser from "bowser";
import Parser from "bowser/src/parser";
import Utils from "bowser/src/utils";
import {DEV} from "./settings";

interface ExtendedParser extends Bowser.Parser.Parser {
  /**
   * Check if parsed OS matches certain conditions
   *
   * @param {checkTree} checkTree It's one layered object,
   * which should have an OS on the first layer
   *
   * @returns {Boolean|undefined} Whether the OS satisfies the set conditions or not.
   * Returns `undefined` when the OS is no described in the checkTree object.
   *
   * @example
   * const browser = new Bowser(UA);
   * if (browser.satisfiesOS({ios: "14.3" }))
   */
  satisfiesOS: (checkTree: checkList) => boolean | undefined

  /**
   * Compare target version with current version
   *
   * @param {targetVersion} targetVersion Version to check,
   * starts with prefixes ">", "<", "=" ">=", "<=" or "~"
   *
   * @param {currentVersion} currentVersion Current version,
   * checks if it meets target version
   *
   * @returns
   * Whether current version satisfies target or not.
   */
  compareVersions: (targetVersion: string, currentVersion: string) => boolean

  /**
   * Check if current browser is WebView
   */
  isWebView: () => boolean

  /**
   * Check if current browser is WebView on Android
   */
  isAndroidWebView: () => boolean

  /**
   * Check if current browser is WebView on iOS
   */
  isIOSWebView: () => boolean

  /**
   * Check if current browser is WebView on iPad in desktop mode
   */
  isIPadWebviewDesktop: () => boolean
}

interface checkList {
  [key: string]: string;
}

class ExParser extends Parser implements ExtendedParser {
  /**
   * Create instance of Parser
   *
   * @param {String} UA User-Agent string
   * @param {Boolean} [skipParsing=false] parser can skip parsing in purpose of performance
   * improvements if you need to make a more particular parsing
   * like {@link Parser#parseBrowser} or {@link Parser#parsePlatform}
   *
   * @throw {Error} in case of empty UA String
   *
   * @constructor
   */
  constructor(UA: string, skipParsing: boolean = false) {
    super(UA, skipParsing)
  }

  compareVersions(version: string, currentVersion: string) {
    let expectedResults = [0];
    let comparableVersion = version;
    let isLoose = false;

    if (version[0] === ">" || version[0] === "<") {
      comparableVersion = version.substr(1);
      if (version[1] === "=") {
        isLoose = true;
        comparableVersion = version.substr(2);
      } else {
        expectedResults = [];
      }
      if (version[0] === ">") {
        expectedResults.push(1);
      } else {
        expectedResults.push(-1);
      }
    } else if (version[0] === "=") {
      comparableVersion = version.substr(1);
    } else if (version[0] === "~") {
      isLoose = true;
      comparableVersion = version.substr(1);
    }

    return expectedResults.indexOf(
      Utils.compareVersions(currentVersion, comparableVersion, isLoose),
    ) > -1;
  }

  satisfiesOS(checkList: checkList) {
    const allDefinitions = Object.keys(checkList);

    const currentVersion = this.getOSVersion()

    const matchingDefinition = allDefinitions.find(this.isOS, this)

    if (matchingDefinition !== undefined) {
      return this.compareVersions(checkList[matchingDefinition], currentVersion);
    }
  }

  satisfiesChromium(version: string) {
    if (this.getEngineName() !== "Blink") {
      return false
    }
    const currentVersion = /Chrome\/(.*?)\s/i.exec(this.getUA())![1];
    return this.compareVersions(version, currentVersion);
  }


  isWebView() {
    return this.isAndroidWebView() || this.isIOSWebView() || this.isIPadWebviewDesktop()
  }

  isAndroidWebView() {
    return this.test(/(Android)(?=.*wv)/i)
  }

  isIOSWebView() {
    return this.test(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i)
  }

  isIPadWebviewDesktop() {
    return (
      !!this.satisfiesOS({
        "macos": ">=10.15"
      }) && (this.getPlatformType(true) === "mobile")
    )
  }
}

const userAgent = window.navigator.userAgent;
const browser = new ExParser(userAgent);

if (DEV) {
  console.debug("Platform:", browser.getPlatformType(true));
  console.debug("OS:", browser.getOSName(true), browser.getOSVersion());
  console.debug("Browser:", browser.getBrowserName(true), browser.getBrowserVersion());
  console.debug("Engine:", browser.getEngine().name, browser.getEngine().version);
}


/**
 * Check if current browser is supported by bundle
 */
export function isBrowserSupported(bundleName?: string) {
  switch (bundleName) {
    case "player":
      return browser.satisfies({
        "safari": ">=13",
        "chrome": ">=64",
        "firefox": ">=58",
        "edge": ">=79",
        "opera": ">=51",
        "samsung_internet": ">=7.4"
      }) || browser.satisfiesOS({
        "ios": ">=14.3"
      }) || browser.satisfiesChromium(
        ">=64"
      ) || browser.isIPadWebviewDesktop()

    default:
      return browser.satisfies({
        "safari": ">=7",
        "chrome": ">=64",
        "firefox": ">=58",
        "edge": ">=79",
        "opera": ">=51",
        "samsung_internet": ">=7.4"
      }) || browser.satisfiesOS({
        "ios": ">=7"
      }) || browser.satisfiesChromium(
        ">=64"
      ) || browser.isIPadWebviewDesktop()
  }
}

/**
 * Check if current platform is desktop
 */
export function isDesktop() {
  return browser.getPlatformType(true) === "desktop";
}

/**
 * Check if current platform is mobile
 */
export function isMobile() {
  return browser.getPlatformType(true) === "mobile";
}

/**
 * Check if current device is iPhone
 */
export function isIPhone() {
  return browser.getPlatformType(true) === "mobile" && browser.getOSName(true) === "ios";
}

/**
 * Check if current browser is Safari
 */
export function isSafari() {
  return browser.getBrowserName(true) === "safari";
}

export default browser;
