import React, {NamedExoticComponent} from "react";
import {gql} from "@apollo/client";

import cn from "classnames";
import {Avatar} from "../schema";

import classes from "./AvatarIcon.module.css";
import WithTooltip from "../ui/tooltip";
import {useTranslation} from "react-i18next";

type props = React.ComponentProps<"div"> & {
  avatar: Avatar,
  adaptive?: boolean,
}

type AvatarIconType = NamedExoticComponent<props> & {
  fragments: typeof fragments
}

const AvatarIcon: AvatarIconType = React.memo(
  function ({avatar, className: extraClassName, adaptive, ...props}: props) {
    const {t} = useTranslation();
    const [hasPreview, setHasPreview] = React.useState(!!avatar.preview);
    const disablePreview = React.useCallback(() => setHasPreview(false), [setHasPreview]);

    return (
      <WithTooltip
        className={cn(classes.root, {[classes.adaptive]: adaptive}, extraClassName)}
        showAfter={1000}
        helpText={t("components.AvatarIcon.helpText", {name: avatar.name})}
        {...props}
      >
        {hasPreview ? (
          <img src={avatar.preview!} alt={avatar.name} onError={disablePreview}/>
        ) : (
          <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
            <circle fill="currentColor" r="50%" cx="50%" cy="50%"/>
            <text textAnchor="middle" x="50%" y="80" fill="#fff" fontFamily="Helvetica" fontSize="52"
                  fontWeight="bold">
              {t("components.AvatarIcon.letter")}
            </text>
          </svg>
        )}
      </WithTooltip>
    )
  }
) as AvatarIconType;

const fragments = {
  root: gql`
    fragment AvatarIcon on Avatar {
      id
      name(languageCode: $language)
    }
  `
}

AvatarIcon.fragments = fragments;

export default AvatarIcon;
