import {PlayerReduxState} from "../types";
import getCurrentAction from "./getCurrentAction";
import isRecognizing from "./isRecognizing";
import isErrorState from "./isErrorState";
import {isAnyUserInputAction} from "../utils";

export default function canRecord(state: PlayerReduxState): boolean {
  if (isRecognizing(state) || isErrorState(state)) {
    return false;
  }

  const action = getCurrentAction(state);

  return !!action && isAnyUserInputAction(action);
}
