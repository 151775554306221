import {PlayerReduxState} from "../types";
import {isAnyUserInputAction} from "../utils";
import getCurrentScenario from "./getCurrentScenario";

export default function getRepeatFromIndex(state: PlayerReduxState, index?: number): number | null {
  if (index === undefined) {
    index = state.index
  }

  for (let i = index - 1; i >= 0; i--) {
    const action = getCurrentScenario(state).actions[i];

    if (isAnyUserInputAction(action)) {
      return i + 1;
    }
  }

  return getCurrentScenario(state).actions[0] ? 0 : null;
}
