import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import useVisibility from "../../hooks/useVisibility";
import useAdaptiveClasses from "../../hooks/useAdaptiveClasses";
import {EducationSessionResult, DetailedSessionFeedbackInput} from "../../schema";
import {useFormState} from "../../hooks/useFormState";
import {mapObject} from "../../utils";

import Button from "../../ui/button";
import StarRating from "../StarRating";
import Textarea from "../../ui/textarea";
import PopupPortal from "../../ui/popupportal";
import Modal, {ModalContent, ModalFooter, ModalHeader} from "../../ui/modal";
import Link from "../../ui/link";
import DetailedFeedback from "./DetailedFeedback";

import cn from "classnames";
import classes from "./FeedbackForm.module.css";

type props = React.ComponentProps<"div"> & {
  onFeedback?: (score?: number, data?: DetailedSessionFeedbackInput, message?: string, contacts?: string) => void
  result?: Pick<EducationSessionResult, "feedbackText" | "feedbackScore" | "feedbackContacts" | "detailedFeedback">
  adaptive?: boolean
  modalVisibleByDefault?: boolean
  isQuizMode?: boolean
}

type formState = {
  score: number | undefined,
  message?: string,
  contacts?: string,
  data: DetailedSessionFeedbackInput
}

const defaultData = {
  recognition: false,
  performance: false,
  avatar: false,
  content: false,
  format: false,
  difficulty: false,
  hardware: false,
}

export default function FeedbackForm({
  onFeedback, result, modalVisibleByDefault, isQuizMode, adaptive, className, ...props
}: props) {
  const {t} = useTranslation();

  const ac = useAdaptiveClasses(classes, adaptive)

  const [
    feedbackModalVisible, showFeedbackModal, hideFeedbackModal
  ] = useVisibility(modalVisibleByDefault && !result?.feedbackScore);
  const [feedbackSent, setFeedbackSent] = useState<boolean>(!!result?.feedbackScore)

  const {__typename, ...inputFromFeedback} = result?.detailedFeedback ?? {};

  const formState = useFormState<formState>({
    initialValues: {
      score: result?.feedbackScore ?? undefined,
      message: result?.feedbackText ?? undefined,
      contacts: result?.feedbackContacts ?? undefined,
      data: result?.detailedFeedback ? {...defaultData, ...inputFromFeedback} : defaultData
    },
    preventDefault: true,
    onSubmit: (values) => {
      hideFeedbackModal();
      setFeedbackSent(true);
      onFeedback && onFeedback(values.score, values.data, values.message, values.contacts);
    }
  });

  const setDetailedFeedback = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    const el = e.currentTarget ?? e.target;
    const name = el.getAttribute("name");
    if (!name) {
      return;
    }
    const value = el.checked

    const newData = {
      ...formState.values.data,
      [name]: value
    }

    formState.setValues({
      data: newData,
    })
  }, [formState])

  const setScoreValue = useCallback((score) => {
    if (isPositiveScore(score) !== isPositiveScore(formState.values.score)) {
      formState.setValues({
        score,
        data: mapObject(formState.values.data, () => false)
      })
    }
    formState.setValues({score})
  }, [formState])

  const onStarRate = useCallback((value: number) => {
    formState.setValues({score: value});
    onFeedback && onFeedback(value);
    showFeedbackModal();
  }, [showFeedbackModal, onFeedback, formState])

  const {score, message} = formState.values

  const disableSubmit = (score === undefined) && (message === undefined)
  const question = isQuizMode ? t("components.FeedbackForm.questionAlt") : t("components.FeedbackForm.question")
  const detailsPositive = isQuizMode
    ? t("components.FeedbackForm.details.positiveAlt")
    : t("components.FeedbackForm.details.positive")

  return (
    <div className={cn(ac.root, className)} {...props}>
      {!feedbackSent && (
        <span className={ac.helpText}>
          {question}
        </span>
      )}

      <StarRating
        adaptive={adaptive}
        className={ac.starRating}
        onValueChange={onStarRate}
        value={score}
        disabled={feedbackSent}
      />

      {!feedbackSent ? (
        <Link type="button" className={ac.linkButton} onClick={showFeedbackModal}>
          {t("components.FeedbackForm.leave")}
        </Link>
      ) : (
        <span className={ac.helpText}>
          {t("components.FeedbackForm.thanks")}
        </span>
      )}

      {feedbackModalVisible && (
        <PopupPortal center as="form"
          role="form"
          method="post"
          onSubmit={formState.submitHandler}
          autoComplete="off"
        >
          <Modal adaptive
            size="m"
            onClose={hideFeedbackModal}
          >
            <ModalHeader>
              <span>{t("components.FeedbackForm.title")}</span>
            </ModalHeader>
            <ModalContent className={ac.modalContent}>
              <span className={ac.helpText}>{question}</span>
              <StarRating adaptive={adaptive} className={ac.starRating} onValueChange={setScoreValue} value={score}/>
              <span className={ac.helpText}>{t("components.FeedbackForm.shareOpinion")}</span>
              <Textarea adaptive
                className={ac.textarea}
                name="message"
                rows={4}
                value={formState.values.message}
                onChange={formState.changeHandler}
              />
              <span className={ac.helpText}>{t("components.FeedbackForm.contacts")}</span>
              <Textarea adaptive
                className={ac.textarea}
                name="contacts"
                rows={1}
                value={formState.values.contacts}
                onChange={formState.changeHandler}
              />
              {!!score && (
                <div className={ac.details}>
                  <span className={ac.helpText}>

                    {(score === 5)
                      ? detailsPositive
                      : t("components.FeedbackForm.details.negative")
                    }
                  </span>
                  <DetailedFeedback
                    adaptive={adaptive}
                    altLocales={isQuizMode}
                    isPositive={isPositiveScore(score)}
                    values={formState.values.data}
                    changeHandler={setDetailedFeedback}
                  />
                </div>
              )}
            </ModalContent>
            <ModalFooter className={ac.modalFooter}>
              <Button
                type="submit"
                size='l'
                color={disableSubmit ? "secondary" : "primary"}
                disabled={disableSubmit}
                className={cn(ac.btn, ac.feedbackSendButton)}
              >
                {t("components.FeedbackForm.send")}
              </Button>
            </ModalFooter>
          </Modal>
        </PopupPortal>
      )}
    </div>
  );
}

function isPositiveScore(score: number | undefined) {
  return (score ?? 0) > 4
}
