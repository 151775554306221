import {gql, useMutation} from "@apollo/client";
import CourseCustomizeForm from "../../components/editor/CourseCustomizeForm";
import {Course, LanguageCode, MutationUpdateChildrenArgs, MutationUpdateCourseArgs} from "../../schema";

export type queryVars = {
  courseId: Course["id"],
  language: LanguageCode
}

export type queryData = {
  course: Course,
  courses: Course[]
}

export const query = gql`
  query EditorEditCourseSceneQuery($courseId: ID!) {
    course: getCourse(courseId: $courseId) {
      accessToken
      ...CourseCustomizeForm
    }

    courses: getCourses {
      __typename
      id
      name
    }
  }
  ${CourseCustomizeForm.fragments.root}
`;

export function useUpdateCourseMutation() {
  return useMutation<{course: Course}, MutationUpdateCourseArgs>(updateCourseMutation);
}

const updateCourseMutation = gql`
  mutation UpdateCourseMutation($id: ID!, $data: CourseInput!) {
    course: updateCourse(id: $id, data: $data) {
      name
      languageCode
      ...CourseCustomizeForm
    }
  }

  ${CourseCustomizeForm.fragments.root}
`;

export function useUpdateChildrenMutation() {
  return useMutation<{succsess: Boolean}, MutationUpdateChildrenArgs>(updateChildrenMutation);
}

const updateChildrenMutation = gql`
  mutation updateChildrenMutation($courseId: ID) {
    success: updateChildren(courseId: $courseId)
  }
`;