import compact from "lodash/compact";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
  getLessonScenariosLength, getMainScenario
} from "../../../scenes/editor/lesson-edit.utils";

import {MAX_LESSON_LENGTH, MAX_MAIN_SCENARIO_LENGTH} from "../../../settings";
import {Action, Scenario} from "../../../types";
import {LessonScenarioActivationMethod} from "../../../schema";
import {AsSpecificComponent} from "../../../ui";

import {AlertCircleIcon} from "../../../ui/icons";
import WithTooltip from "../../../ui/tooltip";

import classes from "./LessonWarnings.module.css";


export type LessonWarningsProps = Omit<AsSpecificComponent<typeof WithTooltip, "div">, "helpText" | "children"> & {
  scenarios: Scenario[]
}

export default function LessonWarnings({
  scenarios, ...props
}: LessonWarningsProps) {
  const {t} = useTranslation();

  const lessonLength = useMemo(() => (
    getLessonScenariosLength(scenarios)
  ), [scenarios]);

  const lessonHaveSecondaryScenario = scenarios.length > 1;

  const mainScenario = useMemo(() => (
    getMainScenario(scenarios)
  ), [scenarios]);
  const mainScenarioLength = mainScenario.actions.length;

  const lessonOverflow = lessonLength - MAX_LESSON_LENGTH;
  const mainScenarioOverflow = mainScenarioLength - MAX_MAIN_SCENARIO_LENGTH;

  const emptyActionsCount = useMemo(() => (
    scenarios.reduce((count, scenario) => (
      count + scenario.actions.filter(isActionEmpty).length
    ), 0)
  ), [scenarios]);

  const [emptyScenariosCount, emptyScenariosNames] = useMemo(() => {
    const empty = scenarios.filter(scenario => scenario.actions.length === 0)
    return [empty.length, empty.map(scenario => scenario.name)];
    }, [scenarios]);

  const [unusedScenariosCount, unusedScenariosNames] = useMemo(() => {
    const noTriggerPhrasesScenarios = scenarios.filter(
      scenario => ((scenario.triggerPhrases.length === 0 &&
        scenario.activationMethod === LessonScenarioActivationMethod.TRIGGER_PHRASE
      ) || (scenario.activationMethod !== LessonScenarioActivationMethod.TRIGGER_PHRASE)) && !scenario.isMain
    );
    const actionsWithBranches = scenarios.flatMap(scenario => scenario.actions.map(
      action => {
        if (action.__typename === "ChoiceUserInputAction" || action.__typename === "RandomAvatarAction") {
          return action
        }
        return undefined
      }
    ))
    const targetIds = actionsWithBranches.flatMap(
      action => action && action.branches.map(branch => branch.targetScenarioId)
    )
    let count = 0;
    let names: string[] = [];
    noTriggerPhrasesScenarios.forEach(scenario => {
      if (scenario && targetIds.filter(id => id === scenario.id).length === 0) {
        count++;
        names.push(scenario.name)
      }
    })
    return [count, names];
  }, [scenarios])

  const warningText = useMemo(() => {
    const warnings: (string | false)[] = [];

    function getActionPlural(count: number) {
      return t("types.plurals.action", {count})
    }

    function getScenarioPlural(count: number) {
      return t("types.plurals.scenario", {count})
    }

    if (lessonHaveSecondaryScenario) {
      warnings.push(...[
        (lessonOverflow > 0) && t("components.LessonWarnings.tooManyActions.lesson", {
          excess: getActionPlural(lessonOverflow)
        }),
        (mainScenarioOverflow > 0) && t("components.LessonWarnings.tooManyActions.mainScenario", {
          excess: getActionPlural(mainScenarioOverflow)
        })
      ])
    } else {
      warnings.push(...[
        (mainScenarioOverflow > 0) && t("components.LessonWarnings.tooManyActions.lesson", {
          excess: getActionPlural(mainScenarioOverflow)
        })
      ])
    }

    if (emptyActionsCount) {
      warnings.push(...[
        emptyActionsCount > 0 && t("components.LessonWarnings.emptyActions", {
          excess: getActionPlural(emptyActionsCount)
        })
      ])
    }

    if (emptyScenariosCount > 0) {
      warnings.push(...[
        emptyScenariosCount > 0 && t("components.LessonWarnings.emptyScenarios", {
          excess: getScenarioPlural(emptyScenariosCount)
        }) + " (" + emptyScenariosNames.map(name => name) + ")"
      ])
    }

    if (unusedScenariosCount > 0) {
      warnings.push(...[
        unusedScenariosCount > 0 && t("components.LessonWarnings.unusedScenarios", {
          excess: getScenarioPlural(unusedScenariosCount)
        }) + " (" + unusedScenariosNames.map(name => name) + ")"
      ])
    }

    return compact(warnings).map(item => `<p>${item}</p>`).join("\n")
  }, [
    t, lessonHaveSecondaryScenario, lessonOverflow, mainScenarioOverflow,
    emptyActionsCount, emptyScenariosCount, unusedScenariosCount, emptyScenariosNames, unusedScenariosNames
  ]);

  return (
    <>
      {warningText && (
        <WithTooltip helpText={warningText} {...props}>
          <AlertCircleIcon className={classes.warning}/>
        </WithTooltip>
      )}
    </>
  )
}

function isActionEmpty(action: Action) {
  switch (action.__typename) {
    case "AvatarAction":
    case "SystemAction":
      return action.text === "";
    case "UserInputAction":
      return action.rawExpectedText === "";
    case "ChoiceUserInputAction":
      return action.branches.some(branch => {
        if (branch.avatarReaction && isActionEmpty(branch.avatarReaction)) {
          return true;
        }
        if (branch.systemReaction && isActionEmpty(branch.systemReaction)) {
          return true;
        }
        if (branch.userInput && isActionEmpty(branch.userInput)) {
          return true;
        }
        return false;
      })
    case "RandomAvatarAction":
      return action.branches.some(branch => {
        if (branch.avatarAction && isActionEmpty(branch.avatarAction)) {
          return true;
        }
        return false;
      });
    default:
      throw new Error(`isActionEmpty not implemented for type ${action.__typename}`);
  }
}
