import React, {useMemo} from "react";
import {useFormState} from "../../../hooks/useFormState";
import Button from "../../../ui/button";
import classes from "./MoveLessonModal.module.css";
import {useTranslation} from "react-i18next";
import Select from "../../../ui/select";
import {Course, Lesson, LessonInput} from "../../../schema";
import {gql} from "@apollo/client";

type contentProps = Omit<React.ComponentProps<"form">, "className" | "method" | "onSubmit"> & {
  initialId?: Course["id"] | null,
  lesson: Pick<Lesson, "id" | "name">,
  courses?: Pick<Course, "id" | "name">[],
  onMove: (data: Pick<LessonInput, "courseId">) => void,
}

function MoveLessonModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.MoveLessonModal.title")}</span>
}

 function MoveLessonModalContent({initialId, lesson, courses, onMove, ...props}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    courseId: string | "no"
  }>({
    initialValues: {
      courseId: initialId ?? "no"
    },

    preventDefault: true,
    onSubmit: (data) => {
      onMove({
        courseId: (data.courseId === "no") ? null : data.courseId
      })
    }
  });
  const targetCourseId = formState.values.courseId
  const idChanged = targetCourseId !== (initialId ?? "no")

  const initialCourse = useMemo(() => courses?.find(({id}) => id === initialId), [courses, initialId])
  const targetCourse = useMemo(() => courses?.find(({id}) => id === targetCourseId), [courses, targetCourseId])

  const [moveWarning, moveHelpText] = useMemo<[string | undefined, string | undefined]>(() => {
    if (!idChanged) {
      return [undefined, undefined]
    }

    const names = {
      lessonName: lesson.name,
      initialCourseName: initialCourse?.name,
      targetCourseName: targetCourse?.name
    }

    if (initialCourse && targetCourse) {
      // Move lesson between two courses
      return [
        t("components.MoveLessonModal.loseAssignWarning.course", names),
        t("components.MoveLessonModal.autoAssign", names)
      ]
    }
    if (!initialCourse && targetCourse) {
      // Move lesson into course
      return [
        t("components.MoveLessonModal.loseAssignWarning.lesson", names),
        t("components.MoveLessonModal.autoAssign", names)
      ]
    }
    if (initialCourse && !targetCourse) {
      // Move lesson out of course
      return [
        t("components.MoveLessonModal.loseAssignWarning.course", names),
        undefined
      ]
    }
    return [undefined, undefined]
  }, [t, idChanged, lesson, initialCourse, targetCourse])

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler} {...props}>
      <p className={classes.helpText}>{t("components.MoveLessonModal.helpText")}</p>
      <Select
        className={classes.input}
        name="courseId"
        value={formState.values.courseId}
        onChange={formState.changeHandler}
      >
        <option value="no">
          {t("components.MoveLessonModal.no")}
        </option>
        {courses && courses.map((item) => (
          <option key={item.id} value={item.id} translate="no">
            {item.name}
          </option>
        ))}
      </Select>

      {moveHelpText && (<p className={classes.helpText}>{moveHelpText}</p>)}
      {moveWarning && (<p className={classes.warning}>{moveWarning}</p>)}

      <Button type="submit"
        className={classes.submitButton}
        color={idChanged ? "success" : "secondary"}
        disabled={!idChanged}
      >
        {t("components.MoveLessonModal.submit")}
      </Button>
    </form>
  )
}

const MoveLessonModal = {
  Header: MoveLessonModalHeader,
  Content: MoveLessonModalContent,
  fragments: {
    root: gql`
      fragment MoveLessonModal on Lesson {
        id
        name
      }
    `,
    courses: gql`
      fragment MoveLessonModalCourses on Course {
        id
        name
      }
    `
  }
}

export default MoveLessonModal;
