/* eslint-disable max-lines */
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {getLanguageCode} from "../../i18n";

import {useModal} from "../ModalProvider";
import useVisibility from "../../hooks/useVisibility";

import {CutWordsDictionary, HotWordsDictionary, TriggerDictionaryType} from "../../schema";
import {analyticsSendEvent} from "../../libs/analytics";

import WithTooltip from "../../ui/tooltip";
import {AddIcon, HelpCircleIcon} from "../../ui/icons";
import Button from "../../ui/button";
import Link from "../../ui/link";

import {
  useAddCutWordsDictionaryMutation, useAddHotWordsDictionaryMutation,
  useAddTriggerDictionaryMutation, useDeleteCutWordsDictionaryMutation,
  useDeleteHotWordsDictionaryMutation, useDeleteTriggerDictionaryMutation,
  useUpdateCutWordsDictionaryMutation, useUpdateHotWordsDictionaryMutation,
  useUpdateTriggerDictionaryMutation} from "../../scenes/editor/lesson-edit.graphql";
import TriggerDictionaryModal from "./TriggerDictionaryModal";
import CutWordsDictionaryModal from "./CutWordsDictionaryModal";
import HotWordsDictionaryModal from "./HotWordsDictionaryModal";

import cn from "classnames";
import classes from "./LessonCustomizeForm.module.css";
import {classes as tourClasses} from "../../scenes/editor/lesson-edit.tour";

type props = {
  dictionaryType: "trigger" | "cutWords" | "hotWords",
  triggerDicts?: TriggerDictionaryType[],
  cutWordsDicts?: CutWordsDictionary[],
  hotWordsDicts?: HotWordsDictionary[],
  lessonId: string,
  disabled?: boolean,
}

export default function LessonDictionaryForm(
    {dictionaryType, triggerDicts, cutWordsDicts, hotWordsDicts, lessonId, disabled}: props
) {

  const {t, i18n} = useTranslation();
  const [allDictsVisible, showAll, showLess] = useVisibility();
  const {add: addModal} = useModal();

  const [applyAddTriggerDictionaryMutation] = useAddTriggerDictionaryMutation();
  const [applyUpdateTriggerDictionaryMutation] = useUpdateTriggerDictionaryMutation();
  const [applyDeleteTriggerDictionaryMutation] = useDeleteTriggerDictionaryMutation();
  const [applyAddCWDictionaryMutation] = useAddCutWordsDictionaryMutation();
  const [applyUpdateCWDictionaryMutation] = useUpdateCutWordsDictionaryMutation();
  const [applyDeleteCWDictionaryMutation] = useDeleteCutWordsDictionaryMutation();
  const [applyAddHWDictionaryMutation] = useAddHotWordsDictionaryMutation();
  const [applyUpdateHWDictionaryMutation] = useUpdateHotWordsDictionaryMutation();
  const [applyDeleteHWDictionaryMutation] = useDeleteHotWordsDictionaryMutation();

  const onDictAdd = useCallback((data) => {
    switch (dictionaryType) {
      case "trigger":
        data.languageCode = getLanguageCode(i18n.language);
        analyticsSendEvent("editorLessonTriggerDictAdd", {lessonId});
        applyAddTriggerDictionaryMutation({
          variables: {data, lessonId},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      case "cutWords":
        analyticsSendEvent("editorLessonCutWordsDictAdd", {lessonId});
        applyAddCWDictionaryMutation({
          variables: {data, lessonId},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      case "hotWords":
        analyticsSendEvent("editorLessonHotWordsDictAdd", {lessonId});
        applyAddHWDictionaryMutation({
          variables: {data, lessonId},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      default:
        return;
    }

  }, [
    i18n.language, lessonId, dictionaryType,
    applyAddTriggerDictionaryMutation,
    applyAddCWDictionaryMutation,
    applyAddHWDictionaryMutation
  ])

  const onDictUpdate = useCallback((data, id: string) => {
    switch(dictionaryType) {
      case "trigger":
        data.languageCode = getLanguageCode(i18n.language);
        analyticsSendEvent("editorLessonTriggerDictUpdate", {lessonId, id});
        applyUpdateTriggerDictionaryMutation({
          variables: {data, lessonId, triggerDictionaryId: id},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      case "cutWords":
        analyticsSendEvent("editorLessonCutWordsDictUpdate", {lessonId, id});
        applyUpdateCWDictionaryMutation({
          variables: {data, lessonId, cutWordsDictionaryId: id},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      case "hotWords":
        analyticsSendEvent("editorLessonHotWordsDictUpdate", {lessonId, id});
        applyUpdateHWDictionaryMutation({
          variables: {data, lessonId, hotWordsDictionaryId: id},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      default:
        return;
    }
  }, [
    i18n.language, lessonId, dictionaryType,
    applyUpdateTriggerDictionaryMutation, applyUpdateCWDictionaryMutation, applyUpdateHWDictionaryMutation
  ])

  const onDictDelete = useCallback((data, id: string) => {
    switch(dictionaryType) {
      case "trigger":
        analyticsSendEvent("editorLessonTriggerDictDelete", {lessonId, id});
        applyDeleteTriggerDictionaryMutation({
          variables: {lessonId, triggerDictionaryId: id},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      case "cutWords":
        analyticsSendEvent("editorLessonCutWordsDictDelete", {lessonId, id});
        applyDeleteCWDictionaryMutation({
          variables: {lessonId, cutWordsDictionaryId: id},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      case "hotWords":
        analyticsSendEvent("editorLessonHotWordsDictDelete", {lessonId, id});
        applyDeleteHWDictionaryMutation({
          variables: {lessonId, hotWordsDictionaryId: id},
          refetchQueries: ["EditorEditLessonSceneQuery"]
        });
        break;
      default:
        return;
    }
  }, [
    lessonId, dictionaryType,
    applyDeleteTriggerDictionaryMutation, applyDeleteCWDictionaryMutation, applyDeleteHWDictionaryMutation
  ])

  const onAddDictButtonClick = useCallback(() => {
    if (dictionaryType === "trigger") {
      const triggerModal = addModal({
        header: t("components.TriggerDictionary.editModalTitle"),
        content: (
          <TriggerDictionaryModal.Content
            onSave={(data) => {
              onDictAdd && onDictAdd(data);
              triggerModal.remove();
            }}
          />
        )
      }, true);
    } else if (dictionaryType === "cutWords") {
      const cutModal = addModal({
        header: t("components.CutWordsDictionary.editModalTitle"),
        content: (
          <CutWordsDictionaryModal.Content
            onSave={(data) => {
              onDictAdd && onDictAdd(data);
              cutModal.remove();
            }}
          />
        )
      }, true);
    } else if (dictionaryType === "hotWords") {
      const hotModal = addModal({
        header: t("components.HotWordsDictionary.editModalTitle"),
        content: (
          <HotWordsDictionaryModal.Content
            onSave={(data) => {
              onDictAdd && onDictAdd(data);
              hotModal.remove();
            }}
          />
        )
      }, true);
    }
  }, [addModal, t, onDictAdd, dictionaryType])

  const onUpdateDictButtonClick = useCallback((e: React.MouseEvent) => {
    const dictId = e.currentTarget.getAttribute("data-dictid")!;
      if (dictionaryType === "trigger") {
        const triggerModal = addModal({
          header: t("components.TriggerDictionary.editModalTitle"),
          content: (
            <TriggerDictionaryModal.Content
              dict={triggerDicts?.find(dict => dict.id === dictId)}
              onSave={(data) => {
                onDictUpdate && onDictUpdate(data, dictId);
                triggerModal.remove();
              }}
              onDelete={() => {
                onDictDelete && onDictDelete(lessonId, dictId)
                triggerModal.remove();
              }}
            />
          )
        }, true);
      } else if (dictionaryType === "cutWords") {
        const cutModal = addModal({
          header: t("components.TriggerDictionary.editModalTitle"),
          content: (
            <CutWordsDictionaryModal.Content
              dict={cutWordsDicts?.find(dict => dict.id === dictId)}
              onSave={(data) => {
                onDictUpdate && onDictUpdate(data, dictId);
                cutModal.remove();
              }}
              onDelete={() => {
                onDictDelete && onDictDelete(lessonId, dictId)
                cutModal.remove();
              }}
            />
          )
        }, true);
      } else if (dictionaryType === "hotWords") {
        const hotModal = addModal({
          header: t("components.HotWordsDictionary.editModalTitle"),
          content: (
            <HotWordsDictionaryModal.Content
              dict={hotWordsDicts?.find(dict => dict.id === dictId)}
              onSave={(data) => {
                onDictUpdate && onDictUpdate(data, dictId);
                hotModal.remove();
              }}
              onDelete={() => {
                onDictDelete && onDictDelete(lessonId, dictId)
                hotModal.remove();
              }}
            />
          )
        }, true);
      }
  }, [addModal, t, lessonId, dictionaryType, triggerDicts, cutWordsDicts, hotWordsDicts, onDictDelete, onDictUpdate])

  const selectedDicts = useMemo(() => {
    switch (dictionaryType) {
      case "trigger":
        return triggerDicts;
      case "cutWords":
        return cutWordsDicts;
      case "hotWords":
        return hotWordsDicts;
      default:
        return;
    }
  }, [dictionaryType, triggerDicts, cutWordsDicts, hotWordsDicts])

  const display = useMemo(() => {
    if (!selectedDicts) {
      return;
    }
    if (!allDictsVisible && selectedDicts.length > 3) {
      return selectedDicts.slice(0, 3)
    }
    return selectedDicts;
  }, [allDictsVisible, selectedDicts])

  const locales = useMemo(() => {
    switch (dictionaryType) {
      case "trigger":
        return {
          label: t("components.LessonDictionaryForm.trigger.label"),
          helpText: t("components.LessonDictionaryForm.trigger.helpText")
        }
      case "cutWords":
        return {
          label: t("components.LessonDictionaryForm.cutWords.label"),
          helpText: t("components.LessonDictionaryForm.cutWords.helpText")
        }
      case "hotWords":
        return {
          label: t("components.LessonDictionaryForm.hotWords.label"),
          helpText: t("components.LessonDictionaryForm.hotWords.helpText")
        }
      default:
        return {
          label: "Not implemented",
          helpText: "Not implemented"
        }
    }
  }, [t, dictionaryType])

  return (
    <div className={cn(classes.formItem, dictionaryType === "trigger" ? tourClasses.triggerDicts : undefined)}>
      <label className={classes.label}>
        {locales.label}
        {" "}
        <WithTooltip className={classes.help} as='span'
                    helpText={locales.helpText}>
          <HelpCircleIcon/>
        </WithTooltip>
      </label>

      {display && display?.length > 0 && (
          display?.map(dict => (
            <Button
              className={classes.listItem}
              onClick={onUpdateDictButtonClick}
              key={dict.id}
              data-dictid={dict.id}
              disabled={disabled}
            >
              {dict.name}
            </Button>
          ))
      )}
      {selectedDicts && selectedDicts.length > 3 && (
        allDictsVisible ? (
          <Link
            className={classes.listItem}
            onClick={showLess}
          >{t("components.LessonDictionaryForm.showLess")}</Link>
        ) : (
          <Link
            className={classes.listItem}
            onClick={showAll}
          >{t("components.LessonDictionaryForm.showAll")}</Link>
        )
      )}

      <Button
        className={classes.listItem}
        onClick={onAddDictButtonClick}
        disabled={disabled}
      >
        <AddIcon/>
        {t("common.add")}
      </Button>
    </div>
  )
}
