import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getCurrentAction, getCurrentScenario} from "../selectors";
import {getBranch, isAnyUserInputAction} from "../utils";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function passAction() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    const action = getCurrentAction(state);

    if (state.branchId) {
      const rootAction = getCurrentScenario(state).actions[state.index];
      const branch = getBranch(rootAction, state.branchId);

      if (branch && branch.targetScenarioId) {
        dispatch({
          type: "startScenario",
          payload: {
            scenarioId: branch.targetScenarioId,
          }
        })
        return;
      }
    }

    if (!action || isAnyUserInputAction(action)) {
      console.warn("passAction: wrong action");
      return;
    }

    analyticsSendEvent("playerPassAction", {
      sessionId: state.sessionId!,
      actionId: action.id,
      actionType: action.__typename
    });

    dispatch({type: "next"});
  }
}
