import React, {useCallback, useMemo} from "react";
import {useFormState} from "../../hooks/useFormState";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";
import {Course, Lesson, LessonCourse, User} from "../../schema";

import Button from "../../ui/button";
import Select from "../../ui/select";
import UsersSelect, {Props as UsersSelectProps} from "./UsersSelect";

import classes from "./AssignModal.module.css";

type PickedLesson = Pick<Lesson, "id" | "name">
type PickedCourse = Pick<Course, "id" | "name"> & {
  lessons: Pick<Lesson, "id">[]
}
type PickedLessonCourse = PickedLesson | PickedCourse

export type QueryData = {
  lessons?: PickedLesson[],
  courses?: PickedCourse[],
}

export type ContentProps = React.ComponentProps<"div"> & ({
  initialId?: undefined,
  initialType?: undefined,
} | {
  initialId: LessonCourse["id"],
  initialType: LessonCourse["__typename"],
}) & QueryData & {
  params: {
    multipleUsers?: false,
    onAssign?: (id: string, type: LessonCourse["__typename"]) => void
  } | ({
    multipleUsers: true,
    onAssign?: (id: string, type: LessonCourse["__typename"], usersIds?: User["id"][], emails?: string[]) => void
  } & Pick<UsersSelectProps, "users" | "groups">)
}

function AssignModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.AssignModal.assignModalTitle")}</span>
}

function AssignModalContent({initialId, initialType, lessons, courses, params}: ContentProps) {
  const {t} = useTranslation();

  const {multipleUsers} = params

  const formState = useFormState<{
    id?: string | "no",
    type: NonNullable<LessonCourse["__typename"]>,
  }>({
    initialValues: {
      id: initialId,
      type: initialType ?? "Lesson",
    },

    preventDefault: true,
    onSubmit: (data) => {
      if (!data.id) {
        return;
      }

      if (params.multipleUsers !== true) {
        params.onAssign && params.onAssign(data.id, data.type)
      } else {
        throw new Error("Using AssignModal submit not intended for multiple users")
      }
    }
  });

  const {type, id: currentId} = formState.values;

  const onTypeChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>((e) => {
    formState.setValues({id: "no"})
    formState.changeHandler(e)
  }, [formState])

  const currentItems = useMemo<typeof lessons | typeof courses>(() => {
    switch (type) {
      case "Lesson":
        return lessons;
      case "Course":
        return courses;
      default:
        console.error(`Unsupported type of item to assign in AssignModal: "${type}"`)
    }
  }, [type, lessons, courses])

  const selectedItem = useMemo<PickedLessonCourse | undefined>(() => (
    currentId === "no"
      ? undefined
      : currentItems?.find(({id}) => id === currentId) as PickedLessonCourse | undefined
  ), [currentItems, currentId])

  const isCurrentCourseEmpty = useMemo(() => (
    selectedItem && ("lessons" in selectedItem) ? (selectedItem.lessons.length === 0) : false
  ), [selectedItem])

  const submitDisabled = !selectedItem || isCurrentCourseEmpty;

  const onSubmit = useCallback((usersIds: string[], emails: string[]) => {
    const {type, id} = formState.values;
    if (!id) {
      return;
    }
    params.onAssign && params.onAssign(id, type, usersIds, emails);
  }, [formState.values, params])

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>

      <p className={classes.helpText}>{t("components.AssignModal.type.helpText")}</p>
      <Select
        className={classes.input}
        name="type"
        value={formState.values.type}
        onChange={onTypeChange}
      >
        <option value="Lesson">
          {t("components.AssignModal.type.lesson")}
        </option>
        <option value="Course">
          {t("components.AssignModal.type.сourse")}
        </option>
      </Select>

      <p className={classes.helpText}>
        {type === "Lesson" && t("components.AssignModal.helpText.lesson")}
        {type === "Course" && t("components.AssignModal.helpText.course")}
      </p>
      {isCurrentCourseEmpty && (
        <p className={classes.warning}>
          {t("components.AssignModal.warning.emptyCourse")}
        </p>
      )}
      <div className={classes.bottom}>
        <Select
          name="id"
          className={classes.select}
          value={formState.values.id}
          onChange={formState.changeHandler}
        >
          <option value="no">
            {t("components.AssignModal.no")}
          </option>
          {currentItems && currentItems.map((item) => (
            <option key={item.id} value={item.id} translate="no">
              {item.name}
            </option>
          ))}
        </Select>

        {!multipleUsers && (
          <Button type="submit" color="success" disabledColor="secondary" disabled={submitDisabled}>
            {t("components.AssignModal.submit")}
          </Button>
        )}
      </div>

      {multipleUsers && (
        <>
          <p className={classes.helpText}>{t("components.AssignModal.helpText.multiple")}</p>
          <UsersSelect
            createNewUsers
            users={params.users}
            groups={params.groups}
            submitDisabled={submitDisabled}
            onSubmit={onSubmit}
          />
        </>
      )}
    </form>
  )
}

const AssignModal = {
  Header: AssignModalHeader,
  Content: AssignModalContent,
  fragments: {
    root: gql`
      fragment AssignModal on LessonCourse {
        __typename
        id
      }
    `,
    lessons: gql`
      fragment AssignModalLessons on Lesson {
        id
        name
      }
    `,
    courses: gql`
      fragment AssignModalCourses on Course {
        id
        name
        lessons {
          id
        }
      }
    `
  }
}

export default AssignModal;
