import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {EducationSessionProgress, MutationEducationSessionResumeArgs} from "../../../schema";
import {sessionResumeMutation} from "../graphql";

export default function resume() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    state.client.mutate<{ progress: EducationSessionProgress }, MutationEducationSessionResumeArgs>({
      mutation: sessionResumeMutation,
      variables: {
        lessonId: state.lessonId,
        externalUserId: state.externalUserId,
      }
    }).then((mutationResult) => {
      const progress = mutationResult.data?.progress;
      if (!progress) {
        throw new Error("Cannot resume education session");
      }

      const mainScenarioIndex = state.scenarios.findIndex((item) => item.isMain),
        mainActions = state.scenarios[mainScenarioIndex].actions,
        mainScenarioLastIndex = mainActions.findIndex((item) => item.id === progress.lastMainScenarioAction),
        scenarioId = state.scenarios.findIndex((item) => item.id === progress.lastScenarioId),
        actions = state.scenarios[scenarioId].actions,
        index = actions.findIndex((item) => item.id === progress.lastScenarioAction)

      const payload = {
        sessionId: progress.sessionId,
        level: progress.level,
        scenarioIndex: scenarioId,
        index: index,
        mainScenarioLastIndex: mainScenarioLastIndex,
        //todo: complete the correct calculation of the maximum indices and hints used
        maxIndexes: [mainScenarioLastIndex],
        exposedActions: progress.exposedActions
      }

      dispatch({
        type: "start",
        payload
      });
      dispatch({type: "next"});
    }).catch(() => {
      dispatch({
        type: "update",
        payload: {
          sessionResumeFailed: true,
        }
      });
    });
  }
}
