import React from "react";
import {useTranslation} from "react-i18next";
import {ChoiceUserInputAction, ChoiceUserInputBranch, ChoiceUserInputBranchState} from "../../../../schema";

import isEqual from "lodash/isEqual";

import Phrase, {PhraseVariant} from "../../../Phrase";

import {useSelector} from "react-redux";
import {PlayerReduxState} from "../../../../redux/player/types";
import {
  getCurrentAction,
  getCurrentActionChoiceMode,
  getCurrentActionPhraseMode,
  isErrorState,
} from "../../../../redux/player/selectors";
import ActionDisplay from "./ActionDisplay";
import UserInputErrorDisplay from "./UserInputErrorDisplay";
import Display from "./Display";
import {ChoiceUserInputDisplayMode} from "../../../../redux/player/selectors/getCurrentActionChoiceMode";
import classes from "./Display.module.css";
import getChosenBranches from "../../../../redux/player/selectors/getChosenBranches";

function ChoiceUserInputActionDisplay() {
  const {t} = useTranslation();

  const {
    action,
    isError,
    phraseMode,
    choiceMode,
    chosenBranches,
  } = useSelector(ChoiceUserInputActionDisplay.selector, isEqual);

  if (isError) {
    return (<UserInputErrorDisplay/>)
  }

  let branches: ChoiceUserInputBranch[];

  switch (choiceMode) {
    case ChoiceUserInputDisplayMode.ALL:
    case ChoiceUserInputDisplayMode.ALL_WITH_HINT:
      branches = action.branches;
      break;

    case ChoiceUserInputDisplayMode.ONLY_VALID:
      branches = action.branches.filter(b => b.state === ChoiceUserInputBranchState.CORRECT);
      break;

    default:
      console.error("NotImplemented");
      branches = [];
      break;
  }

  return (
    <ActionDisplay
      title={t("player.choiceUserInputActionHelpText")}
      translateContent="no"
    >
      <Display.Row>
        {branches.map((b) => (
          <Display.RowItem key={b.id} className={
            b.state !== ChoiceUserInputBranchState.CORRECT && chosenBranches.has(b.id)
              ? classes.incorrectChoice : undefined
          }>
            <Phrase
              text={b.userInput.expectedText}
              variant={phraseMode ?? PhraseVariant.DEFAULT}
            />
          </Display.RowItem>
        ))}
      </Display.Row>
    </ActionDisplay>
  )
}

ChoiceUserInputActionDisplay.selector = (state: PlayerReduxState) => ({
  action: getCurrentAction(state) as ChoiceUserInputAction,
  phraseMode: getCurrentActionPhraseMode(state),
  choiceMode: getCurrentActionChoiceMode(state),
  chosenBranches: getChosenBranches(state),
  isError: isErrorState(state),
})

export default ChoiceUserInputActionDisplay;
