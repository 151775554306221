import React from "react";

import {useFormState} from "../../hooks/useFormState";
import EditableInput from "./EditableInput";

import cn from "classnames";
import classes from "./EditableItemName.module.css";

type NamedItem = {name: string}

type props = {
  className?: string
  item: NamedItem
  onSave: (data: NamedItem) => void
}

export default function EditableItemName({className, item, onSave}: props) {
  const formState = useFormState<NamedItem>({
    initialValues: {
      name: item?.name
    },
    preventDefault: true,
    onChange: onSave
  })

  return (
    <EditableInput.Wrapper as="form"
      className={cn(classes.wrapper, className)}
      method="post"
      onSubmit={formState.submitHandler}
      autoComplete="off"
    >
      <EditableInput
        type="text"
        className={classes.input}
        buttonClassName={classes.nameButtons}
        title={item.name}
        name="name"
        defaultValue={formState.values.name}
        onChange={formState.changeHandler}
        autoComplete="off"
        maxLength={60}
      />
    </EditableInput.Wrapper>
  )
}
