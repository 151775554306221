import React, {HTMLAttributes} from "react";
import classes from "./HomeLessonCard.module.css";
import cn from "classnames";

type props = HTMLAttributes<any> & {
  as?: React.ElementType,
  className?: string
  headline: string
  href: string
  image: string
  title: string
  description: string,
}

export default function HomeLessonCard({as, className, href, headline, image, title, description, ...props}: props) {
  const Tag = as ?? "a";

  return (
    <Tag href={href} className={cn(classes.root, className)} {...props}>
      <h5 className={classes.headline}>{headline}</h5>
      <img
        className={classes.image}
        src={image}
        alt={title}
      />
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.desc}>{description}</p>
    </Tag>
  )
}
