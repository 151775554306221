// @ts-nocheck : This mutes all TS errors in file, be careful
import editorLessonModesRU from "./editor/lesson/modes.ru.md";
import editorLessonModesEN from "./editor/lesson/modes.en.md";

import playerGuideRU from "./player/main.ru.md";
import playerGuideEN from "./player/main.en.md";

const guides = {
  editor: {
    lesson: {
      modes: {
        ru: editorLessonModesRU,
        en: editorLessonModesEN
      }
    }
  },
  player: {
    main: {
      ru: playerGuideRU,
      en: playerGuideEN
    }
  }
}

export default guides;
