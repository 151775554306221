import React from "react";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import classes from "./MembersActivityModal.module.css";
import {useTranslation} from "react-i18next";
import {parseEmails} from "../../utils";
import Select from "../../ui/select";

type contentProps = React.ComponentProps<"div"> & {
  onSetUsersActivity?: (emails: string[], isActive: boolean) => void
}

function MembersActivityModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.MembersActivityModal.title")}</span>
}

 function MembersActivityModalContent({onSetUsersActivity}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    members: string,
    action: "no" | "activate" | "deactivate",
  }>({
    initialValues: {
      members: "",
      action: "no",
    },

    preventDefault: true,
    onSubmit: (data) => {
      const emails = parseEmails(data.members, true)

      if (data.action !== "no") {
        switch (data.action) {
          case "activate":
          case "deactivate":
            onSetUsersActivity && onSetUsersActivity(emails, data.action === "activate");
            break;
          default:
            console.error(`Unsupported action "${data.action}" in MembersActivityModal`)
        }
      }
    }
  });

  const submitDisabled = formState.values.action === "no";

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.MembersActivityModal.action.helpText")}</p>
        <Select
          className={classes.input}
          name="action"
          value={formState.values.action}
          onChange={formState.changeHandler}
        >
          <option value="no">
            {t("components.MembersActivityModal.action.option.no")}
          </option>
          <option value="activate">
              {t("components.MembersActivityModal.action.option.activate")}
          </option>
          <option value="deactivate">
              {t("components.MembersActivityModal.action.option.deactivate")}
          </option>
        </Select>
      </>
      <>
        <p className={classes.helpText}>{t("components.MembersActivityModal.members.helpText")}</p>
        <Textarea
          className={classes.input}
          name="members"
          rows={8}
          required
          placeholder={t("components.MembersActivityModal.members.placeholder")}
          onChange={formState.changeHandler}
        />
      </>
      <Button
        type="submit"
        className={classes.button}
        color="success"
        disabledColor="secondary"
        disabled={submitDisabled}
      >
        {t("components.MembersActivityModal.apply")}
      </Button>
    </form>
  )
}

const AddMembersModal = {
  Header: MembersActivityModalHeader,
  Content: MembersActivityModalContent,
}

export default AddMembersModal;
