import React, {useMemo} from "react";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import {useTranslation} from "react-i18next";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import classes from "./index.module.css";
import CourseCard from "../../components/CourseCard";
import {useQuery} from "@apollo/client";
import {query, QueryData} from "./index.graphql";
import {Course, Lesson} from "../../schema";
import LessonCard from "../../components/LessonCard";
import Breadcrumbs from "../../ui/breadcrumbs";
import {queryByTypenames, getMapByObjectProperty} from "../../utils";

export default function PlayerIndexScene() {
  const {t} = useTranslation();

  const {data, loading: fetching} = useQuery<QueryData>(query, {fetchPolicy: "network-only"});
  const {items, lessonsStates} = data ?? {};

  const mappedLessonsStates = useMemo(() => {
    if (!lessonsStates) {
      return undefined;
    }
    return getMapByObjectProperty(lessonsStates, "lessonId");
  }, [lessonsStates])

  const loading = !items && fetching;

  const [lessons, courses] = useMemo(() => {
    if (!items || (items.length === 0)) {
      return [[], []]
    }
    return queryByTypenames(items, ["Lesson", "Course"]) as [Lesson[], Course[]];
  }, [items])

  return (
    <>
      <ReactDocumentTitle title={t("playerIndex.title")}/>

      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Container className={classes.courses}>
              <Breadcrumbs className={classes.breadcrumbs}>
                <span>{t("breadcrumbs.player")}</span>
              </Breadcrumbs>
              <h1 className={classes.h1}>{t("playerIndex.courses")}</h1>

              {!loading && (
                <>
                  {courses.length > 0 ? (
                    <div className={classes.courseItems}>
                      {courses.map((item) => (
                        <CourseCard
                          key={item.id}
                          className={classes.courseItem}
                          course={item}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={classes.emptyState}>{t("playerIndex.emptyState")}</div>
                  )}
                </>
              )}

              {loading && (
                <div className={classes.courseItems}>
                  <CourseCard.Skeleton className={classes.courseItem}/>
                  <CourseCard.Skeleton className={classes.courseItem}/>
                  <CourseCard.Skeleton className={classes.courseItem}/>
                </div>
              )}
            </Container>
            <Container className={classes.lessons}>
              <h1 className={classes.h1}>{t("playerIndex.lessons")}</h1>

              {!loading && (
                <>
                  {lessons.length > 0 ? (
                    <div className={classes.lessonItems}>
                      {lessons.map((item) => (
                        <LessonCard
                          key={item.id}
                          className={classes.lessonItem}
                          lesson={item}
                          lessonState={mappedLessonsStates!.get(item.id)!}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={classes.emptyState}>{t("playerIndex.emptyState")}</div>
                  )}
                </>
              )}

              {loading && (
                <div className={classes.lessonItems}>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                </div>
              )}
            </Container>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
