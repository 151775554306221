import React from "react";
import {useTranslation} from "react-i18next";
import Content from "./Content"

import classes from "./HintConfirmationContent.module.css"

export default function HintConfirmationContent() {
  const {t} = useTranslation();

  return (
    <Content title={t("player.confirmHint.title")} variant="info" className={classes.hideOnMobile}>
      {t("player.confirmHint.message")}
    </Content>
  );
}
