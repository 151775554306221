/* eslint-disable max-lines */
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {getLanguageCode} from "../../i18n";
import clamp from "lodash/clamp";

import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button"
import Input from "../../ui/input";

import classes from "./PurchaseLicensesModal.module.css";
import {analyticsSendEvent} from "../../libs/analytics";
import {LanguageCode} from "../../schema";
import {useModal} from "../ModalProvider";
import PurchaseLicensesDetailsModal from "./PurchaseLicensesDetailsModal";

const MIN = 50
const MAX = 9999
const STEP = 5
const PRICE_RUB = [1050, 800, 550]
const PRICE_USD = [12, 10, 8]

type props = {
  isEmailActivated?: boolean,
  onBuy?: () => void
}

function PurchaseLicensesModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.PurchaseLicensesModal.title")}</span>;
}

function PurchaseLicensesModalContent({isEmailActivated, onBuy}: props) {
  const {t, i18n} = useTranslation();
  const language = getLanguageCode(i18n.language)

  const formState = useFormState<{ count: number, duration: number }>({
    initialValues: {
      count: 50,
      duration: 3,
    },
    preventDefault: true,
    onSubmit: (values) => {
      analyticsSendEvent("buyLicenses", {
        count: values.count,
        duration: values.duration,
      });

      onBuy && onBuy()
      onBuyClick && onBuyClick()
    }
  });

  const {add: addModal} = useModal();
  const onBuyClick = useCallback(() => {
    analyticsSendEvent("openPurchaseDetailsModal", {
      from: "PurchaseLicensesModal"
    });

    let modal = addModal({
      header: <PurchaseLicensesDetailsModal.Header/>,
      content: (
        <PurchaseLicensesDetailsModal.Content
          count={formState.values.count}
          duration={formState.values.duration}
          languageCode={language}
          onClose={() => modal.remove()}
        />
      )
    })
  }, [formState.values, language, addModal]);

  const currentPrice: number = useMemo(() => {
    const count = formState.values.count;
    let priceIndex = 0;

    if (count < 200) {
      priceIndex = 0;
    } else if (count < 1000) {
      priceIndex = 1;
    } else {
      priceIndex = 2;
    }
    if (language === LanguageCode.RU_RU) {
      return PRICE_RUB[priceIndex];
    }
    return PRICE_USD[priceIndex];

  }, [formState.values.count, language])

  const totalPrice: number = useMemo(() => {
    return currentPrice * formState.values.count;
  }, [currentPrice, formState.values.count])

  const {currency, perMonth} = useMemo(() => {
    if (language === LanguageCode.RU_RU) {
      return {currency: "₽", perMonth: "/мес"}
    }
    return {currency: "$", perMonth: "/month"}
  }, [language])

  const durationTotalCost: Array<number> = useMemo(() => {
    return [
      totalPrice * 3,
      totalPrice * 6,
      totalPrice * 9,
      totalPrice * 11
    ]
  },[totalPrice])

  const onPlus = useCallback(() => {
    formState.setValues({count: clamp(+formState.values.count + STEP, MIN, MAX)})
  }, [formState])

  const onMinus = useCallback(() => {
    formState.setValues({count: clamp(+formState.values.count - STEP, MIN, MAX)})
  }, [formState]);

  return (
    <div>
      {isEmailActivated ? (
        <form className={classes.root} onSubmit={formState.submitHandler}>
          <span className={classes.numberHelp}>{t("components.PurchaseLicensesModal.count")}</span>
          <div className={classes.numberSelector}>
            <span className={classes.pricePerOne}>{currentPrice}{currency}{perMonth}</span>
            <span className={classes.multiplicator}>✖</span>
            <Button className={classes.changeBtn} color='secondary' type='button' onClick={onMinus}>-</Button>
            <Input
              className={classes.numberInput}
              required
              min={MIN}
              max={MAX}
              type='number'
              name='count'
              value={+formState.values.count}
              onChange={formState.changeHandler}
            />
            <Button className={classes.changeBtn} color='secondary' type='button' onClick={onPlus}>+</Button>
            <span className={classes.equals}>=</span>
            <span className={classes.priceFinal}>{totalPrice}{currency}{perMonth}</span>
          </div>

          <div className={classes.border}/>

          <div className={classes.durationSelector}>
            <span className={classes.durationInput}>{t("components.PurchaseLicensesModal.3months")}</span>
            <span className={classes.durationInput}>{t("components.PurchaseLicensesModal.6months")}</span>
            <span className={classes.durationInput}>{t("components.PurchaseLicensesModal.9months")}</span>
            <span className={classes.durationInput}>{t("components.PurchaseLicensesModal.1year")}</span>
          </div>

          <div className={classes.durationSelector}>
            <Input
              className={classes.durationInput}
              required
              type='radio'
              name='duration'
              value={3}
              onChange={formState.changeHandler}
            />
            <Input
              className={classes.durationInput}
              required
              type='radio'
              name='duration'
              value={6}
              onChange={formState.changeHandler}
            />
            <Input
              className={classes.durationInput}
              required
              type='radio'
              name='duration'
              value={9}
              onChange={formState.changeHandler}
            />
            <Input
              className={classes.durationInput}
              required
              type='radio'
              name='duration'
              value={12}
              onChange={formState.changeHandler}
            />
          </div>

          <div className={classes.durationSelector}>
            <span className={classes.durationInput}>{durationTotalCost[0]}{currency}</span>
            <span className={classes.durationInput}>{durationTotalCost[1]}{currency}</span>
            <span className={classes.durationInput}>
              {t("components.PurchaseLicensesModal.1extraMonth")}
              {t("components.PurchaseLicensesModal.forFree")}
              <p>{durationTotalCost[2]}{currency}</p>
              </span>
            <span className={classes.durationInput}>
              {t("components.PurchaseLicensesModal.2extraMonths")}
              {t("components.PurchaseLicensesModal.forFree")}
              <p>{durationTotalCost[3]}{currency}</p>
              </span>
          </div>

          <Button className={classes.submitBtn} type='submit' color='success' size='l'>
            {t("components.PurchaseLicensesModal.buy")}
          </Button>
        </form>
      ) : (
        <span className={classes.numberHelp}>{t("components.PurchaseLicensesModal.emailActivationError")}</span>
      )}
    </div>
  )
}

const PurchaseModal = {
  Header: PurchaseLicensesModalHeader,
  Content: PurchaseLicensesModalContent
}

export default PurchaseModal;
