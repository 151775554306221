import {gql} from "@apollo/client";
import {ComponentProps} from "react";
import CourseCard from "../../components/CourseCard";
import LessonCard from "../../components/LessonCard";
import {Course, Lesson, LessonEducationState} from "../../schema";

export type QueryData = {
  items: (
    Pick<Lesson, "__typename"> & ComponentProps<typeof LessonCard>["lesson"] |
    Pick<Course, "__typename"> & ComponentProps<typeof CourseCard>["course"]
  )[],
  lessonsStates: (
    Pick<LessonEducationState, "__typename" | "lessonId"> &  ComponentProps<typeof LessonCard>["lessonState"]
  )[]
}

export const query = gql`
  query PlayerIndexSceneQuery {
    items: getAssignedLessons {
      __typename
      ...LessonCard
      ...CourseCard
    }
    
    lessonsStates: getLessonsEducationState {
      __typename
      lessonId
      ...LessonCardState
    }
  }
  ${LessonCard.fragments.root}
  ${LessonCard.fragments.state}
  ${CourseCard.fragments.root}
`;
