import {PlayerReduxState} from "../types";
import getCurrentAction from "./getCurrentAction";
import {LessonMode} from "../../../schema";

export enum ChoiceUserInputDisplayMode {
  ALL_WITH_HINT,
  ALL,
  ONLY_VALID
}

export default function getCurrentActionChoiceMode(state: PlayerReduxState): ChoiceUserInputDisplayMode | undefined {
  const action = getCurrentAction(state);

  if (action?.__typename !== "ChoiceUserInputAction") {
    return undefined;
  }

  switch (state.lesson.mode) {
    case LessonMode.STUDY_THREE_STEP:
    case LessonMode.STUDY_TWO_STEP:
    case LessonMode.CHOICE_BASED_STUDY:
    case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
    case LessonMode.CUSTOM_PARAMETERS_TEST:
      switch (state.level) {
        case 1:
          return ChoiceUserInputDisplayMode.ALL;
        case 2:
        case 3:
          return ChoiceUserInputDisplayMode.ONLY_VALID;
        default:
          console.warn("getCurrentActionChoiceMode: wrong level")
          return ChoiceUserInputDisplayMode.ALL;
      }

    case LessonMode.INTERVIEW:
    case LessonMode.QUIZ:
    case LessonMode.CHOICES_TEST_TWO_STEP:
      return ChoiceUserInputDisplayMode.ALL;

    default:
      console.warn("getCurrentActionChoiceMode: unsupported lesson mode");
      return ChoiceUserInputDisplayMode.ALL;
  }
}
