import {useState, useCallback} from "react"

export default function useWaitForFileUpload(): [
  boolean, ((promise: Promise<any>, data: Object) => void)
] {
  const [isUploading, setUploading] = useState(false);
  const waitForFileUpload = useCallback((promise: Promise<any>, data: Object) => {
    const isDataContainsFiles = Object.values(data).some(item => item instanceof File)

    if (isDataContainsFiles) {
      setUploading(true)
    }

    promise.finally(() => isDataContainsFiles && setUploading(false));
  }, []);

  return [isUploading, waitForFileUpload];
}