import React, {useCallback, useMemo} from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {Course} from "../../../schema";

import {useTour} from "../../../providers/tour";
import {isEditorIndexTourMock} from "../../../scenes/editor/index.tour";

import EditItemDropdown from "../../EditItemDropdown";

type props = {
  course: Pick<Course, "id">,
  disabled?: boolean,
  onCopy: (courseId: Course["id"]) => void,
  onDelete: (courseId: Course["id"]) => void
}

export default function CourseEditDropdown({course, disabled, onCopy, onDelete, ...props}: props) {
  const {t} = useTranslation();

  const {mock: tourMock} = useTour();

  const mock = useMemo(() => {
    if (isEditorIndexTourMock(tourMock)) {
      return tourMock.getBy.course.get(course.id);
    }
  }, [course, tourMock]);
  const onDropdownClick = mock?.eventHandlers.dropdownClick;

  const onRefClick = useCallback(() => {
    onDropdownClick && setTimeout(onDropdownClick);
  }, [onDropdownClick]);

  const copyHandler = useCallback(() => {
    onCopy && onCopy(course.id);
  }, [onCopy, course.id]);

  const deleteHandler = useCallback(() => {
    onDelete && onDelete(course.id);
  }, [onDelete, course.id]);

  return (
    <EditItemDropdown
      className={mock?.classes.dropdownContent}
      buttonClassName={mock?.classes.dropdown}
      onRefClick={onRefClick}
    >
      <EditItemDropdown.Action title={t("common.copy")} onClick={copyHandler} disabled={disabled}/>
      <EditItemDropdown.Action title={t("common.delete")} onClick={deleteHandler} disabled={disabled}/>
    </EditItemDropdown>
  )
}

CourseEditDropdown.fragments = {
  root: gql`
    fragment CourseEditDropdown on Course {
      id
    }
  `
}
