import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import useAdaptiveClasses from "../../hooks/useAdaptiveClasses";
import {mapObject} from "../../utils";

import Checkbox from "../../ui/checkbox";

import cn from "classnames";
import classes from "./DetailedFeedback.module.css";
import {DetailedSessionFeedbackInput} from "../../schema";

type PropertiesNames = Record<keyof DetailedSessionFeedbackInput, string>

type props = Omit<React.ComponentProps<"div">, "isPositive" | "altLocales" | "value" | "onChange"> & {
  adaptive?: boolean
  isPositive: boolean
  altLocales?: boolean
  values: DetailedSessionFeedbackInput
  changeHandler: React.ChangeEventHandler<HTMLInputElement>;
}

export default function DetailedFeedback(
  {isPositive, altLocales, values, changeHandler, adaptive, className, ...props}: props) {
  const ac = useAdaptiveClasses(classes, adaptive)

  const {t} = useTranslation();

  const items = useMemo<Partial<PropertiesNames>>(() => ({
    positive: {
      recognition: t("components.DetailedFeedback.recognition.positive"),
      performance: t("components.DetailedFeedback.performance.positive"),
      avatar: t("components.DetailedFeedback.avatar.positive"),
      content: t("components.DetailedFeedback.content.positive"),
      format: altLocales ? t("components.DetailedFeedback.format.positiveAlt")
      : t("components.DetailedFeedback.format.positive"),
    },
    negative: {
      recognition: t("components.DetailedFeedback.recognition.negative"),
      performance: t("components.DetailedFeedback.performance.negative"),
      avatar: t("components.DetailedFeedback.avatar.negative"),
      content: t("components.DetailedFeedback.content.negative"),
      format: altLocales ? t("components.DetailedFeedback.format.negativeAlt")
      : t("components.DetailedFeedback.format.negative"),
      difficulty: t("components.DetailedFeedback.difficulty.negative"),
      hardware: t("components.DetailedFeedback.hardware.negative"),
    }
  }[isPositive ? "positive" : "negative"]), [t, isPositive, altLocales])

  return (
    <div className={cn(ac.root, className)} {...props}>
      {Object.values(mapObject(items, (name, key) => (
        <DetailedFeedbackItem
          adaptive={adaptive}
          key={key}
          name={key}
          itemName={name!}
          checked={!!values[key]}
          onChange={changeHandler}
        />
      )))}
    </div>
  );
}

type itemProps =
  Omit<React.ComponentProps<typeof Checkbox>, "classname" | "children"> &
  Pick<React.ComponentProps<"div">, "className" | "children"> & {
    itemName: string,
  }

function DetailedFeedbackItem({className, itemName, ...props}: itemProps) {
  return(
    <div className={cn(classes.item, className)}>
      <Checkbox className={classes.checkbox} {...props}/>
      <span className={classes.itemName}>{itemName}</span>
    </div>
  )
}
