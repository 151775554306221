import React, {useEffect, useMemo} from "react";
import browser from "../../browser";
import ErrorScreen from "../system/generic-error-screen";
import {useTranslation} from "react-i18next";
import {analyticsSendEvent} from "../../libs/analytics";

export default function NoMediaRecorderScene() {
  const {t} = useTranslation();

  const isIOS13 = useMemo(() => {
    return browser.satisfies({
      "ios": {
        "safari": ">=13"
      }
    })
  }, []);

  useEffect(() => {
    analyticsSendEvent("browserNotSupported", {
      reason: "noMediaRecorder"
    });
  }, []);

  return (
    <ErrorScreen
      documentTitle={t("noMediaRecorder.title")}
      title={t("noMediaRecorder.h1")}
      description={t("noMediaRecorder.desc")}
    >
      {isIOS13 && (
        <p>{t("noMediaRecorder.iOS13Steps")}</p>
      )}
    </ErrorScreen>
  )
}
