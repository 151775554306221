import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Scenario} from "../../../types";

import {LessonScenarioActivationMethod} from "../../../schema";
import Button from "../../../ui/button";
import {AddIcon} from "../../../ui/icons";
import Scroll from "../../../ui/scroll";

import cn from "classnames";
import classes from "./ScenarioSelector.module.css";

export type ScenarioSelectorProps = React.ComponentProps<"div"> & {
  scenarios: Scenario[],
  currentScenarioId: Scenario["id"],
  disabled?: boolean,
  onScenarioIdSelect: (id: Scenario["id"]) => void,
  onScenarioAdd: () => void
}

export default function ScenarioSelector({
  scenarios, currentScenarioId, disabled, onScenarioIdSelect, onScenarioAdd, className, ...props
}: ScenarioSelectorProps) {
  const {t} = useTranslation();

  const selectScenario = useCallback((e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("data-id");
    id && onScenarioIdSelect(id)
  }, [onScenarioIdSelect])

  const sortedScenarios = useMemo(() => [...scenarios].sort(
    (a, b) => Number(b.isMain) - Number(a.isMain)), [scenarios])

  return (
    <Scroll className={cn(classes.root, className)} {...props}>
      {sortedScenarios.map((scenario) => (
        <Button
          key={scenario.id}
          className={classes.scenarioButton}
          data-id={scenario.id}
          onClick={selectScenario}
          color={scenario.isMain
            ? "success"
            : scenario.activationMethod === LessonScenarioActivationMethod.TRIGGER_PHRASE ? "dark"
            : scenario.activationMethod === LessonScenarioActivationMethod.USER_INPUT ? "brand"
            : "primary"
          }
          variant={currentScenarioId === scenario.id ? "outlined" : "filled"}
        >
          {scenario.isMain ? t("editorLessonEdit.scenario.main") : scenario.name}
        </Button>
      ))}
      <Button className={classes.addButton} onClick={onScenarioAdd} disabled={disabled}>
        <AddIcon/>
        {t("common.add")}
      </Button>
    </Scroll>
  )
}
