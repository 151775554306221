import {useState, useCallback} from "react"

type SetState<T> = React.Dispatch<React.SetStateAction<Set<T>>>
type SetStateByArray<T> = React.Dispatch<React.SetStateAction<T[]>>
type Add<T> = (item: T) => void
type Delete<T> = (item: T) => boolean

export default function useSetState<T>(initialState?: readonly T[] | Set<T>): [
  Set<T>, SetState<T>, SetStateByArray<T>, Add<T>, Delete<T>
] {
  const [set, setState] = useState<Set<T>>(new Set(initialState ?? []));

  const addItem = useCallback<Add<T>>((item: T) => {
    setState((prevState) => {
      const newState = new Set(prevState);
      return newState.add(item);
    })
  }, []);

  const deleteItem = useCallback<Delete<T>>((item: T) => {
    let deleted: boolean = false;
    setState((prevState) => {
      const newState = new Set(prevState);
      deleted = newState.delete(item);
      return newState;
    })
    return deleted;
  }, []);

  const setStateByArray = useCallback<SetStateByArray<T>>((arrayOrCallback) => {
    if (Array.isArray(arrayOrCallback)) {
      setState(new Set(arrayOrCallback))
    } else if (typeof arrayOrCallback === "function") {
      setState(prevState => new Set(arrayOrCallback(Array.from(prevState))))
    } else {
      throw new Error("setStateByArray called with argument that is nor array or function")
    }
  }, []);

  return [set, setState, setStateByArray, addItem, deleteItem]
}