import React, {useEffect, useMemo, useState} from "react";

import {getDefaultErrorScreens, useErrorContext} from "../../providers/errorBoundary";
import ErrorScreen from "../../scenes/system/generic-error-screen";

type props = React.PropsWithChildren<{
  errorScreens?: Map<string, ErrorScreenOptions>,
  extra?: React.ReactNode,
  stopFallthrough?: boolean
}>

export type ErrorScreenOptions = {
  screen?: (props?: any) => JSX.Element,
  stopErrorProcessing?: boolean,
}

export default function ScreenErrorBoundary({errorScreens, children, extra, stopFallthrough}: props) {
  const defaultErrorScreens = useMemo(() => (getDefaultErrorScreens()), []);
  errorScreens = errorScreens ?? defaultErrorScreens;

  const {addErrorListener, removeErrorListener} = useErrorContext();

  const [errorCode, setErrorCode] = useState<string>();

  const Screen = errorScreens?.get(errorCode ?? "")?.screen;

  useEffect(() => {
    const onError = (e: ErrorEvent) => {
      let errorCode = e.error.message

      setErrorCode(errorCode)
      if (errorScreens?.get(errorCode)?.stopErrorProcessing) {
        console.warn(e);
        return true;
      }
      if (stopFallthrough) {
        console.error(e);
        return true;
      }
    }

    addErrorListener(onError, true)
    return () => {
      removeErrorListener(onError)
    }
  }, [errorScreens, addErrorListener, removeErrorListener, stopFallthrough]);

  if (errorCode) {
    if (Screen) {
      return (
        <Screen children={extra}/>
      )
    } else {
      return (
        <ErrorScreen>
          {extra}
          <ErrorScreen.RefreshButton/>
          <ErrorScreen.HomepageLink/>
        </ErrorScreen>
      )
    }
  }

  return <>{children}</>;
}