import React, {forwardRef, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getTextWithStressMarks} from "../../../Phrase";
import isEqual from "lodash/isEqual";
import {parseMimeType, PlayableMediaType} from "../../../../utils";

import {AvatarAction} from "../../../../schema";
import {FSImageRef} from "../../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../../ui/fullscreen/video";

import {PlayerReduxState} from "../../../../redux/player/types";
import {getCurrentAction} from "../../../../redux/player/selectors";

import Display from "./Display";
import ActionDisplay from "./ActionDisplay";

type props = {
  avatarName?: string,
  openFullscreenMedia: () => void,
}

type AvatarActionDisplayType = React.ForwardRefExoticComponent<
  props & React.RefAttributes<FSImageRef | FSVideoRef>
> & {
  selector?: (state: PlayerReduxState) => {
    action: AvatarAction;
  }
}

const AvatarActionDisplay: AvatarActionDisplayType = forwardRef(({avatarName, openFullscreenMedia}, ref) => {
  const {t} = useTranslation();
  const {action} = useSelector(AvatarActionDisplay.selector!, isEqual);

  const text = useMemo(() => getTextWithStressMarks(action.text), [action.text])

  let mediaPreview;

  if (action.media && action.mediaType) {
    const mediaType = parseMimeType(action.mediaType).type;

    const mediaProps = {
      onClick: openFullscreenMedia,
      src: action.media,
      ref: ref,
    }

    switch (mediaType) {
      case PlayableMediaType.IMAGE:
        mediaPreview = (
          <Display.Image {...mediaProps} alt={text} openable={!!action.mediaViewRequired}/>
        )
        break;
      case PlayableMediaType.VIDEO:
        mediaPreview = (
          <Display.Video {...mediaProps}/>
        )
        break;
      default:
        console.error(`Unsupported media type in system bubble: ${mediaType}`)
    }
  }

  return (
    <ActionDisplay
      title={t("player.avatarActionTitle", {
        name: avatarName ?? t("common.avatarName")
      })}
      mediaPreview={mediaPreview}
      translateContent="no"
    >{text}</ActionDisplay>
  )
});

export default AvatarActionDisplay;

AvatarActionDisplay.selector = (state: PlayerReduxState) => ({
  action: getCurrentAction(state) as AvatarAction,
});
