import React, {forwardRef} from "react";
import useAdaptiveClasses from "../hooks/useAdaptiveClasses";

import cn from "classnames";
import classes from "./toggle.module.css";

type props = Omit<React.ComponentProps<"input">, "type" | "size" | "adaptive"> & {
  size?: "s" | "m" | "l",
  adaptive?: boolean
}

const Toggle = forwardRef(({className, size, adaptive, ...props}: props, ref: React.Ref<HTMLInputElement>) => {
  const ac = useAdaptiveClasses(classes, adaptive)
  return (
    <label className={cn(ac.switch, {[ac[size ?? ""]]: size}, className)}>
      <input aria-label="Toggle" ref={ref} type="checkbox" {...props}/>
      <span className={ac.slider}></span>
    </label>
  );
});

export default Toggle;
