import {PlayerReduxState, UserInputDisplayMode} from "../types";
import getCurrentAction from "./getCurrentAction";
import {isAnyUserInputAction} from "../utils";
import {getCurrentActionPhraseMode} from "./index";
import {PhraseVariant} from "../../../components/Phrase";
import {isUserInputAction} from "../../../types";
import isLastUserInputError from "./isLastUserInputError";

export default function getCurrentActionDisplayMode(state: PlayerReduxState) {
  const action = getCurrentAction(state);

  if (!action || !isAnyUserInputAction(action)) {
    return undefined;
  }

  if (
    isUserInputAction(action) &&
    action.hintText &&
    (getCurrentActionPhraseMode(state) !== PhraseVariant.HIDDEN) &&
    !state.exposedActions.includes(action.id) &&
    !isLastUserInputError(state)
  ) {
    return UserInputDisplayMode.HINT_TEXT
  } else {
    return UserInputDisplayMode.EXPECTED_TEXT
  }
}
