import React from "react";
import AbstractVideoArea from "./AbstractVideoArea";
import cn from "classnames";
import classes from "./VideoArea.module.css";

type props = React.ComponentProps<"div"> & {
  children: React.ReactNode,
  integer?: boolean,
  waitUntilMeasured?: boolean,
};

const VideoArea = ({className, children, integer, waitUntilMeasured, style, ...props}: props) => {
  return (
    <AbstractVideoArea itemsCount={1} integer={integer} waitUntilMeasured={waitUntilMeasured}>
      {function (size) {
        if (style) {
          style = Object.assign({}, style, size);
        } else {
          style = size;
        }

        return (
          <div className={cn(classes.root, className)} style={style} {...props}>
            {children}
          </div>
        )
      }}
    </AbstractVideoArea>
  );
}

export default VideoArea;
