import React, {forwardRef} from "react";

import FSImage, {FSImageRef} from "../../../../ui/fullscreen/image";
import FSVideo, {FSVideoRef} from "../../../../ui/fullscreen/video";

import cn from "classnames";
import classes from "./Display.module.css";
import ReactImage from "../../../ReactImage";

type props = {
  className?: string,
  children?: React.ReactNode
};

const Display = function ({children, className: extraClassName, ...props}: props) {
  return (
    <div className={cn(classes.display, extraClassName)} {...props}>
      {children}
    </div>
  )
}

Display.Title = React.memo(
  function ({className, title, ...props}:
              Omit<React.ComponentProps<"span">, "title" | "children"> & { title?: React.ReactNode }) {
    return (
      <span className={cn(classes.title, className)} {...props}>{title}</span>
    )
  }
);

Display.Content = React.memo(function ({className, children, ...props}: React.ComponentProps<"div">) {
  return (
    <div className={cn(classes.content, className)} {...props}>
      {children}
    </div>
  )
});

Display.Extra = React.memo(function ({className, children, ...props}: React.ComponentProps<"div">) {
  return (
    <div className={cn(classes.extra, className)} {...props}>
      {children}
    </div>
  )
});

Display.Row = React.memo(function ({className, children, ...props}: React.ComponentProps<"div">) {
  return (
    <div className={cn(classes.row, className)} {...props}>
      {children}
    </div>
  )
});

Display.RowItem = React.memo(function ({className, children, ...props}: React.ComponentProps<"div">) {
  return (
    <div className={cn(classes.rowItem, className)} {...props}>
      {children}
    </div>
  )
});

type imageProps = Omit<React.ComponentProps<"div">, "ref"> & Pick<React.ComponentProps<"img">, "alt" | "src"> & {
  openable?: boolean
}

Display.Image = React.memo(forwardRef<FSImageRef, imageProps>(
  function ({className, alt, openable, ...props}, ref) {
    return openable ? (
      <FSImage className={cn(classes.media, className)} ref={ref} alt={alt ?? ""} adaptive {...props}/>
    ) : (
      <ReactImage className={cn(classes.media, className)} alt={alt ?? ""} {...props}/>
    )
  }
));

type videoProps = React.ComponentProps<typeof FSVideo>

Display.Video = React.memo(forwardRef<FSVideoRef, videoProps>(
  function ({className, ...props}, ref) {
    return (
      <FSVideo className={cn(classes.media, className)} ref={ref} adaptive exitOnEnded {...props}/>
    )
  }
));

export default Display;
