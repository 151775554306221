import {DependencyList, useEffect, useState} from "react"

export default function useAsyncMemo<T>(factory: () => Promise<T>, deps: DependencyList): T | undefined {
  const [value, setValue] = useState<T | undefined>();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    factory().then(result => setValue(result))
  }, deps)

  return value;
}