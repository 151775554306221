import React, {
  ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useCallback, useEffect, useMemo, useRef, useState
} from "react";
import {Manager, Popper, Reference} from "react-popper";
import Portal from "./portal";
import {AsAnyComponentWithDefault} from ".";
import {bindEventListener} from "../utils";

import cn from "classnames";
import classes from "./dropdown.module.css";

type DropdownContext = {
  referenceRef: React.Ref<HTMLElement | undefined>,
  popperRef: React.Ref<HTMLElement | undefined>,
  state: boolean,
  toggle: (newState: boolean) => void
}

type DropdownProps = {
  children?: React.ReactNode,
  stopCloseOnOuterClick?: boolean
};

export type DropdownRef = {
  open: () => void,
  close: () => void,
  state: boolean
}

type DropdownReferenceProps<T extends React.ElementType> = AsAnyComponentWithDefault<T, {}, "div">

type DropdownContentProps<T extends React.ElementType> = AsAnyComponentWithDefault<T, Pick<
  React.ComponentProps<typeof Popper>,
  "placement" | "modifiers"
> & {
  notStopPropagation?: boolean,
  offset?: [number, number]
}, "div">

const Context = React.createContext<DropdownContext>({
  referenceRef: null,
  popperRef: null,
  state: false,
  toggle: (newState: boolean) => {
    console.error("[NotImplemented]")
  }
});

const Dropdown: ForwardRefExoticComponent<PropsWithoutRef<DropdownProps> & RefAttributes<DropdownRef>> =
  React.forwardRef(function ({children, stopCloseOnOuterClick}: DropdownProps, ref) {
    const [state, toggle] = useState(false);

    const referenceRef = useRef<HTMLElement>();
    const popperRef = useRef<HTMLElement>();

    useEffect(() => {
      if (!state) {
        return;
      }

      const outsideClickHandler = (e: any) => {
        if ((popperRef.current && popperRef.current?.contains(e.target))
          || (referenceRef.current && referenceRef.current?.contains(e.target))) {
          return;
        }

        toggle(false);
      }
      if (!stopCloseOnOuterClick) {
        return bindEventListener(document, "click", outsideClickHandler)
      }
    }, [state, stopCloseOnOuterClick, toggle]);

    React.useImperativeHandle(ref, () => ({
      open: () => toggle(true),
      close: () => toggle(false),
      state
    }), [state]);

    return (
      <Context.Provider value={{referenceRef, popperRef, state, toggle}}>
        <Manager>
          {children}
        </Manager>
      </Context.Provider>
    );
  });

function DropdownReference<T extends React.ElementType>({as: Component, ...props}: DropdownReferenceProps<T>) {
  const {referenceRef} = React.useContext(Context);

  return (
    <Reference innerRef={referenceRef}>
      {({ref}) => (
        <Component
          ref={ref}
          {...props}
        />
      )}
    </Reference>
  );
}

DropdownReference.defaultProps = {
  as: "div"
}


function DropdownContent({
  as: Component,
  className,
  style,
  children,
  placement,
  modifiers,
  offset,
  notStopPropagation,
  onClick: onClickEx,
  ...props
}: DropdownContentProps<React.ElementType>) {
  const {popperRef, state} = React.useContext(Context);

  const onClick = useCallback((e: React.SyntheticEvent) => {
    if (!notStopPropagation) {
      stopPropagation(e);
    }

    if (typeof onClickEx === "function") {
      (onClickEx as Function)(e);
    }
  }, [onClickEx, notStopPropagation])

  const calculatedModifiers = useMemo(() => [
    ...(modifiers ?? []),
    {
      name: "offset",
      options: {offset},
    },
  ], [modifiers, offset])

  return state ? (
    <Portal>
      <Popper innerRef={popperRef} placement={placement} modifiers={calculatedModifiers}>
        {(attrs) => (
          <Component
            ref={attrs.ref}
            className={cn(classes.root, className)}
            style={{...attrs.style, ...style}}
            onClick={onClick}
            {...props}
          >
            {children}
          </Component>
        )}
      </Popper>
    </Portal>
  ) : null;
}

DropdownContent.defaultProps = {
  as: "div"
}

function stopPropagation(e: React.SyntheticEvent) {
  e.stopPropagation();
}

export {
  DropdownReference,
  DropdownContent
};
export default Dropdown;
