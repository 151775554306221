import {PlayerReduxState} from "../types";
import getCurrentAction from "./getCurrentAction";
import {PhraseVariant} from "../../../components/Phrase";
import {LessonMode} from "../../../schema";
import {isAnyUserInputAction} from "../utils";

export default function getCurrentActionPhraseMode(state: PlayerReduxState) {
  const action = getCurrentAction(state);

  if (!action || !isAnyUserInputAction(action)) {
    return undefined;
  }

  switch (state.lesson.mode) {
    case LessonMode.STUDY_THREE_STEP:
    case LessonMode.STUDY_TWO_STEP:
      if (state.exposedActions.includes(action.id)) {
        return PhraseVariant.DEFAULT;
      }

      switch (state.level) {
        case 1:
          return PhraseVariant.DEFAULT;
        case 2:
          return PhraseVariant.DISPLAY_HINTS;
        case 3:
          return PhraseVariant.HIDDEN;
        default:
          console.warn("getCurrentActionPhraseMode: wrong level")
          return PhraseVariant.DEFAULT;
      }

    case LessonMode.CHOICE_BASED_STUDY:
    case LessonMode.INTERVIEW:
    case LessonMode.QUIZ:
    case LessonMode.CHOICES_TEST_TWO_STEP:
    case LessonMode.CHOICES_WITH_CUSTOM_SCORES:
    case LessonMode.CUSTOM_PARAMETERS_TEST:
      return PhraseVariant.DEFAULT;

    default:
      console.warn("getCurrentActionPhraseMode: unsupported lesson mode");
      return PhraseVariant.DEFAULT;
  }
}
