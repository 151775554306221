import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getRepeatFromAction} from "../selectors";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function repeat() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();
    const repeatFrom = getRepeatFromAction(state);

    if (!repeatFrom) {
      console.warn("repeat: wrong state");
      return;
    }

    analyticsSendEvent("playerRepeat", {
      sessionId: state.sessionId!,
      repeatFromId: repeatFrom.id
    });

    dispatch({
      type: "back",
      payload: {
        "actionId": repeatFrom.id,
      }
    });
  }
}
