import React from "react";

import Portal from "./portal";
import {CloseIcon} from "./icons";

import classes from "./fullscreenvideo.module.css";
import cn from "classnames";

type props = React.ComponentProps<"video"> & {
  onClose: (e: React.MouseEvent) => void,
}

export default function FullscreenVideo({onClose, className, ...props}: props) {
  return (
    <Portal>
      <video
        className={cn(classes.root, className)}
        controls
        autoPlay
        {...props}
      >
      </video>
      <button className={classes.btn} onClick={onClose}>
        <CloseIcon />
      </button>
    </Portal>
  )
}