import React, {ComponentProps} from "react";
import Skeleton from "../../ui/skeleton";
import {PlayCircleIcon} from "../../ui/icons";
import ClickableDiv from "../../ui/clickableDiv";

import cn from "classnames";
import classes from "./Bubble.module.css";

type BubbleProps = ComponentProps<typeof ClickableDiv>

export default function Bubble({className, ...props}: BubbleProps) {
  return (
    <ClickableDiv stopTriggerOnSpacebar className={cn(classes.root, className)} translate="no" {...props}/>
  )
}

Bubble.Row = function Row({className, ...props}: ComponentProps<"div">) {
  return (
    <div className={cn(classes.row, className)} {...props}/>
  )
}

Bubble.Content = function Content({className, ...props}: ComponentProps<"div">) {
  return (
    <div className={cn(classes.content, className)} {...props}/>
  )
}

Bubble.Placeholder = function Content({className, ...props}: ComponentProps<"div">) {
  return (
    <span className={cn(classes.placeholder, className)} {...props}/>
  )
}

Bubble.PlayButton = function PlayButton({className, ...props}: ComponentProps<"button">) {
  return (
    <button className={cn(classes.playBtn, className)} {...props}>
      <PlayCircleIcon/>
    </button>
  )
}

Bubble.ExtraIconsWrapper = function ExtraIconsWrapper({className, ...props}: ComponentProps<"div">) {
  return (
    <div className={cn(classes.extraIcons, className)} {...props}/>
  )
}

Bubble.Skeleton = React.memo(function ({className, testId, width}: {
  className?: string
  testId?: string
  width: number
}) {
  return (
    <Skeleton.Block
      className={cn(classes.root, classes.skeleton, className)}
      data-testid={testId}
      width={width}
    />
  )
});