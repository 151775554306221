import React, {
  forwardRef, PropsWithChildren, useEffect, useImperativeHandle, useMemo
} from "react";
import {useApolloClient} from "@apollo/client";
import {Provider} from "react-redux";
import createPlayerReduxStore, {PlayerReduxStore} from "./store";
import {Lesson, Scalars} from "../../schema";
import {useAudio} from "../../providers/audio";
import {useMediaRecorder} from "../../providers/mediaRecorder";
import {Scenario} from "../../types";
import {useProblemsWatcher} from "../../providers/problemsWatcher";
import {UTM} from "../../libs/analytics";

type props = PropsWithChildren<{
  lessonId: Scalars["ID"],
  lesson: Lesson,
  scenarios: Scenario[],
  isPreview: boolean,
  isShared: boolean,
  isExternal: boolean,
  externalUserId: Scalars["ID"] | null,
  askForCameraPermission?: boolean,
  utm?: UTM
}>

const PlayerReduxStoreProvider = forwardRef<PlayerReduxStore, props>(function ({
                                                                                 lessonId,
                                                                                 lesson,
                                                                                 scenarios,
                                                                                 children,
                                                                                 isPreview,
                                                                                 isShared,
                                                                                 isExternal,
                                                                                 externalUserId,
                                                                                 askForCameraPermission,
                                                                                 utm
                                                                               }, ref) {
  const client = useApolloClient();
  const {audioManager} = useAudio();
  const {toggleRecorder} = useMediaRecorder();
  const {setLimits, raise: raiseProblem} = useProblemsWatcher();

  useEffect(() => {
    setLimits({
      userInputNotPassed: 3
    })
  }, [setLimits])

  const store = useMemo(() => createPlayerReduxStore({
    client,
    audioManager,
    toggleRecorder,
    raiseProblem,
    lessonId,
    lesson,
    scenarios,
    isPreview,
    isShared,
    isExternal,
    externalUserId: externalUserId ?? undefined,
    askForCameraPermission,
    utm,
  }), [
    client, audioManager, toggleRecorder, raiseProblem, lesson, lessonId, scenarios, isPreview, isShared, isExternal,
    externalUserId, askForCameraPermission, utm
  ]);

  useImperativeHandle(ref, () => ({
    dispatch: (action: any) => store.dispatch(action),
    getState: () => store.getState(),
    subscribe: (listener) => store.subscribe(listener),
    replaceReducer: (nextReducer) => store.replaceReducer(nextReducer),
    [Symbol.observable]: () => store[Symbol.observable]()
  }), [store]);

  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
})

export default PlayerReduxStoreProvider;
