// Hooks
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMediaRecorder} from "../../../providers/mediaRecorder";

// Libs, Types
import isEqual from "lodash/isEqual";
import {Avatar, Lesson, LessonInputMode} from "../../../schema";
import bindHotkey from "../../../libs/bindHotkey";
import {analyticsSendEvent} from "../../../libs/analytics";
import {Action} from "../../../types";

// Redux
import {PlayerReduxState} from "../../../redux/player/types";
import {canRecord, getCurrentAction, isHintConfirmation, isIntroStage} from "../../../redux/player/selectors";
import {changeRecognizingState} from "../../../redux/player/actions";

// Classes
import classes from "./VideoCallPlayer.module.css";
import cn from "classnames";

// Components
import Container from "../../../ui/adaptivecontainer";
import AvatarPlayer, {AvatarPlayerRef} from "../AvatarPlayer";
import UserVideo from "./UserVideo";
import VideoCallStepDisplay from "./VideoCallStepDisplay";
import VideoArea from "./VideoArea";
import Layout, {Header} from "../../Layout";
import BottomBar from "./BottomBar";
import {FSImageRef} from "../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../ui/fullscreen/video";


type props = {
  lesson: Lesson,
  avatar: Avatar,
  gestures: string[],
  initialMode: "2D" | "3D",
  headless?: boolean,
  backUrl?: string,
  allowFullscreen?: boolean,
  toggleFullscreen?: () => void,
}

const VideoCallPlayer = ({
                           allowFullscreen,
                           toggleFullscreen,
                           lesson,
                           initialMode,
                           avatar,
                           gestures,
                           headless,
                           backUrl
                         }: props) => {
  const [avatarLoaded, setAvatarLoaded] = useState<boolean>();
  const onAvatarLoaded = useCallback(() => setAvatarLoaded(true), []);
  const onAvatarUnloaded = useCallback(() => setAvatarLoaded(false), []);
  const avatarPlayerRef = useRef<null | AvatarPlayerRef>()

  const {
    actionType, actionId, canRecord, isHintConfirmation, recognitionEngine, recognitionPipeline,
    currentBackground
  } = useSelector(VideoCallPlayer.selector, isEqual);

  const fsRef = useRef<FSImageRef | FSVideoRef>(null)

  const openFullscreenMedia = useCallback(() => {
    analyticsSendEvent("playerOpenFullscreenMedia")

    setMediaIsViewed(true)
    fsRef.current?.enterFullscreen();
  }, [fsRef]);

  const [mediaIsViewed, setMediaIsViewed] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    setMediaIsViewed(false)
  }, [actionId])

  const {
    recorderReady,
    isRecording,
    record,
    stopRecord,
    mediaType
  } = useMediaRecorder();

  const complete = useCallback(() => {
    if (!isRecording) {
      return
    }

    analyticsSendEvent("playerStopRecord", {
      trigger: "hotkey"
    })

    const hasId = stopRecord();
    if (hasId) {
      dispatch(changeRecognizingState(true));
    }
  }, [isRecording, dispatch, stopRecord]);

  const startRecord = useCallback(() => {
    if (isRecording) {
      return
    }

    analyticsSendEvent("playerStartRecord", {
      trigger: "hotkey",
      mediaType
    })

    record(recognitionEngine!, recognitionPipeline);
  }, [isRecording, mediaType, recognitionEngine, recognitionPipeline, record])

  useEffect(() => {
    if (lesson.inputMode === LessonInputMode.VOICE && canRecord && recorderReady) {
      return bindHotkey(32, startRecord, complete)
    }

    return () => {
    }
  }, [canRecord, recorderReady, lesson.inputMode, startRecord, complete]);

  const stepAlign = (() => {
    if (isHintConfirmation) {
      return undefined;
    }
    switch (actionType) {
      case "AvatarAction":
        return "left";
      case "UserInputAction":
        return "right";
      case "ChoiceUserInputAction":
      case "RandomAvatarAction":
        return "wide";
      default:
        return undefined
    }
  })()

  return (
    <Layout className={classes.layoutRoot}>
      {!headless && (<Header className={classes.header} type={"filled"} hideLinks adaptive/>)}
      <div className={classes.root}>
        <div className={classes.videoRoot}>
          <Container className={classes.videoContainer}>
            <div className={classes.playersContainer}>
              <div className={classes.video}>
                <VideoArea integer className={cn(classes.videoInner, classes.videoBackground)}
                  style={
                    currentBackground || lesson.background ? {
                      backgroundImage: `url(${currentBackground ?? lesson.background})`
                    } : undefined
                  }
                  waitUntilMeasured>
                  <AvatarPlayer
                    portraitMode
                    initialMode={initialMode}
                    avatar={avatar}
                    gestures={gestures}
                    onLoaded={onAvatarLoaded}
                    onUnloaded={onAvatarUnloaded}
                  />
                </VideoArea>
              </div>
              <div className={cn(classes.video, classes.userVideo)}>
                <UserVideo className={classes.videoInner}/>
              </div>
            </div>
            <div className={cn(
              classes.stepDisplay,
              stepAlign && classes[stepAlign]
            )}>
              <div className={classes.stepContainer}>
                <VideoCallStepDisplay
                  lesson={lesson}
                  avatar={avatar}
                  avatarLoaded={avatarLoaded}
                  openFullscreenMedia={openFullscreenMedia}
                  ref={fsRef}
                />
              </div>
            </div>
          </Container>
        </div>
        <BottomBar
          lessonName={lesson.name}
          backUrl={backUrl}
          openFullscreenMedia={openFullscreenMedia}
          mediaIsViewed={mediaIsViewed}
          avatarLoaded={avatarLoaded}
          switchAvatarMode={avatarPlayerRef.current?.switchMode}
          avatarMode={initialMode}
          allowFullscreen={allowFullscreen}
          toggleFullscreen={toggleFullscreen}
        />
      </div>
    </Layout>
  )
}

export default VideoCallPlayer

VideoCallPlayer.selector = (state: PlayerReduxState) => {
  return {
    actionType: (
      isIntroStage(state) ? "Intro" : getCurrentAction(state)?.__typename
    ) as Action["__typename"] | "Intro",
    actionId: getCurrentAction(state)?.id,
    canRecord: canRecord(state),
    isHintConfirmation: isHintConfirmation(state),
    recognitionEngine: state.recognitionEngine,
    recognitionPipeline: state.recognitionPipeline,
    currentBackground: state.currentBackground
  }
}
