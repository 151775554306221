import {ApolloCache, ApolloClient, DocumentNode, Reference, StoreObject} from "@apollo/client";
import {GraphQLError} from "graphql";
import {DeepNullable} from "../types";
import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import {reorder} from "../utils";
import {ReadFieldFunction} from "@apollo/client/cache/core/types/common";


type cacheQuery = {
  query: DocumentNode,
  variables?: any
};

type readFragmentArgs = {
  id: string,
  fragment: DocumentNode,
  fragmentName?: string,
  variables?: any
};

export function rewriteCacheFragment(cache: ApolloCache<any>, {id, fragment, variables}: readFragmentArgs,
                                     callback: (cacheData: any) => any) {
  const cacheData = cache.readFragment({
    id,
    fragment,
    variables
  });

  if (cacheData === null) {
    return;
  }

  const updatedCacheData = callback(cacheData);

  if (updatedCacheData === undefined) {
    return;
  }

  cache.writeFragment({
    id,
    fragment,
    variables,
    data: updatedCacheData
  })
}

export function rewriteCacheQuery(cache: ApolloCache<any>, {query, variables}: cacheQuery,
                                  callback: (cacheData: any) => any) {
  const cacheData = cache.readQuery({
    query,
    variables,
  });

  const updatedCacheData = callback(cacheData);

  if (updatedCacheData === undefined) {
    return;
  }

  cache.writeQuery({
    query: query,
    variables: variables,
    data: updatedCacheData
  })
}

export function hasGraphQLError(message: string, errors: ReadonlyArray<GraphQLError> | undefined) {
  if (!errors) {
    return false;
  }

  return errors.filter((err) => err.message === message).length > 0;
}

export type StoredCache = ApolloCache<Record<string, StoreObject>>

export function findParentOfObject<T extends StoreObject>(
  cache: StoredCache,
  targetObject: StoreObject,
  parentType: T["__typename"],
  arrayFieldName: keyof T,
) {
  const extractedCache = cache.extract();
  const parent = (Object.values(extractedCache).filter(item => item?.__typename === parentType) as T[])
    .find(
      item => (item[arrayFieldName] as Reference[])
        .some(object => object.__ref === cache.identify(targetObject))
    )
  return parent
}

export function isSameIdentity(value: StoreObject, other: StoreObject) {
  return (value.__typename === other.__typename) && (value.id === other.id)
}

export function removeIdentity<
  V extends StoreObject,
  T extends {[key: string]: Array<V>},
  K extends keyof T
>(object: T, fieldName: K, identity: V) {
  return {...object, [fieldName]: object[fieldName].filter(item => !isSameIdentity(item, identity))}
}

export function addIdentity<
  V extends StoreObject,
  T extends {[key: string]: Array<V>},
  K extends keyof T
>(object: T, fieldName: K, identity: V) {
  return {...object, [fieldName]: [...object[fieldName], identity]}
}

export function createOptimisticResponse<T extends Object>(
  client: ApolloClient<any>, readFragmentArgs: readFragmentArgs, opts?: {
    picked?: Partial<T>
  } & ({
    updatedData: Partial<DeepNullable<T>>,
    keys: (keyof T)[]
  } | {})
): T {
  const data = client.readFragment(readFragmentArgs) as T;
  if (opts) {
    let picked = opts.picked;
    if ("updatedData" in opts) {
      const {updatedData, keys} = opts;
      picked = {
        ...picked,
        ...omitBy(pick(updatedData, keys), isNull) as Partial<T>
      };
    }
    return {
      ...data,
      ...picked
    }
  }
  return data;
}

export function moveItemInCache<Parent extends StoreObject, Target extends StoreObject>(
  cache: ApolloCache<any>,
  target: Pick<Target, "__typename" | "id">,
  beforeItemId: Target["id"] | null,
  parentType: Parent["__typename"],
  arrayFieldName: keyof Parent,
) {
  const itemID = target.id
  const parentalCourse = findParentOfObject<Partial<Parent>>(cache, target, parentType, arrayFieldName)
  if (parentalCourse && cache.identify(parentalCourse)) {
    cache.modify({
      id: cache.identify(parentalCourse),
      fields: {
        [arrayFieldName]: (cachedLessons: Target[], {readField}: {readField: ReadFieldFunction}) => {
          const currentIndex = cachedLessons.findIndex((item) => readField("id", item) === itemID);

          if (beforeItemId === null) {
            return reorder(cachedLessons, currentIndex);
          }

          const afterItemIndex = cachedLessons.findIndex((item) => readField("id", item) === beforeItemId);
          const destinationIndex = afterItemIndex > currentIndex ? afterItemIndex - 1 : afterItemIndex;

          if (currentIndex === destinationIndex) {
            return cachedLessons;
          }

          return reorder(cachedLessons, currentIndex, destinationIndex);
        },
      },
    });
    return;
  }
}
