import React from "react";

import DropdownList, {Props as DropdownListProps} from "../ui/dropdownlist";
import IconButton from "../ui/iconbutton";
import {EllipsisVerticalIcon} from "../ui/icons";

import cn from "classnames";
import classes from "./EditItemDropdown.module.css";

export type Props = Omit<DropdownListProps, "content" | "children"> & {
  children: DropdownListProps["content"],
  buttonClassName?: string
}

export default function EditItemDropdown({children, className, buttonClassName, ...props}: Props) {
  return (
    <DropdownList rounded size="s"
      placement="bottom-start" offset={[0, 4]}
      className={cn(classes.root, className)}
      content={children}
    {...props}>
      <IconButton size="s" className={cn(classes.menuButton, buttonClassName)} icon={EllipsisVerticalIcon}/>
    </DropdownList>
  )
}

EditItemDropdown.Action = DropdownList.Action;
