import {ApolloCache, gql} from "@apollo/client";
import {Account, Editor, Student} from "../../schema";

export function loginUpdateCache(
  cache: ApolloCache<any>,
  {user, account}: { user?: Student | Editor, account?: Account }
) {
  cache.writeQuery({
    query: gql`query {
      user: getCurrentUser { id __typename },
      account: getCurrentAccount { id __typename }
    }`,
    data: {
      user: user ? {
        id: user.id,
        __typename: user.__typename
      } : null,
      account: account ? {
        id: account.id,
        __typename: account.__typename
      } : null
    }
  });
}
