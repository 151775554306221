import React, {
  ChangeEvent, ChangeEventHandler,
  useCallback, useEffect, useState
} from "react";
import {useTranslation} from "react-i18next";
import useVisibility from "../../hooks/useVisibility";
import {AsAnyComponentWithDefault} from "../../ui";

import Button from "../../ui/button"

import cn from "classnames";
import classes from "./EditableInput.module.css";
import Textarea from "../../ui/textarea";


type props = Omit<React.ComponentProps<typeof Textarea>, "value"> & {
  buttonClassName?: React.ComponentProps<typeof Button>["className"]
}

export default function EditableTextarea({onChange, defaultValue, className, buttonClassName, ...props}: props) {
  const {t} = useTranslation();

  const [value, setValue] = useState((defaultValue ?? "").toString());

  useEffect(() => {
    setValue((defaultValue ?? "").toString());
  }, [defaultValue])

  const [buttonsVisible, showButtons, hideButtons] = useVisibility();

  const [persistedEvent, setPersistedEvent] = useState<ChangeEvent<HTMLTextAreaElement> | undefined>();

  const onInputChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
    e.persist();
    setPersistedEvent(e);

    setValue(e.target.value);
  }, [])

  const onCancel = useCallback(() => {
    setValue((defaultValue ?? "").toString());
    hideButtons();
  }, [defaultValue, hideButtons])

  const onBlur = useCallback(() => {
    if (value === defaultValue) {
      hideButtons();
    }
  }, [value, defaultValue, hideButtons])

  const onSave = useCallback(() => {
    if (value !== defaultValue) {
      if (persistedEvent) {
        onChange && onChange(persistedEvent);
      }
    }
    hideButtons();
  }, [value, defaultValue, persistedEvent, onChange, hideButtons])

  return (
    <>
      <Textarea
        required
        className={cn(classes.textarea, className)}
        value={value}
        onFocus={showButtons}
        onChange={onInputChange}
        onBlur={onBlur}
        { ...props}
      />
      {buttonsVisible ?
        (<>
          <Button
            className={classes.saveButton}
            onClick={onSave}
            color='success'
          >{t("common.save")}</Button>
          <Button
            className={classes.cancelButton}
            onClick={onCancel}
          >{t("common.cancel")}</Button>
        </>) : null
      }
    </>
  )
}

type WrapperProps<T extends React.ElementType> = AsAnyComponentWithDefault<T, {
  multiline?: boolean
}, "div">

function Wrapper<T extends React.ElementType>({as: Component, multiline, className, ...props}: WrapperProps<T>) {
  return (
    <Component className={cn(classes.wrapper, {[classes.multiline]: multiline}, className)} {...props}/>
  )
}

Wrapper.defaultProps = {
  as: "div"
}

EditableTextarea.Wrapper = Wrapper;
