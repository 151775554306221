import React from "react";
import {useTranslation} from "react-i18next";
import Content from "./Content"
import {useMediaRecorder} from "../../../../providers/mediaRecorder";

export type Props = {}

export default function PermissionContent({...props}: Props) {
  const {t} = useTranslation();
  const {hasPermission} = useMediaRecorder();

  if (hasPermission === false) {
    return (
      <Content variant="info" title={t("player.noPermissionTitle")} {...props}>
        {t("player.noCameraOrMicrophonePermissionMessage")}
      </Content>
    );
  }

  return null;
}
