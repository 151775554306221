import React, {useContext, useEffect, useState} from "react"
import Modal, {ModalContent, ModalFooter, ModalHeader} from "../ui/modal";
import PopupPortal from "../ui/popupportal";
import classes from "./ModalProvider.module.css";


type modal = {
  id?: string

  className?: string
  adaptive?: boolean
  size?: "s" | "m" | "l" | "xl"
  header?: React.ReactNode
  content: React.ReactNode
  footer?: React.ReactNode

  hideCloseButton?: boolean
  stopCloseOnOuterClick?: boolean
};

type context = {
  add: (opts: modal, prepend?: boolean) => {
    id: string,
    remove: () => void
  },
  remove: (id: string) => void,
}

const Context = React.createContext<context>({} as context)

export function useModal() {
  return useContext(Context);
}

export default function ModalProvider({children}: React.PropsWithChildren<{}>) {
  const [items, setItems] = useState<modal[]>([]);

  const remove = React.useCallback((id: string) => {
    setItems((items) => {
      return items.filter(it => it.id !== id);
    });
  }, []);

  const add: context["add"] = React.useCallback((modal, prepend) => {
    let id = (modal.id === undefined)
      ? `${Math.floor(Date.now() / 1000)}_${Math.floor(Math.random() * 100)}`
      : modal.id;

    setItems((items) => {
      items = items.filter(it => it.id !== id);
      const newItem = {
        ...modal,
        id
      };

      return prepend ? [newItem, ...items] : [...items, newItem];
    });

    return {
      id: id,
      remove: () => remove(id)
    };
  }, [remove]);

  const currentItem = items?.[0];
  const currentItemId = currentItem?.id;
  const hasCurrentItem = !!currentItemId;

  useEffect(() => {
    if (hasCurrentItem) {
      document.body.classList.add(classes["noscroll"]);
    }

    return () => {
      document.body.classList.remove(classes["noscroll"]);
    }
  }, [hasCurrentItem])

  const closeHandler = React.useCallback((e?: any) => {
    currentItemId && remove(currentItemId);
  }, [remove, currentItemId]);

  useEffect(() => {
    if (!currentItemId) {
      return;
    }

    const onEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeHandler();
      }
    }

    window.addEventListener("keydown", onEsc);
    return () => {
      window.removeEventListener("keydown", onEsc);
    }
  }, [currentItemId, closeHandler]);

  const {
    header,
    content,
    footer,
    adaptive,
    hideCloseButton,
    stopCloseOnOuterClick
  } = currentItem ?? {};

  return (
    <Context.Provider value={{add, remove}}>
      {children}

      {!!currentItem && (
        <PopupPortal
          className={classes.root}
          onMouseDown={!stopCloseOnOuterClick ? closeHandler : undefined}
        >
          <Modal
            className={currentItem.className}
            adaptive={adaptive}
            size={currentItem.size}
            onClose={closeHandler}
            onMouseDown={stopPropagation}
            hideCloseButton={hideCloseButton}
          >
            {header && (
              <ModalHeader>
                {header}
              </ModalHeader>
            )}
            {content && (
              <ModalContent>
                {content}
              </ModalContent>
            )}
            {footer && (
              <ModalFooter>
                {footer}
              </ModalFooter>
            )}
          </Modal>
        </PopupPortal>
      )}
    </Context.Provider>
  )
}

function stopPropagation(e: React.MouseEvent) {
  e.stopPropagation();
}
