import amplitude from "amplitude-js";
import {DEV} from "../settings";
import {mapObject} from "../utils";

const client = amplitude.getInstance("main");

export function analyticsSetUp(apiKey: string) {
  if (DEV) {
    console.debug("analytics.setUp");
    return;
  }

  client.init(apiKey, undefined, {
    includeUtm: true
  });
}


export function analyticsSetUser(userId: string | null) {
  if (DEV) {
    console.debug("analytics.setUser", userId);
    return;
  }

  client.setUserId(userId);
}

export function analyticsSetUserProperties(properties: any) {
  if (DEV) {
    console.debug("analytics.setUserProperties", properties);
    return;
  }

  client.setUserProperties(properties);
}

export function analyticsSetOptOut(state: boolean) {
  if (DEV) {
    console.debug("analytics.optOut");
    return;
  }

  client.setOptOut(state);
}

export type EventData = {
  [key: string]: string | number | boolean | undefined
}

export function analyticsSendEvent(event: string, opts?: EventData) {
  if (DEV) {
    console.debug("analytics.sendEvent", event, opts);
    return;
  }

  client.logEventWithTimestamp(event, opts);
}

export function toEventData(data: Record<string, any>): EventData {
  return mapObject(data, (value) => {
    if (["string", "number", "boolean", "undefined"].includes(typeof value)) {
      return value
    }
    return String(value);
  })
}

export type UTM = {
  source: string | null
  medium: string | null
  campaign: string | null
  term: string | null
  content: string | null
}

export function getUTMParams(params: URLSearchParams): UTM {
  return {
    source: params.get("utm_source"),
    medium: params.get("utm_medium"),
    campaign: params.get("utm_campaign"),
    term: params.get("utm_term"),
    content: params.get("utm_content")
  }
}
