import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../../hooks/useFormState";
import {gql} from "@apollo/client";

import {SystemAction, SystemActionInput} from "../../../schema";
import {parseMimeType} from "../../../utils";
import {limitSpeechTextOnChange} from "./ActionEditForm";

import {PHRASE_MAX_LENGTH} from "../../../settings";

import LimitedTextarea from "../LimitedTextarea";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import WithTooltip from "../../../ui/tooltip";
import {HelpCircleIcon} from "../../../ui/icons";
import FileInput from "../FileInput";

import cn from "classnames";
import classes from "./forms.module.css";
import {useCurrentUser} from "../../../App.context";


type props = {
  action: SystemAction,
  onSubmit?: (data: SystemActionInput) => void,
  testId?: string
}

type formState = {
  text: NonNullable<SystemActionInput["text"]>,
  customAudio?: SystemActionInput["customAudio"],
  onlyFirstLevel?: NonNullable<SystemActionInput["onlyFirstLevel"]> | "on",
  media?: File | "",
  mediaViewRequired?: NonNullable<SystemActionInput["mediaViewRequired"]> | "on",
  setMediaAsBackground?: NonNullable<SystemActionInput["setMediaAsBackground"]> | "on",
}

export default function SystemActionEditForm({action, onSubmit, testId}: props) {
  const {t} = useTranslation();
  const inExperimentalMode = useCurrentUser()?.experimentalMode

  const formState = useFormState<formState>({
    initialValues: {
      text: action.text ?? "",
      onlyFirstLevel: action.onlyFirstLevel ? "on" : undefined,
      mediaViewRequired: action.mediaViewRequired ? "on" : undefined,
      setMediaAsBackground: action.setMediaAsBackground ? "on" : undefined,
    },
    preventDefault: true,
    onSubmit: (data)=>{
      onSubmit && onSubmit({
        ...data,
        onlyFirstLevel: data.onlyFirstLevel === "on",
        customAudio: data.customAudio === "" ? null : data.customAudio,
        media: data.media === "" ? null : data.media,
        mediaViewRequired: data.mediaViewRequired === "on",
        setMediaAsBackground: data.setMediaAsBackground === "on",
      })
    }
  })

  const audioSrc = useMemo<string | undefined>(() => {
    if (!!formState.values.customAudio || (action.isCustomAudio && action.audio)) {
      if (formState.values.customAudio !== "") {
        return formState.values.customAudio ?? action.audio
      }
    }
  }, [action.audio, action.isCustomAudio, formState.values.customAudio])

  const [mediaSrc, currentMediaType]: [string | undefined | File, string | undefined] = useMemo(() => {
    if (!!formState.values.media || action.media) {
      if (formState.values.media !== "") {
        return [
          formState.values.media ?? action.media ?? undefined,
          formState.values.media?.type ?? action.mediaType ?? undefined
        ];
      }
    }
    return [undefined, undefined];
  }, [action.media, action.mediaType, formState.values.media])

  const mediaIsVideo = !!currentMediaType && (parseMimeType(currentMediaType).type === "video")

  return (
    <form data-testid={`SystemActionEditForm:${testId}`} method='post' onSubmit={formState.submitHandler}>
      <div className={classes.row}>
        <label
          className={classes.label}
          htmlFor="text"
        >
          {t("components.ActionEditForm.systemText.title")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.ActionEditForm.systemText.help")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <LimitedTextarea
          maxLength={PHRASE_MAX_LENGTH}
          id="text"
          name="text"
          rows={4}
          value={formState.values.text ?? ""}
          onChange={formState.changeHandler}
          limitTextOnChange={limitSpeechTextOnChange}
          placeholder={t("components.ActionEditForm.systemText.placeholder")}
        />
      </div>

      <div className={classes.row}>
        <label>
          <Checkbox
            checked={formState.values.onlyFirstLevel === "on"}
            name="onlyFirstLevel"
            onChange={formState.changeHandler}
          />
          <span className={classes.checkboxText}>
            {t("components.ActionEditForm.onlyFirstLevel.title")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.ActionEditForm.onlyFirstLevel.help")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </span>
        </label>
      </div>

      <div className={classes.row}>
        <label className={classes.label}>
          {t("components.ActionEditForm.userMediafile.title")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.ActionEditForm.userMediafile.help")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <FileInput
          variant='audio'
          src={audioSrc ?? undefined}
          name="customAudio"
          allowMicInput
          onDelete={formState.changeHandler}
          setValues={formState.setValues}
        />
      </div>

      <div className={cn(classes.row, classes.mediaPreviewRow)}>
        <label
          className={classes.label}
          htmlFor="text"
        >
          {t("components.ActionEditForm.media.title")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.ActionEditForm.media.help")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <FileInput
          className={classes.mediaPreview}
          variant={["image", "video"]}
          src={mediaSrc}
          name="media"
          onDelete={formState.changeHandler}
          setValues={formState.setValues}
        />
      </div>

      {mediaSrc && (
        <div className={classes.row}>
          <label>
            <Checkbox
              checked={(formState.values.mediaViewRequired === "on") || mediaIsVideo}
              name="mediaViewRequired"
              disabled={mediaIsVideo}
              onChange={formState.changeHandler}
            />
            <span className={classes.checkboxText}>
              {t("components.ActionEditForm.mediaViewRequired.title")}
              {" "}
              <WithTooltip className={classes.help} as='span'
                          helpText={t("components.ActionEditForm.mediaViewRequired.help")}>
                <HelpCircleIcon/>
              </WithTooltip>
            </span>
          </label>
        </div>
      )}

      {inExperimentalMode && mediaSrc && (
        <div className={classes.row}>
          <label>
            <Checkbox
              checked={!!formState.values.setMediaAsBackground}
              name="setMediaAsBackground"
              onChange={formState.changeHandler}
            />
            <span className={classes.checkboxText}>
              {t("components.ActionEditForm.setMediaAsBackground.title")}
              {" "}
              <WithTooltip className={classes.help} as='span'
                           helpText={t("components.ActionEditForm.setMediaAsBackground.help")}>
                <HelpCircleIcon/>
              </WithTooltip>
            </span>
          </label>
        </div>
      )}

      <div className={classes.row}>
        <Button color="success">{t("common.save")}</Button>
      </div>
    </form>
  )
}

SystemActionEditForm.fragments = {
  root: gql`
    fragment SystemActionEditForm on SystemAction {
      id
      text
      audio
      isCustomAudio
      onlyFirstLevel
      media
      mediaType
      mediaViewRequired
      setMediaAsBackground
    }
  `
}
