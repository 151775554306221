import React, {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../../hooks/useFormState";
import {getLanguages} from "../../../i18n";
import {Lesson, LessonMode, LessonFilterInput} from "../../../schema";
import {HelpCircleIcon} from "../../../ui/icons";
import Input from "../../../ui/input";
import Select from "../../../ui/select";
import WithTooltip from "../../../ui/tooltip";

import cn from "classnames";
import classes from "./LessonsFilterForm.module.css";


type props = Omit<ComponentProps<"form">, "children" | "onSubmit"> & {
  initialValues?: LessonFilterInput,
  onSave?: (data: LessonFilterInput) => void,
}

type formState = {
  lessonName?: Lesson["name"],
  lessonMode: Lesson["mode"] | "0",
  languageCode: Lesson["languageCode"] | "0",
}

export default function LessonsFilterForm({initialValues, onSave, className, ...props}: props) {
  const {t} = useTranslation();

  const formState = useFormState<formState>({
    initialValues: {
      lessonName: initialValues?.name ?? undefined,
      lessonMode: initialValues?.lessonMode ?? "0",
      languageCode: initialValues?.languageCode ?? "0",
    },
    preventDefault: true,
    onChange: (data) => onSave && onSave({
      name: data.lessonName,
      lessonMode: data.lessonMode === "0" ? undefined : data.lessonMode,
      languageCode: data.languageCode === "0" ? undefined : data.languageCode,
    })
  });

  return (
    <form method="post" className={cn(classes.root, className)} onSubmit={formState.submitHandler} {...props}>
      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.LessonsFilterForm.name.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonsFilterForm.name.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonsFilterForm.name.helpText")}>
          <Input
            name="lessonName"
            displaySize="sm"
            placeholder={t("components.LessonsFilterForm.name.placeholder")}
            value={formState.values.lessonName ?? undefined}
            onChange={formState.changeHandler}
          />
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.LessonsFilterForm.mode.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonsFilterForm.mode.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonsFilterForm.mode.helpText")}>
          <Select name="lessonMode" value={formState.values.lessonMode ?? undefined}
                  onChange={formState.changeHandler}>
            <option value={0}>
              {t("components.LessonsFilterForm.mode.no")}
            </option>
            <option value={LessonMode.STUDY_THREE_STEP}>
              {t("types.LessonMode.STUDY_THREE_STEP")}
            </option>
            <option value={LessonMode.STUDY_TWO_STEP}>
              {t("types.LessonMode.STUDY_TWO_STEP")}
            </option>
            <option value={LessonMode.CHOICES_TEST_TWO_STEP}>
              {t("types.LessonMode.CHOICES_TEST_TWO_STEP")}
            </option>
            <option value={LessonMode.CHOICE_BASED_STUDY}>
              {t("types.LessonMode.CHOICE_BASED_STUDY")}
            </option>
            <option value={LessonMode.INTERVIEW}>
              {t("types.LessonMode.INTERVIEW")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.LessonsFilterForm.language.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonsFilterForm.language.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonsFilterForm.language.helpText")}>
          <Select name="languageCode" value={formState.values.languageCode ?? undefined}
                  onChange={formState.changeHandler}>
            <option value={0}>
              {t("components.LessonsFilterForm.language.no")}
            </option>
            {getLanguages().map(lang => (
              <option key={lang.lang} value={lang.languageCode}>{lang.verboseName}</option>
            ))}
          </Select>
        </WithTooltip>
      </div>
    </form>
  )
}
