import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Participation} from "../../../schema";

import EditItemDropdown from "../../EditItemDropdown";

type props = {
  participation: Participation,
  onSetStatus?: (userId: string, status: boolean, lessonId?: string, courseId?: string) => void,
  onParticipationDelete?: (userId: string, lessonId: string,) => void,
}

export default function ParticipationEditDropdown({participation, onSetStatus, onParticipationDelete}: props) {
  const {t} = useTranslation();

  const toggleStatus = useCallback(() => {
    onSetStatus && onSetStatus(participation.student.id, !participation.isActive, participation.lesson.id)
  }, [onSetStatus, participation])

  const deleteParticipation = useCallback(() => {
    onParticipationDelete && onParticipationDelete(participation.student.id, participation.lesson.id)
  }, [onParticipationDelete, participation])

  return (
    <EditItemDropdown>
      <EditItemDropdown.Action
        title={participation.isActive
            ? t("components.ParticipationEditDropdown.deactivate")
            : t("components.ParticipationEditDropdown.activate")
        }
        onClick={toggleStatus}
      />
      <EditItemDropdown.Action
        title={t("components.ParticipationEditDropdown.delete")}
        onClick={deleteParticipation}
      />
    </EditItemDropdown>
  )
}
