import React from "react";

import cn from "classnames";
import classes from "./SessionActionDisplay.module.css";

type props = wrapperProps & {
  icon?: React.ReactNode,
  after?: React.ReactNode,
}

const SessionActionDisplay = function ({icon, after, children, ...props}: props) {
  return (
    <SessionActionDisplay.Wrapper {...props}>
      {icon && (
        <SessionActionDisplay.Icon>{icon}</SessionActionDisplay.Icon>
      )}
      <SessionActionDisplay.Content>
        {children}
      </SessionActionDisplay.Content>
      {after && (
        <SessionActionDisplay.After>{after}</SessionActionDisplay.After>
      )}
    </SessionActionDisplay.Wrapper>
  )
}

type wrapperProps = React.ComponentProps<"div"> & {
  variant?: "user" | "avatar" | "system"
}

SessionActionDisplay.Wrapper = ({variant, className, ...props}: wrapperProps) => {
  return (
    <div className={cn(classes.wrapper, variant && classes[variant], className)} {...props}/>
  )
}

SessionActionDisplay.Content = ({className, ...props}: React.ComponentProps<"div">) => {
  return (
    <div className={cn(classes.content, className)} {...props}/>
  )
}

SessionActionDisplay.Icon = ({className, ...props}: React.ComponentProps<"div">) => {
  return (
    <div className={cn(classes.icon, className)} {...props}/>
  )
}

SessionActionDisplay.After = ({className, ...props}: React.ComponentProps<"div">) => {
  return (
    <div className={cn(classes.after, className)} {...props}/>
  )
}


export default SessionActionDisplay;
