/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router"
import {StoreHelpers} from "react-joyride";
import ReactTour, {TourStep} from "../../components/ReactTour";
import {queryData} from "./course-edit.graphql";
import {
  createDummyCourse,
  createDummyLesson,
} from "../../dummies";
import {getLanguageCode} from "../../i18n";
import {useTour} from "../../providers/tour";
import {
  getTargetsClassNamesWithPrefix,
  targetClassNamesToSelectors,
  TourClassesMock
} from "../../tours";
import EditableItemName from "../../components/editor/EditableItemName";
import {Lesson} from "../../schema";

export const NAME = "editorCourseEdit";

/// Targets
type TargetNames = [
  "courseName", "lessonTable", "dragHandle", "courseCustomizeForm", "language", "topRightMenu", "participants",
  "shareButton", "addLesson", "editorMain"
]
type TargetName = TargetNames[number]
const targetNames: TargetNames = [
  "courseName", "lessonTable", "dragHandle", "courseCustomizeForm", "language", "topRightMenu", "participants",
  "shareButton", "addLesson", "editorMain"
]

export type Targets = Record<TargetName, TourStep["target"]>

export const classes = getTargetsClassNamesWithPrefix(targetNames, "tour_editor_courseEdit_");

/// Types
type MockDynamicData = {
  courseName?: string
}

export type EditorCourseEditTourMock = {
  tour: "editorCourseEdit"

  nameEditSave: React.ComponentProps<typeof EditableItemName>["onSave"]
  onReturnToMainPage: () => void,
  data: MockDynamicData,

  getBy: {
    lesson: Map<Lesson["id"], MockByLesson>
  }
}

export function isEditorCourseEditTourMock(mock: any): mock is EditorCourseEditTourMock {
  return mock?.tour === NAME;
}

type MockByLesson = TourClassesMock<"dragHandle">

/// Component
type props = {
  targets?: Partial<Targets>,
  returnToPreviousPage?: boolean
}

export default function EditorCourseEditTour({targets: componentTargets, returnToPreviousPage}: props) {
  const {t} = useTranslation("tours");
  const {end: endTour, run: runTour, setMock} = useTour();

  const history = useHistory();

  const onTourEnd = useCallback(() => {
    if (returnToPreviousPage) {
      history.goBack();
      return true;
    }
    return endTour(NAME);
  }, [returnToPreviousPage, history, endTour])

  useEffect(() => () => {
    endTour(NAME);
  }, [endTour])

  const [helpers, setHelpers] = useState<StoreHelpers>();

  const [data, setData] = useState<EditorCourseEditTourMock["data"]>({})

  const targets = useMemo(() => ({
    ...targetClassNamesToSelectors(classes),
    ...componentTargets
  }), [componentTargets])

  const mock = useMemo<Omit<EditorCourseEditTourMock, "tour" | "mockBy" | "data" | "getBy">>(() => ({
    nameEditSave: ({name}) => {
      setData(prevData => ({...prevData, courseName: name}))
      setTimeout(() => helpers?.next())
    },
    onReturnToMainPage: () => {
      setTimeout(() => runTour("editorIndex_openLesson", true))
    }
  }), [helpers, runTour])

  const lessonMocks = useMemo<Map<Lesson["id"], MockByLesson>>(() => {
    const lessons = ids.course.lessons
    return new Map([
      [lessons[0].id, {
        classes: {
          dragHandle: classes.dragHandle,
        }
      }]
    ])
  }, [])

  useEffect(() => {
    setMock({
      ...mock,
      data,
      tour: NAME,
      getBy: {
        lesson: lessonMocks
      }
    })
    return () => setMock(undefined);
  }, [mock, data, lessonMocks, setMock])

  const steps = useMemo<TourStep[]>(() => ([
    {
      title: t("editorCourseEdit.start.title"),
      content: t("editorCourseEdit.start.content"),
      target: "body",
      placement: "center",
    },
    {
      title: t("editorCourseEdit.courseName.title"),
      content: t("editorCourseEdit.courseName.content"),
      target: targets.courseName,
      placement: "bottom",
      disableNextButton: true,
      spotlightClicks: true
    },
    {
      title: t("editorCourseEdit.courseNameUpdated.title"),
      content: t("editorCourseEdit.courseNameUpdated.content"),
      target: targets.courseName,
      placement: "bottom",
    },
    // List of lessons
    {
      title: t("editorCourseEdit.lessonTable.title"),
      content: t("editorCourseEdit.lessonTable.content"),
      target: targets.lessonTable,
      placement: "right",
    },
    {
      title: t("editorCourseEdit.dragHandle.title"),
      content: t("editorCourseEdit.dragHandle.content"),
      target: targets.dragHandle,
      placement: "bottom-start",
    },
    // Course preferences
    {
      title: t("editorCourseEdit.courseCustomizeForm.title"),
      content: t("editorCourseEdit.courseCustomizeForm.content"),
      target: targets.courseCustomizeForm,
      placement: "left",
    },
    {
      title: t("editorCourseEdit.language.title"),
      content: t("editorCourseEdit.language.content"),
      target: targets.language,
      placement: "left",
    },
    // Top right menu
    {
      title: t("editorCourseEdit.topRightMenu.title"),
      content: t("editorCourseEdit.topRightMenu.content"),
      target: targets.topRightMenu,
      placement: "bottom",
    },
    {
      title: t("editorCourseEdit.participants.title"),
      content: t("editorCourseEdit.participants.content"),
      target: targets.participants,
      placement: "bottom",
    },
    {
      title: t("editorCourseEdit.shareButton.title"),
      content: t("editorCourseEdit.shareButton.content"),
      target: targets.shareButton,
      placement: "bottom",
    },
    {
      title: t("editorCourseEdit.addLesson.title"),
      content: t("editorCourseEdit.addLesson.content"),
      target: targets.addLesson,
      placement: "bottom",
    },
    // Final
    {
      title: t("editorCourseEdit.editorMain.title"),
      content: t("editorCourseEdit.editorMain.content"),
      target: targets.editorMain,
      placement: "bottom",
      spotlightClicks: true
    },
  ]), [t, targets])

  return (
    <ReactTour
      continuous
      run
      steps={steps}
      onTourEnd={onTourEnd}
      getHelpers={setHelpers}
      scrollToFirstStep
      disableOverlayClose
      disableCloseOnEsc
      showSkipButton
      hideCloseButton
    />
  );
}

/// IDs of mock data to refer
const ids = {
  course: {
    id: "editorCourseEditTour_Course_1",
    lessons: [
      {id: "editorCourseEditTour_Lesson_3"},
      {id: "editorCourseEditTour_Lesson_4"},
      {id: "editorCourseEditTour_Lesson_5"}
    ]
  }
}

export function useMockData(courseName?: string): queryData {
  const {t, i18n} = useTranslation("tours");
  const languageCode = getLanguageCode(i18n.language);

  const course = useMemo(() => (
    createDummyCourse({
      name: courseName ?? t("editorCourseEdit.mockData.course.name"), // "Курс продвинутого волшебника"
      id: ids.course.id,
      languageCode,
      lastUpdatedAt: Date.UTC(2021, 8, 16, 15, 28)/1000,
      lessons: [
        createDummyLesson({
          name: t("editorCourseEdit.mockData.course.lessons.1.name"), // "1. Использование волшебной палочки"
          id: ids.course.lessons[0].id,
          languageCode,
          lastUpdatedAt: Date.UTC(2021, 8, 16, 15, 30)/1000
        }),
        createDummyLesson({
          name: t("editorCourseEdit.mockData.course.lessons.2.name"), // "2. Базовые заклинания"
          id: ids.course.lessons[1].id,
          languageCode,
          lastUpdatedAt: Date.UTC(2021, 8, 16, 15, 42)/1000
        }),
        createDummyLesson({
          name: t("editorCourseEdit.mockData.course.lessons.3.name"), // "3. Зельеварение"
          id: ids.course.lessons[2].id,
          languageCode,
          lastUpdatedAt: Date.UTC(2021, 8, 16, 15, 55)/1000
        })
      ]
    })
  ), [courseName, languageCode, t]);
  return {
    course,
    courses: []
  };
}
