import React, {useCallback, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useAdaptiveClasses from "../hooks/useAdaptiveClasses";

import Button from "../ui/button";
import Input from "../ui/input";
import WithTooltip from "../ui/tooltip";
import {ClipboardIcon} from "../ui/icons";

import cn from "classnames"
import classes from "./CopyText.module.css";
import {copyTextToClipboard} from "../utils";


type props = Omit<React.ComponentProps<"div">, "children" | "value"> & {
  value?: string
  disabled?: boolean
  adaptive?: boolean
}

export default function CopyText({className, value, disabled, adaptive, ...props}: props) {
  const {t} = useTranslation();
  const ac = useAdaptiveClasses(classes, adaptive);

  const ref = useRef<HTMLInputElement>(null)

  const [isCopied, setCopied] = useState(false)

  const copyToClipboard = useCallback<React.EventHandler<any>>((e) => {
    copyTextToClipboard(value ?? "").then(() => {
      setCopied(true)
    }, () =>  {
      if (ref.current && ref.current.select) {
        ref.current?.select();
        document.execCommand("copy");
        e.target.focus();
        setCopied(true)
      }
    })
  }, [value])

  const helpText = useMemo(() =>
    isCopied
      ? t("components.CopyText.copied")
      : t("components.CopyText.clickToCopy"),
  [t, isCopied])

  return (
    <div className={cn(ac.root, className)} {...props}>
      <Input ref={ref} readOnly className={ac.input} value={value}/>
      <WithTooltip placement="top" helpText={helpText}>
        <Button
          className={ac.button}
          color="primary"
          disabledColor="secondary"
          disabled={disabled}
          onClick={copyToClipboard}
        >
          <ClipboardIcon/>
        </Button>
      </WithTooltip>
    </div>
  )
}