import format from "date-fns/format"
import formatRelative from "date-fns/formatRelative"
import formatDistanceToNow from "date-fns/formatDistanceToNow"

import enGB from "date-fns/locale/en-GB"
import enUS from "date-fns/locale/en-US"
import ruRU from "date-fns/locale/ru"

const locales: { [key: string]: any } = {
  en: enUS,
  ru: ruRU,
  default: enGB,
}

export function formatDuration(duration: number): string {
  const ss = (duration % 60).toString().padStart(2, "0");
  const mm = Math.floor(duration / 60).toString().padStart(2, "0");

  return `${mm}:${ss}`;
}

export function formatDurationHrs(duration: number): string {
  const ss = (duration % 60).toString().padStart(2, "0");
  const mm = Math.floor(duration % 3600 / 60).toString().padStart(2, "0");
  const hh = Math.floor(duration / 3600).toString().padStart(2, "0");

  return `${hh}:${mm}:${ss}`;
}

export function formatDate(date: number, locale?: string) {
  return format(new Date(date * 1000), "P", {
    locale: locales[locale ?? "default"] ?? locales["default"],
  })
}


export function formatTime(datetime: number, locale?: string) {
  return format(new Date(datetime * 1000), "p", {
    locale: locales[locale ?? "default"] ?? locales["default"],
  })
}

export function formatDateTime(datetime: number, locale?: string) {
  return format(new Date(datetime * 1000), "Pp", {
    locale: locales[locale ?? "default"] ?? locales["default"],
  })
}


export function formatRelativeDateTime(datetime: number, locale?: string) {
  const now = Date.now();
  const now0 = new Date(now);
  now0.setHours(0, 0, 0, 0);

  if (Math.abs((datetime * 1000) - now0.getTime()) >= 86400000) {
    return formatDateTime(datetime, locale);
  }

  if (Math.abs((datetime * 1000) - now) < 90000) {
    return formatDistanceToNow(new Date(datetime * 1000), {
      locale: locales[locale ?? "default"] ?? locales["default"], addSuffix: true
    })
  }

  return formatRelative(new Date(datetime * 1000), now, {
    locale: locales[locale ?? "default"] ?? locales["default"],
  })
}
