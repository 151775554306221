import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import browser from "../../browser";
import {UserInputDisplayMode} from "../../redux/player/types";
import {UserInputAction} from "../../schema";
import {minutesFromDeltaTimestamp} from "../../utils";

type props = {
  action: Pick<UserInputAction, "freeSpeech" | "freeSpeechMinTime" | "freeSpeechMaxTime">,
  displayMode: UserInputDisplayMode,
}

export default function UserInputHelpText({action, displayMode}: props) {
  const {t} = useTranslation();
  const helpText = useMemo(() => {
    if (action.freeSpeech) {
      return t("player.userInputAction.helpText.freeSpeech", {
        min: minutesFromDeltaTimestamp(action.freeSpeechMinTime, true),
        max: minutesFromDeltaTimestamp(action.freeSpeechMaxTime, true)
      })
    }

    if (displayMode === UserInputDisplayMode.HINT_TEXT) {
      return ({
        "desktop": t("player.userInputAction.helpText.toSpeak.desktop")
      } as { [key: string]: string })[browser.getPlatformType(true)]
      ?? t("player.userInputAction.helpText.toSpeak.any")
    }

    if (displayMode === UserInputDisplayMode.SELECTABLE) {
      return ({
        "desktop": t("player.userInputAction.helpText.quiz.desktop")
      } as { [key: string]: string })[browser.getPlatformType(true)]
      ?? t("player.userInputAction.helpText.quiz.any")
    }

    if (displayMode === UserInputDisplayMode.TEXT_INPUT) {
      return ({
        "desktop": t("player.userInputAction.helpText.textInput.desktop")
      } as { [key: string]: string })[browser.getPlatformType(true)]
      ?? t("player.userInputAction.helpText.textInput.any")
    }

    if (displayMode === UserInputDisplayMode.TEXT_INPUT_SELECTABLE) {
      return ({
        "desktop": t("player.userInputAction.helpText.textInputSelectable.desktop")
      } as { [key: string]: string })[browser.getPlatformType(true)]
      ?? t("player.userInputAction.helpText.textInputSelectable.any")
    }


    return ({
      "desktop": t("player.userInputAction.helpText.default.desktop")
    } as { [key: string]: string })[browser.getPlatformType(true)]
    ?? t("player.userInputAction.helpText.default.any")
  }, [t, action.freeSpeech, action.freeSpeechMinTime, action.freeSpeechMaxTime, displayMode])

  return <>{helpText}</>
}