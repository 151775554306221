import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {EducationSessionRecordState} from "../schema";
import {AsAnyComponentWithDefault} from "../ui";
import Spinner from "../ui/spinner";

type props = {
  state?: EducationSessionRecordState,
  label?: string | undefined,
  textVariant?: "short" | "default",
}

export type RecordLinkPropsType<T extends React.ElementType> = AsAnyComponentWithDefault<T, props, "button">

const defaultProps: {
  as: any,
  label: string | undefined,
  textVariant: "short" | "default"
} = {
  as: "button",
  label: undefined,
  textVariant: "default"
}

export default function RecordLink<T extends React.ElementType>({
  as: Component, state, label, textVariant, children, ...props
}: RecordLinkPropsType<T> & typeof defaultProps) {
  const {t} = useTranslation();

  const {text, loading, disabled} = useMemo<{
    text: string,
    loading: boolean,
    disabled: boolean
  }>(() => {
    switch (state) {
      case EducationSessionRecordState.NOT_STARTED:
        return {
          text: {
            short: t("components.RecordLink.initiating.short"),
            default: t("components.RecordLink.initiating.default")
          }[textVariant],
          loading: true,
          disabled: true
        }
      case EducationSessionRecordState.PROCESSING:
        return {
          text: {
            short: t("components.RecordLink.processing.short"),
            default: t("components.RecordLink.processing.default")
          }[textVariant],
          loading: true,
          disabled: true
        }
      case EducationSessionRecordState.READY:
        return {
          text: {
            short: label ? label : t("components.RecordLink.watchRecord.short"),
            default: label ? label : t("components.RecordLink.watchRecord.default")
          }[textVariant],
          loading: false,
          disabled: false
        }
      case EducationSessionRecordState.FAILED:
        return {
          text: {
            short: t("components.RecordLink.failed.short"),
            default: t("components.RecordLink.failed.default")
          }[textVariant],
          loading: false,
          disabled: true
        }
      case EducationSessionRecordState.SKIPPED:
        return {
          text: {
            short: t("components.RecordLink.skipped.short"),
            default: t("components.RecordLink.skipped.default")
          }[textVariant],
          loading: false,
          disabled: true
        }
      default:
        throw new Error("NotImplemented")
    }
  }, [state, label, textVariant, t])

  return (
    <Component disabled={disabled} {...props}>
      {children}
      <span>{text}</span>
      {loading && <Spinner size='small'/>}
    </Component>
  )
}

RecordLink.defaultProps = defaultProps
