import React, {useMemo} from "react";

import {Avatar, AvatarAction} from "../../../schema";

import AvatarIcon from "../../AvatarIcon";
import SessionActionDisplay from "./SessionActionDisplay";
import {gql} from "@apollo/client";
import {getTextWithStressMarks} from "../../Phrase";

type props = React.ComponentProps<"div"> & {
  action: AvatarAction
  avatar: Avatar
}

const SessionAvatarActionDisplay = function ({action, avatar, ...props}: props) {
  const text = useMemo(() => getTextWithStressMarks(action.text), [action.text])

  return (
    <SessionActionDisplay variant="avatar" icon={(
      <AvatarIcon adaptive avatar={avatar}/>
    )} {...props}>
      {text}
    </SessionActionDisplay>
  )
}

SessionAvatarActionDisplay.fragments = {
  root: gql`
    fragment SessionAvatarActionDisplay on Action {
      id

      ... on AvatarAction {
        text
      }
    }
  `
}

export default SessionAvatarActionDisplay;
