import React from "react";
import cn from "classnames";
import classes from "./spinner.module.css";

type props = React.ComponentProps<"div"> & {
  size?: "small" | "regular" | "large"
}

function Spinner({size, className, ...props}: props, ref: React.Ref<any>) {
  return (
    <div
      ref={ref}
      className={cn(classes.root, size && classes[size], className)}
      {...props}
    />
  )
}

export default React.memo(React.forwardRef(Spinner));
