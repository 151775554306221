import React from "react";
import {useTranslation} from "react-i18next";

import Table from "../../../ui/table";
import Skeleton from "../../../ui/skeleton";
import MembersTableTable, {params} from "./MembersTable.table";
import UserItem from "../UserItem";

import classes from "./MembersTable.module.css"

type props = Omit<params, "singleSubRow" | "data"> & {
  members: params["data"],
  unambiguousSubRow?: params["singleSubRow"],
}

export default function MembersTable({
  members, unambiguousSubRow, lessons, courses, onAssign, onSetStatus, onSetParticipationStatus, onParticipationDelete
}: props) {
  const {t} = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = MembersTableTable.use(
      members, !!unambiguousSubRow, lessons, courses,
      onAssign, onSetStatus, onSetParticipationStatus, onParticipationDelete
    );

  return (
    <Table className={classes.root} {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup) => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Table.Cell {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Head>

      <Table.Body {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Table.Row {...row.getRowProps()}>
              {row.cells.map(cell => (
                <Table.Cell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </Table.Cell>
              ))}
            </Table.Row>
          )
        })}

        {!rows.length && (
          <Table.Row>
            <Table.Cell colSpan={999}>
              <div className={classes.noData}>
                {t("components.MembersTable.noData")}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

MembersTable.Skeleton = React.memo(function (props) {
  return (
    <Table className={classes.root} {...props}>
      <Table.Body>
        <MembersTable.SkeletonRow/>
        <MembersTable.SkeletonRow/>
        <MembersTable.SkeletonRow/>
        <MembersTable.SkeletonRow/>
      </Table.Body>
    </Table>
  )
})

MembersTable.SkeletonRow = React.memo(function () {
  return (
    <Table.Row>
      <Table.Cell><Skeleton.Block width={28} height={28}/></Table.Cell>
      <Table.Cell><UserItem.Skeleton/></Table.Cell>
      <Table.Cell><Skeleton.Block width={28} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={60} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={80} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={60} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={60} height={28}/></Table.Cell>
      <Table.Cell><Skeleton.Block width={60} height={28}/></Table.Cell>
    </Table.Row>
  )
})
