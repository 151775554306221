import React from "react";

type props = React.ComponentProps<"div"> & {
  variant: "white" | "black",
  textLogo?: string
}

export default function Logo({variant, textLogo, ...props}: props) {
  return (
    <div {...props}>
      {textLogo ? (
        <b>{`( ${textLogo} )`}</b>
      ) : (
        <svg height="100%" viewBox="0 0 57 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={"M11.676 2h33.648C50.668 2 55 6.332 55 11.676s-4.332 9.676-9.676 9.676H11.676C6.332 21.352 2 17.02 2 " +
            "11.676S6.332 2 11.676 2zM0 11.676C0 5.227 5.227 0 11.676 0h33.648C51.773 0 57 5.227 57 " +
            "11.676c0 6.448-5.227 11.676-11.676 11.676H11.676C5.227 23.352 0 18.124 0 11.676zM16.092 " +
            "5.14v4.032c-.56-.72-1.376-1.072-2.448-1.072-2.08 0-3.632 1.696-3.632 4.32 0 2.592 1.52 4.32 " +
            "3.616 4.32 1.152 0 2.016-.432 2.608-1.312V16.5h1.936V5.14h-2.08zm.08 7.28c0 1.536-.848 2.528-2.064 " +
            "2.528-1.216 0-1.984-.992-1.984-2.528s.8-2.512 2-2.512 2.048.96 2.048 " +
            "2.512zm11.434-1.44c0-1.776-1.264-2.928-3.552-2.928-1.584 0-2.784.608-3.824 1.84l1.312 " +
            "1.2c.496-.48.928-.816 1.264-1.024.336-.208.768-.304 1.28-.304.96 0 1.536.464 1.536 " +
            "1.248v.192l-1.92.352c-2.176.384-3.536 1.152-3.536 2.88 0 1.552 1.248 2.336 2.928 2.336 1.232 0 " +
            "2.128-.416 2.64-1.12v.848h1.872v-5.52zm-1.984 2.192c0 1.328-.976 1.936-2.176 1.936-.784 " +
            "0-1.248-.304-1.248-.816 0-.704.656-1.008 2.048-1.312l1.376-.288v.48zm4.281-4.848h2.08V16.5h-" +
            "2.08V8.324zm0-3.184h2.08v1.952h-2.08V5.14zm6.498 0h-2.08V16.5h2.08V5.14zm6.177 2.912c2.464 0 4.24 1.76 " +
            "4.24 4.352 0 2.592-1.776 4.352-4.24 4.352-2.48 0-4.256-1.76-4.256-4.352 0-2.592 1.776-4.352 " +
            "4.256-4.352zm0 1.76c-1.296 0-2.144 1.04-2.144 2.592 0 1.584.848 2.592 2.144 2.592 1.28 0 " +
            "2.128-1.008 2.128-2.592 0-1.552-.848-2.592-2.128-2.592z"}
            fill={variant}
          />
        </svg>
      )}
    </div>
  )
}
