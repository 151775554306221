import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {getCurrentAction, getLevel} from "../selectors";
import {sessionSelectInputMutation} from "../graphql";
import {isAnyUserInputAction} from "../utils";
import {EducationSessionPassedResult, MutationEducationSessionSelectInputArgs} from "../../../schema";
import {analyticsSendEvent, toEventData} from "../../../libs/analytics";
import {WithoutTypename} from "../../../types";

export default function selectInput(index: number) {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();
    const action = getCurrentAction(state);

    if (!action || !isAnyUserInputAction(action)) {
      console.warn("passUserInput: wrong action");
      return;
    }

    analyticsSendEvent("playerSelectChoiceBranchAction", {
      sessionId: state.sessionId!,
      level: getLevel(state),
      actionId: action.id,
      actionType: action.__typename
    });

    state.client.mutate<{
      result: WithoutTypename<EducationSessionPassedResult>
    }, MutationEducationSessionSelectInputArgs>({
      mutation: sessionSelectInputMutation,
      variables: {
        sessionId: state.sessionId!,
        level: getLevel(state),
        actionId: action.id,
        index: index,
      }
    }).then((mutationResult) => {
      if (!mutationResult.data) {
        console.error("passUserInput: invalid mutation result");
        dispatch({
          type: "update",
          payload: {isRecognizing: false}
        });
        return;
      }

      const result = mutationResult.data.result;

      dispatch({
        type: "update",
        payload: {
          isRecognizing: false,
          lastUserInputResult: {
            ok: result.passed,
            recognizedText: result.recognizedText ?? undefined,
            errorState: result.errorReason ?? null,
          },
          branchId: result.branchId ?? undefined,
          branchIndex: result.branchId ? -1 : undefined
        }
      });

      const resultType =
        result.scenarioId ? "scenarioTrigger" :
        result.branchId ? "branchSelect" :
        result.passed ? "passed" :
        result.interrupt ? "interrupt" :
        "notPassed"

      analyticsSendEvent("playerPassActionResult", {
        sessionId: state.sessionId!,
        level: getLevel(state),
        actionId: action.id,
        actionType: action.__typename,
        result: resultType,
        ...toEventData(result)
      });

      switch (resultType) {
        case "scenarioTrigger":
          dispatch({
            type: "startScenario",
            payload: {
              scenarioId: result.scenarioId!,
            }
          })
          break;
        case "branchSelect":
        case "passed":
          dispatch({type: "next"});
          break;
        case "interrupt":
          dispatch({
            type: "interrupt",
            payload: {
              interruptReason: result.errorReason,
            }
          });
          break;
        case "notPassed":
          dispatch({
            type: "notPassed"
          })
          // falls through
        default:
          state.raiseProblem && state.raiseProblem("userInputNotPassed");
      }
    }).catch((err) => {
      dispatch({
        type: "update",
        payload: {isRecognizing: false}
      });

      console.error(err);
    })
  }
}