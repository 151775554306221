import {useState, useCallback} from "react"

type SetMap<K, V> = React.Dispatch<React.SetStateAction<Map<K, V>>>
type SetMapByArray<K, V> = React.Dispatch<React.SetStateAction<[K, V][]>>
type Set<K, V> = (key: K, value: V) => void
type Delete<K> = (key: K) => boolean

export default function useMapState<K, V>(initialState?: Iterable<readonly [K, V]> | Map<K, V>): [
  Map<K, V>, SetMap<K, V>, SetMapByArray<K, V>, Set<K, V>, Delete<K>
] {
  const [map, setMap] = useState<Map<K, V>>(new Map(initialState ?? []));

  const setValue = useCallback((key: K, value: V) => {
    setMap((prevState) => {
      const newMap = new Map(prevState);
      return newMap.set(key, value);
    })
  }, []);

  const deleteValue = useCallback((key: K) => {
    let deleted: boolean = false;
    setMap((prevState) => {
      const newMap = new Map(prevState);
      deleted = newMap.delete(key);
      return newMap;
    })
    return deleted;
  }, []);

  const setMapByArray = useCallback((arrayOrCallback) => {
    if (Array.isArray(arrayOrCallback)) {
      setMap(new Map(arrayOrCallback))
    } else if (typeof arrayOrCallback === "function") {
      setMap(prevMap => new Map(arrayOrCallback(Array.from(prevMap))))
    } else {
      throw new Error("setMapByArray called with argument that is nor array or function")
    }
  }, []);
  return [map, setMap, setMapByArray, setValue, deleteValue]
}