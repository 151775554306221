import {useEffect} from "react";
import {gql} from "@apollo/client";
import {analyticsSetUser, analyticsSetUserProperties} from "../libs/analytics";
import {User} from "../schema";

export type AnalyticsTrackerProps = {
  user: User | null,
}

export default function AnalyticsTracker({user}: AnalyticsTrackerProps) {
  const userId = user?.id;
  const email = user?.email;
  const isStaff = user?.isStaff;
  const userAgent = navigator.userAgent;

  useEffect(() => {
    analyticsSetUser(userId ?? null);
    analyticsSetUserProperties({userAgent})

    if (userId) {
      analyticsSetUserProperties({email, isStaff});
    }
  }, [userId, email, isStaff, userAgent]);

  return null;
}

AnalyticsTracker.fragments = {
  User: gql`
    fragment AnalyticsTrackerUser on User {
      id
      email
      isStaff
    }
  `
}
