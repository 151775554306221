import React from "react"
import cn from "classnames"
import classes from "./animated.module.css"

type props = React.ComponentProps<"div"> & {
  type: "shake",
}

export default function Animated({type, className: extraClassName, ...props}: props) {
  const className = cn(classes.root, classes[type], extraClassName);

  return (
    <div className={className} {...props}/>
  )
}