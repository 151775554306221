import React from "react";
import cn from "classnames";
import classes from "./scroll.module.css";
import {AsAnyComponentWithDefault} from ".";

type props = {
  scrollSize?: "s" | "m" | "l"
}

type ScrollProps<T extends React.ElementType> = AsAnyComponentWithDefault<T, props, "div">

export default function Scroll<T extends React.ElementType>({
  scrollSize, as: Component, className, ...props
}: ScrollProps<T>) {
  return (
    <Component
      className={cn(classes.root, scrollSize && classes[scrollSize], className)}
      {...props}
    />
  )
}

Scroll.defaultProps = {
  as: "div"
}
