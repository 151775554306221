import {ThunkMiddleware} from "redux-thunk";
import {PlayerReduxState} from "./types";
import {getCurrentAction, getSessionId, isResultStage} from "./selectors";
import passAction from "./actions/passAction";
import {PlayerReduxAction} from "./rootActions";
import {sessionFinish} from "./graphql";
import {isAnyUserInputAction} from "./utils";
import {postMessageOpener} from "../../utils";
import {EducationSessionResult} from "../../schema";

const rootMiddleware: ThunkMiddleware<PlayerReduxState, PlayerReduxAction> = function (store) {
  return function wrapDispatch(next) {
    return function handleAction(action: PlayerReduxAction) {
      const result = next(action);

      if (["next", "back", "navigate", "startScenario"].includes(action.type)) {
        const newState = store.getState();
        const toggleRecorder = newState.toggleRecorder;
        const avatarPlayback = newState.audioManager.getPlayback("player/avatar");
        const systemPlayback = newState.audioManager.getPlayback("player/system");
        const isSpeechMuted = newState.lesson.muteNonUserSpeech;

        avatarPlayback?.abortPlay();
        systemPlayback?.abortPlay();

        const currentAction = getCurrentAction(newState);
        let turnMicOn = false;

        switch (currentAction?.__typename) {
          case "AvatarAction":
            avatarPlayback?.play(currentAction.audio ?? undefined, {
              gesture: currentAction.gesture,
              emotion: currentAction.emotion,
              volume: isSpeechMuted ? 0 : undefined
            }).then(() => {
              !currentAction.mediaViewRequired && store.dispatch(passAction())
            });
            turnMicOn = currentAction.text === "" && !!currentAction.setMediaAsBackground;
            break;

          case "SystemAction":
            systemPlayback?.play(
              currentAction.audio ?? undefined, {volume: isSpeechMuted ? 0 : undefined}).then(() => {
              !currentAction.mediaViewRequired && store.dispatch(passAction());
            });
            turnMicOn = currentAction.text === "" && !!currentAction.setMediaAsBackground;
            break;

          default:
            // noop
            break;
        }

        if (currentAction && (isAnyUserInputAction(currentAction) || turnMicOn)) {
            toggleRecorder && toggleRecorder(true);
        }

        if (isResultStage(newState)) {
          const isExternal = newState.isExternal;

          setTimeout(() => {
            newState.client.mutate<{
              result: EducationSessionResult
            }>({
              mutation: sessionFinish,
              variables: {
                sessionId: getSessionId(newState),
              }
            }).then((mutationResult) => {
              const result = mutationResult.data?.result;

              if (!result) {
                return Promise.reject("NoEducationSessionResult")
              }

              if (isExternal) {
                postMessageOpener({
                  type: "Complete",
                  passed: result.passed,
                  score: result.score,
                  time: result.time,
                });
              }
            })
              .catch(console.error);
          }, 100);
        }
      }

      return result;
    }

  }
}

export default rootMiddleware;
