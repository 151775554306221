import {Scenario} from "../../types";

export function getScenarioById(scenarios: Scenario[], scenarioId: Scenario["id"]) {
  const scenario = scenarios.find(({id}) => id === scenarioId);
  if (!scenario) {
    throw new Error(`SCENARIO_DOES_NOT_EXIST: Can't find scenario by id "${scenarioId}"`)
  }
  return scenario;
}

export function getLessonScenariosLength(scenarios: Scenario[]) {
  return scenarios.reduce((length, scenario) => length + scenario.actions.length, 0)
}

export function getMainScenario(scenarios: Scenario[]) {
  const scenario = scenarios.find(({isMain}) => isMain);
  if (!scenario) {
    throw new Error("MAIN_SCENARIO_DOES_NOT_EXIST: Can't find main scenario")
  }
  return scenario;
}
