import {isEqual} from "lodash";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AudioTracksStatusMap, AudioTrackStatus} from "../libs/audio";
import {MIN_PRELOADED_AUDIOS} from "../settings";
import {countValidMapValues} from "../utils";

type opts = {
  onTracksStatusUpdate?: (tracksStatus: AudioTracksStatusMap) => void
}

export class AudioPreloadManager {
  readonly tracksStatus: AudioTracksStatusMap;
  private readonly updateTracksStatus: () => void;

  constructor({onTracksStatusUpdate}: opts) {
    this.tracksStatus = new Map<string, AudioTrackStatus>()
    this.updateTracksStatus = () => onTracksStatusUpdate && onTracksStatusUpdate(this.tracksStatus)
    this.updateTracksStatus()
  }

  preload = async (srcList: string[], clearTrackStatus?: boolean) => {
    if (clearTrackStatus) {
      this.tracksStatus.clear()
    }
    for (const src of srcList) {
      if (this.tracksStatus.get(src) === undefined) {
        this.tracksStatus.set(src, {
          loaded: false,
          played: false
        })
      }
    }
    this.updateTracksStatus()
    for await (const src of srcList) {
      try {
        await this.preloadAudio(src)
        this.updateTrackStatus(src, {loaded: true})
      } catch (e) {
        this.stopTrackingStatus(src)
        console.error(e)
      }
    }
  }

  updateTrackStatus = (src: string, status: Partial<AudioTrackStatus>) => {
    const prevStatus = this.tracksStatus.get(src)
    if (!isEqual(prevStatus, status)) {
      const newStatus = Object.assign(prevStatus ?? {
        loaded: false,
        played: false
      }, status)
      this.tracksStatus.set(src, newStatus);
      this.updateTracksStatus();
      return newStatus;
    }
    return false;
  }

  stopTrackingStatus = (src: string) => {
    const isFound = this.tracksStatus.delete(src)
    if (isFound) {
      this.updateTracksStatus();
    }
    return isFound;
  }

  private preloadAudio(src: string): Promise<HTMLAudioElement> {
    return new Promise((resolve, reject) => {
      const preloadedAudio = new Audio()
      preloadedAudio.onerror = (ev) => reject(ev)

      // This fixes bug with firing canplaythrough even without timeout call, IDK ¯\_(ツ)_/¯
      const retry = setTimeout(() => {
        preloadedAudio.load()
      }, 3000)

      preloadedAudio.addEventListener("canplaythrough", () => {
        clearTimeout(retry)
        resolve(preloadedAudio)
      }, false);
      preloadedAudio.src = src;
      preloadedAudio.load()

      // Sometimes it won't fire canplaythrough event, so after 5 seconds we reject
      setTimeout(reject, 5000)
    })
  }
}

const Context = React.createContext<{
  audioPreloadManager: AudioPreloadManager,
  tracksStatus: AudioTracksStatusMap,
  isLoaded?: boolean,
  isIntroLoaded?: boolean,
  onPreloadReady: (callback: () => void) => void,
}>(null!);

export default function AudioPreloadProvider({children}: React.PropsWithChildren<{}>) {
  const [tracksStatus, setTracksStatus] = useState<AudioTracksStatusMap>()
  const onTracksStatusUpdate = useCallback((tracksStatus: AudioTracksStatusMap) => {
    setTracksStatus(new Map(tracksStatus))
  }, [])
  const audioPreloadManager = useMemo(() => new AudioPreloadManager({onTracksStatusUpdate}), [onTracksStatusUpdate])

  const {introLoaded: isIntroLoaded, nextLoaded: isLoaded} = useMemo(() => {
    if (!tracksStatus) {
      return {}
    }
    const preloadedAudios = countValidMapValues(tracksStatus, ({loaded, played}) => loaded && !played)
    const queuedAudios = countValidMapValues(tracksStatus, ({loaded, played}) => !loaded && !played)
    return {
      introLoaded: (queuedAudios === 0) || (preloadedAudios >= Math.min(tracksStatus.size, MIN_PRELOADED_AUDIOS)),
      nextLoaded: (queuedAudios === 0) || (preloadedAudios >= Math.min(tracksStatus.size, 1))
    }
  }, [tracksStatus])

  const [callbacks, setCallbacks] = useState<(() => void)[]>([])
  const onPreloadReady = useCallback((callback) => {
    setCallbacks((callbacks) => callbacks.concat(callback))
  }, [])

  useEffect(() => {
    if (isLoaded && callbacks.length !== 0) {
      callbacks.forEach((callback) => callback())
      setCallbacks([])
    }
  }, [isLoaded, callbacks])

  const ctx = useMemo(() => {
    return {
      audioPreloadManager,
      tracksStatus: tracksStatus!,
      isLoaded,
      isIntroLoaded,
      onPreloadReady,
    }
  }, [audioPreloadManager, tracksStatus, isLoaded, isIntroLoaded, onPreloadReady]);

  return (
    <Context.Provider value={ctx}>
      {children}
    </Context.Provider>
  )
}

export function useAudioPreloader() {
  return useContext(Context);
}
