import React, {ChangeEventHandler, ComponentProps, useCallback, useLayoutEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import partition from "lodash/partition";
import difference from "lodash/difference";
import {bindEventListener, KeysOfType, searchString} from "../utils";
import without from "lodash/without";
import {isMobile} from "../browser";

import IconButton from "../ui/iconbutton";
import {ArrowBackIcon, ArrowForwardIcon, SearchIcon} from "../ui/icons";
import Scroll from "../ui/scroll";
import Checkbox from "../ui/checkbox";

import cn from "classnames"
import classes from "./ListSelector.module.css";

type UniqueItem = {
  id: string | number
}

type NamedUniqueItem = {
  [key: string | number | symbol]: string
} & UniqueItem

export type CommonProps<Type extends NamedUniqueItem> = Omit<ComponentProps<"div">, "children" | "onChange"> & {
  items: Type[]
  selectedItemsIds?: Type["id"][]
  nameField: KeysOfType<Type, string>
  onChange?: (selectedItemsIds: Type["id"][]) => void
}

export type HorizontalProps<Type extends NamedUniqueItem> = CommonProps<Type> & {
  restTitle?: string
  pickedTitle?: string
}

export type VerticalProps<Type extends NamedUniqueItem> = CommonProps<Type>

export type Props<Type extends NamedUniqueItem> = HorizontalProps<Type> & VerticalProps<Type> & {
  horizontalMediaQuery: string
}

export enum ListSelectorDisplayType {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export default function ListSelector<Type extends NamedUniqueItem>({horizontalMediaQuery, ...props}: Props<Type>) {
  const displayType = useListSelectorDisplayType(horizontalMediaQuery);
  switch (displayType) {
    case ListSelectorDisplayType.HORIZONTAL:
      return <HorizontalListSelector {...props}/>;
    case ListSelectorDisplayType.VERTICAL:
      return <VerticalListSelector {...props}/>;
    default:
      throw new Error("Got unknown ListSelectorDisplayType");
  }
}

function HorizontalListSelector<Type extends NamedUniqueItem>({
  items, selectedItemsIds, nameField, onChange, className, restTitle, pickedTitle, ...props
}: HorizontalProps<Type>) {
  const [pickedItems, restItems] = useMemo(() => (
    selectedItemsIds
      ? partition(items, ({id}) => selectedItemsIds.includes(id))
      : [[], items]
  ), [items, selectedItemsIds]);

  const [restSearchQuery, setRestSearchQuery] = useState<string>();
  const [pickedSearchQuery, setPickedSearchQuery] = useState<string>();

  const filteredRestItems = useMemo(() => !restSearchQuery ? restItems : (
    searchString(restItems, nameField, restSearchQuery)
  ), [restItems, nameField, restSearchQuery]);
  const filteredPickedItems = useMemo(() => !pickedSearchQuery ? pickedItems : (
    searchString(pickedItems, nameField, pickedSearchQuery)
  ), [pickedItems, nameField, pickedSearchQuery]);

  const [selectedRestItems, setSelectedRestItems] = useState<Type["id"][]>([])
  const [selectedPickedItems, setSelectedPickedItems] = useState<Type["id"][]>([])

  const onRestSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSelectedRestItems([]);
    setRestSearchQuery(e.currentTarget.value);
  }, [])
  const onPickedSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSelectedPickedItems([]);
    setPickedSearchQuery(e.currentTarget.value);
  }, [])

  const onRestItemsSelect = useCallback<ChangeEventHandler<HTMLSelectElement>>((e) => {
    const selectedValues = Array.from(e.currentTarget.selectedOptions).map(option => option.value);
    setSelectedRestItems(selectedValues);
  }, [])

  const onPickedItemsSelect = useCallback<ChangeEventHandler<HTMLSelectElement>>((e) => {
    const selectedValues = Array.from(e.currentTarget.selectedOptions).map(option => option.value);
    setSelectedPickedItems(selectedValues);
  }, [])

  const onAddButtonClick = useCallback(() => {
    const newSelectedIds = (selectedItemsIds ?? []).concat(selectedRestItems);
    onChange && onChange(newSelectedIds);
  }, [selectedItemsIds, selectedRestItems, onChange])

  const onRemoveButtonClick = useCallback(() => {
    const newSelectedIds = difference(getIds(pickedItems), selectedPickedItems);
    onChange && onChange(newSelectedIds);
  }, [pickedItems, selectedPickedItems, onChange])

  const disableAddButton = selectedRestItems.length === 0;
  const disableRemoveButton = selectedPickedItems.length === 0;

  return (
    <Scroll className={cn(classes.root, className)} {...props}>
      <div className={classes.listWrapper}>
        {restTitle && <span className={classes.listTitle}>{restTitle}</span>}
        <SearchBar onChange={onRestSearch}/>
        <Scroll as="select" multiple className={classes.select} onChange={onRestItemsSelect}>
          {filteredRestItems.map(item => (
            <option key={item.id} className={classes.item} value={item.id} title={item[nameField]}>
              {item[nameField]}
            </option>
          ))}
        </Scroll>
      </div>
      <div className={classes.controls}>
        <IconButton icon={ArrowForwardIcon} type="button" disabled={disableAddButton} onClick={onAddButtonClick}/>
        <IconButton icon={ArrowBackIcon} type="button" disabled={disableRemoveButton} onClick={onRemoveButtonClick}/>
      </div>
      <div className={classes.listWrapper}>
        {pickedTitle && <span className={classes.listTitle}>{pickedTitle}</span>}
        <SearchBar onChange={onPickedSearch}/>
        <Scroll as="select" multiple className={classes.select} onChange={onPickedItemsSelect}>
          {filteredPickedItems.map(item => (
            <option key={item.id} className={classes.item} value={item.id} title={item[nameField]}>
              {item[nameField]}
            </option>
          ))}
        </Scroll>
      </div>
    </Scroll>
  )
}

function VerticalListSelector<Type extends NamedUniqueItem>({
  items, selectedItemsIds=[], nameField, onChange, className, ...props
}: VerticalProps<Type>) {
  const [searchQuery, setSearchQuery] = useState<string>();

  const filteredItems = useMemo(() => !searchQuery ? items : (
    searchString(items, nameField, searchQuery)
  ), [items, nameField, searchQuery]);

  const onSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSearchQuery(e.currentTarget.value);
  }, [])

  const onItemSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const id = e.currentTarget.getAttribute("data-id")!;
    const checked = e.currentTarget.checked;
    onChange && onChange(checked
      ? selectedItemsIds.concat(id)
      : without(selectedItemsIds, id)
    )
  }, [selectedItemsIds, onChange])

  return (
    <div className={cn(classes.verticalRoot, className)} {...props}>
      <SearchBar onChange={onSearch}/>
      <Scroll className={classes.verticalWrapper}>
        {filteredItems.map((item) => {
          const id = item.id;
          const name = item[nameField];
          return (
            <label key={id} className={classes.checkboxLabel}>
              <Checkbox
                checked={selectedItemsIds?.includes(id)}
                onChange={onItemSelect}
                data-id={id}
              />
              <span className={classes.checkboxText}>{name}</span>
            </label>
          )
        })}
      </Scroll>
    </div>
  );
}

ListSelector.Horizontal = HorizontalListSelector;
ListSelector.Vertical = VerticalListSelector;

type SearchBarProps = Omit<ComponentProps<"input">, "className">

function SearchBar(props: SearchBarProps) {
  const {t} = useTranslation();

  return (
    <div className={classes.searchBar} title={t("common.search")}>
      <input className={classes.searchInput} {...props}/>
      <SearchIcon className={classes.searchIcon}/>
    </div>
  )
}

export function getListSelectorDisplayType(mediaQuery: string): ListSelectorDisplayType {
  const isMobileView = isMobile();
  const isEnoughHorizontalSpace = window.matchMedia(mediaQuery).matches;
  if (isMobileView || !isEnoughHorizontalSpace) {
    return ListSelectorDisplayType.VERTICAL;
  } else {
    return ListSelectorDisplayType.HORIZONTAL;
  }
}

export function useListSelectorDisplayType(mediaQuery: string): ListSelectorDisplayType {
  const [displayType, setDisplayType] = useState<ListSelectorDisplayType>(ListSelectorDisplayType.HORIZONTAL);

  useLayoutEffect(() => {
    const handler = () => {
      setDisplayType(getListSelectorDisplayType(mediaQuery));
    };

    handler();
    return bindEventListener(window, "resize", handler);
  }, [mediaQuery]);

  return displayType;
}

function getIds(arrayOfObjects: UniqueItem[]) {
  return arrayOfObjects.map(({id}) => id);
}
