import {useMemo, useCallback} from "react";
import {clamp} from "lodash";
import {
  LessonScenarioActivationMethod,
  RandomAvatarAction,
  RandomAvatarActionBranchInput,
  RandomAvatarActionInput,
  Scalars,
  Scenario,
} from "../../../schema";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";
import Select from "../../../ui/select";
import {useFormState} from "../../../hooks/useFormState";

import Button from "../../../ui/button";
import WithTooltip from "../../../ui/tooltip";
import {HelpCircleIcon} from "../../../ui/icons";

import classes from "./forms.module.css";
import Input from "../../../ui/input";

const MIN = 0
const MAX = 100
const STEP = 1

type props = {
  action: RandomAvatarAction,
  branchId: Scalars["ID"],
  scenarios?: Scenario[],
  onSubmit?: (data: RandomAvatarActionInput) => void,
  testId?: string
}

type formState = {
  targetScenarioId?: NonNullable<RandomAvatarActionBranchInput["targetScenarioId"]>,
  dropChance?: NonNullable<RandomAvatarActionBranchInput["dropChance"]>
}

export default function RandomAvatarActionEditForm({action, branchId, scenarios, onSubmit, testId}: props) {
  const {t} = useTranslation();

  const branch = useMemo(() => {
    return action.branches.find(b => b.id === branchId)!;
  }, [action, branchId]);

  const formState = useFormState<formState>({
    initialValues: {
      targetScenarioId: branch.targetScenarioId!,
      dropChance: branch.dropChance!
    },
    preventDefault: true,
    onSubmit: (data) => {
      onSubmit && onSubmit({
        branches: action.branches.map(({id}) => {
          if (id === branchId) {
            return {
              id: id,
              targetScenarioId: data.targetScenarioId,
              dropChance: data.dropChance,
            }
          }

          return {id}
        }),
      })
    }
  })

  const onPlus = useCallback(() => {
    formState.setValues({dropChance: clamp(+formState.values.dropChance! + STEP, MIN, MAX)})
  }, [formState])

  const onMinus = useCallback(() => {
    formState.setValues({dropChance: clamp(+formState.values.dropChance! - STEP, MIN, MAX)})
  }, [formState]);

  return (
    <div>
      <div className={classes.label}>
        {t("components.RandomAvatarAction.scenarioLabel")}
        {" "}
        <WithTooltip className={classes.help} as='span'
            helpText={t("components.ActionEditForm.randomAvatarBranch.scenarioHelp")}>
          <HelpCircleIcon/>
        </WithTooltip>
      </div>

      <div className={classes.row}>
        {scenarios && scenarios.length > 1 ? (
          <Select name="targetScenarioId" value={formState.values.targetScenarioId} onChange={formState.changeHandler}>
            <option
              key={"none"}
              value={""}
            >{t("components.ActionEditForm.randomAvatarBranch.noTargetScenario")}</option>
            {scenarios.map((scenario) => (
              !scenario.isMain && scenario.activationMethod === LessonScenarioActivationMethod.AVATAR_PHRASE && (
                <option
                  key={scenario.id}
                  value={scenario.id}
                >{scenario.name}</option>
              )
          ))}
          </Select>
        ) : t("components.ActionEditForm.randomAvatarBranch.singleScenario")}
      </div>

      <div className={classes.row}>

        <div className={classes.label}>
          {t("components.RandomAvatarAction.dropChanceLabel")}
          {" "}
          <WithTooltip className={classes.help} as='span'
              helpText={t("components.ActionEditForm.randomAvatarBranch.dropChanceHelp")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </div>

        <Button className={classes.changeBtn} color='secondary' type='button' onClick={onMinus}>-</Button>
        <Input
          style={{width: "100px", height: "40px", margin: "0 6px"}}
          required
          min={MIN}
          max={MAX}
          type='number'
          name='dropChance'
          value={formState.values.dropChance!}
          onChange={formState.changeHandler}
        />
        <Button className={classes.changeBtn} color='secondary' type='button' onClick={onPlus}>+</Button>
      </div>

      <div className={classes.row}>
        <Button color="success" disabledColor="secondary" onClick={formState.submitHandler}>{t("common.save")}</Button>
      </div>
    </div>
  )
}


RandomAvatarActionEditForm.fragments = {
  root: gql`
    fragment RandomAvatarActionEditForm on RandomAvatarAction {
      id
      branches {
        id
        targetScenarioId
        dropChance
      }
    }
  `
}
