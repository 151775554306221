import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
  getScenarioById, getLessonScenariosLength
} from "../../../scenes/editor/lesson-edit.utils";

import {MAX_LESSON_LENGTH, MAX_MAIN_SCENARIO_LENGTH} from "../../../settings";
import {Scenario} from "../../../types";
import {TFunction} from "i18next";

import cn from "classnames";
import classes from "./LessonScenariosLength.module.css";

export type LessonScenariosLengthProps = React.ComponentProps<"div"> & {
  scenarios: Scenario[],
  currentScenarioId: Scenario["id"]
}
export default function LessonScenariosLength({
  scenarios, currentScenarioId, className, ...props
}: LessonScenariosLengthProps) {
  const {t} = useTranslation();

  const currentScenario = useMemo(() => (
    getScenarioById(scenarios, currentScenarioId)
  ), [scenarios, currentScenarioId]);

  const lessonLength = useMemo(() => (
    getLessonScenariosLength(scenarios)
  ), [scenarios]);

  const currentScenarioLength = currentScenario.actions.length;

  const lessonHaveSecondaryScenario = scenarios.length > 1;

  const isLessonTooLong = lessonLength > MAX_LESSON_LENGTH;

  const isMainScenarioTooLong = currentScenario.isMain && (currentScenarioLength > MAX_MAIN_SCENARIO_LENGTH);

  if (isLessonTooLong || isMainScenarioTooLong) {
    if (lessonHaveSecondaryScenario) {
      return (
        <div className={cn(classes.root, className)} {...props}>
          <span>
            {t("components.LessonScenariosLength.lessonLength", {
              length: getActionPlural(t, lessonLength),
              max: lessonHaveSecondaryScenario ? MAX_LESSON_LENGTH : MAX_MAIN_SCENARIO_LENGTH
            })}
          </span>
          {currentScenario.isMain && isMainScenarioTooLong ? (
            <span>
              {t("components.LessonScenariosLength.mainScenarioLength", {
                length: getActionPlural(t, currentScenarioLength),
                max: MAX_MAIN_SCENARIO_LENGTH
              })}
            </span>
          ) : (
            <span>
              {t("components.LessonScenariosLength.scenarioLength", {
                length: getActionPlural(t, currentScenarioLength)
              })}
            </span>
          )}
        </div>
      )
    } else {
      return (
        <div className={cn(classes.root, className)} {...props}>
          <span>
            {t("components.LessonScenariosLength.lessonLength", {
              length: getActionPlural(t, lessonLength),
              max: MAX_MAIN_SCENARIO_LENGTH
            })}
          </span>
        </div>
      )
    }
  }

  return null;
}

function getActionPlural(t: TFunction,count: number) {
  return t("types.plurals.action", {count})
}

