import React from "react";
import {useHistory} from "react-router";

type props = React.ComponentProps<"a">;

const ReactLink = React.forwardRef(function ({href, onClick, children, ...props}: props, ref: any) {
  const history = useHistory();

  const onClickHandler = React.useCallback((e) => {
    onClick && onClick(e);

    if (!e.isDefaultPrevented()) {
      e.preventDefault();

      if (href) {
        history.push(href);
      }
    }
  }, [href, onClick, history]);

  return <a ref={ref} href={href} onClick={onClickHandler} {...props}>{children}</a>;
});

export default ReactLink;
