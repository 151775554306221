import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import canNavigate from "../selectors/canNavigate";
import getRepeatFromIndex from "../selectors/getRepeatFromIndex";
import getCurrentMaxIndex from "../selectors/getCurrentMaxIndex";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function navigate(index: number) {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    if (!canNavigate(state)) {
      console.warn("navigate: wrong state");
      return;
    }

    if (index > getCurrentMaxIndex(state)!) {
      console.warn("navigate: unreachable action");
      return;
    }

    const newIndex = getRepeatFromIndex(state, index);

    if (newIndex === null) {
      return
    }

    analyticsSendEvent("playerNavigate", {
      sessionId: state.sessionId!,
      toUserInputIndex: index,
      toIndex: newIndex
    });

    dispatch({
      type: "navigate",
      payload: {
        "index": newIndex
      }
    });
  }
}
